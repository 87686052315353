import { db } from '../firebase-init'
import { readAs } from '../firebase-utils'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { PriceListEntry, ProductPrices } from '@2meters/shared'

export const getSubscriptionPrice = (
  countryCode: string,
  callback: (country: ProductPrices) => void,
  notFound: () => void
): (() => void) => {
  const currentCountry = query(collection(db, 'priceList'), where('countryCode', '==', countryCode))

  return onSnapshot(currentCountry, snapshot => {
    let country: PriceListEntry[] = snapshot.docs.map(doc => readAs(doc))

    if (country.length != 0 && country[0].prices.pro_monthly) callback(country[0].prices)
    else notFound()
  })
}

export default getSubscriptionPrice
