import { Box } from '@mui/material'
import DesktopLayout, { Container } from 'components/Layouts/Desktop'
import React from 'react'
import NotFoundPage from 'components/NotFoundPage'
import { CircularProgress } from 'components/Preloader'
import { PlaceHeader } from '../PlaceHeader'

const LoadingPage: React.FC<any> = ({ place, notFound }) => {
  return (
    <DesktopLayout>
      <PlaceHeader place={place} />
      <Container>
        <>
          {!notFound ? (
            <CircularProgress />
          ) : (
            <Box display='flex' justifyContent='center' textAlign='center' pt={6}>
              <NotFoundPage />
            </Box>
          )}
        </>
      </Container>
    </DesktopLayout>
  )
}

export default LoadingPage
