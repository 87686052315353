export default {
  'weekdays.0': 'Pazar',
  'weekdays.1': 'Pazartesi',
  'weekdays.2': 'Salı',
  'weekdays.3': 'Çarşamba',
  'weekdays.4': 'Perşembe',
  'weekdays.5': 'Cuma',
  'weekdays.6': 'Cumartesi',

  'wd.1': 'Mo',
  'wd.2': 'Tu',
  'wd.3': 'We',
  'wd.4': 'Th',
  'wd.5': 'Fr',
  'wd.6': 'Sa',
  'wd.7': 'Su',
  'wd.0': 'Su',

  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Українська',
  'tr-TRLang': 'Türkçe',
  EUR: 'Euro',
  USD: 'US Dollar',
  GBP: 'British Pound',
  INR: 'Indian Rupee',
  UAH: 'Ukrayna Grivnası',
  JPY: 'Japon Yeni',
  BTC: 'BTC',
  TRY: 'Türk Lirası',
  Rand: 'Güney Afrika Rand',
  generalError: 'Beklenmeyen bir hata oluştu',
  error: '{error}',
  enabled: 'Etkin',
  disabled: 'Engelli',
  appointments: 'Randevular',
  livequeues: 'Kuyruklar',
  livequeue: 'Kuyruk',
  info: 'Bilgi',
  ok: 'OK',
  today: 'Bugün',
  delete: 'Silme',
  remove: 'Kaldırmak',
  email: 'Email',
  role: 'Rol',
  admin: 'Yönetici',
  manager: 'Müdür',
  cancel: 'İptal',
  create: 'Oluştur',
  upgrade: 'Yükseltme',
  update: 'Güncelleme',
  invite: 'Davetiye',
  queueIn: 'Sıraya girin',
  edit: 'Düzenle',
  sure: 'Emin misin?',
  yes: 'Evet',
  no: 'Hayır',
  add: 'Ekle',
  save: 'Kaydet',
  go: 'Git',
  next: 'Next',
  leave: 'Ayrılın',
  yourAppointments: 'Randevularınız',
  yourAppointment: 'Randevularınız',
  address: 'Adres',
  copyToClipboard: 'Anlaşıldı',
  copiedToClipboard: 'Metin kopyalandı',
  showQRCode: 'QR kodunu göster',
  showPDF: 'PDF Göster',
  showNumberTickets: 'Bilet sayısı',
  clipboardCopyError: 'Pano kopyalama hatası',
  imageUploadFailed: 'Resim yükleme başarısız oldu',
  uploadLogo: 'Logo Yükle',
  thankYou: 'Teşekkürler',

  // Not Found Page
  'notfoundpage.header': 'Sayfa bulunamadı',
  'notfoundpage.message':
    "Belirtilen dosya bu web sitesinde bulunamadı. Lütfen URL'de hata olup olmadığını kontrol edin ve tekrar deneyin.",

  // Reset Password
  'resetPassword.title': 'Şifreyi sıfırla',
  'resetPassword.newPassword': 'Yeni şifre',
  'resetPassword.logout': 'Oturumu Kapat',
  'resetPassword.logout.title': 'Şifreniz değiştirildi',
  'resetPassword.logout.descritption': 'Lütfen hesabınıza tekrar giriş yapın',

  // Update Page
  'rolledOutUpdate.reload': 'Sayfayı Yeniden Yükle',
  'rolledOutUpdate.header': 'Güncelleme çıktı!',
  'rolledOutUpdate.message':
    'Yeni bir güncelleme kullanıma sunuldu. Lütfen sayfayı yeniden yükleyin!',

  //*************** VISITOR *******************/

  'dialogs.pageIsForMobile':
    'Bu sayfayı masaüstü tarayıcınızdan görüntülüyorsunuz.' +
    'Rezervasyon yaptığınız randevuya akıllı telefonunuzdan erişmek istiyorsanız lütfen bağlantıyı mobil tarayıcınızla açın.',
  'dialogs.pageIsForMobileTitle': 'Mobil cihazınızı kullanın',
  'dialogs.messagemodal.walkin': 'Canlı kuyruk',
  'dialogs.whatsapp.text':
    'Sizden önce kaç kişinin beklediğini veya bekleme sürenizin ne kadar olacağını öğrenmek istiyorsanız WhatsApp bildirimlerine bağlanmanızı tavsiye ederiz. WhatsApp\'ı açmak için "Bağlan" butonuna tıklayın ve hazırladığımız mesajı değiştirmeden bize gönderin.',
  'dialogs.whatsapp.connect': 'Bağlan',
  'dialogs.whatsapp.urlText':
    'Biletimin%20durum%20de%C4%9Fi%C5%9Fikli%C4%9Fi%20hakk%C4%B1nda%20bilgi%20i%C3%A7eren%20bildirimler%20almak%20istiyorum',

  // Main
  'home.queues': 'Canlı kuyruklarınız',
  'home.queue.showCode': 'Talep üzerine bu numarayı gösterin',
  'home.queue.peopleAhead': 'Önünüzdeki insanlar',
  'home.placesNearby': 'Yakındaki yerler',
  'home.noPlacenear': 'Yakınınızda kayıtlı bir yer yok',
  'home.enableGeoLocation':
    'Coğrafi konumu devre dışı bıraktınız. Bu özelliği kullanmak için lütfen tarayıcı ayarlarından etkinleştirin',
  'home.bookASpot': 'Randevu almak veya dijital kuyruğa katılmak için',
  'home.scanQR': 'QR kodunu tarayın',
  'home.enterShortUrl': 'veya yerin kısa kodunu girin',
  'home.enterShortcode': 'Kısa kodu girin',
  'home.selectNearby': 'veya sadece yakındaki yerler arasından seçim yapın',
  'home.useToBook': 'Rezervasyon yapmak için Arama veya QR kod tarayıcıyı kullanın',
  'home.findNearby': 'Yakınlarda bir yer bulun',
  'home.loginAsAdmin': 'Yer yöneticisi olarak giriş yapın',

  //LiveQueueTab
  'messaging.permissionGranted': 'Bildirim izni verildi',
  'messaging.permissionNotGranted':
    'Sıranız ilerledikçe uygulama arka plandayken herhangi bir bildirim almayacaksınız. Lütfen bildirimlere izin verin.',
  'messaging.tokenError': 'Bir şeyler ters gitti',
  'messaging.webPushNotificationsNotSupported':
    'Tarayıcınız web push bildirimlerini desteklemiyor. Hiçbir bildirim almayacaksınız. ' +
    'Sıranız ilerledikçe bildirimler. Lütfen aşağıda görüntülenen sıradaki kişi sayısını izleyin.',

  //AppointmentsPage
  'appointment.disabledButtonHint': 'Randevu alın',
  'appointment.dayOff': 'Mekan şu anda kapalıdır. Lütfen başka bir tarih seçin.',
  'appointment.visitorInformation': 'Bilgileriniz',
  'appointment.visitorInformation.hint': 'Lütfen kendiniz hakkında aşağıdaki bilgileri sağlayın',
  'appointment.customInformation': 'Ek bilgi',
  'appointment.customInformation.hint': 'Lütfen ek bilgi veriniz',
  'appointment.visitorInformation.numberOfVisitors': 'Ziyaretçi sayısı',
  'appointment.visitorInformation.numberOfVisitors.alert': 'Kaç kişi geliyor?',
  'appointment.visitorInformation.counter': 'Sayaç',
  'appointment.visitorInformation.notRequired': 'İletişim verisi gerekmez3',
  'appointment.visitorPhoneNumValidationStep': 'telefon numarası doğrulama',
  'appointment.visitorPhoneNumValidationStep.hint':
    'Size {telefon} adresine bir kod gönderdik. Lütfen aşağıdaki alanlara girin.',
  'appointment.waitToSendAgain': 'Kodu {sec} saniye içinde tekrar gönder',
  'appointment.sendAgain': 'Tekrar gönder',
  'appointment.services': 'Service',
  'appointment.services.hint': 'Almak istediğiniz hizmeti seçin',
  'appointment.order.hint':
    'Sipariş etmek istediğiniz ürünleri seçin. Sipariş başına ürün miktarında bir sınırlama olabileceğini lütfen unutmayın.',
  'appointment.order': 'Sipariş',
  'appointment.visitorSelectDate': 'Tarih seçiniz',
  'appointment.noMoreToday': 'Bugün için başka randevu yok',
  'appointment.add': 'Randevu alın',
  'appointment.cancelAppointment': 'Randevu iptal',
  'appointment.leaveQueue': 'Randevu iptal',
  'appointment.next': 'Sonraki: {step}',
  'appointment.next.contactInfo': 'İletişim bilgileri',
  'appointment.next.customFields': 'Ek bilgi',
  'appointment.next.timeslot': 'Zaman aralığı',
  'appointment.next.messagingPrompt': 'Bildirimler',
  'appointment.next.phoneNumValidation': 'Telefon Numarası Doğrulama',
  'appointment.next.book': 'Rezervasyona',
  'appointment.next.whatsapp': 'WhatsApp bildirimi',
  'appointment.next.numberOfVisitors': 'Ziyaretçi sayısı',
  'appointment.next.queueIn': 'Şimdi sıraya girin',
  'appointment.next.services': 'Hizmetler',
  'appointment.next.products': 'Ürünler',
  'appointment.next.submit': 'Gönder',
  'appointment.messagingPrompt': 'Bildirimleri etkinleştirin',
  'appointment.submit': 'İstek gönder',
  'appointment.submit.error': 'Talebinizi gönderirken bir hata oluştu',
  'appointment.submit.standby': 'Bir dakika lütfen.',
  'appointment.cancel': 'İptal',
  'appointment.back': 'Geri',
  'appointment.chooseTime': 'Zaman seçin',
  'appointment.makeAnother': 'Başka bir randevu alın',
  'appointment.queueInAgain': 'Tekrar sıraya girin',
  'appointment.nickname': 'Çağrılabileceğiniz bir isim ekleyin',
  'appointment.group': 'Kaç kişi geliyor',
  'appointment.openHours': 'Açık saatler:',
  'appointment.contacts': 'İletişim:',
  'appointment.description': 'Genel açıklama:',
  'appointment.address': 'Adres',
  'appointment.comment': 'Yorum',
  'appointment.birthDate': 'Doğum tarihi',
  'appointment.visitType': 'Hizmet',
  'appointment.shareName': 'Sizin adınız',
  'appointment.sharePhone': 'Telefon numarası',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Arama',
  'appointment.sharePhone.searchNotFound': 'Gösterilecek giriş yok',
  'appointment.sharePhone.inValid':
    'Telefon numarası geçersiz. Lütfen numaranızı uluslararası formatta girmeyi deneyin: +1123456789',
  'appointment.shareEmail': 'Email',
  'appointment.shareAddress': 'Adres',
  'appointment.shareBirthDate': 'Doğum tarihi',
  'appointment.sure': 'Emin misin?',
  'appointment.vaccinationStatus': 'Covid-19 aşılama durumu',
  'appointment.not_vaccinated': 'Aşılanmamış',
  'appointment.partially_vaccinated': 'Kısmen aşılanmış',
  'appointment.fully_vaccinated': 'Tamamen aşılanmış',
  'appointment.time': 'Tarih ve saat',
  'appointment.invitedAt': 'Davetli',
  'appointment.queuedAt': 'Kuyrukta',
  'appointment.closedTime': 'Kapalı. Açılıyor',
  'appointment.opensToday': 'Kapalı. Bugün açılıyor',
  'appointment.ticket': 'Bilet',
  'appointment.position': 'Sizden önce',
  'appointment.queueLength': 'Kuyrukta',
  'appointment.eta': 'tahmini süre',
  'appointment.pleaseProceed': 'Lütfen önümüzdeki 5 dakika içinde devam edin',
  'appointment.header': 'Biletleriniz',
  'appointment.estimatedWaitingTime': 'Tahmini bekleme süresi',
  'appointment.waitingTime': 'Bekleme süresi',
  'appointment.min': 'min',
  'appointment.people': 'insanlar',
  'appointment.error.invalidFormFields':
    'Devam etmek için lütfen vurgulanan form alanlarını doldurun',
  'appointment.error.invalidVisitType': 'Lütfen devam etmek için ziyaretinizin nedenini belirtin',
  'appointment.error.noSlotSelected': 'Seçili zaman aralığı yok',
  'appointment.confirmation.preliminaryWarning':
    'Bu rezervasyon, size bir onay mektubu gönderilene kadar ön rezervasyon niteliğindedir.',
  'appointment.confirmation.rejectedWarningAppointment':
    'Üzgünüz, ancak yönetici randevunuzu iptal etti. Başka bir randevu almayı deneyin.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'Üzgünüz, ancak yönetici biletinizi iptal etti. Tekrar sıraya girmeyi deneyin.',
  'appointment.products.error.maxOrder':
    'Sipariş en fazla {miktar} ürün içerebilir. Lütfen miktarı azaltın.',
  'appointment.products.price': 'Fiyat',
  'appointment.products.inStock': 'Stokta var',
  'appointment.products.outofstock': 'Stokta yok',
  'appointment.confirmation.appointmentHasPreference':
    'Önceden rezerve edilen randevuların canlı sıraya göre önceliği olabileceğini lütfen unutmayın.',

  'appointment.disabled': 'Trandevu sistemi yer yöneticisi tarafından devre dışı bırakılır',

  'appointment.messaging.pending':
    'Randevu durumu güncellemeleri hakkında sizi bilgilendirmek için anlık bildirimleri etkinleştirmeniz gerekir. ' +
    'Kabul ediyorsanız <b>izin ver</b> seçeneğine tıklayın.',
  'appointment.messaging.denied':
    'Bildirim gönderme izinleri etkinleştirilmedi. Anlık bildirimler almayacaksınız',
  'appointment.messaging.granted': 'Anlık bildirim gönderme izni verildi. Teşekkürler!',

  //LiveQueuetab
  'livequeue.peopleNum': 'Kuyruktaki kişi sayısı',
  'livequeue.yourNumberInQueue': 'Kuyruktaki konumunuz',
  'livequeue.youAreNext': 'Sırada ilk siz varsınız',
  'livequeue.leave': 'Kuyruktan ayrılın',
  'livequeue.showAtRequest': 'İstek üzerine bu bileti göster',
  'livequeue.submit': 'İstek üzerine bu bileti göster',
  'livequeue.liveQueuePaused':
    'Dijital kuyruk beklemede. Lütfen birkaç dakika içinde sıraya girmeyi deneyin',
  'livequeue.liveQueueOpenHours':
    'Çalışma saatleri dışında sıraya girmeye çalışıyorsunuz. Lütfen daha sonra geri dönün',
  'livequeue.disabled': 'Dijital kuyruk sistemi yer yöneticisi tarafından devre dışı bırakılır',
  'livequeue.queueTooLong':
    'Yönetici kuyruktaki kişi sayısını sınırlandırmıştır. Lütfen daha sonra tekrar deneyin.',

  //MonitorView
  'monitor.pleaseProceed': 'Lütfen devam edin',
  'monitor.livequeue': 'Canlı kuyruk',
  'monitor.peopleInQueue': 'Daha Fazla Bilet',
  'monitor.appointments': 'Planlanmış randevular',
  'monitor.appointment.type.appointment': 'randevu',
  'monitor.appointment.type.livequeue': 'canlı kuyruk',

  'monitor.stats': 'Bugünkü doluluk oranı',
  'monitor.pleaseScanAppointments': 'Randevu almak için bu QR kodunu tarayın',
  'monitor.pleaseScanLivequeue': 'Sıraya girmek için bu QR kodunu tarayın',
  'monitor.pleaseScanIntegrated': 'Sıraya girmek veya randevu almak için bu QR kodunu tarayın',
  'monitor.followThisLink': "Ya da sadece bu URL'yi takip edin",

  // Notifications
  'notifications.title': 'Bildirimler',
  'notifications.informationBanner.sms': 'SMS gönderildi',
  'notifications.informationBanner.whatsapp': 'Whatsapp gönderildi',
  'notifications.progressBar.freeSms': 'Ücretsiz katman uygun',
  'notifications.progressBar.exhaustedSms': 'Ücretsiz sms havuzu tükendi',
  'notifications.progressBar.exhaustedWhatsapp': 'Ücretsiz whatsapp havuzu tükendi',
  'notifications.informationBanner.totalSmsCost': 'Toplam SMS maliyeti',
  'notifications.informationBanner.totalWhatsappCost': 'Toplam Whatsapp maliyeti',
  'notifications.table.recipient': 'Alıcı',
  'notifications.table.date': 'Tarih',
  'notifications.table.time': 'Zaman',
  'notifications.table.place': 'Yer',
  'notifications.table.type': 'Tip',
  'notifications.table.price': 'Fiyat',
  'notifications.table.country': 'Ülke',
  'notifications.table.message': 'Mesaj',
  'notifications.empty.header':
    'Yerleriniz verilen süre içinde henüz herhangi bir ücretli bildirim oluşturmadı',
  'notifications.filter.startDate': 'Başlangıç tarihi',
  'notifications.filter.endDate': 'Bitiş tarihi',
  'notifications.exportTable.status': 'Durum',
  'notifications.exportTable.type': 'Tip',
  'notifications.exportTable.isEmpty': 'boştur',

  // Export Appointments
  'exportAppointments.tickets': 'Biletler',
  'exportAppointments.tag': 'Etiket',
  'exportAppointments.date': 'Tarih',
  'exportAppointments.time': 'Zaman',
  'exportAppointments.type': 'Tip',
  'exportAppointments.service': 'Hizmet',
  'exportAppointments.name': 'İsim',
  'exportAppointments.status': 'Durum',
  'exportAppointments.statusChangeDate': 'Durum değişikliği tarihi',
  'exportAppointments.statusChangeTime': 'Durum değişim süresi',
  'exportAppointments.phone': 'Telefon',
  'exportAppointments.email': 'Email',
  'exportAppointments.address': 'Adres',
  'exportAppointments.birthDate': 'DoğumTarihi',
  'exportAppointments.comments': 'Yorumlar',
  'exportAppointments.appointment': 'Randevu',
  'exportAppointments.queue': 'Kuyruk',

  //Cookies Concent
  'cookies.concent':
    'Bu web sitesi yalnızca teknik olarak gerekli olan ve seçimi kaldırılamayan çerezleri kullanır. Gerekli çerezler, sayfada gezinme ve web sitesinin güvenli alanlarına erişim gibi temel işlevleri etkinleştirerek bir web sitesini kullanılabilir hale getirmeye yardımcı olur. Web sitesi bu çerezler olmadan düzgün çalışamaz.',
  'cookies.ok': 'Anlamak',

  'dialogs.pageIsForDesktop':
    'Bu sayfayı mobil tarayıcınızdan görüntülüyorsunuz. ' +
    'Bu sayfa bir masaüstü bilgisayar için optimize edilmiştir. Bazı işlevler kullanılamayabilir ' +
    've sayfa hatalarla oluşturulabilir.',
  'dialogs.pageIsForDesktopTitle': 'Masaüstü Görünümü',
  'dialogs.reject': 'Reddet',
  'dialogs.confirm': 'Onayla',

  //*************** ADMIN *******************/

  trial: 'Denemeye başlayın',

  // Login
  'login.header': "2meters'ı tercih ettiğiniz için teşekkür ederiz! ",
  'login.header2': 'Hizmetimizi size yardımcı olmak için oluşturduk',
  'login.text1': 'Müşterilerinizin seveceği rahat bir çevrimiçi randevu rezervasyon sistemi kurun',
  'login.text2':
    'Nerede olursa olsun can sıkıcı kuyruklardan kurtularak eski müşterileri elde tutmak ve yenilerini çekmek için mağazanız veya hizmetiniz için yenilikçi bir dijital kuyruk çözümü oluşturun',
  'login.text3':
    'veya çözümümüz her iki özelliği de entegre ettiğinden her ikisini de yapmak için. Kuyrukta bekleme süresi çok uzunsa, gelen müşterinizin randevu almasına izin verin',
  'login.google': 'Google ile giriş yapın',
  'login.facebook': 'Facebook ile giriş yapın',
  'login.withPassword': 'E-posta ile Giriş Yapın',
  'login.newUser': "2Meters'a kaydolun",
  'login.existingUser': "2Meters'ta oturum açın",
  'login.signUp': 'Kaydolun',
  'login.signIn': 'Oturum Aç',
  'login.email': 'E-posta',
  'login.email.placeholder': 'example@domain.com',
  'login.password': 'Şifre',
  'login.password.placeholder': '8+ karakterler',
  'login.passwordRepeat': 'Şifreyi tekrarla',
  'login.passwordRepeat.placeholder': '8+ karakterler',
  'login.forgotPassword': 'Şifrenizi mi unuttunuz?',
  'login.passwordsDontMatch': 'Parolalar eşleşmiyor',
  'login.sendRecoveryEmail':
    'Şifrenizi değiştirmeniz için size bir bağlantı göndereceğiz. Lütfen kayıt olduğunuz e-postayı bize bildirin',
  'login.checkEmail': 'Lütfen e-postanızı kontrol edin ve talimatları izleyin',
  'login.resetPassword': 'Şifre Sıfırlama',
  'login.recoveryEmailSent': 'Kurtarma e-postası {email} adresine gönderildi',
  'login.passwordTooShort': 'Şifre en az 8 karakter uzunluğunda olmalıdır',
  'login.dontHaveAccount': 'Hesabınız yok mu?',
  'login.alreadyHaveAccount': 'Zaten bir hesabınız var mı?',
  'login.enterDetails': 'Ayrıntıları aşağıya girin',
  'login.alreadyRegistered': 'Oturum aç',
  'login.getStarted': 'Başlayın',
  'login.acceptTerms': 'Kabul ediyorum.',
  'login.termsAndConditions': 'Şartlar ve Koşullar',
  'login.termsNotChecked': 'Devam etmek için lütfen Hüküm ve Koşullarımızı kabul edin',
  'login.acceptPrivacyPolicy': 'Katılıyorum.',
  'login.privacyPolicy': 'Gizlilik Politikası',
  'login.privacyPolicyNotChecked': 'Devam etmek için lütfen Gizlilik Politikamızı kabul edin',
  'login.missingTermsConsent.title': 'Sadece bir adım daha',
  'login.missingTermsConsent.desc':
    'Hizmetimize erişmek için lütfen Hüküm ve Koşullarımızı ve Gizlilik Politikamızı kabul edin. Teşekkür ederiz!',

  'customerProfileForm.firstName': 'İlk İsim',
  'customerProfileForm.lastName': 'Soyadı',
  'customerProfileForm.email': 'E-posta',
  'customerProfileForm.organization': 'Organizasyon adı',
  'customerProfileForm.country': 'Ülke',
  'customerProfileForm.streetAddress': 'Sokak adresi',
  'customerProfileForm.postalCode': 'Posta kodu',
  'customerProfileForm.city': 'Şehir',
  'customerProfileForm.vatId': 'KDV Kimliği',
  'customerProfileForm.placeholder.firstName': 'Lütfen adınızı giriniz',
  'customerProfileForm.placeholder.lastName': 'Lütfen soyadınızı giriniz',
  'customerProfileForm.placeholder.email': 'Lütfen e-posta girin',
  'customerProfileForm.placeholder.organization': 'lütfen kuruluş adını giriniz',
  'customerProfileForm.placeholder.country': 'Lütfen ülke giriniz',
  'customerProfileForm.placeholder.streetAddress': 'Lütfen sokak adresini girin',
  'customerProfileForm.placeholder.postalCode': 'Lütfen posta kodunu girin',
  'customerProfileForm.placeholder.city': 'Lütfen şehir giriniz',
  'customerProfileForm.placeholder.vatId': 'Lütfen KDV Kimliğini girin',
  'customerProfileForm.industry': 'Endüstri',
  'customerProfileForm.industry.governmental': 'Hükümet',
  'customerProfileForm.industry.education': 'Eğitim',
  'customerProfileForm.industry.healthcare': 'Sağlık Hizmetleri',
  'customerProfileForm.industry.retail': 'Perakende',
  'customerProfileForm.industry.wholesale': 'Toptan Satış',
  'customerProfileForm.industry.rental': 'Kiralık',
  'customerProfileForm.industry.banking': 'Bankacılık/finans',
  'customerProfileForm.industry.transportation': 'Ulaşım',
  'customerProfileForm.industry.hospitality': 'Misafirperverlik',
  'customerProfileForm.industry.hotel': 'Otel ve eğlence',
  'customerProfileForm.industry.travel': 'Seyahat ve turizm',
  'customerProfileForm.industry.other': 'Diğer',

  // Onboarding
  'onboarding.wait':
    'Kullanıcı profilinizi oluştururken ve gerekli her şeyi ayarlarken lütfen bekleyin',
  'onboarding.createPlace.wait': 'Lütfen bekleyin, biz yüklerken...',
  'onboarding.loading': 'Yükleniyor...',
  'onboarding.back': 'Geri',
  'onboarding.next': 'Sonraki',
  'onboarding.skip': 'Boşluğa atla',
  // Onboarding: Step 1
  'onboarding.step1.header': '2Meters ile Randevu Alma',
  'onboarding.step1.text.p1':
    'Randevuları etkinleştirmek için, temel verileri doldurarak yerinizi ayarlamanız yeterlidir:',
  'onboarding.step1.text.list1.item1': 'İsim ve Konum',
  'onboarding.step1.text.list1.item2': 'Çalışma günleri ve saatleri',
  'onboarding.step1.text.list1.item3': 'Tipik randevu süreleri',
  'onboarding.step1.text.p2':
    'Daha da iyi hizmet sunmak için, sunduğunuz hizmetlerin veya ürünlerin bir listesini oluşturabilir, böylece müşterilerin ziyaret nedenlerini önceden size bildirmelerini sağlayabilirsiniz.',
  'onboarding.step1.text.p3':
    'Programınız üzerinde tam kontrol sahibi olmak için, müşterileriniz tarafından önceden rezerve edilen randevuları onaylamanıza veya reddetmenize olanak tanıyan bir özelliği açabilirsiniz.',
  // Onboarding: Step 2
  'onboarding.step2.header': '2Meters ile Randevu Alma',
  'onboarding.step2.text.p1':
    'Kurulumdan sonra, verilen bağlantıyı bir E-postaya kopyalayın veya müşterilerinizin tıklayabileceği veya üzerine dokunabileceği web sitenize koyun.',
  'onboarding.step2.text.p2':
    'Alternatif olarak, interaktif arayüzümüzü bir kod parçacığı aracılığıyla web sitenize entegre edebilirsiniz.',
  // Onboarding: Step 3
  'onboarding.step3.header': '2Meters ile Randevu Alma',
  'onboarding.step3.text.p1':
    'İlk randevu müşterilerinize hoş geldiniz! Akıllı telefonlarına kaydettikleri elektronik biletleri göstermelerini isteyerek onları kolayca tanıyabilirsiniz.',
  'onboarding.step3.text.p2':
    'Randevulara ek olarak gelen müşterilerle de ilgilenmeniz gerekiyorsa, dijital kuyruk işlevimizin her iki müşteri akışını da sorunsuz bir şekilde birleştirdiğini duymak hoşunuza gidecektir. Ama nasıl çalışıyor?',
  // Onboarding: Step 4
  'onboarding.step4.header': '2Meters ile Kuyrukları Ortadan Kaldırın',
  'onboarding.step4.text.p1':
    'Dijital kuyruğumuz, müşterilerinizin modern bir hizmet konseptinden tam olarak beklediklerini sunar - sahipleri yürüyüşe çıkabilir veya bir fincan kahve içebilirken bekleme işini akıllı telefonlar yapar.',
  'onboarding.step4.text.p2':
    'Sıra ilerledikçe müşterinizi bilgilendiriyoruz, böylece tam zamanında gelebiliyorlar.',
  'onboarding.step4.text.p3':
    'Dijital bir kuyruk oluşturmak için yerinizi oluştururken bu işlevi etkinleştirmeniz yeterlidir.',
  // Onboarding: Step 5
  'onboarding.step5.header': '2Meters ile Kuyrukları Ortadan Kaldırın',
  'onboarding.step5.text.p1':
    'Sağlanan QR kodu ile bir PDF yazdırın ve müşterilerinizin dijital olarak sıraya girmesini istediğiniz herhangi bir yere koyun.',
  'onboarding.step5.text.p2':
    'Yaklaşan ve halihazırda serviste olan tüm biletleri göstermek için bir ekran kurun ve mekanınızın modern bir silikon vadisi servis merkezi gibi görünmesini ve hissettirmesini sağlayın.',
  // Onboarding: Step 6
  'onboarding.step6.header': '2Meters ile Kuyrukları Ortadan Kaldırın',
  'onboarding.step6.text.p1':
    'Keşfedilecek daha pek çok özellik var. Dijital kuyruğunuzu kişisel durumunuza uyacak şekilde nasıl ayarlayabileceğiniz konusunda ek bilgiye ihtiyacınız varsa bizimle iletişime geçin. Sizin için önceden yapılandırılmış bazı şablonlar hazırladık. Bir şablon seçin ve ihtiyaçlarınıza göre ayarlayın veya yepyeni bir yer oluşturun. Ne yapmak istersiniz?',
  'onboarding.step6.btn1': 'Canlı Kuyruk ile Demo Yeri Oluşturma (Berber Dükkanı)',
  'onboarding.step6.btn2': 'Randevularla Demo Yeri Oluşturun (Berber Dükkanı)',
  'onboarding.step6.btn3': 'Bir randevu sistemi veya dijital bir kuyruk oluşturun',

  'onboarding.template.barbershop.name': 'Demo Place (Berber dükkanı)',
  'onboarding.template.barbershop.description':
    'Sakal bir trenddir. Yüzdeki kıllar ancak iyi bir bakım ve mükemmel bir şekillendirme ile gerçekten güzelleşir. Erkeklerin rahatlayabileceği ve kendi aralarında olabileceği bir yer açıyoruz. Saç / sakal bakımı ve şekillendirme konusunda bireysel tavsiyeler bekleyebilirsiniz, ancak bakımlı eller ve ayaklar da bunun bir parçasıdır! Klasik bir atmosferde - şık deri koltuklar, ahşap aksesuarlar, tuğla duvarlar ve entegre bir viski ve puro salonu arasında - törpüleme, peeling ve tıraş var.',
  'onboarding.template.barbershop.service1': 'Normal saç kesimi',
  'onboarding.template.barbershop.service2': 'Sakal kesimi',
  'onboarding.template.barbershop.service3': 'Boya',
  'onboarding.template.barbershop.service4': 'Kontur kesim',
  'onboarding.template.default.service1': 'Servisim 1',
  'onboarding.template.default.service2': 'Servisim 2',
  'onboarding.template.covid.name': 'Covid Test Merkezi (Şablon)',
  'onboarding.template.popup.name': 'Walk-In PopUp Dükkanı (Şablon)',
  'onboarding.template.shopping.name': 'Randevu Alışverişi (Şablon)',
  'onboarding.template.restaraunt.name': 'Restaraunt (Şablon)',

  // Place
  'admin.place.settings': 'Ayarlar',
  'admin.place.editSetting': 'Düzenleme Yeri',
  'admin.place.editManagers': 'Yöneticileri Düzenle',
  'admin.place.navigation.overview': 'Genel Bakış',
  'admin.place.navigation.dashboard': 'Gösterge Tablosu',
  'admin.place.navigation.queues': 'Gösterge Tablosu',
  'admin.place.navigation.monitor': 'Monitör',
  'admin.place.navigation.billing': 'Faturalandırma',
  'admin.place.navigation.backToPlaces': 'Mekanlara Geri Dön',
  'admin.place.navigation.back': 'Geri',
  'admin.place.averageDuration': 'Slot Süresi (dakika)',
  'admin.place.capacity.help': 'Mekanınıza aynı anda kaç müşteri girebilir?',
  'admin.place.capacity': 'Mağaza kapasitesi',
  'admin.place.closingTime': 'Kapanış saati',
  'admin.place.openingTime': 'Açılış saati',
  'admin.place.openingDays': 'Açılış günleri',
  'admin.place.openingDate': 'Kampanya başlangıç tarihi',
  'admin.place.openingDate.help':
    "2Meters'ı sınırlı bir süre için kullanmayı düşünüyorsanız, etkinleştirileceği tarihi girin",
  'admin.place.closingDate': 'Kampanya bitiş tarihi',
  'admin.place.closingDate.help':
    "2Meters'ı sınırlı bir süre için kullanmayı planlıyorsanız, sistemin kapatılacağı tarihi girin",
  'admin.place.contactEmail': 'Cyer yöneti̇ci̇si̇ni̇n i̇leti̇şi̇m e-posta adresi̇',
  'admin.place.contactEmail.help':
    'Ziyaretçilerinize göndereceğimiz e-posta bildirimlerinde iletişim e-postanızın görünmesini istiyorsanız bu alanı doldurabilirsiniz (yalnızca bu kurulumda e-posta adreslerini vermelerini isteyecekseniz)',
  'admin.place.contactPhoneNumber': 'Yer yöneticisinin iletişim telefon numarası',
  'admin.place.contactPhoneNumber.help':
    'Ziyaretçilerinize göndereceğimiz e-posta bildirimlerinde iletişim telefon numaranızın görünmesini istiyorsanız bu alanı doldurabilirsiniz (yalnızca bu kurulumda e-posta adreslerini vermelerini isteyecekseniz)',
  'admin.place.description': 'Lütfen mekanınız ve hizmetleriniz hakkında kısa bir açıklama yapın.',
  'admin.place.description.help': 'Müşterileriniz bu açıklamayı görecek',
  'admin.place.timezone': 'Saat dilimi',
  'admin.place.noOptions': 'Seçenek yok',
  'admin.place.toVisitors': 'Ziyaretçi sayfası',
  'admin.place.printQR': 'QR yazdır',
  'admin.place.shortUrl':
    'Ziyaretçilerinizin sayfanıza hızlı bir şekilde erişmesini sağlamak için kısa bir kod seçin.',
  'admin.place.shortUrl.error': 'Bu kısa url zaten alınmış. Lütfen farklı bir tane deneyin',
  'admin.place.shortUrl.help':
    'Barbekü yeriniz için örneğin "bbq" seçerseniz, app.2meters.app/p/bbq altındaki herhangi bir tarayıcıdan erişilebilir olacaktır.',
  'admin.place.shortUrlInfo': 'Yerinize bağlantı',
  'admin.place.appointmentUrlInfo': 'Randevu rezervasyon sürecine bağlantı',
  'admin.place.livequeueUrlInfo': 'Canlı kuyruğa bağlantı',
  'admin.place.monitorUrlInfo': 'Monitör görünümüne bağlantı',
  'admin.place.qrCode': 'Yeriniz için QR kodu',
  'admin.place.address': 'Bulunduğunuz yerin adresi',
  'admin.place.yourAppointment': 'Randevunuz',
  'admin.place.appointments.delete': 'Silme',
  'admin.place.appointments.cancel': 'İptal',
  'admin.place.addAppointmentDialogTitle': 'Randevu ekle',
  'admin.place.addAppointmentDialogDescription':
    'Randevuya bir ziyaretçi takma adı ve kısa bir açıklama ekleyebilirsiniz',
  'admin.place.appointmentNickname': 'Takma ad',
  'admin.place.appointmentDescription': 'Açıklama',
  'admin.place.visitType': 'Sunduğunuz hizmetlerin bir listesini sağlayın',
  'admin.place.visitType.help':
    'Müşterilerinizin randevu alırken bu listeden hizmeti seçmesi gerekecektir.',
  'admin.place.managers': 'Yer Yöneticileri Listesi',
  'admin.place.managers.help':
    'Çalışanlarınıza yönetici rolleri atamak için E-Posta adreslerini girin. Her E-Postayı Enter ile onaylayın.',
  'admin.place.titles.settings': 'Mekana Genel Bakış',
  'admin.place.wizard.titles.details': 'Yer Detayları',
  'admin.place.wizard.titles.visitorData': 'Ziyaretçiler',
  'admin.place.wizard.titles.inventory': 'Envanteriniz',
  'admin.place.wizard.titles.queue': 'Canlı Kuyruk',
  'admin.place.wizard.titles.appointments': 'Program',
  'admin.place.wizard.titles.access': 'Erişim',
  'admin.place.wizard.titles.plans': 'Planlar',
  'admin.place.wizard.details': 'Ayrıntılar',
  'admin.place.wizard.visitorData': 'Ziyaretçiler',
  'admin.place.wizard.inventory': 'Envanter',
  'admin.place.wizard.settings': 'Ayarlar',
  'admin.place.wizard.schedule': 'Program',
  'admin.place.wizard.access': 'Erişim',
  'admin.place.wizard.plans': 'Planlar',
  'plans.subscription.cancelAt': 'Şu tarihe kadar geçerlidir',
  'admin.place.wizard.actions.next': 'Sonraki',
  'admin.place.wizard.actions.trial': 'Deneme Başlat',
  'admin.place.wizard.actions.finish': 'Bitir',
  'admin.place.wizard.actions.saveAll': 'Kaydet',
  'admin.place.wizard.actions.back': 'Geri',
  'admin.place.wizard.actions.cancel': 'İptal',
  'admin.place.wizard.actions.agree': 'Kabul ediyorum',
  'admin.place.wizard.actions.create': 'Oluştur',
  'admin.place.wizard.requiredFields': 'Lütfen gerekli tüm alanları doldurun',
  'admin.place.wizard.smsModal.title': 'SMS Bildirimleri',
  'admin.place.wizard.whatsappStep.title':
    'WhatsApp üzerinden bildirim almayı kabul ediyor musunuz?',
  'admin.place.wizard.whatsappStep.accept': 'Kabul Et',
  'admin.place.wizard.whatsAppModal.title': 'WhatsApp Bildirimleri',
  'admin.place.wizard.whatsAppModal.description':
    'WhatsApp bildirimlerini açtığınızda ek ücretler uygulanacağını lütfen unutmayın. Size test için kullanabileceğiniz 100 ücretsiz WhatsApp mesajı veriyoruz. Ancak, bunun üzerinde gönderilen herhangi bir WhatsApp mesajı için, WhatsApp alıcılarınızın geldiği ülkeye bağlı olarak sizden bir ücret talep edeceğiz. Ek faturalar oluşturacağız ve bunları her ay ayrı ayrı göndereceğiz. Bu özelliği istediğiniz zaman kapatabilirsiniz. Fiyatlandırma hakkında ayrıntılı bilgiye ihtiyacınız varsa, lütfen satış departmanımızla şu adresten iletişime geçin ',
  'admin.place.wizard.smsModal.description':
    "Lütfen SMS bildirimlerini açtığınızda ek ücretlerin uygulanacağını göz önünde bulundurmanız gerektiğini unutmayın. Size test için kullanabileceğiniz 100 ücretsiz SMS veriyoruz. Ancak, bunun üzerinde gönderilen tüm SMS'ler için, ziyaretçilerinizin geldiği ülkedeki yerel mobil sağlayıcıların fiyatlarına bağlı ve karşılaştırılabilir bir ücret talep edeceğiz. Ek faturalar oluşturacağız ve bunları her ay ayrı ayrı göndereceğiz. Bu özelliği istediğiniz zaman kapatabilirsiniz. Fiyatlandırma hakkında ayrıntılı bilgiye ihtiyacınız varsa, lütfen satış departmanımızla şu adresten iletişime geçin ",
  'admin.place.settings.text': 'Bu sayfada yeriniz için farklı özellikleri etkinleştirebilirsiniz',
  'admin.place.inventory.text':
    'Herhangi bir ürün satıyorsanız ve ziyaretçilerinizin bunları sipariş etmesini istiyorsanız, bunları envanterinize eklemeniz yeterlidir. Envanterin etkinleştirilmesi, uygulamanın ziyaretçi bölümünde bir ziyaretçinin öğelerinizden herhangi birini rezerve edebileceği veya satın alabileceği ek bir adım oluşturur. Ürünleriniz için stok yapılandırabilir veya sınırsız olarak ayarlayabilirsiniz. Ayrıca, tüm müşterilerinizin paylarını alacağından emin olmak için bir ele giden maksimum ürün miktarını kısıtlamayı da seçebilirsiniz.',
  'admin.place.visitorData.text':
    'Tam kullanıcı anonimliğine izin vermeyi bilinçli olarak seçtik, ancak bazı kullanım durumlarında hizmetinizi sağlamak için veri toplamak gerekli olabilir. Burada, randevu aldıklarında veya sıraya girdiklerinde müşterileriniz hakkında ne bilmek istediğinizi seçebilirsiniz. E-postalarını toplamayı seçerseniz, onlara müşteri dostu olabilecek e-posta bildirimleri de gönderebileceğiz. Herhangi bir seçeneği seçtiyseniz, müşterinizin bıraktığı kişisel verilerin ziyaretten iki gün sonra sunucularımızdan silineceğini unutmayın.',
  'admin.place.appointments.text':
    'Çalışma saatlerinizi ve hizmet programınızı ayarlayın. Dijital kuyruk ve randevu özelliklerimizin nasıl çalıştığını tanımlar. İki tür program yönetimi sağlıyoruz. Basit ve Gelişmiş. Basit ile yalnızca çalışma günlerinizi ve saatlerinizi ayarlarsınız. Gelişmiş olanla, program üzerinde tam kontrole sahip olursunuz.',
  'admin.place.access.text':
    'Çalışanlarınız varsa, kimlik bilgilerinizi paylaşmadan sayaç görünümüne erişmelerini isteyebilirsiniz. Sayaç görünümünden çalışanlar randevuları ve canlı kuyruğu yönetebilir. Ayarları veya yerinizin diğer önemli özelliklerini değiştiremezler. Çalışanlarınıza yönetici rolleri atamak için lütfen e-posta adreslerini aşağıya girin.',
  'admin.place.cancelDialog': 'İptal',
  'admin.place.rte.text': 'Metin',
  'admin.place.rte.link': 'Bağlantı',
  'admin.place.rte.addLink': 'Bağlantı Ekle',
  'admin.place.rte.remove': 'Kaldır',
  'admin.place.rte.save': 'Kaydet',
  'admin.place.addAppointmentManually': 'Ekle',
  'admin.place.livequeue.hint': 'Yerinizde dijital kuyruğu etkinleştirin',
  'admin.place.businessName': 'Evinizin adı nedir?',
  'admin.place.fullscreen': 'Tam Ekran',
  'admin.place.monitor': 'Monitör',
  'admin.account.billingLog': 'Faturalama Günlüğü',
  'admin.account.account': 'Hesap',
  'admin.account.signOut': 'Çıkış yapın',
  'admin.account.billing': 'Planlar ve Faturalar',
  'admin.place.form.inventory': 'Mekanınız hangi ürünleri sağlıyor?',
  'admin.place.form.inventory.help':
    'Ziyaretçiniz randevu alırken veya dijital kuyrukta check-in yaparken bir ürün seçebilecektir. Herhangi bir ürün satmıyorsanız bu alanı boş bırakın.',

  'admin.place.form.settings.appointments.help':
    'Randevular, müşterilerinizin ziyaretleri için önceden tanımlanmış bir zaman dilimi belirlemelerine olanak tanır',
  'admin.place.form.settings.appointments.label': 'Randevuları etkinleştirin',
  'admin.place.form.settings.livequeue.label': 'Canlı kuyruğu etkinleştir',
  'admin.place.form.settings.livequeue.help':
    'Randevulardan farklı olarak canlı kuyruk, müşterilerinizin, gelen müşterilerin genellikle yaptığı gibi, tanımlanmış bir zaman olmadan kuyrukta bir yer belirlemelerine olanak tanır',
  'admin.place.form.settings.livequeue.maxLength.label': 'Maksimum kuyruk uzunluğu',
  'admin.place.form.settings.livequeue.maxLength.help':
    'Kuyruk uzunluğu bu sayıya ulaşırsa hiçbir yeni müşteri kuyruğa giremez. Sınırsız için değeri kaldırın',
  'admin.place.form.settings.livequeue.openingHours.label': 'Sadece açılış saatlerinde',
  'admin.place.form.settings.livequeue.openingHours.help':
    'Etkinleştirildiğinde, müşterilerinizin çalışma saatleriniz içinde sıraya girmeleri kısıtlanacaktır',
  'admin.place.form.settings.appointment.confirmation.label': 'Onay gerektirir',
  'admin.place.form.settings.appointment.confirmation.help':
    'Randevuları manuel olarak onaylamak veya reddetmek isteyip istemediğinizi kontrol edin. Müşterileriniz önceki adımlarda tanımladığınız bildirim kanalları tarafından bilgilendirilecektir',

  'admin.place.form.settings.automation.help': 'Otomasyon',
  'admin.place.form.settings.automation.processing.help':
    'Elle temizlemeyi tercih ediyorsanız boş bırakın',
  'admin.place.form.settings.automation.processing.label':
    'min. Davet edilen biletler bu süreden sonra ekrandan kaldırılacaktır',

  'admin.place.form.settings.automation.eta.auto': 'Otomatik',
  'admin.place.form.settings.automation.eta.constant': 'Sabit',
  'admin.place.form.settings.automation.eta.off': 'Kapalı',
  'admin.place.form.settings.automation.eta.label': 'Tahmini bekleme süresini hesaplama modu',
  'admin.place.form.settings.automation.eta.help': `Belirli bir kuyruk bileti için kalan bekleme süresinin hesaplanma şekli.\n
    Otomatik: İşlenen son 6 biletin ortalama süresini dikkate alır. \n
    Sabit: Bir biletin işlenmesi için geçmesi gereken süreyi belirtebilirsiniz. \n
    Kapalı: Tahmini bekleme süresi gösterilmeyecektir.
  `,
  'admin.place.form.settings.automation.eta.constant.label':
    'dakika. Bir biletin işleme alınma süresi',
  'admin.place.form.settings.automation.eta.constant.help':
    'Kuyrukta bekleme süresini tahmin etmek için kullanılacaktır',

  'admin.place.form.settings.constraints.help': 'Kısıtlamalar',
  'admin.place.form.settings.constraints.appsperuser.label':
    'Bir kullanıcı aynı anda kaç bilet alabilir',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'Teker teker',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'Hizmet başına bir adet',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Sınırsız',

  'admin.place.form.askEmail': 'E-posta',
  'admin.place.form.askPhone': 'Telefon numarası',
  'admin.place.form.phoneVerificationEnabled': 'SMS doğrulama',
  'admin.place.form.phoneVerificationDisabled': 'SMS doğrulama (Özel abonelikte mevcut değildir)',
  'admin.place.form.askName': 'İsim',
  'admin.place.form.askAddress': 'Adres',
  'admin.place.form.askBirthDate': 'Doğum tarihi',
  'admin.place.form.useMailMessenger': 'E-posta bildirimleri',
  'admin.place.form.useUIMessenger': 'Uygulama içi bildirimler',
  'admin.place.form.useWebPushMessenger': 'Anlık bildirimler (yalnızca Android)',
  'admin.place.form.useWhatsAppMessenger': 'WhatsApp bildirimleri',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'WhatsApp bildirimleri (Özel abonelikte mevcut değildir)',
  'admin.place.form.useSMSMessenger': 'SMS bildirimleri',
  'admin.place.form.useSMSMessengerDisabled': 'SMS bildirimleri (Özel abonelikte mevcut değildir)',
  'admin.place.form.askWhat': 'Lütfen zorunlu kullanıcı bilgilerini seçin',
  'admin.place.form.askVaccinationStatus': 'Covid-19 aşılama durumu',
  'admin.place.form.vaccinationStatus': 'Covid-19 aşılama durumu',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Ziyaretçilerinizin aşı durumlarını belirtmelerini istiyorsanız bunu işaretleyin',
  'admin.place.form.numberOfVisitors': 'Ziyaretçi sayısı',
  'admin.place.form.askNumberOfVisitors': 'Ziyaretçi sayısı',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Ziyaretçileriniz grup halinde gelebiliyorsa ve sayılarını öğrenmek istiyorsanız bunu kontrol edin',
  'admin.place.form.askWhat.help':
    'Tam ziyaretçi anonimliğine izin vermek için tüm kutuları işaretlemeyin. Ziyaretçi verileri, ziyaretten sonraki iki gün içinde sunucularımızdan otomatik olarak silinecektir.',
  'admin.place.form.messagingConfiguration': 'Mesajlaşma Yapılandırması',
  'admin.place.form.messagingConfiguration.help':
    'Ziyaretçilerinizi biletlerinin durumu hakkında bilgilendirmek için birden fazla kanal arasından seçim yapabilirsiniz.',
  'admin.place.form.enableFeatures': 'Ek özellikleri etkinleştirin',
  'admin.place.form.placeLocale': 'Varsayılan Yer Dili',
  'admin.place.form.placeLocale.help':
    'Tercih ettikleri dili ayarlamamışlarsa, ziyaretçilerinize bildirim göndermek için kullanacağımız bir dil',
  'admin.place.embedCode.title': 'Yerleştirme kodu',
  'admin.place.embedCode.description':
    "2Meters widget'ını web sitenize yerleştirmek için bu kodu kullanın",
  'admin.place.appointments.enable': 'Bu yer için randevuları etkinleştirin',
  'admin.place.livequeue.enable': 'Bu yer için canlı kuyruğu etkinleştirin',
  'admin.place.form.schedulerType': 'Bir program türü seçin',
  'admin.place.form.schedulerType.help':
    'Gelişmiş program geliştirme sürecinin ilk aşamasındadır. Bu konuda garanti vermiyoruz. Lütfen kullanın ve bize geri bildirimde bulunun.',
  'admin.place.shopCapacity.help':
    'Aynı anda kaç müşteriye hizmet verebilirsiniz. Her zaman dilimi farklı müşteriler tarafından şu kadar kez rezerve edilebilir',
  'admin.place.averageDuration.help':
    'Çalışma saatleriniz aşağıdaki sürelerde zaman dilimlerine bölünecektir',
  'admin.place.inventory.enable': 'Bu yer için envanteri etkinleştirin',
  'admin.place.inventory.placeCurrency': 'Para Birimi',
  'admin.place.inventory.placeCurrency.help':
    'Bu değer ziyaretçilerinize doğru fiyatları göstermek için kullanılacaktır',
  'admin.place.inventory.inventoryMaxOrder': 'Sipariş başına maksimum ürün',
  'admin.place.inventory.inventoryMaxOrder.help':
    'Bir ziyaretçi tarafından mekanınızda sipariş edilebilecek ürün miktarı. Stoklarınız sınırsızsa bu alanı boş bırakın.',
  'admin.place.inventory.appointmentsNotAvailable':
    'Randevular devre dışı bırakılırken randevular için slot planlayıcısı kullanılamıyor',

  'admin.userProfile.title': 'Kullanıcı Profili',
  'admin.profile.title': 'Profili',
  'admin.profile.editProfile': 'Düzenle Profili',

  // Inventory
  'inventory.quantity': 'Stok',
  'inventory.maxPerUser': 'Limit',
  'inventory.newProduct': 'Yeni ürün',
  'inventory.editProduct': 'Ürün düzenleme',
  'inventory.edit': 'Düzenle',
  'inventory.delete': 'Sil',
  'inventory.menu': 'Menü',
  'inventory.duplicate': 'Yinelenen',
  'inventory.addProduct': 'Ürün ekle',
  'inventory.enterProductData': 'Lütfen ürün verilerinizi girin',
  'inventory.productName': 'İsim',
  'inventory.productName.placeholder': 'Bu alan boş bırakılmamalıdır',
  'inventory.productDescription': 'Açıklama',
  'inventory.productDescription.placeholder': 'Bu alan boş bırakılmamalıdır',
  'inventory.productStock': 'Stok',
  'inventory.productStock.placeholder': 'Stoklarınız sınırsız ise lütfen alanı boş bırakın',
  'inventory.unlimited': 'sınırsız',
  'inventory.price': 'Fiyat',
  'inventory.price.placeholder': 'Ürünün fiyatı',
  'inventory.product.crop': 'Mahsul',
  'inventory.product.cropTitle': 'Fotoğrafınızı kırpın',
  'inventory.product.uploadImage': 'Yükle',

  //Subscription
  'admin.subscription': 'Abonelik',
  'admin.subscription.trial': 'Deneme',
  'admin.subscription.nextInvoice': 'Sonraki fatura',
  'admin.subscription.cancelAt': 'Abonelik iptal edilecektir',
  'admin.subscription.cancel': 'İptal',
  'admin.subscription.sure_cancel': 'Aboneliğinizi iptal etmek istediğinizden emin misiniz?',
  'admin.subscription.cancelled': 'Abonelik iptal edildi',
  'admin.subscription.trial_description':
    'Deneme süresi hemen başlayacak ve 30 gün içinde sona erecektir. Deneme süresi boyunca tüm özellikler kullanımınıza açık olacaktır. Denemeye başlamak için sizden ödeme bilgisi istemeyeceğiz. Deneme süresinin sonunda, yer otomatik olarak özel bir plana değiştirilecektir. Hizmetimizi beğendiyseniz istediğiniz zaman abone olun. Deneme sürümü yalnızca bir kez kullanılabilir.',
  'admin.subscription.trial_started': 'Deneme süresi başladı ve şu tarihte sona erecek {date}',

  //PlaceList
  'admin.places.list.empty.createNew': 'Yeni yer oluşturun',
  'admin.places.list.ticketsUsed': 'Kullanılan biletler',
  'admin.places.list.empty.header': 'Henüz yeriniz yok',
  'admin.places.list.empty.body':
    'Yeni bir yer oluşturun ve kuyruğu birlikte idare etmeye başlayalım',
  'admin.places.list.header': 'Sizin yerleriniz',
  'admin.places.list.managed': 'Yönetilen yerler',
  'admin.places.list.createNew': 'Yeni yer oluşturun',
  'admin.places.create.title': 'Yeni yer oluşturun',
  'admin.places.update.title': 'Bir Yeri Güncelleyin',

  //QR
  'qr.print': 'Bu belgeyi yazdırın ve müşterilerinizin kolayca görebileceği bir yere koyun.',
  'qr.pleaseScanAppointments': 'Randevu almak için bu QR kodunu tarayın',
  'qr.pleaseScanLivequeue': 'Sıraya girmek için bu QR kodunu tarayın',
  'qr.pleaseScanIntegrated': 'Sıraya girmek veya randevu almak için bu QR kodunu tarayın',
  'qr.followThisLink': "Ya da sadece bu URL'yi takip edin",
  'qr.error': 'Bir şeyler ters gitti. Daha sonra deneyin, lütfen',

  //CounterPage
  'counter.processing': 'İşleme',
  'counter.processing.add': 'Sıradaki gelsin',
  'counter.liveQueue': 'Canlı Kuyruk',
  'counter.stopQueue': 'Durdurulmamış',
  'counter.startQueue': 'Duraklatıldı',
  'counter.liveQueue.add': 'Yeni ekle',
  'counter.appointments': 'Randevular',
  'counter.calendar': 'Takvim',
  'counter.appointments.add': 'Yeni ekle',
  'counter.appointments.dialog.header': 'Randevu alın',
  'counter.appointments.dialog.edit.header': 'Bilet düzenleme {tag}',
  'counter.appointments.dialog.comment.header': 'Yorum ekle {tag}',
  'counter.appointments.dialog.add': 'Kitap',
  'counter.appointments.dialog.cancel': 'İptal',
  'counter.appointments.dialog.selectDate': 'Tarih seçiniz',
  'counter.appointments.dialog.pickTime': 'Seçim zamanı',
  'counter.appointments.dialog.availableTime':
    'Kullanılabilir zaman (aynı anda birden fazla randevu oluşturmak için farklı yuvalara tıklayın)',
  'counter.appointments.dialog.noAvailableTimeSlot':
    'Belirli bir hizmet için seçilen tarihte boş zaman aralığı yok',
  'counter.appointments.dialog.noAvailableTime':
    'Belirli bir hizmet için seçilen tarihte boş zaman aralığı yok',
  'counter.appointments.dialog.noAvailableTimeToday':
    'Bugün için uygun zaman aralığı bulunmamaktadır',
  'counter.appointments.dialog.selectedSlots': 'Aşağıdaki randevular oluşturulacaktır',
  'counter.appointments.dialog.selectService':
    'Kullanılabilir zaman dilimlerini görmek için hizmet türünü seçin',
  'counter.appointments.dialog.serviceType': 'Hizmet türü',
  'counter.appointments.dialog.order': 'Sipariş',
  'counter.liveQueue.dialog.header': 'Sıraya girin',
  'counter.liveQueue.dialog.cancel': 'İptal',
  'counter.userInfo.nickname': 'İsim',
  'counter.userInfo.email': 'E-posta',
  'counter.userInfo.phone': 'Telefon',
  'counter.userInfo.birthDate': 'Doğum tarihi',
  'counter.userInfo.address': 'Adres',
  'counter.toQueue': 'Sıraya girin',
  'counter.invite': 'Davet et',
  'counter.processed': 'İşlenmiş',
  'counter.processedTime': 'İşlem Süresi',
  'counter.invited': 'Davet Edildi',
  'counter.noname': 'İsim yok',
  'counter.walkin': 'Giriş',
  'counter.done': 'Bitti',
  'counter.error': 'Bir hata oluştu, lütfen destek ekibiyle iletişime geçin: {error}',
  'counter.info.dialog.notset': 'ayarlanmamış',
  'counter.appointments.info.queueIn': 'Sıraya girin',
  'counter.info.dialog.sureCancel': 'Bu müşteriyi reddetmek istiyor musunuz?',
  'counter.info.dialog.time': 'Zaman',
  'counter.info.dialog.email': 'E-posta',
  'counter.info.dialog.phone': 'Telefon',
  'counter.info.dialog.username': 'İsim',
  'counter.info': '...',

  'counter.appointment.status.noShow': 'Gösteri Yok',
  'counter.appointment.status.waiting': 'Beklemede',
  'counter.appointment.status.confirmed': 'Onaylandı',
  'counter.appointment.status.queued': 'Kuyrukta',
  'counter.appointment.status.invited': 'Davet Edildi',
  'counter.appointment.status.scheduled': 'Planlandı',
  'counter.appointment.status.walkin': 'Giriş',
  'counter.appointment.status.processed': 'İşlenmiş',
  'counter.appointment.status.cancelled': 'İptal edildi',
  'counter.appointment.status.rejected': 'Reddedildi',

  'counter.table.header.ticket': 'Bilet',
  'counter.table.header.when': 'Ne zaman',
  'counter.table.header.name': 'İsim',
  'counter.table.header.email': 'E-posta',
  'counter.table.header.service': 'Hizmet',
  'counter.table.header.status': 'Durum',
  'counter.table.header.statusChanged': 'Durum değişti',
  'counter.table.header.type': 'Tip',

  'counter.appointment.action.noShow': 'Gösteri Yok',
  'counter.appointment.action.invite': 'Davet et',
  'counter.appointment.action.queueIn': 'Kuyruğa Ekle',
  'counter.appointment.action.done': 'Bitti',
  'counter.appointment.action.confirm': 'Onayla',
  'counter.appointment.action.cancel': 'İptal',
  'counter.appointment.action.reject': 'Reddet',
  'counter.appointment.action.edit': 'Düzenle',
  'counter.appointment.action.comment': 'Yorum',
  'counter.appointment.action.noComment': 'Henüz yorum yok',
  'counter.appointment.action.backToQueue': 'Kuyruğa Geri Dön',
  'counter.appointment.action.backToAppointment': 'Randevuya Geri Dön',
  'counter.showOnly': 'Sadece göster',
  'counter.filter.all': 'Hepsi',
  'counter.filter.allServices': 'Tüm hizmetler',
  'counter.noAppointments': 'Seçilen tarih için alınmış bir randevu yok',
  'counter.noAppointmentsToday': 'Bugün için alınmış herhangi bir randevu bulunmamaktadır',
  'counter.noTickets': 'Seçilen tarih için bilet kesilmemiştir',
  'counter.tabs.today': 'Bugün',
  'counter.tabs.calendar': 'Tarih',
  'counter.filter.types.noShow': 'Gösteri Yok',
  'counter.filter.types.active': 'Aktif Biletler',
  'counter.filter.types.rejected': 'Reddedildi',
  'counter.filter.types.cancelled': 'İptal edildi',
  'counter.filter.types.processed': 'İşlenmiş',
  'counter.filter.types.notConfirmed': 'Onaylanmadı',
  'counter.tabs.counterName': 'Sayaç Adı',
  'counter.tabs.anyName': 'Herhangi Bir İsim',

  //PlaceQRPage
  'qr.neverWaste': '2Meters. Bir daha asla kuyruklarda ömür kaybetmeyin.',
  'qr.header': 'Zaman aralığınızı talep etmek için bu qr kodunu tarayın',

  // PlansPage
  'plans.text':
    '2Meters hizmetinin tüm özellikleri ücretsiz olarak kullanılabilir, ancak ayda 100 bilet ile sınırlıdır. Buna hem sıra hem de randevu biletleri dahildir. Temel plan ile herhangi bir sınırlama getirmez',
  'plans.free': 'Ücretsiz',
  'plans.month.place': '/AY/YER',
  'plans.list.private': 'Özel',
  'plans.list.pro': 'Pro',
  'plans.list.basic': 'Temel',
  'plans.list.basic_annual': 'Temel',
  'plans.list.basic_monthly': 'Temel',
  'plans.list.business': 'İşletme',
  'plans.list.business_annual': 'İşletme',
  'plans.list.business_monthly': 'İşletme',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.enterprise': 'Kurumsal',
  'plans.list.trial': 'Deneme',
  'plans.chooseAPlan': 'Bir plan seçin',
  'plans.ps':
    '* SMS ve Whatsapp bildirimlerini kullanmayı tercih ederseniz, her ayın sonunda bunların tüketimi için ek ücret talep edeceğiz. Fiyatlar, alıcının telefon numarasının menşe ülkesi için tipiktir ve yerel sağlayıcıların fiyatlarına bağlıdır. Ayrıntılı bilgi için satış ile iletişime geçin.',
  'plans.promo': 'Ekibinize başarılı olmak için ihtiyaç duydukları özellikleri verin.',
  'plans.contactUs': 'Bize Ulaşın',
  'plans.missingFeature': 'Eksik bir özellik mi var?',
  'plans.currentPlan': 'Mevcut plan',
  'plans.upgradePlan': 'Yükseltme planı',
  'plans.cancelSubscription': 'Aboneliği iptal et',
  'plans.private.features.1': 'Temel ile aynı',
  'plans.private.features.2': 'Ayda 100 randevu veya canlı kuyruk bileti',
  'plans.private.features.3': 'Canlı Kuyruk sistemi',
  'plans.private.features.4': 'Slot Randevu sistemi',
  'plans.private.features.5': 'Ürün kataloğu',
  'plans.private.features.6': 'Sipariş sistemi',
  'plans.private.features.7': 'Sezgisel Sayaç Uygulaması',
  'plans.private.features.8': 'Tarih takvimi',
  'plans.private.features.9': 'Ekran Uygulaması',
  'plans.private.features.10': 'SMS/Whatsapp/E-posta/Push',
  'plans.pro.features.first': 'Özel ile aynı',
  'plans.pro.features.1': 'Sınırsız ziyaretçi',
  'plans.pro.features.2': 'Sınırsız Uygulama istemcisi',
  'plans.pro.features.3': 'SMS başına ziyaretçi doğrulama',
  'plans.pro.features.4': 'Performans analizi',
  'plans.pro.features.5': 'Özel ziyaretçi formları',
  'plans.pro.features.6': 'Adlandırılmış sayaçlar',
  'plans.pro.features.7': 'Genişletilmiş destek',
  'plans.basic.features.1': 'sınırsız bilet',
  'plans.basic.features.2': 'Bekleme odası ekranı görünümü',
  'plans.basic.features.3': 'Sezgisel sayaç görünümü',
  'plans.basic.features.4': 'Çevrimiçi destek',
  'plans.basic.features.5': 'Logo yükleme',
  'plans.basic.features.6': 'Temel yer analitiği',
  'plans.business.features.1': 'En fazla beş sayaç',
  'plans.business.features.2': 'Sıra zincirleme',
  'plans.business.features.3': 'Müşteri geri bildirim özelliği',
  'plans.business.features.4': 'Gelişmiş yer analitiği',
  'plans.enterprise.promo': 'Özel',
  'plans.switcher.monthly': 'Aylık',
  'plans.switcher.annualy': 'Yıllık',
  'plans.mostPopular': 'En popüler',
  'plans.pro.discont': '10% indirim',
  'plans.enterprise.features.first': 'Pro ile aynı',
  'plans.enterprise.features.1': 'sınırlama yok',
  'plans.enterprise.features.2': 'Uyarlanmış işlevsellik',
  'plans.enterprise.features.3': 'Özelleştirilmiş fiyatlandırma',
  'plans.enterprise.features.4': 'Beyaz etiket entegrasyonu mümkün',
  'plans.enterprise.features.5': 'Özel markalama',
  'plans.enterprise.features.6': 'Özel özellikler',
  'plans.enterprise.features.7': 'Premium destek',
  'plans.discont': '10% indirim',

  'plans.pro_monthly.cancelSubscription': 'Pro Aylık Planı İptal Et',
  'plans.pro_annual.cancelSubscription': 'Pro Yıllık Planı İptal Et',
  'plans.enterprise_monthly.cancelSubscription': 'Kurumsal Aylık Planı İptal Et',
  'plans.enterprise_annual.cancelSubscription': 'Kurumsal Yıllık Planı İptal Et',
  'plans.keepSubscribtion': 'Aboneliği sakla',
  'plans.cancelSubscription.text':
    'Abonelik planınızı iptal etmek istediğinizden emin misiniz? İptal işlemi, gelecekteki ödemeleri durduracak ve premium özelliklere erişiminiz mevcut faturalandırma döngünüzün sonunda sona erecektir Herhangi bir sorunuz veya endişeniz varsa, yardım için lütfen destek ekibimizle iletişime geçin.',

  'poorConection.title': 'Zayıf internet bağlantısı',
  'poorConection.description':
    'İnternet bağlantınız maalesef 2metre Uygulamasının çalışması için yetersiz. Lütfen internet bağlantınızı veya ağ güvenlik politikalarınızı kontrol edin. Lütfen canlı verileri size ulaştırmak için web soketleri kullandığımızı göz önünde bulundurun.',

  //Checkout
  'checkout.success': 'Ödeme başarılı oldu',
  'checkout.redirect': 'Bir saniye içinde yönlendirileceksiniz',
  'checkout.falied': 'Ödeme başarısız oldu. Lütfen desteğe başvurun',

  'monitor.desktopViewHeader': 'Monitör Görünümü',
  'monitor.desktopViewBody':
    'Ziyaretçilerinize kuyruğun durumunu göstermek için bir ekran kurabilirsiniz. Ekrana bağlı bilgisayarda bu sayfayı açın ve tam ekran moduna girmek için butona tıklayın.',
  'monitor.fullscreen': 'Tam ekran',
  'monitor.switchTheMonitor': 'Monitör dilini değiştir',

  //Statistics
  'statistics.dashboard': 'İstatistik Panosu',
  'statistics.ticketsAndServices': 'Biletler ve Hizmetler',
  'statistics.thisMonth': 'Bu ay',
  'statistics.thisWeek': 'Bu hafta',
  'statistics.today': 'Bugün',
  'statistics.booked': 'rezerve edildi',
  'statistics.noData': 'Seçilen dönem için veri yok',
  'statistics.totalAmount': 'Toplam tutar',
  'statistics.avgWaitingTime': 'Ortalama Bekleme Süresi',
  'statistics.avgProcessingTime': 'Ortalama İşlem Süresi',
  'statistics.minutes': 'dakika',

  'statistics.period': 'Dönem',
  'statistics.lastSevenDays': 'Son 7 gün',
  'statistics.month': 'Bu ay',
  'statistics.custom': 'Özel tarih aralığı',
  'statistics.services': 'Hizmetler',
  'statistics.all': 'Tüm hizmetler',
  'statistics.periodError': 'Başlangıç tarihi, bitiş tarihinden önce olmalıdır',
  'statistics.сonfirmationStatus': 'Onay Durumu',
  'statistics.ticketType': 'bilet tipi',
  'statistics.ticketStatus': 'Bilet Durumu',
  'statistics.processed': 'İşlenmiş',
  'statistics.noShow': 'Gösteri yok',
  'statistics.invited': 'davet edildi',
  'statistics.cancelled': 'İptal edildi',
  'statistics.confirmed': 'Onaylanmış',
  'statistics.pending': 'Askıda olması',
  'statistics.rejected': 'Reddedildi',
  'statistics.queued': 'sıraya alındı',
  'statistics.dashboardIsEmpty': 'Pano boş',
  'statistics.dashboardIsEmptyDesc':
    'İstatistik panosuna erişebilmek için lütfen daha yüksek abonelik düzeyine geçin.',
  'statistics.upgadePlan': 'Yükseltme Planı',

  // Scheduler

  'scheduler.error.endBeforeStart': 'Başlangıç tarihi, bitiş tarihinden önce olmalıdır',
  'scheduler.error.notSameDay': 'Başlangıç ve bitiş zamanı aynı gün olmalıdır',
  'scheduler.error.visitTypeNotSet': 'Hizmetin türü ayarlanmalıdır',
  'scheduler.error.invalidSchedule':
    'Geçersiz program. Tekrar süresinin bitişinin başlangıç tarihinden önce olmadığını kontrol edin',
  'scheduler.numberOfAppointments': '{num} bu aralıktaki zaman dilimleri',
  'scheduler.simple': 'Basit',
  'scheduler.advanced': 'Gelişmiş',
  'scheduler.serviceType': 'Servis tipi',
  'scheduler.timePeriodForm.commit': 'Onaylamak',
  'scheduler.timePeriodForm.repeat': 'Tekrar et',
  'scheduler.timePeriodForm.never': 'Hiçbir zaman',
  'scheduler.timePeriodForm.daily': 'Günlük',
  'scheduler.timePeriodForm.weekly': 'Haftalık',
  'scheduler.timePeriodForm.monthly': 'Aylık',
  'scheduler.timePeriodForm.yearly': 'Yıllık',
  'scheduler.timePeriodForm.repeatEvery': 'Her tekrarla',
  'scheduler.timePeriodForm.days': 'gün(ler)',
  'scheduler.timePeriodForm.endRepeat': 'Tekrarlamayı bitir',
  'scheduler.timePeriodForm.on': 'Açık',
  'scheduler.timePeriodForm.after': 'Sonrasında',
  'scheduler.timePeriodForm.occurs': 'olay(lar)',
  'scheduler.timePeriodForm.weeksOn': 'hafta(lar) üzerinde:',
  'scheduler.timePeriodForm.month': 'ay(lar)',
  'scheduler.timePeriodForm.everyMonth': 'her ayın',
  'scheduler.timePeriodForm.the': 'bu',
  'scheduler.timePeriodForm.first': 'Öncelikle',
  'scheduler.timePeriodForm.second': 'İkinci',
  'scheduler.timePeriodForm.third': 'Üçüncü',
  'scheduler.timePeriodForm.fourth': 'Dördüncü',
  'scheduler.timePeriodForm.last': 'Son',
  'scheduler.timePeriodForm.years': 'yıl(lar)',
  'scheduler.timePeriodForm.of': 'nın-nin',
  'scheduler.timePeriodForm.every': 'Her',
  'scheduler.timePeriodForm.timePeriods': 'Zaman Dönemleri',
  'scheduler.timePeriodForm.headerCaption':
    'Lütfen belirli hizmeti verdiğiniz her bir zaman dilimini tanımlayın. Bu periyot için slotlar, slot süresi değerine bağlı olarak otomatik olarak hesaplanacaktır.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Servis Atama ve Slotlar',
  'scheduler.timePeriodForm.createButton': 'Oluşturmak',

  'scheduler.confirmCancel': 'Kaydedilmemiş değişiklikleri gerçekten silmek istiyor musunuz?',
  'scheduler.confirmDelete': 'Bu zaman dilimini gerçekten silmek istiyor musunuz?',
  'scheduler.cancelButton': 'İptal',
  'scheduler.deleteButton': 'Silmek',
  'scheduler.discardButton': 'Sil',

  'scheduler.editRecurrenceMenu.editTitle': 'Yinelenen dönemleri düzenleyin',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Yinelenen zaman dilimlerini sil',
  'scheduler.editRecurrenceMenu.cancelButton': 'İptal',
  'scheduler.editRecurrenceMenu.commitButton': 'Onaylamak',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'Tüm zaman dilimleri',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods': 'Bu ve sonraki zaman dilimleri',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'Bu zaman dilimi',

  // Managers

  'manager.title': 'Yöneticiler',
  'manager.description':
    'Burada, bu yere erişimi olan kişileri ekleyebilir ve kaldırabilirsiniz. Yöneticiler, sayaç görünümünden randevuları ve kuyrukları değiştirebilir. Ancak yer ayarlarını düzenleyemezler.',
  'manager.add': 'yönetici ekle',
  'manager.remove': 'Kaldırmak',
  'manager.admin': 'Yönetici',
  'manager.manager': 'Müdür',
  'manager.name': 'Soyadı',
  'manager.email': 'e-posta',
  'manager.role': 'Rol',
  'manager.invitationSent': 'Davet gönderildi',
  'manager.sendInvite': 'Davetiye gönder',
  'manager.invitePlaceholder': 'Yeni kullanıcılar davet edin',
  'manager.table.name': 'Soyadı',
  'manager.table.accepted': 'Kabul edilmiş',
  'manager.table.invited': 'Gönderilmiş',
  'manager.table.email': 'E-posta',
  'manager.table.role': 'Rol',
  'manager.table.dateAdded': 'Ekleme Tarihi',
}
