import React, { useState, MouseEvent, useEffect } from 'react'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import { backend } from 'api/Firebase'
import { useHistory } from 'react-router-dom'
import * as ROUTES from 'constants/routes'
import { users } from 'api/Firebase'
import { Logo } from 'components'
import AccountIcon from 'assets/account.svg'
import { User } from '@2meters/shared'
import LogoutIcon from 'assets/logout.svg'
import BillingLogNotificationsIcon from '@mui/icons-material/NotificationsNone'
import { PersonOutlineRounded } from '@mui/icons-material'
import { useSnackBar } from 'store/useSnackBar'

const useStyles = makeStyles({
  container: {
    width: 180,
  },
})

const Account: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null)
  const [userId, setUserId] = useState<string>('')
  const [imageFile, setImageFile] = useState<string>('')
  const showError = useSnackBar(state => state.showError)

  const onSignOut = () => {
    backend?.doSignOut()
    setMenuAnchor(null)
    history.push(ROUTES.LOGIN)
  }

  const onBillingLogNotifications = () => {
    history.push(ROUTES.ADMIN.BILLING_LOG_NOTIFICATIONS)
  }

  const onUserProfile = () => {
    history.push(ROUTES.ADMIN.USER_PROFILE)
  }
  useEffect(() => {
    users
      .getCurrentUser()
      .then((user: User | null) => {
        if (user) {
          setUserId(user?.id || '')
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    if (userId) {
      users.getUserSubscribe(
        userId,
        (userData: any) => {
          setImageFile(userData?.imageFile || '')
        },
        () => {
          showError('User was not found')
        }
      )
    }
  }, [userId, imageFile])

  return (
    <Box
      display='inline-block'
      ml={1}
      style={{
        marginRight: '-3px',
      }}
    >
      <Box>
        <IconButton
          size='small'
          style={{
            width: 34,
            height: 34,
          }}
          onClick={(event: MouseEvent) => setMenuAnchor(event.currentTarget as Element)}
        >
          {(imageFile && <Logo width='24px' height='24px' round userProfile />) || (
            <img src={AccountIcon} alt='account' width='24' height='24' />
          )}
        </IconButton>
      </Box>
      <Menu
        id='account-menu'
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box ml={1} className={classes.container}>
          <Typography
            variant='overline'
            style={{ lineHeight: 0, fontWeight: 'bold', color: '#959595' }}
          >
            <FormattedMessage id='admin.account.billingLog' />
          </Typography>
        </Box>
        <MenuItem onClick={onBillingLogNotifications}>
          <BillingLogNotificationsIcon style={{ marginRight: 5, paddingRight: 1, marginLeft: 5 }} />
          <FormattedMessage id='appointment.next.messagingPrompt' />
        </MenuItem>
        <Box ml={1}>
          <Typography
            variant='overline'
            style={{ lineHeight: 0, fontWeight: 'bold', color: '#959595' }}
          >
            <FormattedMessage id='admin.account.account' />
          </Typography>
        </Box>
        <MenuItem onClick={onUserProfile}>
          <PersonOutlineRounded style={{ marginRight: 5, paddingRight: 1, marginLeft: 5 }} />
          <FormattedMessage id='admin.userProfile.title' />
        </MenuItem>
        <MenuItem onClick={onSignOut}>
          <img src={LogoutIcon} alt='logout' style={{ marginRight: 5, marginLeft: 9 }} />
          <FormattedMessage id='admin.account.signOut' />
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Account
