import React, { memo } from 'react'
import { Select, MenuItem, SelectProps, FormLabel, Typography } from '@mui/material'
import timezones from './timezones.json'

export type TimeZonesProps = SelectProps & {
  required?: boolean
  label?: string
}

const TimeZones: React.FC<TimeZonesProps> = memo(({ required, label, ...other }) => (
  <>
    <FormLabel required={required}>
      <Typography variant='caption'>{label}</Typography>
    </FormLabel>
    <Select {...other} variant='standard'>
      {timezones.map(({ offset, label, tzCode }) => (
        <MenuItem key={tzCode} value={tzCode}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </>
))

export default TimeZones
