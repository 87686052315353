import { db } from 'api/Firebase/firebase-init'
import { users } from 'api/Firebase/users'
import { useEffect } from 'react'
import { PacmanProgress } from 'components/Preloader'
import { doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useCurrentUser } from 'store/useCurrentUser'

const withFirestoreUser = Component => {
  const WithFirestoreUser = ({ onSetAuthUser, ...rest }) => {
    const { authUser, userRecord, setUserRecord } = useCurrentUser()

    useEffect(() => {
      const userId = authUser?.uid

      if (!userId) {
        return () => undefined
      }

      console.log('Subscribing to user', userId)
      const ref = doc(db, `user/${userId}`)
      return onSnapshot(ref, doc => {
        const firestoreUser = doc.data()

        if (firestoreUser) {
          setUserRecord(firestoreUser)
          console.log('Firestore user set', firestoreUser)
          users.updateUserOriginOnce(firestoreUser)
        } else {
          console.log(`Firebase User ${userId} does not exists`)
        }
      })
    }, [authUser.uid, setUserRecord])

    return userRecord ? (
      <Component {...rest} />
    ) : (
      <PacmanProgress text='onboarding.createPlace.wait' />
    )
  }

  return WithFirestoreUser
}

export { withFirestoreUser }
