import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Switch, Typography } from '@mui/material'
import { SwitchProps } from '@mui/material/Switch'
import { COLORS } from '@2meters/ui-lib'
import { FormattedMessage } from 'react-intl'

interface PlanSwitcherProps {
  onChangeTypeSubscription?: (switcher: boolean) => void
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#f2f2f2',
        opacity: 1,
        border: '1px solid #DCDCDC',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#4ec2ee',
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: '1px solid #DCDCDC',
    backgroundColor: '#f2f2f2;',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const PlanSwitcher: React.FC<PlanSwitcherProps> = ({ onChangeTypeSubscription }) => {
  const [switcher, setSwitcher] = useState(false)

  const handleChange = useCallback(checked => {
    setSwitcher(checked)
  }, [])

  useEffect(() => {
    if (onChangeTypeSubscription) {
      onChangeTypeSubscription(switcher)
    }
  }, [onChangeTypeSubscription, switcher])

  return (
    <Box display='flex' alignItems='center' mr={2}>
      <Box mr={1}>
        <Typography
          sx={{
            fontWeight: '600',
            color: `${!switcher ? 'rgba(44, 53, 63, 1)' : 'rgba(44, 53, 63, 0.6)'}`,
          }}
          variant='body1'
        >
          <FormattedMessage id='plans.switcher.monthly' />
        </Typography>
      </Box>
      <IOSSwitch
        color='primary'
        checked={switcher}
        onChange={e => handleChange(Boolean(e.target.checked))}
      />
      <Box ml={1}>
        <Typography
          sx={{
            fontWeight: '600',
            color: `${switcher ? 'rgba(44, 53, 63, 1)' : 'rgba(44, 53, 63, 0.6)'}`,
          }}
          variant='body1'
        >
          <FormattedMessage id='plans.switcher.annualy' />
        </Typography>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        ml={1}
        sx={{
          border: `1px solid ${COLORS.primary.main}`,
          borderRadius: '100px',
          padding: '3px 10px',
        }}
      >
        <Typography
          variant='body2'
          align='center'
          sx={{
            color: COLORS.primary.main,
            fontWeight: '600',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id='plans.discont' />
        </Typography>
      </Box>
    </Box>
  )
}

export default PlanSwitcher
