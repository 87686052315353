import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { IntlProvider } from 'react-intl'
import LuxonUtils from '@date-io/luxon'
import { useAdminLocale } from 'store/useAdminLocale'

type Props = {}

export const AdminLanguageProvider: React.FC<Props> = ({ children, ...props }) => {
  const { adminLocale, translations } = useAdminLocale()

  return (
    <IntlProvider locale={adminLocale} messages={translations(adminLocale)} {...props}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        utils={LuxonUtils}
        adapterLocale={adminLocale}
      >
        {children}
      </LocalizationProvider>
    </IntlProvider>
  )
}
