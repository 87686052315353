export default {
  'weekdays.0': 'domingo',
  'weekdays.1': 'lunes',
  'weekdays.2': 'martes',
  'weekdays.3': 'miércoles',
  'weekdays.4': 'jueves',
  'weekdays.5': 'viernes',
  'weekdays.6': 'sábado',
  'wd.1': 'Lun',
  'wd.2': 'Mar',
  'wd.3': 'Mie',
  'wd.4': 'Jue',
  'wd.5': 'Vie',
  'wd.6': 'Sab',
  'wd.7': 'Dom',
  'wd.0': 'Dom',
  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Українська',
  'tr-TRLang': 'Türkçe',
  EUR: 'Euro',
  USD: 'Dólar estadounidense',
  GBP: 'Libra británica',
  INR: 'Rupia india',
  UAH: 'Hryvnia ucraniana',
  JPY: 'Yen japonés',
  BTC: 'BTC',
  TRY: 'Lira turca',
  Rand: 'Rand sudafricano',
  generalError: 'Ha ocurrido un error inesperado',
  error: '{error}',
  enabled: 'Activado',
  disabled: 'Discapacitado',
  appointments: 'Horas de entrada',
  livequeues: 'Colas',
  livequeue: 'Cola',
  info: 'Información',
  ok: 'OK',
  today: 'Hoy',
  delete: 'Borrar',
  cancel: 'Cancelar',
  create: 'Crear',
  upgrade: 'Potenciar',
  update: 'Actualizar',
  invite: 'Invitación',
  queueIn: 'Cola en',
  edit: 'Editar',
  sure: '¿Está seguro?',
  yes: 'sí',
  no: 'No',
  add: 'Agregar',
  save: 'Ahorrar',
  go: 'Ir',
  next: 'próximo',
  leave: 'Licencia',
  yourAppointments: 'Tus citas',
  yourAppointment: 'Tus citas',
  address: 'Habla a',
  copyToClipboard: 'Dupdo',
  copiedToClipboard: 'Texto copiado',
  showQRCode: 'Mostrar código QR',
  showPDF: 'Mostrar pdf',
  showNumberTickets: 'Número de entradas',
  clipboardCopyError: 'Error de copia del portapapeles',
  imageUploadFailed: 'Error al cargar la imagen',
  uploadLogo: 'Subir logotipo',
  thankYou: '¡Gracias!',

  // Update Page
  'rolledOutUpdate.reload': 'Recargar la página',
  'rolledOutUpdate.header': '¡La actualización está en marcha!',
  'rolledOutUpdate.message':
    'Se ha puesto en marcha una nueva actualización. Por favor, recargue la página.',

  // Reset Password
  'resetPassword.resetPassword': 'Restablecer contraseña',
  'resetPassword.newPassword': 'Nueva contraseña',
  'resetPassword.currentPassword': 'Contraseña actual',
  'resetPassword.changePassword': 'Cambiar la contraseña',
  'resetPassword.currentPasswordWrong': 'La contraseña actual no es correcta',
  'resetPassword.confirmNewPassword': 'Confirmar la nueva contraseña',
  'resetPassword.linkNotValid':
    'Su enlace de restablecimiento no es válido. Por favor, vuelva a enviar su solicitud',
  'resetPassword.dontMatch': 'Las contraseñas no coinciden',
  'resetPassword.passwordChanged': 'Contraseña cambiada',
  'resetPassword.signNewPassword': 'Ya puedes iniciar sesión con tu nueva contraseña',
  'resetPassword.errorEncountered': 'Error encontrado',
  'resetPassword.modeIsInvalid': 'El modo de página seleccionado no es válido',
  'resetPassword.logout': 'Cierre de sesión',
  'resetPassword.logout.title': 'Su contraseña ha sido modificada',
  'resetPassword.logout.descritption': 'Vuelva a iniciar sesión en su cuenta',

  'notfoundpage.header': 'Página no encontrada',
  'notfoundpage.message':
    'El archivo especificado no se encontró en este sitio web. Verifique que no haya errores en la URL y vuelva a intentarlo.',
  'dialogs.pageIsForMobile':
    'Estás viendo esta página desde tu navegador de escritorio.\nPor favor, abra el enlace con el navegador de su teléfono móvil, si desea que la cita reservada esté disponible en su teléfono inteligente.',
  'dialogs.pageIsForMobileTitle': 'Usa tu dispositivo móvil',
  'dialogs.messagemodal.walkin': 'Cola',
  'dialogs.whatsapp.text':
    'Te recomendamos que te conectes a las notificaciones de WhatsApp, si quieres saber, cuántas personas están esperando antes que tú o cuánto será tu tiempo de espera. Haga clic en el botón "Conectar" para abrir WhatsApp y enviar un mensaje que hemos preparado sin cambiarlo.',
  'dialogs.whatsapp.connect': 'Conectar',
  'dialogs.whatsapp.urlText':
    'Me%20gustaría%20recibir%20notificaciones%20con%20información%20sobre%20el%20cambio%20de%20estado%20de%20mi%20ticket',
  'home.queues': 'Tus colas en vivo',
  'home.queue.showCode': 'Muestre este número a petición',
  'home.queue.peopleAhead': 'Gente delante de ti',
  'home.placesNearby': 'Lugares cercanos',
  'home.noPlacenear': 'No hay ningún lugar registrado cerca de ti',
  'home.enableGeoLocation':
    'Ha desactivado la ubicación geográfica. Habilítelo en la configuración del navegador para usar esta función',
  'home.bookASpot': 'Para concertar una cita o unirse a una cola digital',
  'home.scanQR': 'escanear el código QR',
  'home.enterShortUrl': 'o ingrese el shortcode del lugar',
  'home.enterShortcode': 'Ingrese el shortcode',
  'home.selectNearby': 'o simplemente seleccione de lugares cercanos',
  'home.useToBook': 'Utilice la búsqueda o el escáner de códigos QR para reservar uno',
  'home.findNearby': 'Encontrar un lugar cercano',
  'home.loginAsAdmin': 'Iniciar sesión como administrador del lugar',
  'messaging.permissionGranted': 'Permiso de notificación concedido',
  'messaging.permissionNotGranted':
    'No recibirá ninguna notificación mientras la aplicación esté en segundo plano a medida que avanza la cola. Permita las notificaciones.',
  'messaging.tokenError': 'Algo salió mal',
  'messaging.webPushNotificationsNotSupported':
    'Su navegador no admite notificaciones push web. No obtendrás ninguna\nnotificaciones a medida que avanza la cola. Observe el número de personas en la línea que se muestra a continuación.',
  'appointment.disabledButtonHint': 'Reservar entrada',
  'appointment.dayOff': 'El lugar está cerrado en este momento. Elija otra fecha.',
  'appointment.visitorInformation': 'Su información',
  'appointment.visitorInformation.numberOfVisitors': 'Número de visitantes',
  'appointment.visitorInformation.numberOfVisitors.alert': 'Cuántas personas vienen?',
  'appointment.visitorInformation.counter': 'Contador',
  'appointment.visitorInformation.hint':
    'Por favor, proporcione la siguiente información sobre usted mismo.',
  'appointment.customInformation': 'Información complementaria',
  'appointment.customInformation.hint': 'Por favor, proporcione la información complementaria',
  'appointment.visitorInformation.notRequired': 'No se requieren datos de contacto',
  'appointment.services': 'Servicio',
  'appointment.order': 'Pedido',
  'appointment.services.hint': 'Elija un servicio que le gustaría recibir',
  'appointment.order.hint':
    'Seleccione los productos que le gustaría ordenar. Tenga en cuenta que puede haber un límite en la cantidad de artículos por pedido.',
  'appointment.visitorSelectDate': 'Seleccione fecha',
  'appointment.noMoreToday': 'No hay más citas disponibles hoy',
  'appointment.add': 'Reservar entrada',
  'appointment.cancelAppointment': 'Cancelar cita',
  'appointment.leaveQueue': 'Borrar el ticket de la cola',
  'appointment.next': 'Siguiente: {step}',
  'appointment.next.contactInfo': 'Información de contacto',
  'appointment.next.timeslot': 'Hora de entrada',
  'appointment.next.customFields': 'Información adicional',
  'appointment.next.messagingPrompt': 'Notificaciones',
  'appointment.next.book': 'Libro',
  'appointment.next.whatsapp': 'Notificación de WhatsApp',
  'appointment.next.queueIn': 'Únete a la cola',
  'appointment.next.services': 'Servicios',
  'appointment.next.numberOfVisitors': 'Número de visitantes',
  'appointment.next.products': 'Productos',
  'appointment.next.submit': 'Enviar',
  'appointment.messagingPrompt': 'Permitir notificaciones',
  'appointment.submit': 'Enviar peticion',
  'appointment.submit.error': 'Se produjo un error al enviar su solicitud',
  'appointment.submit.standby': 'Un momento por favor',
  'appointment.cancel': 'Cancelar',
  'appointment.back': 'atrás',
  'appointment.visitorPhoneNumValidationStep': 'Verificación de número de teléfono',
  'appointment.visitorPhoneNumValidationStep.hint':
    'Le hemos enviado un código a {phone}.Por favor ingrese en los campos a continuación.',
  'appointment.sendAgain': 'Envie novamente',
  'appointment.chooseTime': 'Elige el tiempo',
  'appointment.makeAnother': 'Concierte otra cita',
  'appointment.queueInAgain': 'Vuelve a hacer cola',
  'appointment.nickname': 'Agregue un nombre por el que pueda ser llamado',
  'appointment.group': 'Cuántas personas vienen',
  'appointment.openHours': 'Horarios de apertura:',
  'appointment.contacts': 'Contactos:',
  'appointment.description': 'Descripción general:',
  'appointment.address': 'Habla a',
  'appointment.comment': 'Comentario',
  'appointment.birthDate': 'Fecha de nacimiento',
  'appointment.visitType': 'Razón de la visita',
  'appointment.shareName': 'Tu nombre',
  'appointment.sharePhone': 'Número de teléfono',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Buscar en',
  'appointment.sharePhone.searchNotFound': 'No hay entradas que mostrar',
  'appointment.sharePhone.inValid':
    'El número de teléfono no es válido. Por favor, intente introducir su número en formato internacional: +1123456789',
  'appointment.shareEmail': 'Correo electrónico',
  'appointment.shareAddress': 'Habla a',
  'appointment.shareBirthDate': 'Fecha de nacimiento',
  'appointment.vaccinationStatus': 'Estado de vacunación de Covid-19',
  'appointment.not_vaccinated': 'No está vacunado',
  'appointment.partially_vaccinated': 'Parcialmente vacunado',
  'appointment.fully_vaccinated': 'Totalmente vacunado',
  'appointment.sure': '¿Está seguro?',
  'appointment.time': 'Fecha y hora',
  'appointment.invitedAt': 'Invitado en',
  'appointment.queuedAt': 'En cola en',
  'appointment.closedTime': 'Cerrado. Abre en',
  'appointment.opensToday': 'Cerrado. Abre hoy en',
  'appointment.ticket': 'Billete',
  'appointment.position': 'Antes de ti',
  'appointment.queueLength': 'En espera',
  'appointment.eta': 'hora prevista',
  'appointment.pleaseProceed': 'Estamos listos para servirte.\nPor favor continúa',
  'appointment.header': 'Tus entradas',
  'appointment.estimatedWaitingTime': 'Tiempo de espera estimado',
  'appointment.min': 'min',
  'appointment.people': 'personas',
  'appointment.error.invalidFormFields':
    'Complete los campos del formulario resaltados para continuar',
  'appointment.error.invalidVisitType': 'Indique el motivo de su visita para continuar.',
  'appointment.error.noSlotSelected': 'No se seleccionó ningún intervalo de tiempo',
  'appointment.confirmation.preliminaryWarning':
    'Esta reserva es preliminar hasta que reciba una carta de confirmación.',
  'appointment.confirmation.rejectedWarningAppointment':
    'Lo sentimos, pero el administrador ha rechazado su cita. Intente reservar otro.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'Lo sentimos, pero el administrador ha rechazado su ticket. Intente hacer cola de nuevo.',
  'appointment.confirmation.appointmentHasPreference':
    'Tenga en cuenta que las citas reservadas con antelación pueden tener prioridad sobre LiveQueue',
  'appointment.products.error.maxOrder':
    'El pedido puede contener hasta {quantity} artículos. Reduzca la cantidad.',
  'appointment.products.price': 'Precio',
  'appointment.products.inStock': 'En stock',
  'appointment.products.outofstock': 'Agotado',

  'appointment.disabled':
    'El administrador ha desactivado el sistema de programación para esta ubicación.',

  'appointment.messaging.pending':
    'Para notificarle acerca de las actualizaciones del estado de la cita, necesitamos que habilite las notificaciones automáticas. Haga clic en <b>permitir</b> si está de acuerdo.',
  'appointment.messaging.denied':
    'No se han habilitado los permisos para enviar notificaciones. No recibirás notificaciones push',
  'appointment.messaging.granted': 'Se concede permiso para enviar notificaciones push. ¡Gracias!',

  'livequeue.peopleNum': 'Número de personas en la cola',
  'livequeue.yourNumberInQueue': 'Tu posición en la cola',
  'livequeue.youAreNext': 'Eres el primero en la cola',
  'livequeue.leave': 'Salir de la cola',
  'livequeue.showAtRequest': 'Muestre este boleto a pedido',
  'livequeue.submit': 'Únete a la cola',
  'livequeue.liveQueuePaused':
    'El administrador del lugar ha detenido la cola digital temporalmente. Por favor, inténtelo más tarde.',
  'livequeue.liveQueueOpenHours':
    'Está intentando hacer cola fuera del horario de apertura. Por favor, vuelva más tarde',
  'livequeue.disabled':
    'El sistema de colas digitales es desactivado por el administrador del lugar',
  'livequeue.queueTooLong':
    'El administrador ha limitado el número de personas en la cola. Por favor, inténtelo más tarde.',

  'monitor.pleaseProceed': 'Por favor continúa',
  'monitor.livequeue': 'Cola en vivo',
  'monitor.peopleInQueue': 'Más entradas',
  'monitor.appointments': 'Citas programadas',
  'monitor.appointment.type.appointment': 'cita',
  'monitor.appointment.type.livequeue': 'cola en vivo',
  'monitor.stats': 'Ocupación hoy',
  'monitor.pleaseScanAppointments': 'Escanee este código QR para programar una cita',
  'monitor.pleaseScanLivequeue': 'Escanee este código QR para hacer cola',
  'monitor.pleaseScanIntegrated': 'Escanee este código QR para hacer cola o concertar una cita',
  'monitor.followThisLink': 'O simplemente sigue esta URL',
  'cookies.concent':
    'Este sitio web solo utiliza cookies técnicamente necesarias que no se pueden deseleccionar. Las cookies necesarias ayudan a que un sitio web sea utilizable al habilitar funciones básicas como la navegación de páginas y el acceso a áreas seguras del sitio web. El sitio web no puede funcionar correctamente sin estas cookies.',
  'cookies.ok': 'Entender',
  'dialogs.pageIsForDesktop':
    'Estás viendo esta página desde tu navegador móvil.\nEsta página está optimizada para una computadora de escritorio. Es posible que algunas funciones no estén disponibles\ny la página podría presentarse con errores.',
  'dialogs.pageIsForDesktopTitle': 'Vista de escritorio',
  'dialogs.reject': 'Rechazar',
  'dialogs.confirm': 'Confirmar',
  trial: 'Iniciar prueba',

  // SignUp
  'signUp.referralCode': 'Introduzca un código de referencia si tiene uno',

  'login.header': '¡Gracias por elegir 2 metros!',
  'login.header2': 'Creamos nuestro servicio para ayudarte a',
  'login.text1':
    'establezca un cómodo sistema de reserva de citas en línea que a sus clientes les encantará',
  'login.text2':
    'Establezca una solución de cola digital innovadora para su tienda o servicio con el fin de retener a los clientes antiguos y atraer a otros nuevos eliminando las molestas colas dondequiera que aparezcan.',
  'login.text3':
    'o para hacer ambas cosas, ya que nuestra solución integra ambas funciones. Deje que su cliente sin cita previa haga una cita si el tiempo de espera en la cola es demasiado largo',
  'login.google': 'Iniciar sesión con Google',
  'login.facebook': 'Iniciar sesión con Facebook',
  'login.withPassword': 'Iniciar sesión con correo electrónico',
  'login.newUser': 'Regístrese hasta 2 metros',
  'login.existingUser': 'Iniciar sesión en 2Meters',
  'login.signUp': 'Inscribirse',
  'login.signIn': 'Iniciar sesión',
  'login.email': 'Correo electrónico',
  'login.email.placeholder': 'ejemplo@dominio.com',
  'login.password': 'Contraseña',
  'login.password.placeholder': '8+ personajes',
  'login.passwordRepeat': 'repite la contraseña',
  'login.passwordRepeat.placeholder': '8+ personajes',
  'login.forgotPassword': '¿Se te olvidó tu contraseña?',
  'login.passwordsDontMatch': 'Las contraseñas no coinciden',
  'login.sendRecoveryEmail':
    'Le enviaremos un vínculo para cambiar su contraseña. Indíquenos el correo electrónico con el que se registró.',
  'login.checkEmail': 'Por favor revise su correo electrónico y siga las instrucciones',
  'login.resetPassword': 'Restablecer la contraseña',
  'login.recoveryEmailSent': 'Se envió un correo electrónico de recuperación a {email}',
  'login.passwordTooShort': 'La contraseña debe tener al menos 8 caracteres',
  'login.dontHaveAccount': '¿No tienes una cuenta?',
  'login.alreadyHaveAccount': '¿Ya tienes una cuenta?',
  'login.enterDetails': 'Ingrese los detalles a continuación',
  'login.alreadyRegistered': 'Registrarse',
  'login.getStarted': 'Empezar',
  'login.acceptTerms': 'Acepto',
  'login.termsAndConditions': 'Términos y condiciones',
  'login.termsNotChecked': 'Acepte nuestros Términos y condiciones para continuar.',
  'login.acceptPrivacyPolicy': 'estoy de acuerdo con',
  'login.privacyPolicy': 'Política de privacidad',
  'login.privacyPolicyNotChecked': 'Acepte nuestra Política de privacidad para continuar.',
  'login.missingTermsConsent.title': 'Sólo un paso más',
  'login.missingTermsConsent.desc':
    'Acepte nuestros Términos y condiciones y Política de privacidad para acceder a nuestro servicio. ¡Gracias!',

  'customerProfileForm.firstName': 'Nombre',
  'customerProfileForm.lastName': 'Apellido',
  'customerProfileForm.email': 'Email',
  'customerProfileForm.organization': 'Nombre de la organización',
  'customerProfileForm.country': 'País',
  'customerProfileForm.streetAddress': 'Dirección de la calle',
  'customerProfileForm.postalCode': 'Código postal',
  'customerProfileForm.city': 'Ciudad',
  'customerProfileForm.vatId': 'Número de pagador del IVA',
  'customerProfileForm.referralCode': 'Código de referencia',
  'customerProfileForm.placeholder.firstName': 'Por favor, introduzca el nombre',
  'customerProfileForm.placeholder.lastName': 'Por favor, introduzca su apellido',
  'customerProfileForm.placeholder.email':
    'Por favor, introduzca la dirección de correo electrónico',
  'customerProfileForm.placeholder.organization':
    'Por favor, introduzca el nombre de la organización',
  'customerProfileForm.placeholder.country': 'Por favor, introduzca el país',
  'customerProfileForm.placeholder.streetAddress': 'Por favor, introduzca la dirección de la calle',
  'customerProfileForm.placeholder.postalCode': 'Por favor, introduzca el código postal',
  'customerProfileForm.placeholder.city': 'Por favor, introduzca la ciudad',
  'customerProfileForm.placeholder.vatId': 'Por favor, introduzca el número de IVA',
  'customerProfileForm.placeholder.referralCode': 'Introduzca el código de referencia',
  'customerProfileForm.industry': 'Industria',
  'customerProfileForm.industry.governmental': 'Gubernamental',
  'customerProfileForm.industry.education': 'Educación',
  'customerProfileForm.industry.healthcare': 'Sanidad',
  'customerProfileForm.industry.retail': 'Venta al por menor',
  'customerProfileForm.industry.wholesale': 'Venta al por mayor',
  'customerProfileForm.industry.rental': 'Alquiler',
  'customerProfileForm.industry.banking': 'Banca/finanzas',
  'customerProfileForm.industry.transportation': 'Transporte',
  'customerProfileForm.industry.hospitality': 'Hospitalidad',
  'customerProfileForm.industry.hotel': 'Hotelero y recreativo',
  'customerProfileForm.industry.travel': 'Viajes y turismo',
  'customerProfileForm.industry.other': 'Otros',

  'onboarding.wait':
    'Por favor, espere mientras creamos su perfil de usuario y configuramos todo lo necesario',
  'onboarding.createPlace.wait': 'Por favor, espere, mientras cargamos...',
  'onboarding.loading': 'Cargando...',
  'onboarding.back': 'atrás',
  'onboarding.next': 'próximo',
  'onboarding.skip': 'Saltar al espacio en blanco',
  'onboarding.step1.header': 'Hacer citas con 2Meters',
  'onboarding.step1.text.p1':
    'Para habilitar citas, simplemente configure su lugar completando sus datos básicos:',
  'onboarding.step1.text.list1.item1': 'Nombre y ubicación',
  'onboarding.step1.text.list1.item2': 'Horas y días laborables',
  'onboarding.step1.text.list1.item3': 'Duraciones típicas de las citas',
  'onboarding.step1.text.p2':
    'Para brindar un servicio aún mejor, puede configurar una lista de los servicios o bienes que ofrece, de modo que los clientes puedan informarle el motivo de su visita con anticipación.',
  'onboarding.step1.text.p3':
    'Para tener un control total sobre su horario, puede activar una función que le permite confirmar o rechazar citas reservadas de manera preliminar por sus clientes.',
  'onboarding.step2.header': 'Hacer citas con 2Meters',
  'onboarding.step2.text.p1':
    'Después de la configuración, simplemente copie el enlace proporcionado en un correo electrónico o colóquelo en su sitio web donde sus clientes pueden hacer clic en él o tocarlo.',
  'onboarding.step2.text.p2':
    'Alternativamente, puede integrar nuestra interfaz interactiva en su sitio web a través de un fragmento de código.',
  'onboarding.step3.header': 'Hacer citas con 2Meters',
  'onboarding.step3.text.p1':
    '¡Dé la bienvenida a sus clientes de su primera cita! Puede identificarlos fácilmente solicitando que proporcionen los boletos electrónicos guardados en sus teléfonos inteligentes.',
  'onboarding.step3.text.p2':
    'Si tiene que atender a los clientes sin cita previa además de las citas, le encantará saber que nuestra función de cola digital fusiona perfectamente ambos flujos de clientes. Pero, ¿cómo funciona?',
  'onboarding.step4.header': 'Elimina colas con 2Meters',
  'onboarding.step4.text.p1':
    'Nuestra cola digital ofrece exactamente lo que sus clientes esperan de un concepto de servicio moderno: los teléfonos inteligentes hacen la espera mientras sus propietarios pueden salir a caminar o tomar una taza o un café.',
  'onboarding.step4.text.p2':
    'Notificamos a su cliente a medida que avanza la cola, para que puedan aparecer justo a tiempo.',
  'onboarding.step4.text.p3':
    'Para crear una cola digital, simplemente habilite esta funcionalidad mientras crea su lugar.',
  'onboarding.step5.header': 'Elimina colas con 2Meters',
  'onboarding.step5.text.p1':
    'Imprima un PDF con el código QR proporcionado y colóquelo en cualquier lugar donde desee que sus clientes hagan cola digitalmente.',
  'onboarding.step5.text.p2':
    'Configure una pantalla para mostrar todos los boletos que se acercan y que se encuentran en servicio actualmente y haga que su lugar se vea y se sienta como un moderno centro de servicio de Silicon Valley.',
  'onboarding.step6.header': 'Elimina colas con 2Meters',
  'onboarding.step6.text.p1':
    'Hay muchas más funciones para explorar. Contáctenos, si necesita información adicional sobre cómo puede ajustar su cola digital para que se ajuste a su caso personal. Preparamos algunas plantillas preconfiguradas para ti. Seleccione una plantilla y ajústela a sus necesidades o cree un lugar nuevo. Que te gustaría hacer?',
  'onboarding.step6.btn1': 'Crear un lugar de demostración con cola en vivo (barbería)',
  'onboarding.step6.btn2': 'Crear un lugar de demostración con citas (barbería)',
  'onboarding.step6.btn3': 'Crea un sistema de citas o una cola digital',
  'onboarding.template.barbershop.name': 'Demo Place (peluquería)',
  'onboarding.template.barbershop.description':
    'La barba es tendencia. El cabello de la cara solo se vuelve realmente hermoso con un buen cuidado y un peinado perfecto. Abrimos un lugar donde los hombres pueden relajarse y estar entre ellos. Puede esperar consejos individuales sobre el cuidado y peinado del cabello / barba, ¡pero las manos y los pies bien cuidados también son parte de esto! En un ambiente clásico, entre elegantes sillones de cuero, accesorios de madera, paredes de ladrillo y un salón integrado para whisky y puros, hay limado, pelado y afeitado.',
  'onboarding.template.barbershop.service1': 'Corte de pelo regular',
  'onboarding.template.barbershop.service2': 'Corte de barba',
  'onboarding.template.barbershop.service3': 'Teñir',
  'onboarding.template.barbershop.service4': 'Corte de contorno',
  'onboarding.template.default.service1': 'Mi servicio 1',
  'onboarding.template.default.service2': 'Mi servicio 2',
  'onboarding.template.covid.name': 'Centro de pruebas de Covid (plantilla)',
  'onboarding.template.popup.name': 'Tienda emergente sin cita (plantilla)',
  'onboarding.template.shopping.name': 'Compras por cita (plantilla)',
  'onboarding.template.restaraunt.name': 'Restaurante (plantilla)',
  'admin.place.livequeueUrlInfo': 'Enlace a la cola en vivo',
  'admin.place.settings': 'Configuración',
  'admin.place.settings.text':
    'En esta página puede habilitar diferentes características para su lugar',
  'admin.place.editSetting': 'Editar lugar',
  'admin.place.editManagers': 'Editar los gestores',
  'admin.place.navigation.overview': 'Resumen',
  'admin.place.navigation.dashboard': 'Salpicadero',
  'admin.place.navigation.queues': 'Encimera',
  'admin.place.navigation.monitor': 'Monitor',
  'admin.place.navigation.billing': 'Facturación',
  'admin.place.navigation.backToPlaces': 'Volver a lugares',
  'admin.place.navigation.back': 'atrás',
  'admin.place.averageDuration': 'Duración de la ranura (minutos)',
  'admin.place.capacity.help': 'Cuántos clientes pueden ingresar a su lugar al mismo tiempo',
  'admin.place.capacity': 'Capacidad de la tienda',
  'admin.place.closingTime': 'Hora de cierre',
  'admin.place.openingTime': 'Hora de apertura',
  'admin.place.openingDays': 'Días de apertura',
  'admin.place.openingDate': 'Fecha de inicio de la campaña',
  'admin.place.openingDate.help':
    'Si tiene la intención de usar 2Meters por un período limitado, ingrese la fecha en la que se habilitará',
  'admin.place.closingDate': 'Fecha de finalización de la campaña',
  'admin.place.closingDate.help':
    'Si tiene la intención de usar 2Meters por un período limitado, ingrese la fecha en la que se apagará el sistema',
  'admin.place.contactEmail': 'Correo electrónico de contacto del administrador del lugar',
  'admin.place.contactEmail.help':
    'Puede completar este campo, si desea que su correo electrónico de contacto aparezca en las notificaciones por correo electrónico que enviaremos a sus visitantes (solo si les pedirá que proporcionen sus direcciones de correo electrónico en esta configuración)',
  'admin.place.contactPhoneNumber': 'Número de teléfono de contacto del administrador del lugar',
  'admin.place.contactPhoneNumber.help':
    'Puede completar este campo si desea que su número de teléfono de contacto aparezca en las notificaciones por correo electrónico que enviaremos a sus visitantes (solo si les solicita que proporcionen sus direcciones de correo electrónico en esta configuración)',
  'admin.place.description': 'Proporcione una breve descripción de su lugar y sus servicios.',
  'admin.place.description.help': 'Tus clientes verán esta descripción',
  'admin.place.timezone': 'Zona horaria',
  'admin.place.noOptions': 'No hay opciones',
  'admin.place.toVisitors': 'Pagina de visitantes',
  'admin.place.printQR': 'imprimir QR',
  'admin.place.shortUrl':
    'Elija un código corto para permitir que sus visitantes accedan rápidamente a su página.',
  'admin.place.shortUrl.error': 'Esta URL corta ya está en uso. Prueba uno diferente',
  'admin.place.shortUrl.help':
    'Si elige, por ejemplo, "bbq" para su lugar de barbacoa, será accesible desde cualquier navegador en app.2meters.app/p/bbq',
  'admin.place.shortUrlInfo': 'Enlace a su lugar',
  'admin.place.monitorUrlInfo': 'Enlace a la vista del monitor',
  'admin.place.qrCode': 'Código QR para tu lugar',
  'admin.place.address': 'Dirección de tu lugar',
  'admin.place.yourAppointment': 'Su cita',
  'admin.place.appointments.delete': 'Borrar',
  'admin.place.appointments.cancel': 'Cancelar',
  'admin.place.addAppointmentDialogTitle': 'Agregar cita',
  'admin.place.addAppointmentDialogDescription':
    'Puede agregar un apodo de visitante y una breve descripción a la cita',
  'admin.place.appointmentNickname': 'Apodo',
  'admin.place.appointmentDescription': 'Descripción',
  'admin.place.visitType': 'Proporcione una lista de los servicios que ofrece.',
  'admin.place.visitType.help':
    'Sus clientes deberán elegir el servicio de esta lista al reservar una cita.',
  'admin.place.managers': 'Lista de administradores de lugares',
  'admin.place.managers.help':
    'Ingrese direcciones de correo electrónico para asignar roles de gerente a sus empleados. Confirme cada correo electrónico con Enter.',
  'admin.place.titles.settings': 'Descripción general del lugar',
  'admin.place.wizard.titles.details': 'Detalles del lugar',
  'admin.place.wizard.titles.visitorData': 'Datos de visitantes',
  'admin.place.wizard.titles.inventory': 'Tu inventario',
  'admin.place.wizard.titles.queue': 'Cola en vivo',
  'admin.place.wizard.titles.appointments': 'Horario',
  'admin.place.wizard.titles.access': 'Acceso',
  'admin.place.wizard.titles.plans': 'Elija su plan',
  'admin.place.wizard.details': 'Detalles',
  'admin.place.wizard.visitorData': 'Datos de visitantes',
  'admin.place.wizard.inventory': 'Inventario',
  'admin.place.wizard.settings': 'Configuración',
  'admin.place.wizard.schedule': 'Horario',
  'admin.place.wizard.queue': 'Cola',
  'admin.place.wizard.appointment': 'Cita',
  'admin.place.wizard.access': 'Acceso',
  'admin.place.wizard.plans': 'Planes',
  'admin.place.wizard.actions.next': 'próximo',
  'admin.place.wizard.actions.trial': 'Iniciar prueba',
  'admin.place.wizard.actions.finish': 'Terminar',
  'admin.place.wizard.actions.saveAll': 'Ahorrar',
  'admin.place.wizard.actions.back': 'atrás',
  'admin.place.wizard.actions.cancel': 'Cancelar',
  'admin.place.wizard.actions.agree': 'De acuerdo',
  'admin.place.wizard.actions.create': 'Crear',
  'admin.place.wizard.whatsappStep.title': 'Acepta recibir notificaciones por WhatsApp?',
  'admin.place.wizard.whatsappStep.accept': 'Aceptar',
  'admin.place.wizard.whatsAppModal.title': 'Notificaciónes de WhatsApp',
  'admin.place.wizard.whatsAppModal.description':
    'Tenga en cuenta, al activar las notificaciones de WhatsApp debe considerar, que se aplicarán cargos adicionales. Le concedemos 100 mensajes de WhatsApp gratuitos, que puede utilizar para hacer pruebas. Sin embargo, por cualquier mensaje de WhatsApp que se envíe por encima de eso, le cobraremos un precio, que depende del país del que provengan sus destinatarios de WhatsApp. Crearemos facturas adicionales y las enviaremos por separado cada mes. Puedes desactivar esta función en cualquier momento. Si necesita información detallada sobre los precios, póngase en contacto con nuestro departamento de ventas en ',
  'admin.place.wizard.requiredFields': 'Por favor llene todos los campos requeridos',
  'admin.place.wizard.smsModal.title': 'Notificaciones SMS',
  'admin.place.wizard.smsModal.description':
    'Tenga en cuenta que al activar las notificaciones por SMS debe considerar que se aplicarán cargos adicionales. Le concedemos 100 SMS gratuitos, que puede utilizar para probar. Sin embargo, para cualquier SMS enviado por encima de eso, le cobraremos un precio, que depende y es comparable a los precios de los proveedores locales de telefonía móvil del país del que provienen sus visitantes. Crearemos facturas adicionales y las enviaremos por separado cada mes. Puede desactivar esta función en cualquier momento. Si necesita información detallada sobre los precios, póngase en contacto con nuestro departamento de ventas en ',
  'admin.place.queue.text':
    'Nuestra exclusiva función Live Queue permite a sus visitantes hacer cola digitalmente escaneando un código QR o ingresando la URL corta de su lugar en la ventana de su navegador. Puede administrar fácilmente la cola desde su mostrador, lo que garantiza un servicio rápido y conveniente para todos los visitantes. Nuestro sistema fusiona a la perfección la cola en vivo con las citas tradicionales: los clientes de citas se insertan automáticamente en la cola en vivo poco antes de la hora programada. Marque la casilla a continuación para habilitar las colas en vivo en su lugar.',
  'admin.place.inventory.text':
    'Si vende algún artículo y quiere que sus visitantes lo pidan, sólo tiene que añadirlo a su inventario. La activación del inventario crea un paso adicional en la parte del visitante de la aplicación. donde un visitante puede reservar o comprar cualquiera de sus artículos. Puede configurar el stock de sus artículos o establecerlo como ilimitado. También puede optar por restringir la cantidad máxima de artículos que van a\n' +
    'una mano para asegurarse de que todos sus clientes tendrán su parte.',
  'admin.place.visitorData.text':
    'Elegimos deliberadamente permitir el anonimato total del usuario, pero en algunos casos de uso puede ser necesario recopilar datos para proporcionar su servicio. Aquí puede elegir qué desea saber sobre de sus clientes cuando piden una cita o hagan cola. Si opta por recopilar sus correos electrónicos, también podremos enviarles notificaciones por correo electrónico, que pueden ser amigables para el cliente. Tenga en cuenta que los datos personales que deja su cliente, si ha elegido alguna opción, se eliminarán de nuestros servidores dos días después de la visita.',
  'admin.place.appointments.text':
    'Configure su horario de apertura y de servicio para definir cómo funcionarán nuestras funciones digitales de gestión de colas y citas. Ofrecemos dos tipos de gestión de horarios. Simple y Avanzado. Con la Simple, usted sólo establece sus días y horas de trabajo. Con el Avanzado, usted tiene el control total del horario.',
  'admin.place.access.text':
    'Si tiene empleados, es posible que desee que tengan acceso a la vista del contador sin compartir sus credenciales. Desde la vista del mostrador, los empleados pueden administrar las citas y la cola en vivo. No pueden cambiar la configuración ni ninguna otra propiedad vital de su lugar. Para asignar roles de gerente a sus empleados, ingrese sus direcciones de correo electrónico a continuación.',
  'admin.place.cancelDialog': 'Cancelar',
  'admin.place.rte.text': 'Texto',
  'admin.place.rte.link': 'Enlace',
  'admin.place.rte.addLink': 'Añadir enlace',
  'admin.place.rte.remove': 'Remover',
  'admin.place.rte.save': 'Save',
  'admin.place.addAppointmentManually': 'Agregar',
  'admin.place.livequeue.hint': 'Habilite la cola digital en su lugar',
  'admin.place.businessName': '¿Cuál es el nombre de tu lugar?',
  'admin.place.fullscreen': 'Pantalla completa',
  'admin.place.monitor': 'Monitor',
  'admin.account.billingLog': 'Registro de facturación',
  'admin.account.account': 'Cuenta',
  'admin.account.signOut': 'desconectar',
  'admin.account.notifications': 'Notificaciones',
  'admin.account.billing': 'Planes y facturación',
  'admin.place.form.inventory': '¿Qué productos ofrece su lugar?',
  'admin.place.form.inventory.help':
    'Su visitante podrá elegir un producto mientras hace una cita o se registra en la cola digital. Deje esto vacío, si no vende ningún producto.',
  'admin.place.form.askEmail': 'Correo electrónico',
  'admin.place.form.askPhone': 'Número de teléfono',
  'admin.place.form.phoneVerificationEnabled': 'Validación de SMS',
  'admin.place.form.phoneVerificationDisabled':
    'Validación de SMS (no disponible en la suscripción privada)',
  'admin.place.form.askName': 'Nombre',
  'admin.place.form.askAddress': 'Habla a',
  'admin.place.form.askBirthDate': 'Fecha de nacimiento',
  'admin.place.form.useMailMessenger': 'Notificaciones por email',
  'admin.place.form.useUIMessenger': 'Notificaciones en la aplicación',
  'admin.place.form.useWebPushMessenger': 'Notificaciones push (Android)',
  'admin.place.form.useWhatsAppMessenger': 'Notificaciones de WhatsApp',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'Notificaciones de WhatsApp (no disponible en la suscripción privada)',
  'admin.place.form.useSMSMessenger': 'Notificaciones por SMS',
  'admin.place.form.useSMSMessengerDisabled':
    'Notificaciones por SMS (no está disponible en la suscripción privada)',
  'admin.place.form.askVaccinationStatus': 'Estado de vacunación de Covid-19',
  'admin.place.form.vaccinationStatus': 'Estado de vacunación de Covid-19',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Marque esto, si quiere que sus visitantes proporcionen su estado de vacunación',
  'admin.place.form.numberOfVisitors': 'Número de visitantes',
  'admin.place.form.askNumberOfVisitors': 'Número de visitantes',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Comprueba esto, si tus visitantes pueden venir en grupo y quieres saber su número',
  'admin.place.form.askWhat': 'Seleccione la información de usuario obligatoria',
  'admin.place.form.askWhat.help':
    'Para permitir el anonimato total de los visitantes, deje todas las casillas sin marcar. Los datos de los visitantes se eliminarán automáticamente de nuestros servidores dentro de los dos días posteriores a la visita.',
  'admin.place.form.messagingConfiguration': 'Configuración de la mensajería',
  'admin.place.form.messagingConfiguration.help':
    'Puede elegir entre varios canales para notificar a sus visitantes el estado de sus entradas. ',
  'admin.place.form.enableFeatures': 'Habilitar funciones adicionales',
  'admin.place.form.allowAppointmentConfirmation': 'Requiere confirmación',
  'admin.place.form.allowAppointmentConfirmation.help':
    'Compruebe si desea confirmar o rechazar citas manualmente. Sus clientes serán notificados por correo electrónico.',
  'admin.place.form.placeLocale': 'Idioma del lugar predeterminado',
  'admin.place.form.placeLocale.help':
    'Un idioma que usaremos para enviar notificaciones a sus visitantes, si no han establecido su idioma preferido.',
  'admin.place.embedCode.title': 'Código de inserción',
  'admin.place.embedCode.description':
    'Utilice este código para incrustar el widget 2Meters en su sitio web',
  'admin.place.appointments.enable': 'Habilitar citas para este lugar',
  'admin.place.livequeue.enable': 'Habilita la cola en vivo para este lugar',
  'admin.place.form.schedulerType': 'Seleccione un tipo de horario',
  'admin.place.form.schedulerType.help':
    'El horario avanzado se encuentra en una etapa temprana de desarrollo. No ofrecemos garantías al respecto. Úselo y envíenos sus comentarios.',
  'admin.place.form.settings.constraints.help': 'Restricciones',
  'admin.place.form.settings.constraints.appsperuser.label':
    '¿Cuántos boletos puede tomar un usuario a la vez?',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'Uno a la vez',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'Uno por servicio',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Ilimitada',
  'admin.place.inventory.appointmentsNotAvailable':
    'El programador de franjas horarias para las citas no está disponible, mientras que las citas están desactivadas',

  'admin.userProfile.title': 'Perfil del usuario',
  'admin.profile.title': 'Perfil',
  'admin.profile.editProfile': 'Editar perfil',

  'admin.place.shopCapacity.help':
    '¿A cuántos clientes puede atender al mismo tiempo? Cada intervalo de tiempo puede ser reservado por este número de veces por diferentes clientes.',
  'admin.place.averageDuration.help':
    'Sus horas de trabajo se dividirán en las franjas horarias de la siguiente duración',
  'admin.place.inventory.enable': 'Habilitar productos para este lugar',
  'admin.place.inventory.placeCurrency': 'Divisa',
  'admin.place.inventory.placeCurrency.help':
    'Este valor se utilizará para mostrar a sus visitantes los precios correctos.',
  'admin.place.inventory.inventoryMaxOrder': 'Max artículos por pedido',
  'admin.place.inventory.inventoryMaxOrder.help':
    'La cantidad de artículos que un visitante puede pedir en su lugar. Deje este campo vacío, si su stock es ilimitado.',
  'inventory.quantity': 'Existencias',
  'inventory.maxPerUser': 'Límite',
  'inventory.newProduct': 'Nuevo producto',
  'inventory.editProduct': 'Editar producto',
  'inventory.edit': 'Editar',
  'inventory.delete': 'Borrar',
  'inventory.menu': 'Menú',
  'inventory.duplicate': 'Duplicado',
  'inventory.addProduct': 'Añadir producto',
  'inventory.enterProductData': 'Ingrese los datos de su producto',
  'inventory.productName': 'Nombre',
  'inventory.productName.placeholder': 'Este campo no debe estar vacío',
  'inventory.productDescription': 'Descripción',
  'inventory.productDescription.placeholder': 'Este campo no debe estar vacío',
  'inventory.productStock': 'Existencias',
  'inventory.productStock.placeholder': 'Por favor, deje el campo vacío, si su stock es ilimitado.',
  'inventory.unlimited': 'ilimitado',
  'inventory.product.crop': 'Crop',
  'inventory.product.cropTitle': 'Recortar la foto',
  'inventory.product.uploadImage': 'Cargar',
  'inventory.price': 'Precio',
  'inventory.price.placeholder': 'Precio del producto',
  'admin.subscription': 'Suscripción',
  'admin.subscription.trial': 'Juicio',
  'admin.subscription.nextInvoice': 'Próxima factura',
  'admin.subscription.cancelAt': 'Se cancelará la suscripción',
  'admin.subscription.cancel': 'Cancelar',
  'admin.subscription.sure_cancel': '¿Estás seguro de que deseas cancelar tu suscripción?',
  'admin.subscription.cancelled': 'Se canceló la suscripción',
  'admin.subscription.trial_description':
    'El período de prueba comenzará de inmediato y finalizará en 30 días. Durante el período de prueba, todas las funciones estarán disponibles para usted. No le pediremos información de pago para comenzar la prueba. Al final del período de prueba, el lugar se cambiará automáticamente a un plan privado. Si te gusta nuestro servicio, suscríbete en cualquier momento. La versión de prueba solo se puede utilizar una vez.',
  'admin.subscription.trial_started': 'El período de prueba ha comenzado y finalizará el {date}',
  'admin.places.list.empty.createNew': 'Crear nuevo lugar',
  'admin.places.list.ticketsUsed': 'Entradas utilizadas',
  'admin.places.list.empty.header': 'Aún no tienes lugares',
  'admin.places.list.empty.body': 'Cree un nuevo lugar y comencemos a manejar la cola juntos',
  'admin.places.list.header': 'Tus lugares',
  'admin.places.list.managed': 'Tus lugares',
  'admin.places.list.createNew': 'Crear nuevo lugar',
  'admin.places.create.title': 'Crea un lugar',
  'admin.places.update.title': 'Actualizar un lugar',
  'qr.print': 'Imprima este documento y colóquelo donde sus clientes puedan verlo fácilmente',
  'qr.pleaseScanAppointments': 'Escanee este código QR para programar una cita',
  'qr.pleaseScanLivequeue': 'Escanee este código QR para hacer cola',
  'qr.pleaseScanIntegrated': 'Escanee este código QR para hacer cola o concertar una cita',
  'qr.followThisLink': 'O simplemente sigue esta URL',
  'qr.error': 'Algo salió mal. Inténtelo más tarde, por favor',
  'counter.processing': 'Procesando',
  'counter.processing.add': 'Deja entrar el siguiente',
  'counter.liveQueue': 'Cola en vivo',
  'counter.stopQueue': 'Sin pausa',
  'counter.startQueue': 'En pausa',
  'counter.liveQueue.add': 'Agregar nuevo',
  'counter.calendar': 'Calendario',
  'counter.appointments': 'Horas de entrada',
  'counter.appointments.add': 'Agregar nuevo',
  'counter.appointments.dialog.header': 'Reservar una cita',
  'counter.appointments.dialog.edit.header': 'Editar billete {tag}',
  'counter.appointments.dialog.comment.header': 'Añadir un comentario a {tag}',
  'counter.appointments.dialog.add': 'Libro',
  'counter.appointments.dialog.cancel': 'Cancelar',
  'counter.appointments.dialog.selectDate': 'Seleccione fecha',
  'counter.appointments.dialog.pickTime': 'Hora de la recogida',
  'counter.appointments.dialog.availableTime':
    'Tiempo disponible (haga clic en diferentes espacios para crear varias citas a la vez)',
  'counter.appointments.dialog.noAvailableTimeSlot':
    'No hay intervalos de tiempo disponibles en la fecha seleccionada para el servicio dado.',
  'counter.appointments.dialog.noAvailableTime':
    'No hay franjas horarias disponibles en la fecha seleccionada para un servicio determinado',
  'counter.appointments.dialog.noAvailableTimeToday':
    'No hay espacios de tiempo disponibles para hoy',
  'counter.appointments.dialog.selectedSlots': 'Se crearán las siguientes citas',
  'counter.appointments.dialog.selectService':
    'Seleccione el tipo de servicio para ver las franjas horarias disponibles',
  'counter.appointments.dialog.serviceType': 'Tipo de servicio',
  'counter.appointments.dialog.order': 'Pedido',
  'counter.liveQueue.dialog.header': 'Cola en',
  'counter.liveQueue.dialog.cancel': 'Cancelar',
  'counter.userInfo.nickname': 'Nombre',
  'counter.userInfo.email': 'Correo electrónico',
  'counter.userInfo.phone': 'Teléfono',
  'counter.userInfo.birthDate': 'Fecha de nacimiento',
  'counter.userInfo.address': 'Dirección',
  'counter.toQueue': 'Cola en',
  'counter.invite': 'Invitación',
  'counter.processed': 'Procesada',
  'counter.invited': 'Invitado',
  'counter.noname': 'Sin nombre',
  'counter.walkin': 'Sin cita',
  'counter.done': 'Hecho',
  'counter.error': 'Se produjo un error, comuníquese con el servicio de asistencia: {error}',
  'counter.info.dialog.notset': 'no establecido',
  'counter.appointments.info.queueIn': 'Cola en',
  'counter.info.dialog.sureCancel': '¿Quiere rechazar a este cliente?',
  'counter.info.dialog.time': 'Hora',
  'counter.info.dialog.email': 'Correo electrónico',
  'counter.info.dialog.phone': 'Teléfono',
  'counter.info.dialog.username': 'Nombre',
  'counter.info': '...',

  'counter.appointment.status.noShow': 'No apareció',
  'counter.appointment.status.waiting': 'Pendiente',
  'counter.appointment.status.confirmed': 'Confirmado',
  'counter.appointment.status.queued': 'En cola',
  'counter.appointment.status.invited': 'Invitado',
  'counter.appointment.status.scheduled': 'Programado',
  'counter.appointment.status.walkin': 'Entrar',
  'counter.appointment.status.processed': 'Procesado',
  'counter.appointment.status.cancelled': 'Cancelado',
  'counter.appointment.status.rejected': 'Rechazado',

  'counter.table.header.ticket': 'Billete',
  'counter.table.header.when': 'Cuando',
  'counter.table.header.name': 'Nombre',
  'counter.table.header.email': 'Email',
  'counter.table.header.service': 'Servicio',
  'counter.table.header.status': 'Estatus',
  'counter.table.header.statusChanged': 'Estado modificado',
  'counter.table.header.type': 'Tipo',

  'counter.appointment.action.noShow': 'No apareció',
  'counter.appointment.action.invite': 'Invitación',
  'counter.appointment.action.queueIn': 'Añadir a la cola',
  'counter.appointment.action.done': 'Hecho',
  'counter.appointment.action.confirm': 'Confirmar',
  'counter.appointment.action.cancel': 'Cancelar',
  'counter.appointment.action.reject': 'Rechazar',
  'counter.appointment.action.edit': 'Editar',
  'counter.appointment.action.comment': 'Comentario',
  'counter.appointment.action.noComment': 'No hay comentarios todavía',
  'counter.appointment.action.backToQueue': 'Volver a la cola',
  'counter.appointment.action.backToAppointment': 'Volver a la cita',
  'counter.showOnly': 'Mostrar solo',
  'counter.filter.all': 'Todo',
  'counter.filter.allServices': 'Todos los servicios',
  'counter.noAppointments': 'No hay citas para la fecha seleccionada',
  'counter.noAppointmentsToday': 'No hay citas concertadas para hoy',
  'counter.noTickets': 'No hay entradas hechas para la fecha seleccionada',
  'counter.tabs.today': 'Hoy',
  'counter.tabs.calendar': 'Historia',
  'counter.filter.types.noShow': 'No apareció',
  'counter.filter.types.active': 'Aktive Tickets',
  'counter.filter.types.rejected': 'Abgelehnt',
  'counter.filter.types.cancelled': 'Abgesagt',
  'counter.filter.types.processed': 'Verarbeitet',
  'counter.filter.types.notConfirmed': 'Nicht bestätigt',
  'counter.tabs.counterName': 'Nombre del mostrador',
  'counter.tabs.anyName': 'Cualquier nombre',

  'admin.place.form.settings.automation.help': 'Automatización',
  'admin.place.form.settings.automation.processing.help':
    'Establezca en blanco si prefiere limpiar a mano',
  'admin.place.form.settings.automation.processing.label':
    'min. Eliminar boletos invitados desde la pantalla después de esta hora',

  'admin.place.form.settings.automation.eta.auto': 'Auto',
  'admin.place.form.settings.automation.eta.constant': 'Constante',
  'admin.place.form.settings.automation.eta.off': 'Apagada',
  'admin.place.form.settings.automation.eta.label':
    'Modo para calcular el tiempo de espera estimado',
  'admin.place.form.settings.automation.eta.help': `Se calcula la forma en que salió el tiempo de espera para un boleto de cola en particular.\n
    Auto: Tiene en cuenta un tiempo promedio de los últimos 6 boletos procesados. \n
    Constante: Puede especificar el tiempo que debe tomar para procesar un boleto. \n
    Apagada: No se mostrará el tiempo de espera estimado.
  `,
  'admin.place.form.settings.automation.eta.constant.label': 'minutos. Hora de procesar un boleto',
  'admin.place.form.settings.automation.eta.constant.help':
    'Se usará para estimar el tiempo de espera en la cola',

  // Notifications
  'notifications.title': 'Notificaciones por SMS',
  'notifications.informationBanner.sms': 'SMS enviados',
  'notifications.informationBanner.whatsapp': 'Envío de whatsapp',
  'notifications.progressBar.freeSms': 'Todavía disponible en el contingente libre',
  'notifications.progressBar.exhaustedWhatsapp': 'Piscina de whatsapp gratis agotada',
  'notifications.informationBanner.totalSmsCost': 'Total SMS cost',
  'notifications.informationBanner.totalWhatsappCost': 'Coste total de Whatsapp',
  'notifications.progressBar.exhaustedSms': 'Piscina de sms gratis agotada',
  'notifications.informationBanner.totalCost': 'Coste total',
  'notifications.table.recipient': 'Destinatario',
  'notifications.table.date': 'Fecha',
  'notifications.table.time': 'Tiempo',
  'notifications.table.place': 'Lugar',
  'notifications.table.type': 'Tipo',
  'notifications.table.price': 'Precio',
  'notifications.table.country': 'País',
  'notifications.table.message': 'Mensaje',
  'notifications.empty.header':
    'Sus plazas no han generado aún ninguna notificación de pago en el periodo de tiempo indicado',
  'notifications.filter.startDate': 'Fecha de inicio',
  'notifications.filter.endDate': 'Fecha de finalización',
  'notifications.exportTable.status': 'Estado',
  'notifications.exportTable.type': 'Tipo',
  'notifications.exportTable.isEmpty': 'está vacío',

  // Export Appointments
  'exportAppointments.tickets': 'Entradas',
  'exportAppointments.tag': 'Etiqueta',
  'exportAppointments.date': 'Fecha',
  'exportAppointments.time': 'Tiempo',
  'exportAppointments.service': 'Servicio',
  'exportAppointments.type': 'Tipo',
  'exportAppointments.name': 'Nombre',
  'exportAppointments.status': 'Estatus',
  'exportAppointments.statusChangeDate': 'Fecha de cambio de estado',
  'exportAppointments.statusChangeTime': 'Tiempo de cambio de estado',
  'exportAppointments.phone': 'Teléfono',
  'exportAppointments.email': 'Email',
  'exportAppointments.address': 'Dirección',
  'exportAppointments.birthDate': 'Fecha de nacimiento',
  'exportAppointments.comments': 'Comentarios',
  'exportAppointments.appointment': 'Cita',
  'exportAppointments.queue': 'Cola de espera',

  'admin.place.form.settings.appointments.help':
    'Los nombramientos permiten a sus clientes detectar un Timestol predefinido para su visita',
  'admin.place.form.settings.appointments.label': 'Habilitar citas',
  'admin.place.form.settings.livequeue.label': 'Habilitar livequeue',
  'admin.place.form.settings.livequeue.help':
    'A diferencia de las citas, LiveQueue permite a sus clientes establecer un lugar en una cola sin un tiempo definido',
  'admin.place.form.settings.livequeue.maxLength.label': 'Longitud máxima de la cola',
  'admin.place.form.settings.livequeue.maxLength.help':
    'Ningún nuevo cliente puede hacer cola en si la longitud de la cola llega a este número',
  'admin.place.form.settings.livequeue.openingHours.label': 'Solo en horario de apertura',
  'admin.place.form.settings.livequeue.openingHours.help':
    'Cuando está habilitado, sus clientes se limitarán a la cola en el exterior del horario comercial',
  'admin.place.form.settings.appointment.confirmation.label': 'Requiere confirmación',
  'admin.place.form.settings.appointment.confirmation.help':
    'Compruebe si desea confirmar o rechazar manualmente las citas.Sus clientes serán notificados por correo electrónico.',

  'qr.neverWaste': '2 metros. No vuelvas a perder la vida en colas.',
  'qr.header': 'Escanee este código qr para reclamar su franja horaria',
  'plans.text':
    'Todas las funciones del servicio 2Meters se pueden utilizar de forma gratuita, pero se limitan a 100 boletos al mes. Esto incluye entradas para citas y cola. Con el plan Pro no impone ninguna limitación',
  'plans.free': 'Gratis*',
  'plans.month.place': '/MES/LUGAR',
  'plans.preloader':
    'Por favor, espere mientras engrasamos los engranajes antes de procesar su pago',
  'plans.list.private': 'Privado',
  'plans.list.pro': 'Pro',
  'plans.list.basic_monthly': 'Pro',
  'plans.list.basic_annual': 'Pro',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.business': 'Negocio',
  'plans.list.business_annual': 'Negocio',
  'plans.list.business_monthly': 'Negocio',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.enterprise': 'Enterprise',
  'plans.list.trial': 'Juicio',
  'plans.chooseAPlan': 'Elige un plan',
  'plans.ps':
    '* cobraremos costes adicionales por el consumo de notificaciones SMS y Whatsapp al final de cada mes si decides utilizarlas. Los precios son los típicos del país de origen del número de teléfono del destinatario y dependen de los precios de los proveedores locales. Para obtener información detallada, póngase en contacto con el departamento de ventas.',
  'plans.promo': 'Brinde a su equipo las características que necesitan para tener éxito.',
  'plans.contactUs': 'Contáctenos',
  'plans.missingFeature': '¿Echa en falta alguna función?',
  'plans.currentPlan': 'Plan actual',
  'plans.upgradePlan': 'Plan de actualización',
  'plans.subscription.cancelAt': 'Válida hasta',
  'plans.cancelSubscription': 'Cancelar suscripciónn',
  'plans.private.features.1': '100 visitantes al mes',
  'plans.private.features.2': 'Ilimitados clientes App',
  'plans.private.features.3': 'Sistema de colas en vivo',
  'plans.private.features.4': 'Slot Appointment system',
  'plans.private.features.5': 'Catálogo de productos',
  'plans.private.features.6': 'Sistema de pedidos',
  'plans.private.features.7': 'Intuitive Counter App',
  'plans.private.features.8': 'Calendario histórico',
  'plans.private.features.9': 'Display App',
  'plans.private.features.10': 'SMS/Whatsapp/Email/Push',
  'plans.pro.features.first': 'Igual que Privado',
  'plans.pro.features.1': 'Visitantes ilimitados',
  'plans.pro.features.2': 'Ilimitados clientes de App',
  'plans.pro.features.3': 'Validación de visitantes por SMS',
  'plans.pro.features.4': 'Análisis de rendimiento',
  'plans.pro.features.5': 'Formularios de visitantes personalizados',
  'plans.pro.features.6': 'Contadores personalizados',
  'plans.pro.features.7': 'Soporte ampliado',
  'plans.enterprise.features.first': 'Igual que Pro',
  'plans.enterprise.features.1': 'Visitantes ilimitados',
  'plans.enterprise.features.2': 'Clientes de aplicaciones ilimitados',
  'plans.enterprise.features.3': 'Integraciones',
  'plans.enterprise.features.4': 'Pasarela SMS personalizada',
  'plans.enterprise.features.5': 'Marca personalizada',
  'plans.enterprise.features.6': 'Funciones personalizadas',
  'plans.enterprise.features.7': 'Asistencia Premium',
  'plans.enterprise.promo': 'A medida',
  'plans.switcher.monthly': 'Mensualmente',
  'plans.switcher.annualy': 'Anualmente',
  'plans.mostPopular': 'Los más populares',
  'plans.private.submit': 'Plan actual',
  'plans.pro.submit': 'Seleccione',
  'plans.enterprise.submit': 'Póngase en contacto con nosotros',
  'plans.discont': '10% de descuento',

  'plans.keepSubscribtion': 'Mantener suscripción',
  'plans.pro_monthly.cancelSubscription': 'Cancelar Plan Pro Mensual',
  'plans.pro_annual.cancelSubscription': 'Cancelar Plan Anual Pro',
  'plans.enterprise_monthly.cancelSubscription': 'Cancelar el plan mensual Enterprise',
  'plans.enterprise_annual.cancelSubscription': 'Cancelar el plan anual para Enterprise',
  'plans.cancelSubscription.text':
    '¿Está seguro de que desea cancelar su plan de suscripción? Si cancela su suscripción, dejará de recibir pagos en el futuro y su acceso a las funciones Premium finalizará al final de su ciclo de facturación actual. Si tiene alguna pregunta o duda, póngase en contacto con nuestro equipo de asistencia para obtener ayuda.',

  'checkout.success': 'El pago se realizó correctamente',
  'checkout.redirect': 'Serás redirigido en un segundo',
  'checkout.falied': 'No se pudo completar la compra. Comuníquese con el soporte',
  'poorConection.title': 'Mala conexión a Internet',
  'poorConection.description':
    'Lamentablemente, su conexión a Internet es insuficiente para que la aplicación 2meters funcione. Por favor, compruebe su conexión a Internet o las políticas de seguridad de la red. Tenga en cuenta que utilizamos web sockets para ofrecerle los datos en directo.',
  'monitor.desktopViewHeader': 'Vista de monitor',
  'monitor.desktopViewBody':
    'Puedes instalar una pantalla para mostrar a tus visitantes el estado de la cola. Abra esta página en el ordenador conectado a la pantalla y haga clic en el botón para entrar en el modo de pantalla completa',
  'monitor.fullscreen': 'Pantalla completa',
  'monitor.switchTheMonitor': 'Cambiar el idioma del monitor',
  'statistics.dashboard': 'Panel de estadísticas',
  'statistics.ticketsAndServices': 'Entradas y servicios',
  'statistics.thisMonth': 'Este mes',
  'statistics.thisWeek': 'Esta semana',
  'statistics.today': 'Hoy',
  'statistics.booked': 'Reservado',
  'statistics.period': 'Periodo',
  'statistics.lastSevenDays': 'Últimos 7 días',
  'statistics.month': 'Este mes',
  'statistics.custom': 'Rango de fechas personalizado',
  'statistics.services': 'Servicios',
  'statistics.all': 'Todos los servicios',
  'statistics.noData': 'No hay datos para el período seleccionado',
  'statistics.totalAmount': 'Importe total',
  'statistics.avgWaitingTime': 'Tiempo medio de espera',
  'statistics.avgProcessingTime': 'Tiempo medio de procesamiento',
  'statistics.minutes': 'minutos',
  'statistics.periodError': 'La fecha de inicio debe ser anterior a la fecha de finalización',
  'statistics.сonfirmationStatus': 'Estado de la confirmación',
  'statistics.ticketType': 'Tipo de billete',
  'statistics.ticketStatus': 'Estado del billete',
  'statistics.processed': 'Procesado',
  'statistics.noShow': 'No apareció',
  'statistics.invited': 'Invitado',
  'statistics.cancelled': 'Cancelado',
  'statistics.confirmed': 'Confirmado',
  'statistics.pending': 'Pendiente',
  'statistics.rejected': 'Rechazado',
  'statistics.queued': 'En cola',
  'statistics.dashboardIsEmpty': 'El tablero de mandos está vacío',
  'statistics.dashboardIsEmptyDesc':
    'Para tener acceso al panel de estadísticas, por favor, actualice al nivel de suscripción más alto.',
  'statistics.upgadePlan': 'Plan Upgade',

  // Scheduler

  'scheduler.error.endBeforeStart':
    'La fecha de inicio debe ser anterior a la fecha de finalización.',
  'scheduler.error.notSameDay': 'La hora de inicio y finalización debe ser el mismo día',
  'scheduler.error.visitTypeNotSet': 'Se debe configurar el tipo de servicio',
  'scheduler.error.invalidSchedule':
    'Horario inválido. Compruebe que el final del período de repetición no esté antes de la fecha de inicio',
  'scheduler.numberOfAppointments': '{num} citas en este intervalo',
  'scheduler.simple': 'Sencillo',
  'scheduler.advanced': 'Avanzado',

  'scheduler.serviceType': 'Tipo de servicio',
  'scheduler.timePeriodForm.commit': 'Confirmar',
  'scheduler.timePeriodForm.repeat': 'Repetir',
  'scheduler.timePeriodForm.never': 'Nunca',
  'scheduler.timePeriodForm.daily': 'Diario',
  'scheduler.timePeriodForm.weekly': 'Semanal',
  'scheduler.timePeriodForm.monthly': 'Mensual',
  'scheduler.timePeriodForm.yearly': 'Anual',
  'scheduler.timePeriodForm.repeatEvery': 'Repite cada',
  'scheduler.timePeriodForm.days': 'día(s)',
  'scheduler.timePeriodForm.endRepeat': 'Finalizar la repetición',
  'scheduler.timePeriodForm.on': 'En',
  'scheduler.timePeriodForm.after': 'Después de',
  'scheduler.timePeriodForm.occurs': 'ocurrencia(s)',
  'scheduler.timePeriodForm.weeksOn': 'semana(s) en:',
  'scheduler.timePeriodForm.month': 'mes(es)',
  'scheduler.timePeriodForm.everyMonth': 'de cada mes',
  'scheduler.timePeriodForm.the': 'El',
  'scheduler.timePeriodForm.first': 'Primero',
  'scheduler.timePeriodForm.second': 'Segundo',
  'scheduler.timePeriodForm.third': 'Tercero',
  'scheduler.timePeriodForm.fourth': 'Cuarto',
  'scheduler.timePeriodForm.last': 'Último',
  'scheduler.timePeriodForm.years': 'año(s)',
  'scheduler.timePeriodForm.of': 'de',
  'scheduler.timePeriodForm.every': 'Cada',
  'scheduler.timePeriodForm.timePeriods': 'Periodos de tiempo',
  'scheduler.timePeriodForm.headerCaption':
    'Por favor, defina cada periodo de tiempo, cuando preste su servicio particular. Las franjas horarias de este periodo se calcularán automáticamente en función del valor de la duración de la franja.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Asignación de servicios y franjas horarias',
  'scheduler.timePeriodForm.createButton': 'Crear',

  'scheduler.confirmCancel': '¿Realmente quieres descartar los cambios no guardados?',
  'scheduler.confirmDelete': '¿Realmente quiere eliminar este periodo de tiempo?',
  'scheduler.cancelButton': 'Cancelar',
  'scheduler.deleteButton': 'Borrar',
  'scheduler.discardButton': 'Descartar',

  'scheduler.editRecurrenceMenu.editTitle': 'Editar periodos de tiempo recurrentes',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Borrar periodos de tiempo recurrentes',
  'scheduler.editRecurrenceMenu.cancelButton': 'Cancelar',
  'scheduler.editRecurrenceMenu.commitButton': 'Confirmar',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'Todos los periodos de tiempo',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods':
    'Éste y los siguientes períodos de tiempo',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'Este periodo de tiempo',

  // Managers
  'manager.title': 'Gerentes',
  'manager.description':
    'Aquí puede agregar y eliminar personas que tengan acceso a este lugar.Los gerentes pueden manipular citas y colas desde la vista de contador.Pero no pueden editar la configuración del lugar.',
  'manager.add': 'Administrar',
  'manager.remove': 'Eliminar',
  'manager.admin': 'Administradora',
  'manager.manager': 'Gerente',
  'manager.name': 'Nombre',
  'manager.email': 'Email',
  'manager.role': 'Papel',
  'manager.invitationSent': 'Invitación enviada',
  'manager.sendInvite': 'Enviar invitación',
  'manager.invitePlaceholder': 'Invitar a nuevos usuarios',
  'manager.table.name': 'Nombre',
  'manager.table.email': 'Email',
  'manager.table.role': 'Papel',
  'manager.table.dateAdded': 'Fecha añadida',
}
