import { GMTEvents, GtmEventStatuses } from '@2meters/shared'
import { db } from 'api/Firebase/firebase-init'
import { doc, DocumentSnapshot, onSnapshot, setDoc } from 'firebase/firestore'
import { create } from 'zustand'

export interface GTMStoreStore {
  userId?: string
  gmtEventStatuses?: GtmEventStatuses
  subscribe: (userId: string) => () => void
  unsubscribe: () => void
  sendGTMeventOnce: (event: GMTEvents) => Promise<void>
}

export const useGTM = create<GTMStoreStore>((set, get) => ({
  userId: undefined,
  gmtEventStatuses: undefined,
  subscribe: userId => {
    const q = doc(db, `gtm_event_status/${userId}`)
    console.log('Subscriving to GMT user status', userId)
    const unsubscribe = onSnapshot(q, (doc: DocumentSnapshot<GtmEventStatuses>) => {
      const statuses = doc.exists() ? doc.data() : {}

      console.log(`GMT status for user ${userId}`, statuses)
      set({ gmtEventStatuses: statuses as GtmEventStatuses })
    })
    set({ userId, unsubscribe })
    return unsubscribe
  },
  unsubscribe: () => {
    if (get().unsubscribe) {
      get().unsubscribe()
    }
  },
  sendGTMeventOnce: async (event: GMTEvents) => {
    sendGTMeventOnce(get().userId, get().gmtEventStatuses, event)
  },
}))

function updateGTMStatusDoc(id, field: string) {
  return setDoc(doc(db, `gtm_event_status/${id}`), { [field]: true }, { merge: true })
}

async function sendGTMeventOnce(
  userId: string | undefined,
  eventStatuses: GtmEventStatuses | undefined,
  event: GMTEvents
) {
  if (eventStatuses && eventStatuses[event]) {
    return
  }

  if (!userId) {
    console.log(`GTM event ${event} not set, not user id provided`, eventStatuses)
    return
  }

  try {
    //@ts-ignore
    window.dataLayer.push({
      event: event,
    })
    console.log(`setting GTM event status ${event} for user ${userId}`, eventStatuses)
    await updateGTMStatusDoc(userId, event)
  } catch (e) {
    console.error(`Error sending GTM event ${event}`, e)
  }
}
