import React from 'react'
import { Box, BoxProps } from '@mui/material'

type BoxRoundedProps = {
  disabled?: boolean
  boxRadiusIsZero?: boolean
}
export const BoxRounded: React.FC<BoxRoundedProps & BoxProps> = ({
  children,
  boxRadiusIsZero,
  onClick,
  disabled,
  ...other
}) => {
  return (
    <Box
      sx={{
        bgcolor: `${disabled ? '#eee' : 'background.paper'}`,
        borderRadius: boxRadiusIsZero ? '0px' : '9px',
        color: 'text.primary',
        textAlign: other.textAlign || 'center',
        cursor: onClick && !disabled ? 'pointer' : 'default',
        ...other,
      }}
      p={2}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </Box>
  )
}

export default BoxRounded
