import { func, number, Struct, type } from 'superstruct'
import { StructSchema } from 'superstruct/dist/utils'

export interface Timestamp {
  readonly seconds: number
  readonly nanoseconds: number
  isEqual(other: Timestamp): boolean
  toDate(): Date
  toMillis: () => number
}

export class TimestampMock implements Timestamp {
  readonly seconds: number
  readonly nanoseconds: number

  constructor(seconds: number, nanoseconds: number) {
    this.seconds = seconds
    this.nanoseconds = nanoseconds
  }

  isEqual(other: Timestamp) {
    return other.seconds === this.seconds && other.nanoseconds === this.nanoseconds
  }

  toDate() {
    return new Date(this.toMillis())
  }

  toMillis() {
    return this.seconds * 1000 + this.nanoseconds / 1e6
  }
}

export const TimestampStruct = new Struct<Timestamp, StructSchema<Timestamp>>({
  ...type({
    nanoseconds: number(),
    seconds: number(),
    isEqual: func() as unknown as Struct<Timestamp['isEqual'], null>,
    toDate: func() as unknown as Struct<Timestamp['toDate'], null>,
    toMillis: func() as unknown as Struct<Timestamp['toMillis'], null>,
  }),
  coercer: value => value,
})

export function isTimestamp(obj: unknown): obj is Timestamp {
  return TimestampStruct.is(obj)
}
