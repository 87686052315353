const COLORS = {
  primary: {
    main: '#4ec2ee',
    dark: '#4ca6e8',
  },
  secondary: {
    main: '#fd9311',
  },
  error: {
    main: '#FF6162',
  },
  success: {
    main: '#2BC096',
  },
  purple: 'rgba(190,87,226,1)',
  white_500: '#fafafa',
  white_900: '#f6f9fc',
  black: 'rgba(0, 0, 0)',
  black_300: 'rgba(0, 0, 0, 0.3)',
  black_900: 'rgba(0, 0, 0, 0.9)',
  grey_500: '#e0e0e0',
  grey_700: '#38434F',
}

export { COLORS }
