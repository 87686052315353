import { LocaleLong } from '@2meters/shared'
import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'

function navigatorLanguage() {
  let language
  if (Array.isArray(navigator.languages) && navigator.languages[0]) {
    // eslint-disable-next-line prefer-destructuring
    language = navigator.languages[0]
  } else {
    // @ts-ignore
    language = navigator.language || navigator.userLanguage
  }

  if (adminLocalesNames.includes(language)) {
    return language
  }

  return 'en-GB' as LocaleLong
}

const adminMessages = {
  'en-GB': require('constants/en').default,
  'pt-BR': require('constants/pt').default,
  'de-DE': require('constants/de').default,
  'es-ES': require('constants/es').default,
  'uk-UA': require('constants/ua').default,
  'tr-TR': require('constants/tr').default,
} as { [locale in LocaleLong]: any }
export const adminLocalesNames = Object.keys(adminMessages) as LocaleLong[]

export interface AdminLocaleStore {
  adminLocale: LocaleLong
  translations: (locale: LocaleLong) => Record<string, any>
  setAdminLocale: (locale: LocaleLong) => void
}

export const useAdminLocale = create<AdminLocaleStore>()(
  devtools(
    persist(
      (set, get) => ({
        adminLocale: navigatorLanguage(),
        translations: (locale: LocaleLong) => {
          return adminMessages[locale]
        },
        setAdminLocale: (locale: LocaleLong) => set({ adminLocale: locale }),
      }),
      {
        name: '2meters-admin-locale',
      }
    )
  )
)
