import { ApplicationVersion } from '@2meters/shared'
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from './firebase-init'
import { readAs } from './firebase-utils'

const getVersionSubscribe = (
  callback: (version: ApplicationVersion[]) => void,
  notFound: () => void
): (() => void) => {
  const q = query(collection(db, 'version'), orderBy('releasedAt', 'desc'), limit(1))
  // let query = db.collection('version').orderBy('releasedAt', 'desc').limit(1);
  return onSnapshot(q, snapshot => {
    if (snapshot.empty) {
      notFound()
    } else {
      let apps: ApplicationVersion[] = snapshot.docs.map(doc => readAs<ApplicationVersion>(doc))
      callback(apps)
    }
  })
}

export const version = {
  getVersionSubscribe,
}
