import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid } from '@mui/material'
import { ADMIN } from 'constants/routes'
import { backend } from 'api/Firebase'
import { LanguageSelect } from 'components/Desktop/LanguageSelect'
import LogoImage from 'assets/logo.svg'
import LogoDarkImage from 'assets/logo-dark.svg'
import { Account } from './Account'

interface Props {
  SubHeader?: JSX.Element | null
  dark?: boolean
}

const Header: React.FC<Props> = ({ dark = false, SubHeader, children }) => {
  return (
    <Box
      style={{
        backgroundImage: dark ? 'linear-gradient(66deg, #485563 5%, #29323c 93%)' : undefined,
      }}
      mb={3}
      bgcolor={dark ? undefined : 'background.paper'}
      // className={classes.container}
    >
      <Container style={{ minHeight: 86, display: 'flex' }}>
        <Grid
          container
          spacing={2}
          justifyContent='space-between'
          alignItems='center'
          style={{
            gridTemplateColumns: 'min-content 1fr min-content',
            display: 'grid',
          }}
        >
          <Grid item style={{ width: 100 }}>
            <Link to={ADMIN.PLACES_LIST}>
              <img src={dark ? LogoDarkImage : LogoImage} alt='2 meters' />
            </Link>
          </Grid>
          <Grid style={{ textAlign: 'center' }}>{children}</Grid>
          <Grid item style={{ width: 100, textAlign: 'right' }}>
            <LanguageSelect color='primary' />
            {!backend?.isUserAnonymous() && <Account />}
          </Grid>
        </Grid>
      </Container>
      {SubHeader}
    </Box>
  )
}

export default Header
