import React from 'react'
import { Box } from '@mui/material'
import { WithPlace } from 'api/Firebase/usePlace/usePlace'
import DesktopLayout from 'components/Layouts/Desktop'
import { withPlace } from 'components/Session/withPlace'
import LoadingPage from '../LoadingPage/LoadingPage'
import { PlaceHeader } from '../PlaceHeader'

import * as ROUTES from 'constants/routes'
import Helmet from 'react-helmet'
import { compose } from 'recompose'

const MonitorPage: React.FC<WithPlace> = props => {
  const { place } = props

  return (
    <DesktopLayout>
      <Helmet>
        <title>Monitor | 2Meters App</title>
      </Helmet>
      <PlaceHeader place={place} />
      <Box
        style={{
          height: '100vh',
        }}
      >
        <iframe
          src={ROUTES.monitorUrl(place)}
          allowFullScreen
          style={{
            border: 'none',
            marginBottom: '-10px',
            height: '100%',
            width: '100%',
          }}
        ></iframe>
      </Box>
    </DesktopLayout>
  )
}

export default compose<WithPlace, Record<string, unknown>>(withPlace(LoadingPage))(MonitorPage)
