import { LegalProps, LocaleLong, RegistrationOrigin, User, UserOrigin } from '@2meters/shared'
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { isBrowser, isMobile } from 'react-device-detect'
import { httpsCallable } from 'firebase/functions'
import { auth, db, functions } from './firebase-init'
import { prepareRequest, readAs } from './firebase-utils'

export const getCurrentUserUID = () => auth.currentUser?.uid

export const getCurrentUser = () => {
  const userId = auth.currentUser?.uid
  return getUser(userId)
}

export const getCurrentUserSubscribe = (
  callback: (user: User) => void,
  notFound: () => void
): (() => void) => {
  const userId = auth.currentUser?.uid
  return onSnapshot(doc(db, `user/${userId}`), doc => {
    if (doc.exists()) callback(readAs<User>(doc))
    else notFound()
  })
}

export const getUser = (userId?: string) => {
  if (userId)
    return getDoc(doc(db, `user/${userId}`)).then(doc => {
      if (doc.exists()) return readAs<User>(doc)
      else return Promise.reject(Error(`User ${userId} not found`))
    })
  else return Promise.reject(Error(`User ${userId} not found`))
}

export const getUserSubscribe = (
  userId: string,
  callback: (user: User) => void,
  notFound: () => void
): (() => void) => {
  console.info('Subscribing to user')
  return onSnapshot(doc(db, `user/${userId}`), doc => {
    if (doc.exists()) callback(readAs<User>(doc))
    else notFound()
  })
}

export const hasUserUserTrial = () => {
  return getCurrentUser()
    .then(user => !!user.trialSubscriptionId)
    .catch(() => false)
}

export const getCurrentUserLegalProps = (): Promise<LegalProps | null> => {
  const userId = auth.currentUser?.uid
  if (userId)
    return getDoc(doc(db, `user/${userId}`)).then(doc => {
      const user = readAs<User>(doc)
      if (user.legal) return user.legal
      else return null
    })
  else return Promise.reject(Error(`User ${userId} not found`))
}

export const setCurrentUserPreferredLanguage = (locale: LocaleLong) => {
  const userId = auth.currentUser?.uid
  if (userId)
    return setDoc(
      doc(db, `user/${userId}`),
      {
        preferredLanguage: locale,
      },
      { merge: true }
    ).then(() => true)
  else return Promise.reject(Error(`User ${userId} not found`))
}

export const setCurrentUserLegalProps = (legalProps: LegalProps) => {
  const userId = auth.currentUser?.uid
  if (userId) {
    return updateDoc(doc(db, `user/${userId}`), {
      legal: legalProps,
    }).then(() => {
      if (!legalProps.termsAccepted && !legalProps.privacyPolicyAccepted) {
        return true
      }
      getCurrentUser().then(user => {
        httpsCallable(
          functions,
          'owner-createPipedriveLead'
        )(prepareRequest({ user }))
          .then(() => true)
          .catch(e => {
            console.error(`Pipedrive lead was not created ${user}: ${e}`)
            return Promise.reject(e)
          })
      })
    })
  } else {
    return Promise.reject(Error(`User ${userId} not found`))
  }
}

export const setCurrentUserUpdateStats = (user: User) => {
  const userId = auth.currentUser?.uid
  if (!userId) {
    return Promise.reject(Error(`User ${userId} not found`))
  }

  return updateDoc(doc(db, `user/${userId}`), {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    organization: user.organization,
    imageFile: user.imageFile,
    imageStoragePath: user.imageStoragePath,
    streetAddress: user.streetAddress,
    postalCode: user.postalCode,
    referralCode: user.referralCode,
    city: user.city,
    country: user.country,
    vatId: user.vatId,
    industry: user.industry,
  }).then(() => true)
}

export const setCurrentUserStats = (registrationOrigin: RegistrationOrigin) => {
  const userId = auth.currentUser?.uid

  if (!userId) {
    return Promise.reject(Error(`User ${userId} not found`))
  }

  const userRef = doc(db, `user/${userId}`)
  const unsubscribe = onSnapshot(userRef, user => {
    const userData = user.data()

    if (!user.exists) {
      return Promise.reject(Error(`User ${userId} not found`))
    }

    if (!userData?.origin) {
      updateDoc(userRef, {
        origin: registrationOrigin,
      })
        .then(() => {
          unsubscribe()
          return true
        })
        .catch(e => {
          console.log('Origin not updated: ', e)
          return false
        })
    }
  })
}

const getUserOriginFromQuery = (): UserOrigin => {
  const urlParams: any = window.location.search
    .substring(window.location.search.indexOf('?') + 1)
    .split('&')
    .reduce(
      (memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {}
    )

  const iRefParam = urlParams.iRef ? urlParams.iRef : ''
  const iRef = urlParams.utm_campaign ? urlParams.utm_campaign : iRefParam

  const iRef2Param = urlParams.iRef2 ? urlParams.iRef2 : ''
  const iRef2 = urlParams.utm_content ? urlParams.utm_content : iRef2Param

  const iRef3Param = urlParams.iRef3 ? urlParams.iRef3 : ''
  const iRef3 = urlParams.utm_source ? urlParams.utm_source : iRef3Param

  const referrer = urlParams.referrer ? decodeURI(urlParams.referrer) : document.referrer

  const device = isMobile ? 'mobile' : 'other'

  return {
    device: isBrowser ? 'browser' : device,
    code: iRef,
    code2: iRef2,
    code3: iRef3,
    referrer,
  }
}

function updateUserOriginInLocal(userOrigin: UserOrigin): void {
  const localUserOriginJson = localStorage.getItem('userOrigin')

  if (!localUserOriginJson) {
    localStorage.setItem('userOrigin', JSON.stringify(userOrigin))
    return
  }

  const localUserOrigin: UserOrigin = JSON.parse(localUserOriginJson)

  if (!(localUserOrigin.code || localUserOrigin.code2 || localUserOrigin.code3)) {
    localStorage.setItem('userOrigin', JSON.stringify(userOrigin))
  }
}

async function updateUserOriginOnce(user: User) {
  if (user?.origin) {
    console.log('User Origin already set')
    return Promise.resolve()
  }

  const userOriginJson = localStorage.getItem('userOrigin')

  if (!userOriginJson) {
    console.log('No local record for user origin')
    return Promise.resolve()
  }

  const userOrigin = JSON.parse(userOriginJson)

  const userRef = doc(db, `user/${user.id}`)
  console.log('Updating user origin', origin, userOrigin)

  return await updateDoc(userRef, {
    origin: userOrigin,
  })
}

export const users = {
  getCurrentUserUID,
  getCurrentUser,
  getCurrentUserSubscribe,
  getUser,
  getUserSubscribe,
  setCurrentUserUpdateStats,
  hasUserUserTrial,
  setCurrentUserStats,
  getCurrentUserLegalProps,
  setCurrentUserLegalProps,
  updateUserOriginInLocal,
  updateUserOriginOnce,
  getUserOriginFromQuery,
}
