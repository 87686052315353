import { SubscriptionType } from './subscription'
import { MessageProvider, MessageType } from './messaging'

export const DefaultTimezone = 'Europe/Berlin'
export const DefaultSlotDurationMin = 15
export const DefaultVisitType = 'Unspecified'

export const Currencies = ['$', '£', '€', '₽', '¥', '₹', '₿', '₴', '₺', 'R'] as const
export type Currency = typeof Currencies[number]
export function isCurrency(value: string): boolean {
  return Currencies.includes(value as any)
}
export const ISO4217Strings = [
  'USD',
  'GBP',
  'EUR',
  'RUB',
  'JPY',
  'INR',
  'BTC',
  'UAH',
  'TRY',
  'Rand',
]
export type ISO4217 = typeof ISO4217Strings[number]
export function isISO4217(value: string): boolean {
  return ISO4217Strings.includes(value as any)
}
type ISO4217toCurrencyMap = {
  [key in ISO4217]: Currency
}
export function mapISO4217toCurrency(isoString: string): Currency | undefined {
  let map: ISO4217toCurrencyMap = {
    USD: '$',
    GBP: '£',
    EUR: '€',
    RUB: '₽',
    JPY: '¥',
    INR: '₹',
    BTC: '₿',
    UAH: '₴',
    TRY: '₺',
  }
  return map[isoString.toUpperCase() as ISO4217]
}

export const DefaultCurrency = '€'

export declare class Coords {
  lat: number
  lng: number
}

export declare class Price {
  netValue: number
  serviceFee: number
  amount: number
  currency: Currency
  unit?: 'notification' | 'subscription'
}

export type ProductPrices = {
  [key in MessageProvider | SubscriptionType]?: Price
}

export const DefaultProductPrices: ProductPrices = {
  whatsapp: {
    netValue: 0.09,
    serviceFee: 0.01,
    amount: 0.1,
    currency: '€',
    unit: 'notification',
  },
  sms: {
    netValue: 0.09,
    serviceFee: 0.01,
    amount: 0.1,
    currency: '€',
    unit: 'notification',
  },
  private: {
    netValue: 0,
    serviceFee: 0,
    amount: 0,
    currency: '€',
    unit: 'subscription',
  },
  pro_monthly: {
    netValue: 0,
    serviceFee: 0,
    amount: 20,
    currency: '€',
    unit: 'subscription',
  },
  pro_annual: {
    netValue: 0,
    serviceFee: 0,
    amount: 212,
    currency: '€',
    unit: 'subscription',
  },
  enterprise_monthly: {
    netValue: 0,
    serviceFee: 0,
    amount: 50,
    currency: '€',
    unit: 'subscription',
  },
  enterprise_annual: {
    netValue: 0,
    serviceFee: 0,
    amount: 540,
    currency: '€',
    unit: 'subscription',
  },
}

export declare class PriceListEntry {
  countryCode: string
  timestamp: Date
  prices: ProductPrices
}

export declare class BillingLogEntry {
  refId: string
  placeId: string
  createdAt: Date
  type: MessageType | SubscriptionType
  countryCode: string
  price: Price
}

export declare class ApplicationVersion {
  version: string
  releasedAt: Date
}
