const COLORS = {
  text: {
    primary: '#2C353F',
    secondary: '#2C353F',
  },
  primary: {
    main: '#7669ff',
    dark: '#7669ff',
  },
  secondary: {
    main: '#fd9311',
  },
  error: {
    main: '#FF6162',
    light: '#FDA29B',
  },
  success: {
    main: '#2BC096',
  },
  visitor: {
    bgColor: '#FFFFFF',
    textColor: '#353A41',
  },
  monitor: {
    clock: {
      bgColor: '#FFE279',
      textColor: '#353A41',
    },
    logo: {
      bgColor: '#343E49',
    },
    url: {
      bgColor: '#3A4553',
    },
  },
  buttonColorDefault: '#FFE279',
  buttonColorHover: 'rgba(255, 226, 121, 0.75)',
  inputBorderBottom: '#7669FF',
  inputBorderBottomHover: 'rgba(118, 105, 255, 0.75)',
  pushTimesButtons: 'rgba(118, 105, 255, 0.5)',
  pushTimesButtonsHover: 'rgba(118, 105, 255, 0.1)',
  radioChecked: 'rgba(118, 105, 255, 0.1)',
  radioCheckedBorder: 'rgba(118, 105, 255, 0.5)',
  main: '#7669FF',
  mainHover: 'rgba(118, 105, 255, 0.75)',
  plansBgColor: '#2B333B',
  purple: 'rgba(190,87,226,1)',
  purple_200: 'rgba(190,87,226,0.2)',
  white_500: '#fafafa',
  white_900: '#f6f9fc',
  black: '#353A41',
  black_300: 'rgba(0, 0, 0, 0.3)',
  black_900: 'rgba(0, 0, 0, 0.9)',
  grey_500: '#e0e0e0',
  grey_700: '#38434F',
  grey_800: '#29323c',
  drawer_black: '#1D2227',
  drawer_grey: '#2B333C',
  drawer_hover: 'rgba(118, 105, 255, 0.3)',
  drawer_active: '#7669FF',
  ticket_black: '#2C353F',
  ticketColor: {
    noShow: '#C76EE7',
    processed: '#2CC197',
    cancelled: '#EF476F',
    rejected: '#FF6162',
    invited: '#7669FF',
    queued: '#38A3A5',
    confirmed: '#47BAE8',
    pending: '#4137AC',
  },
}

export { COLORS }
