import { useEffect, useState } from 'react'
import { Subscription } from '@2meters/shared'
import { places } from 'api/Firebase/places'

type UseSubscriptionProps = {
  placeId: string
}

const useSubscription = ({ placeId }: UseSubscriptionProps) => {
  const [subscription, setSubscription] = useState<Subscription | undefined>()
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (!placeId) return
    places
      .getSubscription(placeId)
      .then((subscription?: Subscription) => {
        setSubscription(subscription)
      })
      .catch(e => {
        console.error(e)
        setNotFound(true)
      })
  }, [placeId])

  return {
    subscription,
    notFound,
  }
}

export default useSubscription
