import { injectIntl } from 'react-intl'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import ButtonLoading from 'components/ButtonLoading'
import { compose } from 'recompose'

interface Props {
  confirmationOnly?: boolean
  loading?: boolean
  title?: string
  text: string
  confirmCaption?: string
  rejectCaption?: string
  open: any
  onConfirm: () => void
  onReject?: () => void
  intl?: any
  children?: any
}

const ConfirmationDialog = (props: Props) => {
  const confirmCaption = props.confirmCaption
    ? props.confirmCaption
    : props.intl.formatMessage({ id: 'dialogs.confirm' })
  const rejectCaption = props.rejectCaption
    ? props.rejectCaption
    : props.intl.formatMessage({ id: 'dialogs.reject' })
  const open = Number(props.open) > 0

  return (
    <Dialog
      open={open}
      onClose={props.onReject}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Box padding={3}>
        {props.title && (
          <DialogTitle id='alert-dialog-title'>
            <Box textAlign='center'> {props.title}</Box>
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{props.text}</DialogContentText>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Box display='flex' width='100%' minWidth='300px' justifyContent='space-between'>
            {!props.confirmationOnly && (
              <ButtonLoading
                onClick={props.onReject}
                color='inherit'
                variant='contained'
                disableElevation
              >
                {rejectCaption}
              </ButtonLoading>
            )}
            <ButtonLoading
              onClick={props.onConfirm}
              color='primary'
              variant='contained'
              loading={props.loading}
              autoFocus
              disableElevation
            >
              {confirmCaption}
            </ButtonLoading>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default compose<Props, Props>(
  injectIntl
  //@ts-ignore
)(ConfirmationDialog)
