export default {
  'weekdays.0': 'Domigo',
  'weekdays.1': 'Segunda-feira',
  'weekdays.2': 'Terça-feira',
  'weekdays.3': 'Quarta-feira',
  'weekdays.4': 'Quinta-feira',
  'weekdays.5': 'Sexta-feira',
  'weekdays.6': 'Sábado',

  'wd.1': 'Seg',
  'wd.2': 'Ter',
  'wd.3': 'Qua',
  'wd.4': 'Qui',
  'wd.5': 'Sex',
  'wd.6': 'Sab',
  'wd.7': 'Dom',
  'wd.0': 'Dom',

  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Українська',
  'tr-TRLang': 'Türkçe',
  EUR: 'Euro',
  USD: 'Dólar Americano',
  GBP: 'Libra Britânica',
  INR: 'Rúpia indiana',
  UAH: 'Hryvnia ucraniana',
  JPY: 'Yen japonês',
  BTC: 'BTC',
  TRY: 'Lira turca',
  Rand: 'Rand da África do Sul',
  generalError: 'Ocorreu um erro inesperado',
  error: '{error}',
  enabled: 'Habilitado',
  disabled: 'Desativado',
  appointments: 'Compromissos',
  livequeues: 'Filas',
  livequeue: 'Fila',
  info: 'Informações',
  ok: 'OK',
  today: 'Hoje',
  delete: 'Excluir',
  remove: 'Remover',
  email: 'Email',
  role: 'Função',
  admin: 'Administradora',
  manager: 'Gerente',
  cancel: 'Cancelar',
  create: 'Criar',
  upgrade: 'Melhorar',
  update: 'Atualizar',
  invite: 'Convidar',
  queueIn: 'Enfileirar',
  edit: 'Editar',
  sure: 'Tem certeza?',
  yes: 'Sim',
  no: 'Não',
  add: 'Adicionar',
  save: 'Salvar',
  go: 'Ir',
  next: 'Próximo',
  leave: 'Sair',
  yourAppointments: 'Seus compromissos',
  yourAppointment: 'Suas filas',
  address: 'Endereço',
  copyToClipboard: 'Copiar',
  copiedToClipboard: 'Texto copiado',
  showQRCode: 'Mostrar QR Code',
  showPDF: 'Show pdf',
  showNumberTickets: 'Número de bilhetes',
  clipboardCopyError: 'Erro de cópia para área de transferência',
  imageUploadFailed: 'Falha no upload da imagem',
  uploadLogo: 'Carregar logotipo',
  thankYou: 'Obrigado!',

  // Update Page
  'rolledOutUpdate.reload': 'Recarregar Página',
  'rolledOutUpdate.header': 'A actualização está fora de questão!',
  'rolledOutUpdate.message':
    'Uma nova actualização foi lançada. Por favor, volte a carregar a página!',

  // Reset Password
  'resetPassword.resetPassword': 'Redefinir palavra-passe',
  'resetPassword.newPassword': 'Nova palavra-passe',
  'resetPassword.currentPassword': 'Senha actual',
  'resetPassword.changePassword': 'Alterar Palavra-passe',
  'resetPassword.currentPasswordWrong': 'A palavra-passe actual não está correcta',
  'resetPassword.confirmNewPassword': 'Confirmar nova senha',
  'resetPassword.linkNotValid':
    'O seu link de reinicialização é inválido. Por favor, submeta novamente o seu pedido',
  'resetPassword.dontMatch': 'As palavras-passe não coincidem',
  'resetPassword.passwordChanged': 'Senha alterada',
  'resetPassword.signNewPassword': 'Pode agora iniciar sessão com a sua nova palavra-passe',
  'resetPassword.errorEncountered': 'Erro encontrado',
  'resetPassword.modeIsInvalid': 'O modo de página seleccionado é inválido',
  'resetPassword.logout': 'Cierre de sesión',
  'resetPassword.logout.title': 'Su contraseña ha sido modificada',
  'resetPassword.logout.descritption': 'Vuelva a iniciar sesión en su cuenta',

  'appointment.waitToSendAgain': 'Envie o código novamente em {sec} segundos',
  'notfoundpage.header': 'Página não encontrada',
  'notfoundpage.message':
    'O arquivo especificado não foi encontrado neste site. Verifique se há erros no URL e tente novamente.',
  'dialogs.pageIsForMobile':
    'Você está visualizando esta página no navegador do seu desktop.\nPor favor, abra o link com o navegador do seu celular, se você deseja que a consulta marcada esteja disponível no seu smartphone.',
  'dialogs.pageIsForMobileTitle': 'Use seu dispositivo móvel',
  'dialogs.messagemodal.walkin': 'Fila',
  'dialogs.whatsapp.text':
    'Recomendamos que se ligue às notificações WhatsApp, se quiser saber, quantas pessoas estão à sua espera ou quanto tempo será o seu tempo de espera. Clique no botão "Ligar" para abrir o WhatsApp e enviar-nos uma mensagem que preparámos sem a alterar.',
  'dialogs.whatsapp.connect': 'Ligar',
  'dialogs.whatsapp.urlText':
    'Gostava%20de%20receber%20notificações%20contendo%20informação%20sobre%20mudança%20de%20estado%20do%20meu%20bilhete',
  'home.queues': 'Suas filas ativas',
  'home.queue.showCode': 'Mostrar este número a pedido',
  'home.queue.peopleAhead': 'Pessoas à sua frente',
  'home.placesNearby': 'Lugares próximos',
  'home.noPlacenear': 'Não há nenhum lugar registrado perto de você',
  'home.enableGeoLocation':
    'Você desativou a localização geográfica. Ative-o nas configurações do navegador para usar este recurso',
  'home.bookASpot': 'Para fazer uma marcação ou juntar-se a uma fila digital',
  'home.scanQR': 'escaneie o código QR',
  'home.enterShortUrl': 'ou digite o código de acesso do lugar',
  'home.enterShortcode': 'Digite o código de acesso',
  'home.selectNearby': 'ou simplesmente selecione lugares próximos',
  'home.useToBook': 'Use a Pesquisa ou o leitor de código QR para reservar um',
  'home.findNearby': 'Encontre um lugar próximo',
  'home.loginAsAdmin': 'Faça login como administrador do local',
  'messaging.permissionGranted': 'Permissão de notificação concedida',
  'messaging.permissionNotGranted':
    'Você não receberá nenhuma notificação enquanto o aplicativo estiver em segundo plano à medida que a fila avança. Permita notificações.',
  'messaging.tokenError': 'Algo deu errado',
  'messaging.webPushNotificationsNotSupported':
    'Seu navegador não suporta notificações push da web. Você não conseguirá receber nenhuma\nnotificação à medida que a fila avança. Observe o número de pessoas na fila exibida abaixo.',
  'appointment.disabledButtonHint': 'Marque uma consulta',
  'appointment.dayOff': 'O local está fechado neste momento. Escolha outra data.',
  'appointment.visitorInformation': 'Insira seu nome',
  'appointment.visitorInformation.hint': 'Por favor, forneça as seguintes informações sobre você',
  'appointment.customInformation': 'Informações adicionais',
  'appointment.customInformation.hint': 'Favor fornecer as informações adicionais',
  'appointment.visitorInformation.numberOfVisitors': 'Número de visitantes',
  'appointment.visitorInformation.numberOfVisitors.alert': 'Quantas pessoas vêm?',
  'appointment.visitorInformation.counter': 'Contador',
  'appointment.visitorInformation.notRequired': 'Não são necessários dados de contato',
  'appointment.services': 'Serviço',
  'appointment.order': 'Pedido',
  'appointment.services.hint': 'Escolha um serviço que você gostaria de receber',
  'appointment.order.hint':
    'Selecione os produtos que você gostaria de encomendar. Por favor, note que pode haver um limite na quantidade de itens por pedido.',
  'appointment.visitorSelectDate': 'Selecione a data',
  'appointment.noMoreToday': 'Não há mais compromissos disponíveis hoje',
  'appointment.add': 'Marque uma consulta',
  'appointment.cancelAppointment': 'Cancelar consulta',
  'appointment.leaveQueue': 'Eliminar o bilhete de fila',
  'appointment.next': 'Próximo: {step}',
  'appointment.next.contactInfo': 'Informação de contacto',
  'appointment.next.customFields': 'Informação adicional',
  'appointment.next.timeslot': 'Intervalo de tempo',
  'appointment.next.messagingPrompt': 'Notificações',
  'appointment.next.book': 'Agendar',
  'appointment.next.whatsapp': 'Notificação WhatsApp',
  'appointment.next.queueIn': 'Entre na fila virtual',
  'appointment.next.numberOfVisitors': 'Número de visitantes',
  'appointment.next.services': 'Serviços',
  'appointment.next.products': 'Produtos',
  'appointment.next.submit': 'Enviar',
  'appointment.messagingPrompt': 'Ativar notificações',
  'appointment.submit': 'Enviar pedido',
  'appointment.submit.error': 'Ocorreu um erro ao enviar sua solicitação',
  'appointment.submit.standby': 'Um momento por favor',
  'appointment.visitorPhoneNumValidationStep': 'Verificação de número de telefone',
  'appointment.visitorPhoneNumValidationStep.hint':
    'Nós lhe enviamos um código para {phone}. Por favor, insira-o nos campos abaixo.',
  'appointment.sendAgain': 'Envie novamente',
  'appointment.cancel': 'Cancelar',
  'appointment.back': 'Voltar',
  'appointment.chooseTime': 'Escolha o intervalo de tempo',
  'appointment.makeAnother': 'Marque outra consulta',
  'appointment.queueInAgain': 'Faça fila novamente',
  'appointment.nickname': 'Adicione um nome pelo qual você pode ser chamado',
  'appointment.group': 'Quantas pessoas vão vir',
  'appointment.openHours': 'Horário de funcionamento:',
  'appointment.contacts': 'Contatos:',
  'appointment.description': 'Descrição geral:',
  'appointment.address': 'Endereço',
  'appointment.comment': 'Comentário',
  'appointment.birthDate': 'Data de nascimento',
  'appointment.visitType': 'Serviço',
  'appointment.shareName': 'Seu nome',
  'appointment.sharePhone': 'Número de telefone',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Pesquisa',
  'appointment.sharePhone.searchNotFound': 'Sem entradas para mostrar',
  'appointment.sharePhone.inValid':
    'O número de telefone é inválido. Por favor, tente introduzir o seu número em formato internacional: +1123456789',
  'appointment.next.phoneNumValidation': 'Validação de número de telefone',
  'appointment.shareEmail': 'E-mail',
  'appointment.shareAddress': 'Endereço',
  'appointment.shareBirthDate': 'Data de nascimento',
  'appointment.vaccinationStatus': 'Estado de vacinação Covid-19',
  'appointment.not_vaccinated': 'Não vacinados',
  'appointment.partially_vaccinated': 'Parcialmente vacinados',
  'appointment.fully_vaccinated': 'Totalmente vacinados',
  'appointment.sure': 'Tem a certeza?',
  'appointment.time': 'Data e hora',
  'appointment.invitedAt': 'Convidado em',
  'appointment.queuedAt': 'Enfileirado em',
  'appointment.closedTime': 'Fechado. Abre em',
  'appointment.opensToday': 'Fechado. Abre hoje em',
  'appointment.ticket': 'Bilhete',
  'appointment.position': 'Antes de você',
  'appointment.queueLength': 'Na fila',
  'appointment.eta': 'tempo estimado',
  'appointment.pleaseProceed':
    'Estamos prontos para atendê-lo.\n Dirija-se para o serviço agendado',
  'appointment.header': 'Suas filas',
  'appointment.waitingTime': 'Tempo estimado de espera',
  'appointment.ticketWaitingTime': 'Tempo de espera',
  'appointment.estimatedWaitingTime': 'Tempo estimado de espera',
  'appointment.min': 'min',
  'appointment.people': 'pessoas',
  'appointment.error.invalidFormFields':
    'Por favor, preencha os campos destacados do formulário para prosseguir',
  'appointment.error.invalidVisitType': 'Por favor, forneça o motivo de sua visita para continuar',
  'appointment.error.noSlotSelected': 'Nenhum intervalo de tempo selecionado',
  'appointment.confirmation.preliminaryWarning':
    'Esta reserva é preliminar até que você receba uma carta de confirmação.',
  'appointment.confirmation.rejectedWarningAppointment':
    'Lamentamos, mas o administrador rejeitou sua nomeação. Experimente reservar outro.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'Sentimos muito, mas o administrador rejeitou seu bilhete. Tente fazer fila novamente.',
  'appointment.confirmation.appointmentHasPreference':
    'Por favor, note que as nomeações reservadas antecipadamente podem ter prioridade sobre a fila',
  'appointment.products.error.maxOrder':
    'O pedido pode conter até {quantity} itens. Reduza o valor.',
  'appointment.products.price': 'Preço',
  'appointment.products.inStock': 'Em stock',
  'appointment.products.outofstock': 'Fora de estoque',

  'appointment.disabled': 'O administrador desativou o sistema de programação para este local.',

  'appointment.messaging.pending':
    'Para notificá-lo sobre atualizações de status de compromisso, precisamos que você habilite as notificações push. Clique em <b>permitir</b> se você concordar.',
  'appointment.messaging.denied':
    'As permissões de envio de notificações não foram activadas. Não receberá notificações push',
  'appointment.messaging.granted': 'Permissão para enviar noificação push concedida. Obrigado!',

  'livequeue.peopleNum': 'Número de pessoas na fila',
  'livequeue.yourNumberInQueue': 'Sua posição na fila',
  'livequeue.youAreNext': 'Você é o primeiro na fila',
  'livequeue.leave': 'Sair da fila',
  'livequeue.showAtRequest': 'Mostre este ingresso a pedido',
  'livequeue.submit': 'Entre na fila virtual',
  'livequeue.liveQueuePaused':
    'O administrador do local parou temporariamente a fila digital. Por favor, tente mais tarde.',
  'livequeue.liveQueueOpenHours':
    'Está a tentar fazer fila fora do horário de abertura. Por favor, volte mais tarde.',
  'livequeue.disabled': 'O administrador desativou a fila digital para este local.',
  'livequeue.queueTooLong':
    'O administrador limitou o número de pessoas na fila. Por favor, tente novamente mais tarde.',

  'monitor.pleaseProceed': 'Por favor dirija-se ao serviço agendado',
  'monitor.peopleInQueue': 'Mais bilhetes',
  'monitor.livequeue': 'Fila Virtual',
  'monitor.appointments': 'Agendamentos',
  'monitor.appointment.type.appointment': 'compromisso',
  'monitor.appointment.type.livequeue': 'fila ao vivo',
  'monitor.stats': 'Ocupação hoje',
  'monitor.pleaseScanAppointments': 'Digitalize este código QR para agendar uma consulta',
  'monitor.pleaseScanLivequeue': 'Aponte sua câmera para o QR Code para entrar na fila virtual',
  'monitor.pleaseScanIntegrated':
    'Digitalize este código QR para fazer fila de espera ou para marcar uma consulta',
  'monitor.followThisLink': 'Ou acesse este endereço',
  'cookies.concent':
    'Este site usa apenas cookies tecnicamente necessários que não podem ser desmarcados. Os cookies necessários ajudam a tornar um site utilizável, permitindo funções básicas, como navegação na página e acesso a áreas seguras do site. O site não pode funcionar corretamente sem esses cookies.',
  'cookies.ok': 'Entendo',
  'dialogs.pageIsForDesktop':
    'Você está visualizando esta página no navegador do seu celular.\nEsta página é otimizada para um computador desktop. Algumas funções podem não estar disponíveis\ne a página pode ser renderizada com erros.',
  'dialogs.pageIsForDesktopTitle': 'Visualização da área de trabalho',
  'dialogs.reject': 'Rejeitar',
  'dialogs.confirm': 'confirmar',
  trial: 'Iniciar teste',

  // SignUp
  'signUp.referralCode': 'Introduza um código de referência, se tiver um',

  'login.header': 'Obrigado por escolher 2meters!',
  'login.header2': 'Criamos nosso serviço para ajudá-lo a',
  'login.text1':
    'estabelecer um sistema de reserva de compromissos online confortável que seus clientes vão adorar',
  'login.text2':
    'estabelecer uma solução inovadora de filas digitais para sua loja ou serviço a fim de reter clientes antigos e atrair novos, eliminando filas irritantes onde quer que apareçam',
  'login.text3':
    'ou a fazer os dois, pois nossa solução integra os dois recursos. Permita que seu cliente marque diretamente uma consulta se o tempo de espera na fila for muito longo',
  'login.google': 'Login com o Google',
  'login.facebook': 'Login com o Facebook',
  'login.withPassword': 'Login com e-mail',
  'login.newUser': 'Inscreva-se em 2meters',
  'login.existingUser': 'Faça login em 2meters',
  'login.signUp': 'Inscreva-se',
  'login.signIn': 'Entrar',
  'login.email': 'E-mail',
  'login.email.placeholder': 'example@domain.com',
  'login.password': 'Senha',
  'login.password.placeholder': '8+ caracteres',
  'login.passwordRepeat': 'Repita a senha',
  'login.passwordRepeat.placeholder': '8+ caracteres',
  'login.forgotPassword': 'Esqueceu a senha?',
  'login.passwordsDontMatch': 'As senhas não correspondem',
  'login.sendRecoveryEmail':
    'Enviaremos a você um link para alterar sua senha. Por favor, diga-nos o e-mail no qual você se registrou',
  'login.checkEmail': 'Por favor, verifique seu e-mail e siga as instruções',
  'login.resetPassword': 'Redefinir senha',
  'login.recoveryEmailSent': 'O e-mail de recuperação foi enviado para {email}',
  'login.passwordTooShort': 'A senha deve ter pelo menos 8 caracteres',
  'login.dontHaveAccount': 'Não tem uma conta?',
  'login.alreadyHaveAccount': 'Já possui uma conta?',
  'login.enterDetails': 'Insira os detalhes abaixo',
  'login.alreadyRegistered': 'Entrar',
  'login.getStarted': 'Iniciar',
  'login.acceptTerms': 'eu aceito',
  'login.termsAndConditions': 'Termos e Condições',
  'login.termsNotChecked': 'Aceite nossos Termos e Condições para prosseguir',
  'login.acceptPrivacyPolicy': 'Eu concordo com',
  'login.privacyPolicy': 'Política de Privacidade',
  'login.privacyPolicyNotChecked': 'Aceite nossa Política de Privacidade para continuar',
  'login.missingTermsConsent.title': 'Só mais um passo',
  'login.missingTermsConsent.desc':
    'Aceite nossos Termos e Condições e Política de Privacidade para acessar nosso serviço. Obrigado!',

  'customerProfileForm.firstName': 'Primeiro nome',
  'customerProfileForm.lastName': 'Sobrenome',
  'customerProfileForm.email': 'Email',
  'customerProfileForm.organization': 'Nome da organização',
  'customerProfileForm.country': 'País',
  'customerProfileForm.streetAddress': 'Dirección de la calle',
  'customerProfileForm.postalCode': 'Código postal',
  'customerProfileForm.city': 'Ciudad',
  'customerProfileForm.vatId': 'Número de contribuinte de IVA',
  'customerProfileForm.referralCode': 'Código de referência',
  'customerProfileForm.placeholder.firstName': 'Por favor introduza o primeiro nome',
  'customerProfileForm.placeholder.lastName': 'Por favor introduza o apelido',
  'customerProfileForm.placeholder.email': 'Por favor introduza o e-mail',
  'customerProfileForm.placeholder.organization': 'Por favor introduza o nome da organização',
  'customerProfileForm.placeholder.country': 'Por favor, introduza o país',
  'customerProfileForm.placeholder.streetAddress': 'Por favor introduza o endereço de rua',
  'customerProfileForm.placeholder.postalCode': 'Por favor introduza o código postal',
  'customerProfileForm.placeholder.city': 'Por favor, introduza a cidade',
  'customerProfileForm.placeholder.vatId': 'Por favor introduza o ID IVA',
  'customerProfileForm.placeholder.referralCode': 'Por favor introduza o código de referência',
  'customerProfileForm.industry': 'Industria',
  'customerProfileForm.industry.governmental': 'Governo',
  'customerProfileForm.industry.education': 'Educação',
  'customerProfileForm.industry.healthcare': 'Cuidados de saúde',
  'customerProfileForm.industry.retail': 'Retalho',
  'customerProfileForm.industry.wholesale': 'Venda por atacado',
  'customerProfileForm.industry.rental': 'Aluguer',
  'customerProfileForm.industry.banking': 'Banca/finanças',
  'customerProfileForm.industry.transportation': 'Transportes',
  'customerProfileForm.industry.hospitality': 'Hospitalidade',
  'customerProfileForm.industry.hotel': 'Hotel e recriativo',
  'customerProfileForm.industry.travel': 'Viagens e turismo',
  'customerProfileForm.industry.other': 'Outros',

  // Notifications
  'notifications.title': 'Notificações SMS',
  'notifications.informationBanner.sms': 'SMS enviados',
  'notifications.informationBanner.whatsapp': 'O que foi enviado',
  'notifications.progressBar.freeSms': 'Nível gratuito elegível',
  'notifications.progressBar.exhaustedWhatsapp': 'Liberte o que se esgotou',
  'notifications.informationBanner.totalSmsCost': 'Custo total do SMS',
  'notifications.informationBanner.totalWhatsappCost': 'Custo total do Whatsapp',
  'notifications.progressBar.exhaustedSms': 'Piscina sms gratuita esgotada',
  'notifications.informationBanner.totalCost': 'Custo total',
  'notifications.table.recipient': 'Destinatário',
  'notifications.table.date': 'Dados',
  'notifications.table.time': 'Hora',
  'notifications.table.place': 'Lugar',
  'notifications.table.type': 'Tipo',
  'notifications.table.price': 'Preço',
  'notifications.table.country': 'País',
  'notifications.table.message': 'Mensagem',
  'notifications.empty.header':
    'Seus lugares ainda não geraram nenhuma notificação paga no período de tempo determinado',
  'notifications.filter.startDate': 'Data de início',
  'notifications.filter.endDate': 'Data final',
  'notifications.exportTable.status': 'Estado',
  'notifications.exportTable.type': 'Tipo',
  'notifications.exportTable.isEmpty': 'está vazio',

  // Export Appointments
  'exportAppointments.tickets': 'Bilhetes',
  'exportAppointments.tag': 'Tag',
  'exportAppointments.date': 'Data',
  'exportAppointments.time': 'Hora',
  'exportAppointments.service': 'Serviço',
  'exportAppointments.type': 'Tipo',
  'exportAppointments.name': 'Nome',
  'exportAppointments.status': 'Estado',
  'exportAppointments.statusChangeDate': 'Data de alteração do estado',
  'exportAppointments.statusChangeTime': 'Tempo de mudança de estado',
  'exportAppointments.phone': 'Telefone',
  'exportAppointments.email': 'Email',
  'exportAppointments.address': 'Endereço',
  'exportAppointments.birthDate': 'BirthDate',
  'exportAppointments.comments': 'Comentários',
  'exportAppointments.appointment': 'Nomeação',
  'exportAppointments.queue': 'Fila',
  'onboarding.wait':
    'Por favor aguarde, enquanto criamos o seu perfil de utilizador e configuramos todas as coisas necessárias',
  'onboarding.createPlace.wait': 'Por favor, aguardem, enquanto estamos carregando...',
  'onboarding.loading': 'Carregamento...',
  'onboarding.back': 'Voltar',
  'onboarding.next': 'Próximo',
  'onboarding.skip': 'Saltar para o espaço em branco',
  'onboarding.step1.header': 'Marcando compromissos com 2meters',
  'onboarding.step1.text.p1':
    'Para habilitar o agendamento, basta configurar o seu local preenchendo seus dados básicos:',
  'onboarding.step1.text.list1.item1': 'Nome e localização',
  'onboarding.step1.text.list1.item2': 'Dias e horas de trabalho',
  'onboarding.step1.text.list1.item3': 'Durações típicas de compromissos',
  'onboarding.step1.text.p2':
    'Para fornecer um serviço ainda melhor, você pode configurar uma lista de serviços ou produtos que oferece, para que os clientes possam informar com antecedência o motivo da visita.',
  'onboarding.step1.text.p3':
    'Para ter controle total sobre sua programação, você pode ativar um recurso que permite confirmar ou recusar compromissos agendados preliminarmente por seus clientes.',
  'onboarding.step2.header': 'Marcando compromissos com 2meters',
  'onboarding.step2.text.p1':
    'Após a configuração, basta copiar o link fornecido em um e-mail ou colocá-lo em seu site onde seus clientes possam clicar ou tocar nele.',
  'onboarding.step2.text.p2':
    'Como alternativa, você pode integrar nossa interface interativa em seu site por meio de um snippet de código.',
  'onboarding.step3.header': 'Marcando compromissos com 2meters',
  'onboarding.step3.text.p1':
    'Dê as boas-vindas aos seus clientes pela primeira vez! Você pode identificá-los facilmente, pedindo para fornecer os bilhetes eletrônicos salvos em seus smartphones.',
  'onboarding.step3.text.p2':
    'Se você precisa atender clientes que estão entrando, além de reservados, vai adorar saber que nossa função de fila digital mescla perfeitamente os dois fluxos de clientes. Mas como isso funciona?',
  'onboarding.step4.header': 'Elimine filas com 2meters',
  'onboarding.step4.text.p1':
    'Nossa fila digital oferece exatamente o que seus clientes esperam de um conceito de serviço moderno - os smartphones fazem a espera enquanto seus proprietários podem passear ou tomar uma xícara ou café.',
  'onboarding.step4.text.p2':
    'Notificamos seu cliente à medida que a fila avança, para que ele apareça na hora certa.',
  'onboarding.step4.text.p3':
    'Para criar uma fila digital, basta habilitar essa funcionalidade ao criar seu local.',
  'onboarding.step5.header': 'Elimine filas com 2meters',
  'onboarding.step5.text.p1':
    'Imprima um PDF com o código QR fornecido e coloque-o em qualquer lugar, onde você gostaria que seus clientes fizessem fila digitalmente.',
  'onboarding.step5.text.p2':
    'Configure um display para mostrar todos os tíquetes que se aproximam e atendidos no momento e faça com que seu local pareça um moderno centro de serviços do Vale do Silício.',
  'onboarding.step6.header': 'Elimine filas com 2meters',
  'onboarding.step6.text.p1':
    'Existem muito mais recursos para explorar. Entre em contato conosco se precisar de informações adicionais sobre como ajustar sua fila digital para se adequar ao seu caso específico. Preparamos alguns modelos pré-configurados para você. Selecione um modelo e ajuste-o às suas necessidades ou crie um novo local. O que você gostaria de fazer?',
  'onboarding.step6.btn1': 'Crie um local demo com Fila ao Vivo (barbearia)',
  'onboarding.step6.btn2': 'Crie um local demo com compromissos (barbearia)',
  'onboarding.step6.btn3': 'Crie um sistema de agendamento ou uma fila digital',
  'onboarding.template.barbershop.name': 'Local demo (barbearia)',
  'onboarding.template.barbershop.description':
    'A barba é uma tendência. O cabelo do rosto só fica realmente bonito com um bom cuidado e um penteado perfeito. Abrimos um lugar onde os homens podem relaxar e estar entre si. Você pode esperar conselhos individuais sobre como cuidar do cabelo / barba e pentear, mas mãos e pés bem cuidados também fazem parte dessa experiência! Em uma atmosfera clássica - entre poltronas de couro elegantes, acessórios de madeira, paredes de tijolo e um salão integrado de uísque e charuto - há lixamento, descascamento e barbear.',
  'onboarding.template.barbershop.service1': 'Corte de cabelo normal',
  'onboarding.template.barbershop.service2': 'Corte de barba',
  'onboarding.template.barbershop.service3': 'Corante',
  'onboarding.template.barbershop.service4': 'Corte de contorno',
  'onboarding.template.default.service1': 'Meu serviço 1',
  'onboarding.template.default.service2': 'Meu serviço 2',
  'onboarding.template.covid.name': 'Centro de Teste de Covid (modelo)',
  'onboarding.template.popup.name': 'Loja pop-up walk-in (modelo)',
  'onboarding.template.shopping.name': 'Compromisso de compra (modelo)',
  'onboarding.template.restaraunt.name': 'Restaraunte (modelo)',
  'admin.place.livequeueUrlInfo': 'Link para a fila ao vivo',
  'admin.place.settings': 'Definições',
  'admin.place.settings.text':
    'Nesta página você pode habilitar recursos diferentes para o seu lugar',
  'admin.place.editSetting': 'Editar lugar',
  'admin.place.editManagers': 'Gestores de edição',
  'admin.place.navigation.overview': 'Visão geral',
  'admin.place.navigation.dashboard': 'Painel',
  'admin.place.navigation.queues': 'Balcão',
  'admin.place.navigation.monitor': 'Monitor',
  'admin.place.navigation.billing': 'Cobrança',
  'admin.place.navigation.backToPlaces': 'Voltar para lugares',
  'admin.place.navigation.back': 'Voltar',
  'admin.place.averageDuration': 'Duração do slot (minutos)',
  'admin.place.capacity.help': 'Quantos clientes podem entrar em seu local ao mesmo tempo',
  'admin.place.capacity': 'Capacidade da loja',
  'admin.place.closingTime': 'Horário de encerramento',
  'admin.place.openingTime': 'Tempo de abertura',
  'admin.place.openingDays': 'Dias de abertura',
  'admin.place.openingDate': 'Data de início da campanha',
  'admin.place.openingDate.help':
    'Se você pretende usar 2neters por um período limitado, insira a data em que deve ser ativado',
  'admin.place.closingDate': 'Data de término da campanha',
  'admin.place.closingDate.help':
    'Se você pretende usar 2meters por um período limitado, insira a data em que o sistema deve ser desligado',
  'admin.place.contactEmail': 'E-mail de contato do administrador do local',
  'admin.place.contactEmail.help':
    'Você pode preencher este campo, se quiser que seu e-mail de contato apareça nas notificações por e-mail que enviaremos aos seus visitantes (somente se você solicitar que eles forneçam seus endereços de e-mail nesta configuração)',
  'admin.place.contactPhoneNumber': 'Telefone de contato do administrador do local',
  'admin.place.contactPhoneNumber.help':
    'Você pode preencher este campo, se quiser que seu número de telefone de contato apareça nas notificações por e-mail que enviaremos aos seus visitantes (somente se você solicitar que eles forneçam seus endereços de e-mail nesta configuração)',
  'admin.place.description': 'Forneça uma breve descrição do seu local e serviços.',
  'admin.place.description.help': 'Seus clientes verão esta descrição',
  'admin.place.timezone': 'Fuso horário',
  'admin.place.noOptions': 'Sem opções',
  'admin.place.toVisitors': 'Página de visitantes',
  'admin.place.printQR': 'imprimir QR',
  'admin.place.shortUrl':
    'Escolha um código de acesso para permitir que seus visitantes acessem rapidamente sua página.',
  'admin.place.shortUrl.error': 'Este breve url já está em uso. Experimente um diferente',
  'admin.place.shortUrl.help':
    'Se você escolher, por exemplo "bbq" para o seu churrasco, estará acessível de qualquer navegador em app.2meters.app/p/bbq',
  'admin.place.shortUrlInfo': 'Link para o seu lugar',
  'admin.place.monitorUrlInfo': 'Link para visualização do monitor',
  'admin.place.qrCode': 'Código QR para o seu lugar',
  'admin.place.address': 'Endereço do seu lugar',
  'admin.place.yourAppointment': 'Seu compromisso',
  'admin.place.appointments.delete': 'Excluir',
  'admin.place.appointments.cancel': 'Cancelar',
  'admin.place.addAppointmentDialogTitle': 'Adicionar compromisso',
  'admin.place.addAppointmentDialogDescription':
    'Você pode adicionar um apelido de visitante e uma breve descrição ao compromisso',
  'admin.place.appointmentNickname': 'Apelido',
  'admin.place.appointmentDescription': 'Descrição',
  'admin.place.visitType': 'Forneça uma lista dos serviços que você oferece',
  'admin.place.visitType.help':
    'Seus clientes terão que escolher o serviço desta lista ao fazer uma reserva.',
  'admin.place.managers': 'Lista de Place-Managers',
  'admin.place.managers.help':
    'Insira os endereços de e-mail para atribuir funções de gerente aos seus funcionários. Confirme cada e-mail com Enter.',
  'admin.place.titles.settings': 'Visão geral do local',
  'admin.place.wizard.titles.details': 'Detalhes do lugar',
  'admin.place.wizard.titles.visitorData': 'Dados do Visitante',
  'admin.place.wizard.titles.inventory': 'Seu Inventário',
  'admin.place.wizard.titles.queue': 'Fila virtual',
  'admin.place.wizard.titles.appointments': 'Horário',
  'admin.place.wizard.titles.access': 'Acesso',
  'admin.place.wizard.titles.plans': 'Escolha o seu Plano',
  'admin.place.wizard.details': 'Detalhes',
  'admin.place.wizard.visitorData': 'Dados do Visitante',
  'admin.place.wizard.inventory': 'Inventário',
  'admin.place.wizard.settings': 'Configurações',
  'admin.place.wizard.schedule': 'Horário',
  'admin.place.wizard.queue': 'Fila',
  'admin.place.wizard.appointment': 'Encontro',
  'admin.place.wizard.access': 'Acesso',
  'admin.place.wizard.plans': 'Planos',
  'admin.place.wizard.actions.next': 'Próximo',
  'admin.place.wizard.actions.trial': 'Iniciar teste',
  'admin.place.wizard.actions.finish': 'Terminar',
  'admin.place.wizard.actions.saveAll': 'Salvar',
  'admin.place.wizard.actions.back': 'Voltar',
  'admin.place.wizard.actions.cancel': 'Cancelar',
  'admin.place.wizard.actions.agree': 'Concorda',
  'admin.place.wizard.actions.create': 'Criar',
  'admin.place.wizard.whatsappStep.title': 'Concorda em receber notificações através do WhatsApp ?',
  'admin.place.wizard.whatsappStep.accept': 'Aceitar',
  'admin.place.wizard.whatsAppModal.title': 'Notificações WhatsApp',
  'admin.place.wizard.whatsAppModal.description':
    'Observe que, ao ativar as notificações da WhatsApp, você deve considerar que serão aplicados encargos adicionais. Nós lhe concedemos 100 mensagens WhatsApp gratuitas, que você pode usar para testes. No entanto, para qualquer mensagem WhatsApp enviada acima, cobraremos um preço, que depende do país de origem dos destinatários da WhatsApp. Criaremos faturas adicionais e as enviaremos separadamente a cada mês. Você pode desativar este recurso a qualquer momento. Se você precisar de informações detalhadas sobre os preços, entre em contato com nosso departamento de vendas em ',
  'admin.place.wizard.requiredFields': 'Por favor preencha todos os campos requeridos',
  'admin.place.wizard.smsModal.title': 'Notificações SMS',
  'admin.place.wizard.smsModal.description':
    'Favor observar, ligando as notificações por SMS que você deve considerar, que serão aplicados encargos adicionais. Nós lhe concedemos 100 SMS grátis, que você pode usar para testes. Entretanto, para qualquer SMS enviado acima disso, cobraremos um preço, que depende e é comparável aos preços dos provedores móveis locais do país de onde seus visitantes estão vindo. Criaremos faturas adicionais e as enviaremos separadamente a cada mês. Você pode desativar este recurso a qualquer momento. Se você precisar de informações detalhadas sobre os preços, entre em contato com nosso departamento de vendas em ',
  'admin.place.queue.text':
    'Nossa exclusiva função Fila ao vivo permite que seus visitantes façam fila digitalmente, digitalizando um código QR ou digitando o URL curto do seu lugar na janela do navegador. Você pode gerenciar facilmente a fila do seu balcão, garantindo um atendimento rápido e conveniente para todos os visitantes. Nosso sistema mescla perfeitamente a fila ativa com os compromissos tradicionais - os clientes com agendamento são inseridos automaticamente na fila ativa pouco antes do horário agendado. Marque a caixa abaixo para habilitar as filas ao vivo em seu local.',
  'admin.place.inventory.text':
    'Se vende quaisquer itens e quer que os seus visitantes os encomende, basta adicioná-los ao seu inventário. A activação do inventário cria uma etapa adicional na parte do visitante da aplicação, onde um visitante pode reservar ou comprar qualquer um dos seus artigos. Pode configurar o stock para os seus artigos ou definir como não limitado. Pode também optar por restringir a quantidade máxima de artigos que vai para uma mão para se certificar de que todos os seus clientes receberão a sua parte.',
  'admin.place.visitorData.text':
    'Optamos deliberadamente por permitir o anonimato total do usuário, mas em alguns casos de uso pode ser necessário coletar dados para fornecer seu serviço. Aqui você pode escolher o que deseja saber sobre seus clientes quando eles marcam um encontro ou fazem fila. Se você optar por coletar seus e-mails, também poderemos enviar notificações por e-mail, que podem ser amigáveis para o cliente. Note que os dados pessoais que o seu cliente deixa, se você tiver escolhido alguma opção, serão apagados dos nossos servidores dois dias após a visita.',
  'admin.place.appointments.text':
    'Estabeleça o seu horário de abertura e horário de serviço a fim de definir como funcionarão as nossas funcionalidades digitais de gestão de filas e marcações. Oferecemos dois tipos de gerenciamento de horários. Simples e Avançado. Com o Simple, você só define seus dias e horas de trabalho. Com um Avançado, você tem controle total sobre a programação.',
  'admin.place.access.text':
    'Se você tiver funcionários, convém que eles tenham acesso ao contador sem compartilhar suas credenciais. Com acesso ao contador, os funcionários podem gerenciar os compromissos e a fila ao vivo. Eles não podem alterar as configurações ou quaisquer outras propriedades vitais do seu local. Para atribuir funções de gerente a seus funcionários, insira seus endereços de e-mail abaixo.',
  'admin.place.cancelDialog': 'Cancelar',
  'admin.place.rte.text': 'Texto',
  'admin.place.rte.link': 'Link',
  'admin.place.rte.addLink': 'Adicionar link',
  'admin.place.rte.remove': 'Remover',
  'admin.place.rte.save': 'Save',
  'admin.place.addAppointmentManually': 'Adicionar',
  'admin.place.livequeue.hint': 'Habilite a fila digital em seu lugar',
  'admin.place.businessName': 'Qual é o nome do seu lugar?',
  'admin.place.fullscreen': 'Tela cheia',
  'admin.place.monitor': 'Monitor',
  'admin.account.billingLog': 'Registo de facturação',
  'admin.account.account': 'Conta',
  'admin.account.signOut': 'Sair',
  'admin.account.notifications': 'Notificações',
  'admin.account.billing': 'Planos e Faturamento',
  'admin.place.form.inventory': 'Quais produtos seu local oferece?',
  'admin.place.form.inventory.help':
    'O seu visitante poderá escolher um produto ao mesmo tempo que marca uma consulta ou faz o check-in na fila digital. Deixe em branco, se você não vende nenhum produto.',
  'admin.place.form.askEmail': 'E-mail',
  'admin.place.form.askPhone': 'Número de telefone',
  'admin.place.form.phoneVerificationEnabled': 'Validação de SMS',
  'admin.place.form.phoneVerificationDisabled':
    'Validação de SMS (não disponível em Subscrição privada)',
  'admin.place.form.askName': 'Nome',
  'admin.place.form.askAddress': 'Endereço',
  'admin.place.form.askBirthDate': 'Data de nascimento',
  'admin.place.form.useMailMessenger': 'Notificações por e-mail',
  'admin.place.form.useUIMessenger': 'Notificações por UI',
  'admin.place.form.useWebPushMessenger': 'Notificações por Web Push (Android)',
  'admin.place.form.useWhatsAppMessenger': 'Notificações da WhatsApp',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'Notificações WhatsApp (não disponíveis em Subscrição privada)',
  'admin.place.form.useSMSMessenger': 'Notificações por SMS',
  'admin.place.form.useSMSMessengerDisabled':
    'Notificações por SMS (não disponível em Subscrição privada)',
  'admin.place.form.askVaccinationStatus': 'Estado de vacinação Covid-19',
  'admin.place.form.vaccinationStatus': 'Estado de vacinação Covid-19',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Verifique isto, se você quiser que seus visitantes forneçam seu status de vacinação',
  'admin.place.form.numberOfVisitors': 'Número de visitantes',
  'admin.place.form.askNumberOfVisitors': 'Número de visitantes',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Verifique isto, se seus visitantes podem vir em grupo e você quer saber o número deles',
  'admin.place.form.askWhat': 'Selecione as informações obrigatórias do usuário',
  'admin.place.form.askWhat.help':
    'Para permitir o anonimato total do visitante, deixe todas as caixas desmarcadas. Os dados do visitante serão excluídos automaticamente de nossos servidores dentro de dois dias após a visita.',
  'admin.place.form.messagingConfiguration': 'Configuração de mensagens',
  'admin.place.form.messagingConfiguration.help':
    'Pode escolher entre vários canais para notificar os seus visitantes sobre o estado dos seus bilhetes.',
  'admin.place.form.enableFeatures': 'Habilite recursos adicionais',
  'admin.place.form.allowAppointmentConfirmation': 'Requer confirmação',
  'admin.place.form.allowAppointmentConfirmation.help':
    'Verifique se deseja confirmar ou rejeitar compromissos manualmente. Seus clientes serão notificados por e-mail.',
  'admin.place.form.placeLocale': 'Idioma local padrão',
  'admin.place.form.placeLocale.help':
    'Um idioma que usaremos para enviar notificações aos seus visitantes, caso eles não tenham definido o idioma de preferência',
  'admin.place.embedCode.title': 'Código embutido',
  'admin.place.embedCode.description':
    'Use este código para incorporar o widget 2meters em seu site',
  'admin.place.appointments.enable': 'Habilitar compromissos para este lugar',
  'admin.place.livequeue.enable': 'Ativar fila ao vivo para este lugar',
  'admin.place.form.schedulerType': 'Selecione um tipo de programação',
  'admin.place.form.schedulerType.help':
    'O cronograma avançado está em um estágio inicial de desenvolvimento. Não oferecemos garantias sobre isso. Por favor, use-o e forneça-nos alguns comentários.',
  'admin.place.form.settings.constraints.help': 'Restrições',
  'admin.place.form.settings.constraints.appsperuser.label':
    'Quantos ingressos um usuário pode levar de cada vez',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'Um por vez',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'Um por serviço',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Ilimitada',
  'admin.place.inventory.appointmentsNotAvailable':
    'O programador de vagas para marcações não está disponível, enquanto as marcações estão desactivadas',

  'admin.userProfile.title': 'Perfil do utilizador',
  'admin.profile.title': 'Perfil',
  'admin.profile.editProfile': 'Editar perfil',

  'admin.place.shopCapacity.help':
    'Quantos clientes você pode atender ao mesmo tempo. Cada intervalo de tempo pode ser reservado por esse número de vezes por clientes diferentes',
  'admin.place.averageDuration.help':
    'Suas horas de trabalho serão divididas em intervalos de tempo com a seguinte duração',
  'admin.place.inventory.enable': 'Ativar produtos para este lugar',
  'admin.place.inventory.placeCurrency': 'Moeda',
  'admin.place.inventory.placeCurrency.help':
    'Este valor será usado para mostrar aos seus visitantes os preços corretos',
  'admin.place.inventory.inventoryMaxOrder': 'Máximo de itens por pedido',
  'admin.place.inventory.inventoryMaxOrder.help':
    'A quantidade de itens que podem ser encomendados em sua casa por um visitante. Deixe este campo vazio, se o seu estoque for ilimitado.',
  'inventory.quantity': 'Estoque',
  'inventory.maxPerUser': 'Limite',
  'inventory.newProduct': 'Novo produto',
  'inventory.editProduct': 'Editar produto',
  'inventory.edit': 'Editar',
  'inventory.delete': 'Eliminar',
  'inventory.menu': 'Menu',
  'inventory.duplicate': 'Duplicado',
  'inventory.addProduct': 'Adicionar produto',
  'inventory.enterProductData': 'Por favor, insira os dados do seu produto',
  'inventory.productName': 'Nome',
  'inventory.productName.placeholder': 'Este campo não deve estar vazio',
  'inventory.productDescription': 'Descrição',
  'inventory.productDescription.placeholder': 'Este campo não deve estar vazio',
  'inventory.productStock': 'Estoque',
  'inventory.productStock.placeholder':
    'Por favor, deixe o campo vazio, se o seu estoque for ilimitado',
  'inventory.unlimited': 'ilimitado',
  'inventory.product.crop': 'Crop',
  'inventory.product.cropTitle': 'Cortar a sua fotografia',
  'inventory.product.uploadImage': 'Carregar',
  'inventory.price': 'Preço',
  'inventory.price.placeholder': 'Preço do produto',
  'admin.subscription': 'Inscrição',
  'admin.subscription.trial': 'Tentativas',
  'admin.subscription.nextInvoice': 'Próxima fatura',
  'admin.subscription.cancelAt': 'A assinatura será cancelada',
  'admin.subscription.cancel': 'Cancelar',
  'admin.subscription.sure_cancel': 'Tem certeza que deseja cancelar sua assinatura?',
  'admin.subscription.cancelled': 'Assinatura cancelada',
  'admin.subscription.trial_description':
    'O período de teste começará imediatamente e terminará em 30 dias. Durante o período de teste, todos os recursos estarão disponíveis para você. Não pediremos informações de pagamento para iniciar o teste. Ao final do período de teste, o local será automaticamente alterado para um plano privado. Se você gosta de nosso serviço, assine a qualquer hora. A avaliação só pode ser usada uma vez.',
  'admin.subscription.trial_started': 'O período de teste começou e terminará em {date}',
  'admin.places.list.empty.createNew': 'Crie um novo lugar',
  'admin.places.list.ticketsUsed': 'Ingressos usados',
  'admin.places.list.empty.header': 'Você ainda não tem lugares',
  'admin.places.list.empty.body': 'Crie um novo lugar e vamos começar a lidar com a fila juntos',
  'admin.places.list.header': 'Seus lugares',
  'admin.places.list.managed': 'Seus lugares',
  'admin.places.list.createNew': 'Crie um novo lugar',
  'admin.places.create.title': 'Crie um lugar',
  'admin.places.update.title': 'Atualizar um lugar',
  'qr.print': 'Imprima este documento e coloque-o onde seus clientes possam vê-lo facilmente',
  'qr.pleaseScanAppointments': 'Aponte sua câmera para o QR Code para entrar na fila virtual',
  'qr.pleaseScanLivequeue': 'Digitalize este código QR para fazer fila em',
  'qr.pleaseScanIntegrated':
    'Digitalize este código QR para fazer fila de espera ou para marcar uma consulta',
  'qr.followThisLink': 'Ou acesse este endereço',
  'qr.error': 'Alguma coisa correu mal. Tente mais tarde, por favor',
  'counter.processing': 'Em processamento',
  'counter.processing.add': 'Deixe o próximo entrar',
  'counter.liveQueue': 'Fila Virtual',
  'counter.stopQueue': 'Não-pintado',
  'counter.startQueue': 'Pausado',
  'counter.liveQueue.add': 'Adicionar novo',
  'counter.appointments': 'Compromissos',
  'counter.calendar': 'Calendário',
  'counter.appointments.add': 'Adicionar novo',
  'counter.appointments.dialog.header': 'Anotação de livro',
  'counter.appointments.dialog.edit.header': 'Editar bilhete {tag}',
  'counter.appointments.dialog.comment.header': 'Adicionar comentário a {tag}',
  'counter.appointments.dialog.add': 'Agendar',
  'counter.appointments.dialog.cancel': 'Cancelar',
  'counter.appointments.dialog.selectDate': 'Selecione a data',
  'counter.appointments.dialog.pickTime': 'Escolher o tempo',
  'counter.appointments.dialog.availableTime':
    'Tempo disponível (clique em slots diferentes para criar vários compromissos de uma vez)',
  'counter.appointments.dialog.noAvailableTimeSlot':
    'Não há horários disponíveis na data selecionada para o serviço determinado',
  'counter.appointments.dialog.noAvailableTime':
    'Não há faixas horárias disponíveis na data seleccionada para determinado serviço',
  'counter.appointments.dialog.noAvailableTimeToday': 'Não há horários disponíveis para hoje',
  'counter.appointments.dialog.selectedSlots': 'Os compromissos a seguir serão criados',
  'counter.appointments.dialog.selectService':
    'Selecione o tipo de serviço para ver os horários disponíveis',
  'counter.appointments.dialog.serviceType': 'Tipo de serviço',
  'counter.appointments.dialog.order': 'Pedido',
  'counter.liveQueue.dialog.header': 'Enfileirar',
  'counter.liveQueue.dialog.cancel': 'Cancelar',
  'counter.userInfo.nickname': 'Nome',
  'counter.userInfo.email': 'E-mail',
  'counter.userInfo.phone': 'Telefone',
  'counter.userInfo.birthDate': 'Data de nascimento',
  'counter.userInfo.address': 'Endereço',
  'counter.toQueue': 'Enfileirar',
  'counter.invite': 'Convidar',
  'counter.processed': 'Processado',
  'counter.invited': 'Convidamos',
  'counter.noname': 'Sem nome',
  'counter.walkin': 'Sem compromisso',
  'counter.done': 'Feito',
  'counter.error': 'Ocorreu um erro, entre em contato com o suporte: {error}',
  'counter.info.dialog.notset': 'não configurado',
  'counter.appointments.info.queueIn': 'Enfileirar',
  'counter.info.dialog.sureCancel': 'Quer rejeitar este cliente?',
  'counter.info.dialog.time': 'Tempo',
  'counter.info.dialog.email': 'E-mail',
  'counter.info.dialog.phone': 'Telefone',
  'counter.info.dialog.username': 'Nome',
  'counter.info': '...',

  'counter.appointment.status.noShow': 'Não aparećeu',
  'counter.appointment.status.waiting': 'Pendente',
  'counter.appointment.status.confirmed': 'Confirmado',
  'counter.appointment.status.queued': 'Enfileirado',
  'counter.appointment.status.invited': 'Convidamos',
  'counter.appointment.status.scheduled': 'Agendado',
  'counter.appointment.status.walkin': 'Walk-in',
  'counter.appointment.status.processed': 'Processado',
  'counter.appointment.status.cancelled': 'Cancelado',
  'counter.appointment.status.rejected': 'Rejeitado',

  'counter.table.header.ticket': 'Bilhete',
  'counter.table.header.when': 'Quando',
  'counter.table.header.name': 'Nome',
  'counter.table.header.email': 'Email',
  'counter.table.header.service': 'Serviço',
  'counter.table.header.status': 'Estado',
  'counter.table.header.statusChanged': 'Estado alterado',
  'counter.table.header.type': 'Tipo',

  'counter.appointment.action.noShow': 'Não aparećeu',
  'counter.appointment.action.invite': 'Convidar',
  'counter.appointment.action.queueIn': 'Adicionar à fila',
  'counter.appointment.action.done': 'Feito',
  'counter.appointment.action.confirm': 'Confirmar',
  'counter.appointment.action.cancel': 'Cancelar',
  'counter.appointment.action.reject': 'Rejeitar',
  'counter.appointment.action.edit': 'Editar',
  'counter.appointment.action.comment': 'Comentário',
  'counter.appointment.action.noComment': 'Ainda sem comentários',
  'counter.appointment.action.backToQueue': 'Voltar à Fila de espera',
  'counter.appointment.action.backToAppointment': 'Voltar a Nomeação',
  'counter.showOnly': 'Apenas mostrar',
  'counter.filter.all': 'Todos',
  'counter.filter.allServices': 'Todos os serviços',
  'counter.noAppointments': 'Não há compromissos marcados para a data selecionada',
  'counter.noAppointmentsToday': 'Não há marcações para hoje',
  'counter.noTickets': 'Não há bilhetes feitos para a data seleccionada',
  'counter.tabs.today': 'Hoje',
  'counter.tabs.calendar': 'História',
  'counter.filter.types.noShow': 'Não aparećeu',
  'counter.filter.types.active': 'Bilhetes activos',
  'counter.filter.types.rejected': 'Rejeitado',
  'counter.filter.types.cancelled': 'Cancelado',
  'counter.filter.types.processed': 'Processado',
  'counter.filter.types.notConfirmed': 'Não confirmado',
  'counter.tabs.counterName': 'Nome do contador',
  'counter.tabs.anyName': 'Qualquer nome',

  'admin.place.form.settings.automation.help': 'Automação',
  'admin.place.form.settings.automation.processing.help':
    'Definir em branco se você preferir limpar à mão',
  'admin.place.form.settings.automation.processing.label':
    'min. Os ingressos convidados serão removidos da tela após esse período',

  'admin.place.form.settings.automation.eta.auto': 'Auto',
  'admin.place.form.settings.automation.eta.constant': 'Constante',
  'admin.place.form.settings.automation.eta.off': 'Desligada',
  'admin.place.form.settings.automation.eta.label': 'Modo para calcular o tempo estimado de espera',
  'admin.place.form.settings.automation.eta.help': `A maneira como o tempo de espera deixou para um bilhete de fila específico é calculado.\n
      Auto: Leva em consideração um tempo médio dos últimos 6 ingressos processados. \n
      Constante: você pode especificar o tempo necessário para processar um ticket. \n
      OFF: O tempo de espera estimado não será mostrado.
    `,
  'admin.place.form.settings.automation.eta.constant.label':
    'minutos. Tempo de processamento de um bilhete',
  'admin.place.form.settings.automation.eta.constant.help':
    'Será usado para estimar o tempo de espera na fila',

  'admin.place.form.settings.appointments.help':
    'Compromissos permitem que seus clientes identifiquem um intervalo de tempo predefinido para sua visita',
  'admin.place.form.settings.appointments.label': 'Ativar compromissos',
  'admin.place.form.settings.livequeue.label': 'Ativar LiveQueue.',
  'admin.place.form.settings.livequeue.help':
    'Ao contrário de compromissos, LiveQueue permite que seus clientes estabeleçam um lugar em uma fila sem um horário definido',
  'admin.place.form.settings.livequeue.maxLength.label': 'Comprimento máximo da fila',
  'admin.place.form.settings.livequeue.maxLength.help':
    'Nenhum novo cliente pode enfileirar se o comprimento da fila atingir esse número.',
  'admin.place.form.settings.livequeue.openingHours.label': 'Apenas no horário de funcionamento',
  'admin.place.form.settings.livequeue.openingHours.help':
    'Novos clientes somente poderão entarna fila dentro do horário comercial',
  'admin.place.form.settings.appointment.confirmation.label': 'Requer confirmação',
  'admin.place.form.settings.appointment.confirmation.help':
    'Verifique se você quer confirmar manualmente ou rejeitar compromissos. Seus clientes serão notificados por e-mail.',

  'qr.neverWaste': '2meters. Nunca mais perca a vida em filas.',
  'qr.header': 'Digitalize este código QR para reivindicar seu intervalo de tempo',
  'plans.text':
    'Todos os recursos do serviço 2meters podem ser usados gratuitamente, mas limitados a 100 ingressos por mês. Isso inclui tíquetes de fila e de compromisso. Com o plano Pro não impõe quaisquer limitações',
  'plans.free': 'Grátis*',
  'plans.month.place': '/MÊS/LUGAR',
  'plans.list.private': 'Privado',
  'plans.preloader':
    'Por favor aguarde enquanto oleamos as engrenagens antes de processar o seu pagamento',
  'plans.list.pro': 'Pro',
  'plans.list.basic_monthly': 'Pro',
  'plans.list.basic_annual': 'Pro',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.business': 'O negócio',
  'plans.list.business_annual': 'O negócio',
  'plans.list.business_monthly': 'O negócio',
  'plans.list.enterprise': 'Enterprise',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.trial': 'Teste',
  'plans.chooseAPlan': 'Escolha um plano',
  'plans.ps':
    '* cobraremos custos adicionais pelo consumo de notificações SMS e Whatsapp até ao final de cada mês, se optar por utilizá-los. Os preços são típicos para o país de origem do número de telefone do destinatário e dependem dos preços dos fornecedores locais. Para informações detalhadas, contacte as vendas.',
  'plans.promo': 'Dê à sua equipe os recursos de que ela precisa para ter sucesso.',
  'plans.contactUs': 'Contate-Nos',
  'plans.missingFeature': 'Falta uma característica?',
  'plans.currentPlan': 'Plano atual',
  'plans.upgradePlan': 'Fazer upgrade do Plano',
  'plans.subscription.cancelAt': 'Válido até',
  'plans.cancelSubscription': 'Cancelar subscrição',
  'plans.private.features.1': '100 visitantes por mês',
  'plans.private.features.2': 'Clientes App Ilimitada',
  'plans.private.features.3': 'Sistema de Fila de espera ao vivo',
  'plans.private.features.4': 'Sistema de nomeação de slots',
  'plans.private.features.5': 'Catálogo de produtos',
  'plans.private.features.6': 'Sistema de encomendas',
  'plans.private.features.7': 'Intuitive Counter App',
  'plans.private.features.8': 'Calendário histórico',
  'plans.private.features.9': 'Mostrar App',
  'plans.private.features.10': 'SMS/Whatsapp/Email/Push',
  'plans.pro.features.first': 'O mesmo que Privado',
  'plans.pro.features.1': 'Visitantes ilimitados',
  'plans.pro.features.2': 'Clientes App Ilimitada',
  'plans.pro.features.3': 'Validação dos visitantes por SMS',
  'plans.pro.features.4': 'Análise de desempenho',
  'plans.pro.features.5': 'Formulários personalizados para visitantes',
  'plans.pro.features.6': 'Contadores nomeados',
  'plans.pro.features.7': 'Apoio alargado',
  'plans.enterprise.features.first': 'O mesmo que Pro',
  'plans.enterprise.features.1': 'Visitantes ilimitados',
  'plans.enterprise.features.2': 'Clientes App Ilimitada',
  'plans.enterprise.features.3': 'Integrações',
  'plans.enterprise.features.4': 'Porta de SMS personalizada',
  'plans.enterprise.features.5': 'Marca personalizada',
  'plans.enterprise.features.6': 'Características personalizadas',
  'plans.enterprise.features.7': 'Apoio Premium',
  'plans.enterprise.promo': 'Personalizado',

  'plans.switcher.monthly': 'Mensal',
  'plans.switcher.annualy': 'Anual',
  'plans.mostPopular': 'Mais popular',
  'plans.private.submit': 'Plano actual',
  'plans.pro.submit': 'Seleccione',
  'plans.enterprise.submit': 'Contacte-nos',
  'plans.discont': '10% de desconto',

  'plans.keepSubscribtion': 'Manter assinatura',
  'plans.pro_monthly.cancelSubscription': 'Cancelar o Plano Mensal Pro',
  'plans.pro_annual.cancelSubscription': 'Cancelar o Plano Anual Pro',
  'plans.enterprise_monthly.cancelSubscription': 'Cancelar o Plano Mensal da Enterprise',
  'plans.enterprise_annual.cancelSubscription': 'Cancelar o Plano Anual da Enterprise',
  'plans.cancelSubscription.text':
    'Tem a certeza de que quer cancelar o seu plano de subscrição? O cancelamento irá parar quaisquer pagamentos futuros e o seu acesso a funcionalidades premium irá terminar no final do seu ciclo de facturação actual. Se tiver quaisquer perguntas ou preocupações, por favor contacte a nossa equipa de apoio para obter assistência.',

  'poorConection.title': 'Má ligação à Internet',
  'poorConection.description':
    'A sua ligação à Internet é infelizmente insuficiente para 2metros App para funcionar. Por favor, verifique a sua ligação à Internet ou as políticas de segurança de rede. Por favor, considere que utilizamos as tomadas web para lhe trazer os dados em directo.',
  'checkout.success': 'Check-out bem-sucedido',
  'checkout.redirect': 'Você será redirecionado em um segundo',
  'checkout.falied': 'Check-out falhou. Entre em contato com o suporte',
  'monitor.desktopViewHeader': 'Visualização do monitor',
  'monitor.desktopViewBody':
    'Você pode instalar uma tela para mostrar aos seus visitantes o estado da fila. Abra esta página no computador conectado à tela e clique no botão para entrar no modo de tela cheia.',
  'monitor.fullscreen': 'Tela cheia',
  'monitor.switchTheMonitor': 'Trocar a língua do monitor',
  'statistics.dashboard': 'Painel de estatísticas',
  'statistics.ticketsAndServices': 'Bilhetes e Serviços',
  'statistics.thisMonth': 'Este mês',
  'statistics.thisWeek': 'Esta semana',
  'statistics.today': 'Hoje',
  'statistics.booked': 'Reservado',
  'statistics.period': 'Período',
  'statistics.lastSevenDays': 'Últimos 7 dias',
  'statistics.month': 'Este mês',
  'statistics.custom': 'Gama de datas personalizadas',
  'statistics.services': 'Serviços',
  'statistics.all': 'Todos os serviços',
  'statistics.noData': 'Não há dados para o período seleccionado',
  'statistics.totalAmount': 'Montante total',
  'statistics.avgWaitingTime': 'Tempo médio de espera',
  'statistics.avgProcessingTime': 'Tempo médio de processamento',
  'statistics.periodError': 'A data de início deve ser antes da data final',
  'statistics.minutes': 'acta',
  'statistics.сonfirmationStatus': 'Estado de Confirmação',
  'statistics.ticketType': 'Tipo de bilhete',
  'statistics.ticketStatus': 'Estado do bilhete',
  'statistics.processed': 'Processado',
  'statistics.noShow': 'Não aparećeu',
  'statistics.invited': 'Convidado',
  'statistics.cancelled': 'Cancelado',
  'statistics.confirmed': 'Confirmado',
  'statistics.pending': 'Pendente',
  'statistics.rejected': 'Rejeitado',
  'statistics.queued': 'Enfileirado',
  'statistics.dashboardIsEmpty': 'O painel de instrumentos está vazio',
  'statistics.dashboardIsEmptyDesc':
    'A fim de ter acesso ao painel de estatísticas, por favor actualize para o nível superior de subscrição.',
  'statistics.upgadePlan': 'Plano Upgade',

  // Scheduler

  'scheduler.error.endBeforeStart': 'A data de início deve ser antes da data de término',
  'scheduler.error.notSameDay': 'Os horários de início e término devem ser no mesmo dia',
  'scheduler.error.visitTypeNotSet': 'O tipo de serviço deve ser definido',
  'scheduler.error.invalidSchedule':
    'Agenda inválida. Verifique se o final do período de repetição não é antes da data de início',
  'scheduler.numberOfAppointments': '{num} compromissos neste intervalo',
  'scheduler.simple': 'Simples',
  'scheduler.advanced': 'Avançado',
  'scheduler.serviceType': 'Tipo de serviço',

  'scheduler.timePeriodForm.commit': 'Confirme',
  'scheduler.timePeriodForm.repeat': 'Repita',
  'scheduler.timePeriodForm.never': 'Nunca',
  'scheduler.timePeriodForm.daily': 'Diariamente',
  'scheduler.timePeriodForm.weekly': 'Semanalmente',
  'scheduler.timePeriodForm.monthly': 'Mensalmente',
  'scheduler.timePeriodForm.yearly': 'Anualmente',
  'scheduler.timePeriodForm.repeatEvery': 'Repetir cada',
  'scheduler.timePeriodForm.days': 'dia(s)',
  'scheduler.timePeriodForm.endRepeat': 'Repetição final',
  'scheduler.timePeriodForm.on': 'Em',
  'scheduler.timePeriodForm.after': 'Depois de',
  'scheduler.timePeriodForm.occurs': 'ocorrência(ões)',
  'scheduler.timePeriodForm.weeksOn': 'semana(s) em:',
  'scheduler.timePeriodForm.month': 'mês(es)',
  'scheduler.timePeriodForm.everyMonth': 'de cada mês',
  'scheduler.timePeriodForm.the': 'O',
  'scheduler.timePeriodForm.first': 'Primeiro',
  'scheduler.timePeriodForm.second': 'Segundo',
  'scheduler.timePeriodForm.third': 'Terceiro',
  'scheduler.timePeriodForm.fourth': 'Quarto',
  'scheduler.timePeriodForm.last': 'Último',
  'scheduler.timePeriodForm.years': 'ano(s)',
  'scheduler.timePeriodForm.of': 'de',
  'scheduler.timePeriodForm.every': 'Cada',
  'scheduler.timePeriodForm.timePeriods': 'Período de abertura',
  'scheduler.timePeriodForm.headerCaption':
    'Por favor, defina cada período de tempo, quando você fornece seu serviço particular. Os slots para este período serão calculados automaticamente, dependendo do valor da duração do slot.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Atribuição de serviços e slots',
  'scheduler.timePeriodForm.createButton': 'Criar',

  'scheduler.confirmCancel': 'Você gostaria realmente de descartar as mudanças não salvas?',
  'scheduler.confirmDelete': 'Você gostaria realmente de apagar este período de tempo?',
  'scheduler.cancelButton': 'Cancelar',
  'scheduler.deleteButton': 'Excluir',
  'scheduler.discardButton': 'Descartar',

  'scheduler.editRecurrenceMenu.editTitle': 'Editar períodos de tempo recorrentes',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Eliminar os períodos de tempo recorrentes',
  'scheduler.editRecurrenceMenu.cancelButton': 'Cancelar',
  'scheduler.editRecurrenceMenu.commitButton': 'Confirmar',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'Todos os períodos de tempo',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods':
    'Este e os seguintes períodos de tempo',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'Este período de tempo',

  // Managers

  'manager.title': 'Gerentes',
  'manager.description':
    'Aqui você pode adicionar e remover pessoas que têm acesso a este lugar. Os gerentes podem manipular compromissos e fila da verreta. Mas eles não podem editar as configurações do local.',
  'manager.add': 'Adicionar gerente.',
  'manager.remove': 'Remover',
  'manager.admin': 'Administradora',
  'manager.manager': 'Gerente',
  'manager.name': 'Nome',
  'manager.email': 'Email',
  'manager.role': 'Função',
  'manager.invitationSent': 'Convite enviado',
  'manager.sendInvite': 'Enviar Convite',
  'manager.invitePlaceholder': 'Convidar novos utilizadores',
  'manager.table.name': 'Nome',
  'manager.table.email': 'Email',
  'manager.table.role': 'Função',
  'manager.table.dateAdded': 'Data adicionada',
}
