import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack'
import { CookiesProvider } from 'react-cookie'
import { theme } from './themes/theme'
import App from 'components/App'

// const SENTRY_DSN =
//   'https://4a917429d276455d946525d0447fbce8@o450423.ingest.sentry.io/5434879';

// Sentry.init({
//   dsn: SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   environment: process.env.NODE_ENV,
// });

ReactDOM.render(
  // <Sentry.ErrorBoundary fallback="Sentry: error!">
  <CookiesProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </CookiesProvider>,

  // </Sentry.ErrorBoundary>,
  document.getElementById('root')
)
