import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import * as ROUTES from 'constants/routes'
import { getProfileLogoLinkFromStorage } from 'api/Firebase/visitor'
import AccountIcon from 'assets/account.svg'
import { users } from 'api/Firebase'
import { User } from '@2meters/shared'
import styles from './Logo.module.sass'
import { Box, CircularProgress } from '@mui/material'

interface Props {
  imageFile?: string
  baseStoragePath?: string
  width?: string
  height?: string
  margin?: string
  userProfile?: boolean
  size?: 'small' | 'medium' | 'normal' | 'big' | 'oversized'
  round?: boolean
}

const Logo: React.FC<Props> = props => {
  const { imageFile, baseStoragePath, width, height, margin, userProfile = false } = props
  const defaultLogoUrl = ROUTES.DEFAULT_LOGO
  const [logoUrl, setLogoUrl] = useState<string>(userProfile ? AccountIcon : defaultLogoUrl)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userImage, setUserImage] = useState('')
  const [userImageStoragePath, setUserImageStoragePath] = useState('')

  useEffect(() => {
    const placeLogo = !!imageFile ? imageFile : userImage
    const placePath = !!imageFile ? baseStoragePath : userImageStoragePath

    setIsLoading(true)

    users.getCurrentUserSubscribe(
      (user: User) => {
        setUserImage(user?.imageFile || '')
        setUserImageStoragePath(user?.imageStoragePath || '')
      },
      () => null
    )

    getProfileLogoLinkFromStorage(placePath, placeLogo)
      .then((url: string) => {
        setLogoUrl(url)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }, [baseStoragePath, imageFile, userImage, userImageStoragePath, userProfile])

  return (
    <Box width={width} height={height}>
      {(isLoading && (
        <Box
          style={{
            width,
            height,
          }}
        >
          <CircularProgress size={width} />
        </Box>
      )) || (
        <img
          src={logoUrl}
          style={{
            width,
            height,
            margin,
          }}
          className={cx(styles[props.size || ''], styles[props.round ? 'round' : 'square'])}
          alt='logo'
        />
      )}
    </Box>
  )
}

export default Logo
