import { createTheme, Theme } from '@mui/material/styles'
import { grey, common } from '@mui/material/colors'
import { COLORS } from './colors'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonLightGrey: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    buttonLightGrey: Palette['primary']
    purple: Palette['primary']
    boxShadow: Palette['primary']
  }
  interface PaletteOptions {
    buttonLightGrey: Palette['primary']
    purple: Palette['primary']
    boxShadow: Palette['primary']
  }
}

const defaultTheme = createTheme()

export const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: COLORS.grey_700,
      paper: COLORS.white_900,
    },
    primary: {
      main: COLORS.primary.main,
      dark: COLORS.primary.dark,
      contrastText: common.white,
    },
    secondary: {
      main: COLORS.secondary.main,
      contrastText: common.white,
    },
    error: {
      main: COLORS.error.main,
      contrastText: common.white,
    },
    success: {
      main: COLORS.success.main,
      contrastText: common.white,
    },
    purple: defaultTheme.palette.augmentColor({
      color: {
        main: COLORS.purple,
      },
      name: 'purple',
    }),
    buttonLightGrey: defaultTheme.palette.augmentColor({
      color: {
        main: common.white,
        contrastText: grey[900],
        dark: grey[300],
      },
      name: 'buttonLightGrey',
    }),
    boxShadow: defaultTheme.palette.augmentColor({
      color: { main: COLORS.black_300 },
      name: 'boxShadow',
    }),
    grey: {
      700: COLORS.grey_700,
      500: COLORS.grey_500,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 56,
      fontWeight: 600,
      lineHeight: 0.61,
      letterSpacing: 'normal',
    },
    h2: {
      fontSize: 40,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.83px',
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h4: {
      fontSize: 26,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h6: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontSize: 15,
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
  },
})
