import { useEffect, useState } from 'react'
import { Place } from '@2meters/shared'
import { places } from 'api/Firebase/places'

export type WithPlace = {
  place: Place
  placeId: string
}

type UsePlaceProps = {
  id: string
}

const usePlace = ({ id }: UsePlaceProps) => {
  const [place, setPlace] = useState<Place | undefined>()
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    places
      .getPlace(id)
      .then((place?: Place) => {
        if (place?.id === 'undefined') {
          setNotFound(true)
          return
        } else {
          setPlace(place)
        }
      })
      .catch(() => setNotFound(true))
  }, [id])

  return {
    place,
    notFound,
  }
}

export default usePlace
