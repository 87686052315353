import lodash from 'lodash'
import { DocumentData, Timestamp } from 'firebase/firestore'

const _ = require('deepdash')(lodash)

/**
 * Recursively processes the object and changes some
 * values to the transport format
 * Use for calling functions if payload has dates
 */
export function prepareRequest(data: any): any {
  return _.mapValuesDeep(
    data,
    (v: any) => {
      if (v instanceof Date) return { type: 'date', value: v.getTime() } //replace dates
      return v
    },
    { leavesOnly: false }
  )
}

/**
 * Recursively processes the object and changes some
 * values from transport format
 */
export function resultAs<A>(data: any): A {
  const withDates = _.mapValuesDeep(
    data,
    (v: any) => {
      if (v && v.type === 'date') return new Date(v.value)
      return v
    },
    { leavesOnly: false }
  )
  return withDates as A
}

/**
 * Recursively processes the object from firebase and changes some
 * values to app format
 */
export function readAs<A>(doc: DocumentData): A {
  if (!doc.exists) throw new Error(`Item ${doc.id} does not exits`)
  const data = doc.data()
  const withDates = _.mapValuesDeep(
    data,
    (v: any) => {
      if (v instanceof Timestamp) {
        return v.toDate()
      }
      return v
    },
    { leavesOnly: false }
  )
  const withId = { id: doc.id, ...withDates }
  return withId
}
