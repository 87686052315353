import { backend } from 'api/Firebase'
import * as ROUTES from 'constants/routes'
import { places } from 'api/Firebase/places'
import { useEffect } from 'react'
import { PacmanProgress } from 'components/Preloader'
import { useCurrentUser } from 'store/useCurrentUser'
import { useHistory } from 'react-router-dom'
import { useGTM } from 'store/useGTM'

const withNonAnonymousAccess = Component => {
  const WithAuthorization = props => {
    const { authUser, setAuthUser } = useCurrentUser()
    const gmtStatusesStore = useGTM()
    const history = useHistory()

    useEffect(() => {
      if (authUser.uid) {
        return gmtStatusesStore.subscribe(authUser.uid)
      }
    }, [authUser.uid])

    useEffect(() => {
      return backend.onAuthUserListener(
        authUser => {
          if (condition(authUser)) {
            setAuthUser(authUser)
            if (!localStorage.getItem('doneOnboarding')) {
              redirectToOnboarding(authUser.uid)
              localStorage.setItem('doneOnboarding', true)
            }
          } else {
            console.log('redirect to login', authUser)
            history.push(ROUTES.LOGIN)
          }
        },
        () => {
          console.log('No logged in user')
        }
      )
    }, [authUser, history, setAuthUser])

    const redirectToOnboarding = uid => {
      const unsubscribe = places.subscriptions.placesOfUser(uid, data => {
        if (data.length === 0) {
          props.history.push(ROUTES.ADMIN.ONBOARDING)
        }
        unsubscribe()
      })
    }

    const condition = authUser => !!authUser && !authUser.isAnonymous

    return condition(authUser) ? (
      <Component {...props} />
    ) : (
      <PacmanProgress text='onboarding.wait' />
    )
  }

  return WithAuthorization
}

export { withNonAnonymousAccess }
