import { Infer, array, string, type, optional, number, enums } from 'superstruct'
import { Currencies } from './basic'

export const CategoryStruct = type({
  id: string(), // firestore document id
  name: string(), // name of the category
})

export const InventoryProduct3000Struct = type({
  id: string(),
  name: string(),
  description: optional(string()),
  categoryId: optional(string()),
  imageFile: optional(string()), // filename of the image
  imageStoragePath: optional(string()), // path of the image
  stock: optional(number()), // if set represents a quantity of available products
  price: optional(number()), // price of the product
})

export const Inventory3000Struct = type({
  id: string(), // firestore document id
  name: string(), // name of the service
  color: string(), // color of the service
  categories: array(CategoryStruct), // list of categories
  products: array(InventoryProduct3000Struct), // list of products
  currency: enums(Currencies), // currency of the price of the product
  inventoryMaxOrder: optional(number()), // max number of products that can be ordered in a single order
})

export type Inventory3000 = Infer<typeof Inventory3000Struct>
export type Category = Infer<typeof CategoryStruct>
export type InventoryProduct3000 = Infer<typeof InventoryProduct3000Struct>
