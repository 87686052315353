import { array, Infer, number, string, type } from 'superstruct'

export const OrderProduct3000Struct = type({
  productId: string(),
  name: string(),
  quantity: number(),
})

export const OrderStruct = type({
  inventoryId: string(),
  products: array(OrderProduct3000Struct),
})

export type OrderProduct3000 = Infer<typeof OrderProduct3000Struct>
export type Order = Infer<typeof OrderStruct>
