export const getResizedImageFilename = (filename: string, dimensions = '800x800'): string => {
  const filenameIndex = filename.lastIndexOf('.png')
  const filenameExtension = filename.substring(filenameIndex)
  return `${filename.substring(0, filenameIndex)}_${dimensions}${filenameExtension}`
}

export const convertDataUrlToFile = (dataUrl: string): File => {
  let arr = dataUrl.split(',')
  let mime = arr[0].match(/:(.*?);/)
  if (mime === null) {
    throw new Error(`Data url is invalid: ${dataUrl}`)
  }

  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], '', { type: mime[1] })
}
