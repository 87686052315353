import { Infer, nullable, string, type, boolean, optional } from 'superstruct'

export const ServiceStruct = type({
  id: string(), // firestore document id
  name: string(), // name of the service
  description: optional(string()), // description
  color: string(), // color of the service
  queueId: nullable(string()), // queue id
  scheduleId: nullable(string()), // schedule id
  inventoryEnabled: boolean(), // determines if inventory is enabled
  formId: nullable(string()), // form id
  categoryId: optional(string()), // category id
  tagPrefix: string(), // form id
})

export type Service = Infer<typeof ServiceStruct>
