import { number, type, optional, Infer, string, record } from 'superstruct'
import { TimestampStruct } from '../utils/Timestamp'

export const PlaceStats3000Struct = type({
  totalQueuedCount: number(), // total number of people ever queued. needed to keep order indexes in queue
  averageProcessingTimeSec: number(), // running average processing time of a single appointment (t(invited) - t(queued))/queue_length(t(queued))
  currentQueueLength: number(), // current queue length
  appointmentsToday: number(), // number of appointments today
  appointmentsThisWeek: number(), // number of appointments this week
  appointmentsThisMonth: number(), // number of appointments this month
  lastQueuedAt: optional(TimestampStruct), // last queued appointment
  lastAppointmentAt: optional(TimestampStruct), // last appointment
  totalSMSCount: number(), // number of SMS notifications sent
  totalWhatsappCount: number(), // number of Whatsapp notifications sent
  tagCounter: optional(record(string(), number())), // saves the counter for each service letter
})

export type PlaceStats3000 = Infer<typeof PlaceStats3000Struct>
