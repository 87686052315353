export default {
  'weekdays.0': 'Sunday',
  'weekdays.1': 'Monday',
  'weekdays.2': 'Tuesday',
  'weekdays.3': 'Wednesday',
  'weekdays.4': 'Thursday',
  'weekdays.5': 'Friday',
  'weekdays.6': 'Saturday',

  'wd.1': 'Mo',
  'wd.2': 'Tu',
  'wd.3': 'We',
  'wd.4': 'Th',
  'wd.5': 'Fr',
  'wd.6': 'Sa',
  'wd.7': 'Su',
  'wd.0': 'Su',

  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Українська',
  'tr-TRLang': 'Türkçe',
  EUR: 'Euro',
  USD: 'US Dollar',
  GBP: 'British Pound',
  INR: 'Indian Rupee',
  UAH: 'Ukrainian Hryvnia',
  JPY: 'Japanese Yen',
  BTC: 'BTC',
  TRY: 'Turkish Lira',
  Rand: 'South African Rand',
  generalError: 'An unexpected error has occurred',
  error: '{error}',
  enabled: 'Enabled',
  disabled: 'Disabled',
  appointments: 'Appointments',
  livequeues: 'Queues',
  livequeue: 'Queue',
  info: 'Info',
  ok: 'OK',
  today: 'Today',
  delete: 'Delete',
  remove: 'Remove',
  email: 'Email',
  role: 'Role',
  admin: 'Administrator',
  manager: 'Manager',
  cancel: 'Cancel',
  create: 'Create',
  upgrade: 'Upgrade',
  update: 'Update',
  invite: 'Invite',
  queueIn: 'Queue in',
  edit: 'Edit',
  sure: 'Are you sure?',
  yes: 'Yes',
  no: 'No',
  add: 'Add',
  save: 'Save',
  go: 'Go',
  next: 'Next',
  leave: 'Leave',
  yourAppointments: 'Your appointments',
  yourAppointment: 'Your appointments',
  address: 'Address',
  copyToClipboard: 'Copy',
  copiedToClipboard: 'Text copied',
  showQRCode: 'Show QR code',
  showPDF: 'Show PDF',
  showNumberTickets: 'Number of tickets',
  clipboardCopyError: 'Clipboard copy error',
  imageUploadFailed: 'Image upload failed',
  uploadLogo: 'Upload Logo',
  thankYou: 'Thank you',

  // Not Found Page
  'notfoundpage.header': 'Page not found',
  'notfoundpage.message':
    'The specified file was not found on this website. Please check the URL for mistakes and try again.',

  // Reset Password
  'resetPassword.resetPassword': 'Reset Password',
  'resetPassword.newPassword': 'New Password',
  'resetPassword.currentPassword': 'Current Password',
  'resetPassword.changePassword': 'Change Password',
  'resetPassword.currentPasswordWrong': 'The current password is not correct',
  'resetPassword.confirmNewPassword': 'Confirm New Password',
  'resetPassword.linkNotValid': 'Your reset link is invalid. Please resubmit your request',
  'resetPassword.dontMatch': `Passwords don't match`,
  'resetPassword.passwordChanged': 'Password changed',
  'resetPassword.signNewPassword': 'You can now sign in with your new password',
  'resetPassword.errorEncountered': 'Error encountered',
  'resetPassword.modeIsInvalid': 'The selected page mode is invalid',
  'resetPassword.logout': 'Logout',
  'resetPassword.logout.title': 'Your password has been changed',
  'resetPassword.logout.descritption': 'Please log in to your account again',

  // Update Page
  'rolledOutUpdate.reload': 'Reload Page',
  'rolledOutUpdate.header': 'Update is out!',
  'rolledOutUpdate.message': 'A new update has been rolled out. Please reload the page!',

  //*************** VISITOR *******************/

  'dialogs.pageIsForMobile':
    'You are viewing this page from your desktop browser. ' +
    'Please, open the link with your mobile browser, if you want your booked appointment to be available on your smartphone.',
  'dialogs.pageIsForMobileTitle': 'Use your mobile device',
  'dialogs.messagemodal.walkin': 'Live queue',
  'dialogs.whatsapp.text':
    'We recommend you to connect to WhatsApp notifications, if you would like to know, how many people are waiting before you or how long will be your waiting time. Click the "Connect" button to open WhatsApp and send us a message we prepared without changing it.',
  'dialogs.whatsapp.connect': 'Connect',
  'dialogs.whatsapp.urlText':
    'I%27d%20like%20to%20receive%20notifications%20containing%20information%20about%20status%20change%20of%20my%20ticket',

  // Main
  'home.queues': 'Your live queues',
  'home.queue.showCode': 'Show this number on request',
  'home.queue.peopleAhead': 'People ahead of you',
  'home.placesNearby': 'Places nearby',
  'home.noPlacenear': 'There is no place registered near you',
  'home.enableGeoLocation':
    'You have disabled geo location. Please enable it in browser settings to use this feature',
  'home.bookASpot': 'To make an appointment or join a digital queue',
  'home.scanQR': 'scan the QR code',
  'home.enterShortUrl': 'or enter the shortcode of the place',
  'home.enterShortcode': 'Enter the shortcode',
  'home.selectNearby': 'or simply select from places nearby',
  'home.useToBook': 'Use Search or QR-code scanner to book one',
  'home.findNearby': 'Find place nearby',
  'home.loginAsAdmin': 'Login as place administrator',

  //LiveQueueTab
  'messaging.permissionGranted': 'Notification permission granted',
  'messaging.permissionNotGranted':
    'You will not get any notifications while the app in the background as your queue progresses. Please allow notifications.',
  'messaging.tokenError': 'Something went wrong',
  'messaging.webPushNotificationsNotSupported':
    'Your browser does not support web push notifications. You will not get any ' +
    'notifications as your queue progresses. Please watch the number of people in the line displayed below.',

  //AppointmentsPage
  'appointment.disabledButtonHint': 'Make an appointment',
  'appointment.dayOff': 'The place is closed at this time. Please choose another date.',
  'appointment.visitorInformation': 'Your information',
  'appointment.visitorInformation.hint': 'Please provide the following information about yourself',
  'appointment.customInformation': 'Additional information',
  'appointment.customInformation.hint': 'Please provide the additional information',
  'appointment.visitorInformation.numberOfVisitors': 'Number of visitors',
  'appointment.visitorInformation.numberOfVisitors.alert': 'How many people are coming?',
  'appointment.visitorInformation.counter': 'Counter',
  'appointment.visitorInformation.notRequired': 'No contact data is required',
  'appointment.visitorPhoneNumValidationStep': 'Phone number verification',
  'appointment.visitorPhoneNumValidationStep.hint':
    'We have sent you a code to {phone}. Please input it in the fields below.',
  'appointment.waitToSendAgain': 'Send code again in {sec} seconds',
  'appointment.sendAgain': 'Send again',
  'appointment.services': 'Service',
  'appointment.services.hint': 'Choose a service you would like to receive',
  'appointment.order.hint':
    'Select the products you would like to order. Please note that there can be a limit on the amount of items per order.',
  'appointment.order': 'Order',
  'appointment.visitorSelectDate': 'Select date',
  'appointment.noMoreToday': 'No more appointments available today',
  'appointment.add': 'Make an appointment',
  'appointment.cancelAppointment': 'Cancel appointment',
  'appointment.leaveQueue': 'Cancel ticket',
  'appointment.next': 'Next: {step}',
  'appointment.next.contactInfo': 'Contact information',
  'appointment.next.customFields': 'Additional information',
  'appointment.next.timeslot': 'Time slot',
  'appointment.next.messagingPrompt': 'Notifications',
  'appointment.next.phoneNumValidation': 'Phone Number Validation',
  'appointment.next.book': 'Book',
  'appointment.next.whatsapp': 'WhatsApp notification',
  'appointment.next.numberOfVisitors': 'Number of visitors',
  'appointment.next.queueIn': 'Queue in now',
  'appointment.next.services': 'Services',
  'appointment.next.products': 'Products',
  'appointment.next.submit': 'Submit',
  'appointment.messagingPrompt': 'Enable notifications',
  'appointment.submit': 'Submit request',
  'appointment.submit.error': 'An error has occurred while submitting your request',
  'appointment.submit.standby': 'One moment, please',
  'appointment.cancel': 'Cancel',
  'appointment.back': 'Back',
  'appointment.chooseTime': 'Choose time',
  'appointment.makeAnother': 'Make another appointment',
  'appointment.queueInAgain': 'Queue in again',
  'appointment.nickname': 'Add a name you can be called by',
  'appointment.group': 'How many people are coming',
  'appointment.openHours': 'Open hours:',
  'appointment.contacts': 'Contacts:',
  'appointment.description': 'General description:',
  'appointment.address': 'Address',
  'appointment.comment': 'Comment',
  'appointment.birthDate': 'Date of birth',
  'appointment.visitType': 'Service',
  'appointment.shareName': 'Your name',
  'appointment.sharePhone': 'Phone number',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Search',
  'appointment.sharePhone.searchNotFound': 'No entries to show',
  'appointment.sharePhone.inValid':
    'Phone number is invalid. Please try to enter your number in international format: +1123456789',
  'appointment.shareEmail': 'Email',
  'appointment.shareAddress': 'Address',
  'appointment.shareBirthDate': 'Date of birth',
  'appointment.sure': 'Are you sure?',
  'appointment.vaccinationStatus': 'Covid-19 vaccination status',
  'appointment.not_vaccinated': 'Not vaccinated',
  'appointment.partially_vaccinated': 'Partially vaccinated',
  'appointment.fully_vaccinated': 'Fully vaccinated',
  'appointment.time': 'Date and time',
  'appointment.invitedAt': 'Invited at',
  'appointment.queuedAt': 'Queued at',
  'appointment.closedTime': 'Closed. Opens on',
  'appointment.opensToday': 'Closed. Opens today at',
  'appointment.ticket': 'Ticket',
  'appointment.position': 'Before you',
  'appointment.queueLength': 'In queue',
  'appointment.eta': 'estimated time',
  'appointment.pleaseProceed': 'Please proceed in the next 5 minutes',
  'appointment.header': 'Your tickets',
  'appointment.estimatedWaitingTime': 'Estimated waiting time',
  'appointment.waitingTime': 'Waiting time',
  'appointment.min': 'min',
  'appointment.people': 'people',
  'appointment.error.invalidFormFields': 'Please fill the highlighted form fields to proceed',
  'appointment.error.invalidVisitType': 'Please provide the reason for your visit to proceed',
  'appointment.error.noSlotSelected': 'No time slot selected',
  'appointment.confirmation.preliminaryWarning':
    'This booking is preliminary until you receive a confirmation letter.',
  'appointment.confirmation.rejectedWarningAppointment':
    'We are sorry but the administrator has cancelled your appointment. Try booking another one.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'We are sorry but the administrator has cancelled your ticket. Try to queue in again.',
  'appointment.products.error.maxOrder':
    'The order can contain up to {quantity} items. Please reduce the amount.',
  'appointment.products.price': 'Price',
  'appointment.products.inStock': 'In stock',
  'appointment.products.outofstock': 'Out of stock',
  'appointment.confirmation.appointmentHasPreference':
    'Please note that appointments booked in advance may have priority over livequeue',

  'appointment.disabled': 'The appointment system is disabled by the place administrator',

  'appointment.messaging.pending':
    'To notify you about appointment status updates we need you to enable push notifications. ' +
    'Click <b>allow</b> if you agree.',
  'appointment.messaging.denied':
    'Permissions to send notifications was not enabled. You will not get push notifications',
  'appointment.messaging.granted': 'Permission to send push noification granted. Thank you!',

  //LiveQueuetab
  'livequeue.peopleNum': 'Number of people in the queue',
  'livequeue.yourNumberInQueue': 'Your position in the queue',
  'livequeue.youAreNext': 'Your are first in the queue',
  'livequeue.leave': 'Leave queue',
  'livequeue.showAtRequest': 'Show this ticket on request',
  'livequeue.submit': 'Queue in now',
  'livequeue.liveQueuePaused':
    'The digital queue is on hold. Please try to queue yourself in in several minutes',
  'livequeue.liveQueueOpenHours':
    'You are trying to queue in outside of opening hours. Please return later',
  'livequeue.disabled': 'The digital queue system is disabled by the place administrator',
  'livequeue.queueTooLong':
    'The administrator has limited the amount of people in the queue. Please try again later.',

  //MonitorView
  'monitor.pleaseProceed': 'Please proceed',
  'monitor.livequeue': 'Live queue',
  'monitor.peopleInQueue': 'More Tickets',
  'monitor.appointments': 'Scheduled appointments',
  'monitor.appointment.type.appointment': 'appointment',
  'monitor.appointment.type.livequeue': 'live queue',

  'monitor.stats': 'Occupancy today',
  'monitor.pleaseScanAppointments': 'Scan this QR code to schedule an appointment',
  'monitor.pleaseScanLivequeue': 'Scan this QR code to queue in',
  'monitor.pleaseScanIntegrated': 'Scan this QR code to queue in or to make an appointment',
  'monitor.followThisLink': 'Or simply follow this URL',

  // Notifications
  'notifications.title': 'Notifications',
  'notifications.informationBanner.sms': 'SMS sent',
  'notifications.informationBanner.whatsapp': 'Whatsapp sent',
  'notifications.progressBar.freeSms': 'Free tier eligible',
  'notifications.progressBar.exhaustedSms': 'Free sms pool exhausted',
  'notifications.progressBar.exhaustedWhatsapp': 'Free whatsapp pool exhausted',
  'notifications.informationBanner.totalSmsCost': 'Total SMS cost',
  'notifications.informationBanner.totalWhatsappCost': 'Total Whatsapp cost',
  'notifications.table.recipient': 'Recipient',
  'notifications.table.date': 'Date',
  'notifications.table.time': 'Time',
  'notifications.table.place': 'Place',
  'notifications.table.type': 'Type',
  'notifications.table.price': 'Price',
  'notifications.table.country': 'Country',
  'notifications.table.message': 'Message',
  'notifications.empty.header':
    "Your places haven't generated any paid notifications in the given time period yet",
  'notifications.filter.startDate': 'Start date',
  'notifications.filter.endDate': 'End date',
  'notifications.exportTable.status': 'Status',
  'notifications.exportTable.type': 'Type',
  'notifications.exportTable.isEmpty': 'is empty',

  // Export Appointments
  'exportAppointments.tickets': 'Tickets',
  'exportAppointments.tag': 'Tag',
  'exportAppointments.date': 'Date',
  'exportAppointments.time': 'Time',
  'exportAppointments.type': 'Type',
  'exportAppointments.service': 'Service',
  'exportAppointments.name': 'Name',
  'exportAppointments.status': 'Status',
  'exportAppointments.statusChangeDate': 'Status change date',
  'exportAppointments.statusChangeTime': 'Status change time',
  'exportAppointments.phone': 'Phone',
  'exportAppointments.email': 'Email',
  'exportAppointments.address': 'Address',
  'exportAppointments.birthDate': 'BirthDate',
  'exportAppointments.comments': 'Comments',
  'exportAppointments.appointment': 'Appointment',
  'exportAppointments.queue': 'Queue',

  //Cookies Concent
  'cookies.concent':
    'This website only uses technically necessary cookies that cannot be deselected. Necessary cookies help make a website usable by enabling basic functions such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
  'cookies.ok': 'Understand',

  'dialogs.pageIsForDesktop':
    'You are viewing this page from your mobile browser. ' +
    'This page is optimised for a desktop computer. Some functions may be not available ' +
    'and the page could be rendered with errors.',
  'dialogs.pageIsForDesktopTitle': 'Desktop View',
  'dialogs.reject': 'Reject',
  'dialogs.confirm': 'Confirm',

  //*************** ADMIN *******************/

  trial: 'Start trial',

  // SignUp
  'signUp.referralCode': 'Enter a referral code if you have one',

  // Login
  'login.header': 'Thank you for choosing 2meters! ',
  'login.header2': 'We created our service to help you to',
  'login.text1':
    'establish a comfortable online appointment booking system your customers will love',
  'login.text2':
    'establish an innovative digital queue solution for your shop or service in order to retain old customers and attract new ones by getting rid of annoying queues wherever they pop up',
  'login.text3':
    'or to do both, as our solution integrates both features. Let your walk-in customer make an appointment if the waiting time in the queue is too long',
  'login.google': 'Login with Google',
  'login.facebook': 'Login with Facebook',
  'login.withPassword': 'Login with Email',
  'login.newUser': 'Sign up to 2Meters',
  'login.existingUser': 'Sign in to 2Meters',
  'login.signUp': 'Sign Up',
  'login.signIn': 'Sign In',
  'login.email': 'Email',
  'login.email.placeholder': 'example@domain.com',
  'login.password': 'Password',
  'login.password.placeholder': '8+ characters',
  'login.passwordRepeat': 'Repeat password',
  'login.passwordRepeat.placeholder': '8+ characters',
  'login.forgotPassword': 'Forgot password?',
  'login.passwordsDontMatch': "Passwords don't match",
  'login.sendRecoveryEmail':
    "We’ll send you a link to change your password. Please tell us the email you've registered with",
  'login.checkEmail': 'Please check your email and follow the instructions',
  'login.resetPassword': 'Reset Password',
  'login.recoveryEmailSent': 'Recovery email was sent to {email}',
  'login.passwordTooShort': 'Password should be at least 8 characters long',
  'login.dontHaveAccount': "Don't have an account?",
  'login.alreadyHaveAccount': 'Have an account already?',
  'login.enterDetails': 'Enter details below',
  'login.alreadyRegistered': 'Sign in',
  'login.getStarted': 'Get started',
  'login.acceptTerms': 'I accept',
  'login.termsAndConditions': 'Terms and Conditions',
  'login.termsNotChecked': 'Please accept our Terms and Conditions in order to proceed',
  'login.acceptPrivacyPolicy': 'I agree with',
  'login.privacyPolicy': 'Privacy Policy',
  'login.privacyPolicyNotChecked': 'Please accept our Privacy Policy in order to proceed',
  'login.missingTermsConsent.title': 'Just one more step',
  'login.missingTermsConsent.desc':
    'Please accept our Terms and Conditions and Privacy Policy to access our service. Thank you!',

  'customerProfileForm.firstName': 'First Name',
  'customerProfileForm.lastName': 'Last Name',
  'customerProfileForm.email': 'Email',
  'customerProfileForm.organization': 'Organization name',
  'customerProfileForm.country': 'Country',
  'customerProfileForm.streetAddress': 'Street address',
  'customerProfileForm.postalCode': 'Postal code',
  'customerProfileForm.city': 'City',
  'customerProfileForm.vatId': 'VAT Id',
  'customerProfileForm.referralCode': 'Referral code',
  'customerProfileForm.placeholder.firstName': 'Please enter first name',
  'customerProfileForm.placeholder.lastName': 'Please enter last name',
  'customerProfileForm.placeholder.email': 'Please enter email',
  'customerProfileForm.placeholder.organization': 'Please enter organization name',
  'customerProfileForm.placeholder.country': 'Please enter country',
  'customerProfileForm.placeholder.streetAddress': 'Please enter street address',
  'customerProfileForm.placeholder.postalCode': 'Please enter postal code',
  'customerProfileForm.placeholder.city': 'Please enter city',
  'customerProfileForm.placeholder.vatId': 'Please enter VAT ID',
  'customerProfileForm.placeholder.referralCode': 'Please enter referral code',
  'customerProfileForm.industry': 'Industry',
  'customerProfileForm.industry.governmental': 'Governmental',
  'customerProfileForm.industry.education': 'Education',
  'customerProfileForm.industry.healthcare': 'Healthcare',
  'customerProfileForm.industry.retail': 'Retail',
  'customerProfileForm.industry.wholesale': 'Wholesale',
  'customerProfileForm.industry.rental': 'Rental',
  'customerProfileForm.industry.banking': 'Banking/finance',
  'customerProfileForm.industry.transportation': 'Transportation',
  'customerProfileForm.industry.hospitality': 'Hospitality',
  'customerProfileForm.industry.hotel': 'Hotel and recreational',
  'customerProfileForm.industry.travel': 'Travel and tourism',
  'customerProfileForm.industry.other': 'Other',

  // Onboarding
  'onboarding.wait':
    'Please wait, while we create your user profile and set up all things necessary',
  'onboarding.createPlace.wait': 'Please stand by, while we are loading...',
  'onboarding.loading': 'Loading...',
  'onboarding.back': 'Back',
  'onboarding.next': 'Next',
  'onboarding.skip': 'Skip to blank',
  // Onboarding: Step 1
  'onboarding.step1.header': 'Making Appointments with 2Meters',
  'onboarding.step1.text.p1':
    'To enable appointments, just set up your place by filling in its basic data:',
  'onboarding.step1.text.list1.item1': 'Name & Location',
  'onboarding.step1.text.list1.item2': 'Working days and hours',
  'onboarding.step1.text.list1.item3': 'Typical appointment durations',
  'onboarding.step1.text.p2':
    'To provide even better service, you can  set up a list of services or goods you offer, so that customers can let you know the reason for their visit in advance.',
  'onboarding.step1.text.p3':
    'For the full control over your schedule,  you can turn on a feature allowing you to confirm or decline appointments preliminarily booked by your customers.',
  // Onboarding: Step 2
  'onboarding.step2.header': 'Making Appointments with 2Meters',
  'onboarding.step2.text.p1':
    'After the setup just copy the provided link into an Email or put it on your website where your customers can click it or tap on it.',
  'onboarding.step2.text.p2':
    'Alternatively you can integrate our interactive interface into your website via a code snippet.',
  // Onboarding: Step 3
  'onboarding.step3.header': 'Making Appointments with 2Meters',
  'onboarding.step3.text.p1':
    'Welcome your first appointment customers! You can easily identify them by asking to provide the electronic tickets saved on their smartphones.',
  'onboarding.step3.text.p2':
    'If you have to handle walk-in customers in addition to appointments, you will love to hear that our digital queue function seamlessly merges both customer flows. But how does it work?',
  // Onboarding: Step 4
  'onboarding.step4.header': 'Eliminate Queues with 2Meters',
  'onboarding.step4.text.p1':
    'Our digital queue offers exactly what your customers expect from a modern service concept - the smartphones do the waiting while their owners can go for a walk or have a cup or coffee.',
  'onboarding.step4.text.p2':
    'We notify your customer as the queue progresses, so they can show up just in time.',
  'onboarding.step4.text.p3':
    'To create a digital queue simply enable this functionality while creating your place.',
  // Onboarding: Step 5
  'onboarding.step5.header': 'Eliminate Queues with 2Meters',
  'onboarding.step5.text.p1':
    'Print a PDF with the provided QR code and put it anywhere, where you would like your customers to queue in digitally.',
  'onboarding.step5.text.p2':
    'Set up a display to show all approaching and currently serviced tickets and make your place look and feel like a modern silicon valley service center.',
  // Onboarding: Step 6
  'onboarding.step6.header': 'Eliminate Queues with 2Meters',
  'onboarding.step6.text.p1':
    'There are a lot more features to explore. Contact us, if you need additional information on how you can tweak your digital queue to fit your personal case. We prepared some preconfigured templates for you. Select a template and adjust it to your needs or create a brand new place. What would you like to do?',
  'onboarding.step6.btn1': 'Create a Demo Place with Live Queue (Barbershop)',
  'onboarding.step6.btn2': 'Create a Demo Place with Appointments (Barbershop)',
  'onboarding.step6.btn3': 'Create an appointment system or a digital queue',

  'onboarding.template.barbershop.name': 'Demo Place (Barber shop)',
  'onboarding.template.barbershop.description':
    'Beard is a trend. The hair on the face only becomes really beautiful with good care and perfect styling. We open up a place where men can relax and be among themselves. You can expect individual advice on hair / beard care and styling, but well-groomed hands and feet are also part of it! In a classic atmosphere - between stylish leather armchairs, wooden accessories, brick walls and an integrated whiskey and cigar lounge - there is filing, peeling and shaving.',
  'onboarding.template.barbershop.service1': 'Regular haircut',
  'onboarding.template.barbershop.service2': 'Beard cut',
  'onboarding.template.barbershop.service3': 'Dye',
  'onboarding.template.barbershop.service4': 'Contour cut',
  'onboarding.template.default.service1': 'My service 1',
  'onboarding.template.default.service2': 'My service 2',
  'onboarding.template.covid.name': 'Covid Test Center (Template)',
  'onboarding.template.popup.name': 'Walk-In PopUp Shop (Template)',
  'onboarding.template.shopping.name': 'Appointment Shopping (Template)',
  'onboarding.template.restaraunt.name': 'Restaraunt (Template)',

  // Place
  'admin.place.settings': 'Settings',
  'admin.place.editSetting': 'Edit Place',
  'admin.place.editManagers': 'Edit Managers',
  'admin.place.navigation.overview': 'Overview',
  'admin.place.navigation.dashboard': 'Dashboard',
  'admin.place.navigation.queues': 'Counter',
  'admin.place.navigation.monitor': 'Monitor',
  'admin.place.navigation.billing': 'Billing',
  'admin.place.navigation.backToPlaces': 'Back to Places',
  'admin.place.navigation.back': 'Back',
  'admin.place.averageDuration': 'Slot Duration (minutes)',
  'admin.place.capacity.help': 'How many customers can enter your place at the same time',
  'admin.place.capacity': 'Shop capacity',
  'admin.place.closingTime': 'Closing time',
  'admin.place.openingTime': 'Opening time',
  'admin.place.openingDays': 'Opening days',
  'admin.place.openingDate': 'Campaign starting date',
  'admin.place.openingDate.help':
    'If you intend to use 2Meters for a limited period, enter the date when it shall be enabled',
  'admin.place.closingDate': 'Campaign ending date',
  'admin.place.closingDate.help':
    'If you intend to use 2Meters for a limited period, enter the date when the system shall be turned off',
  'admin.place.contactEmail': 'Contact email of place administrator',
  'admin.place.contactEmail.help':
    'You may fill this field, if you want your contact email to show up in the email notifications we will be sending to your visitors (only if you will ask them to provide their email addresses in this setup)',
  'admin.place.contactPhoneNumber': 'Contact phone number of place administrator',
  'admin.place.contactPhoneNumber.help':
    'You may fill this field, if you want your contact phone number to show up in the email notifications we will be sending to your visitors (only if you will ask them to provide their email addresses in this setup)',
  'admin.place.description': 'Please provide a short description of your place and your services.',
  'admin.place.description.help': 'Your customers will see this description',
  'admin.place.timezone': 'Time zone',
  'admin.place.noOptions': 'No options',
  'admin.place.toVisitors': 'Visitors page',
  'admin.place.printQR': 'print QR',
  'admin.place.shortUrl':
    'Choose a short code, to allow your visitors to quickly access your page.',
  'admin.place.shortUrl.error': 'This short url is already taken. Please, try a different one',
  'admin.place.shortUrl.help':
    'If you pick e.g. "bbq" for your BBQ place, it will be accessible from any browser under app.2meters.app/p/bbq',
  'admin.place.shortUrlInfo': 'Link to your place',
  'admin.place.appointmentUrlInfo': 'Link to appointment booking process',
  'admin.place.livequeueUrlInfo': 'Link to live queue',
  'admin.place.monitorUrlInfo': 'Link to monitor view',
  'admin.place.qrCode': 'QR code for your place',
  'admin.place.address': 'Address of your place',
  'admin.place.yourAppointment': 'Your appointment',
  'admin.place.appointments.delete': 'Delete',
  'admin.place.appointments.cancel': 'Cancel',
  'admin.place.addAppointmentDialogTitle': 'Add appointment',
  'admin.place.addAppointmentDialogDescription':
    'You can add a visitor nickname and a short description to the appointment',
  'admin.place.appointmentNickname': 'Nickname',
  'admin.place.appointmentDescription': 'Description',
  'admin.place.visitType': 'Provide a list of the services you offer',
  'admin.place.visitType.help':
    'Your customers will have to choose the service from this list when booking an appointment.',
  'admin.place.managers': 'List of Place-Managers',
  'admin.place.managers.help':
    'Enter E-Mail adresses, to assign manager roles to your employees. Confirm each E-Mail with Enter.',
  'admin.place.titles.settings': 'Place Overview',
  'admin.place.wizard.titles.details': 'Place Details',
  'admin.place.wizard.titles.visitorData': 'Visitors',
  'admin.place.wizard.titles.inventory': 'Your Inventory',
  'admin.place.wizard.titles.queue': 'Live Queue',
  'admin.place.wizard.titles.appointments': 'Schedule',
  'admin.place.wizard.titles.access': 'Access',
  'admin.place.wizard.titles.plans': 'Choose your Plan',
  'admin.place.wizard.details': 'Details',
  'admin.place.wizard.visitorData': 'Visitors',
  'admin.place.wizard.inventory': 'Inventory',
  'admin.place.wizard.settings': 'Settings',
  'admin.place.wizard.schedule': 'Schedule',
  'admin.place.wizard.access': 'Access',
  'admin.place.wizard.plans': 'Plans',
  'admin.place.wizard.actions.next': 'Next',
  'admin.place.wizard.actions.trial': 'Start Trial',
  'admin.place.wizard.actions.finish': 'Finish',
  'admin.place.wizard.actions.saveAll': 'Save',
  'admin.place.wizard.actions.back': 'Back',
  'admin.place.wizard.actions.cancel': 'Cancel',
  'admin.place.wizard.actions.agree': 'Agree',
  'admin.place.wizard.actions.create': 'Create',
  'admin.place.wizard.requiredFields': 'Please fill out all required fields',
  'admin.place.wizard.smsModal.title': 'SMS Notifications',
  'admin.place.wizard.whatsappStep.title': 'Do you agree to receive notifications via WhatsApp ?',
  'admin.place.wizard.whatsappStep.accept': 'Accept',
  'admin.place.wizard.whatsAppModal.title': 'WhatsApp Notifications',
  'admin.place.wizard.whatsAppModal.description':
    'Please note, by turning on WhatsApp notifications you must consider, that additional charges will apply. We grant you 100 free WhatsApp messages, which you can use for testing. However, for any WhatsApp message sent above that, we will charge you a price, which depends on the country your WhatsApp recipients are coming from. We will create additional invoices and send them separately each month. You can turn off this feature at any time. If you need detailed information on the pricing, please contact our sales department at ',
  'admin.place.wizard.smsModal.description':
    'Please note, by turning on SMS notifications you must consider, that additional charges will apply. We grant you 100 free SMS, which you can use for testing. However, for any SMS sent above that, we will charge you a price, which depends on and is comparable to the prices of local mobile providers of the country your visitors are coming from. We will create additional invoices and send them separately each month. You can turn off this feature at any time. If you need detailed information on the pricing, please contact our sales department at ',
  'admin.place.settings.text': 'On this page you can enable different features for your place',
  'admin.place.inventory.text':
    'If you sell any items and want your visitors to order them, just add them to your inventory. Enabling inventory creates additional step in the visitor part of the application, where a visitor can book or purchase any of your items. You may configure stock for your items or set is as unlimmited. You can also choose to restrict the maximal amount of items going to one hands to make sure, that all of your customers will get their share.',
  'admin.place.visitorData.text':
    'We deliberately chose to allow full user anonymity, but in some use cases it may be necessary to collect data in order to provide your service. Here you may choose, what do you want to know about your customers when they make an appointment or queue in. If you choose to collect their emails, we will also be able to send them email notifications, which might be customer friendly. Note, that the personal data your customer leaves, if you have chosen any option, is being deleted from our servers two days after visit.',
  'admin.place.appointments.text':
    'Set up your opening hours and service schedule to define how our digital features of queue and appointment management will work. We provide two types of schedule management. Simple and Advanced. With Simple, you only set your working days and hours. With an Advanced one, you have full control over the schedule.',
  'admin.place.access.text':
    'If you have employees, you might want them to have access to the counter view without sharing your credentials. From the counter view, employees can manage the appointments and the live queue. They can not change the settings or any other vital properties of your place. To assign manager roles to your employees, please enter their E-Mail adresses below.',
  'admin.place.cancelDialog': 'Cancel',
  'admin.place.rte.text': 'Text',
  'admin.place.rte.link': 'Link',
  'admin.place.rte.addLink': 'Add Link',
  'admin.place.rte.remove': 'Remove',
  'admin.place.rte.save': 'Save',
  'admin.place.addAppointmentManually': 'Add',
  'admin.place.livequeue.hint': 'Enable digital queue in you place',
  'admin.place.businessName': 'What is the name of your place?',
  'admin.place.fullscreen': 'Fullscreen',
  'admin.place.monitor': 'Monitor',
  'admin.account.billingLog': 'Billing Log',
  'admin.account.account': 'Account',
  'admin.account.signOut': 'Sign out',
  'admin.account.billing': 'Plans and Billings',
  'admin.place.form.inventory': 'Which products does your place provide?',
  'admin.place.form.inventory.help':
    "Your visitor will be able to choose a product while making an appointment or checking in in the digital queue. Leave this empty, if you don't sell any products.",

  'admin.place.form.settings.appointments.help':
    'Appointments allow your customers to spot a predefined time slot for their visit',
  'admin.place.form.settings.appointments.label': 'Enable appointments',
  'admin.place.form.settings.livequeue.label': 'Enable live queue',
  'admin.place.form.settings.livequeue.help':
    'Unlike appointments live queue allows your customers to set a spot in a queue without a defined time, like walk-in customers usually do',
  'admin.place.form.settings.livequeue.maxLength.label': 'Max queue length',
  'admin.place.form.settings.livequeue.maxLength.help':
    'No new customers can queue in if queue length reaches this number. Remove the value for unlimited',
  'admin.place.form.settings.livequeue.openingHours.label': 'Only in opening hours',
  'admin.place.form.settings.livequeue.openingHours.help':
    'When enabled your customers will be restricted to queue in within your business hours',
  'admin.place.form.settings.appointment.confirmation.label': 'Requires confirmation',
  'admin.place.form.settings.appointment.confirmation.help':
    'Check if you want to manually confirm or reject appointments. Your customers will be notified by the notification channels you defined in the previous steps',

  'admin.place.form.settings.automation.help': 'Automation',
  'admin.place.form.settings.automation.processing.help':
    'Set blank if you prefer to clean up by hand',
  'admin.place.form.settings.automation.processing.label':
    'min. Invited tickets will be removed from the screen after this time',

  'admin.place.form.settings.automation.eta.auto': 'Auto',
  'admin.place.form.settings.automation.eta.constant': 'Constant',
  'admin.place.form.settings.automation.eta.off': 'Off',
  'admin.place.form.settings.automation.eta.label': 'Mode for calulating estimated waiting time',
  'admin.place.form.settings.automation.eta.help': `The way the waiting time left for a particular queue ticket is calculated.\n
    Auto: Takes into account an average time of the last 6 processed tickets. \n
    Constant: You can specify the time it should take to process one ticket. \n
    Off: Estimted waiting time will not be shown.
  `,
  'admin.place.form.settings.automation.eta.constant.label':
    'minutes. Time of processing one ticket',
  'admin.place.form.settings.automation.eta.constant.help':
    'It will be used to estimate waiting time in the queue',

  'admin.place.form.settings.constraints.help': 'Constraints',
  'admin.place.form.settings.constraints.appsperuser.label':
    'How many tickets can a user take at a time',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'One at a time',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'One per service',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Unlimited',

  'admin.place.form.askEmail': 'Email',
  'admin.place.form.askPhone': 'Phone number',
  'admin.place.form.phoneVerificationEnabled': 'SMS validation',
  'admin.place.form.phoneVerificationDisabled':
    'SMS validation (not available in Private subscription)',
  'admin.place.form.askName': 'Name',
  'admin.place.form.askAddress': 'Address',
  'admin.place.form.askBirthDate': 'Date of birth',
  'admin.place.form.useMailMessenger': 'Email notifications',
  'admin.place.form.useUIMessenger': 'In-App notifications',
  'admin.place.form.useWebPushMessenger': 'Push notifications (Android only)',
  'admin.place.form.useWhatsAppMessenger': 'WhatsApp notifications',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'WhatsApp notifications (not available in Private subscription)',
  'admin.place.form.useSMSMessenger': 'SMS notifications',
  'admin.place.form.useSMSMessengerDisabled':
    'SMS notifications (not available in Private subscription)',
  'admin.place.form.askWhat': 'Please select mandatory user information',
  'admin.place.form.askVaccinationStatus': 'Covid-19 vaccination status',
  'admin.place.form.vaccinationStatus': 'Covid-19 vaccination status',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Check this, if you want your visitors to provide their vaccination status',
  'admin.place.form.numberOfVisitors': 'Number of visitors',
  'admin.place.form.askNumberOfVisitors': 'Number of visitors',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Check this, if your visitors can come in group and you want to know their numbers',
  'admin.place.form.askWhat.help':
    'To allow for total visitor anonymity, leave all boxes unchecked. Visitor data will be automatically deleted from our servers within two days after the visit.',
  'admin.place.form.messagingConfiguration': 'Messaging Configuration',
  'admin.place.form.messagingConfiguration.help':
    'You can choose between multiple channels to notify your visitors on the status of their tickets.',
  'admin.place.form.enableFeatures': 'Enable additional features',
  'admin.place.form.placeLocale': 'Default Place Language',
  'admin.place.form.placeLocale.help':
    'A language we will use to send notifications to your visitors, if they have not set their preferred language',
  'admin.place.embedCode.title': 'Embed code',
  'admin.place.embedCode.description': 'Use this code to embed 2Meters widget into your website',
  'admin.place.appointments.enable': 'Enable appointments for this place',
  'admin.place.livequeue.enable': 'Enable live queue for this place',
  'admin.place.form.schedulerType': 'Select a type of schedule',
  'admin.place.form.schedulerType.help':
    'Advanced schedule is in early stage of development. We do not provide garanties about it. Please use it and provide us some feedback.',
  'admin.place.shopCapacity.help':
    'How many customers can you serve at the same time. Each timeslot can be booked by this many times by different customers',
  'admin.place.averageDuration.help':
    'Your working hours will be split into the timeslots of the following duration',
  'admin.place.inventory.enable': 'Enable inventory for this place',
  'admin.place.inventory.placeCurrency': 'Currency',
  'admin.place.inventory.placeCurrency.help':
    'This value will be used to show your visitors the correct prices',
  'admin.place.inventory.inventoryMaxOrder': 'Max items per order',
  'admin.place.inventory.inventoryMaxOrder.help':
    'The quantity of items, which can be ordered at your place by a visitor. Leave this field empty, if your stock is unlimited.',
  'admin.place.inventory.appointmentsNotAvailable':
    'The slot scheduler for appointments is not available, while the appointments are disabled',

  'admin.userProfile.title': 'User Profile',
  'admin.profile.title': 'Profile',
  'admin.profile.editProfile': 'Edit profile',

  // Inventory
  'inventory.quantity': 'Stock',
  'inventory.maxPerUser': 'Limit',
  'inventory.newProduct': 'New product',
  'inventory.editProduct': 'Edit product',
  'inventory.edit': 'Edit',
  'inventory.delete': 'Delete',
  'inventory.menu': 'Menu',
  'inventory.duplicate': 'Duplicate',
  'inventory.addProduct': 'Add product',
  'inventory.enterProductData': 'Please enter you product data',
  'inventory.productName': 'Name',
  'inventory.productName.placeholder': 'This field should not be empty',
  'inventory.productDescription': 'Description',
  'inventory.productDescription.placeholder': 'This field should not be empty',
  'inventory.productStock': 'Stock',
  'inventory.productStock.placeholder': 'Please leave the field empty, if your stock is unlimited',
  'inventory.unlimited': 'unlimited',
  'inventory.price': 'Price',
  'inventory.price.placeholder': 'Price of the product',
  'inventory.product.crop': 'Crop',
  'inventory.product.cropTitle': 'Crop your photo',
  'inventory.product.uploadImage': 'Upload',

  //Subscription
  'admin.subscription': 'Subscription',
  'admin.subscription.trial': 'Trial',
  'admin.subscription.nextInvoice': 'Next invoice',
  'admin.subscription.cancelAt': 'Subscription will be cancelled',
  'admin.subscription.cancel': 'Cancel',
  'plans.subscription.cancelAt': 'Valid until',
  'admin.subscription.sure_cancel': 'Are you sure you want to cancel your subscription?',
  'admin.subscription.cancelled': 'Subscription was cancelled',
  'admin.subscription.trial_description':
    'The trial period will start immediately and will end in 30 days. During the trial period, all features will be available to you. We will not ask you for payment information to start the trial. By the end of the trial period, the place will be automatically changed to a private plan. If you like our service subscribe anytime. The trial can only be used once.',
  'admin.subscription.trial_started': 'The trial period has started and will end on {date}',

  //PlaceList
  'admin.places.list.empty.createNew': 'Create new place',
  'admin.places.list.ticketsUsed': 'Tickets used',
  'admin.places.list.empty.header': "You don't have places yet",
  'admin.places.list.empty.body': "Create a new place and let's start to handle the queue together",
  'admin.places.list.header': 'Your places',
  'admin.places.list.managed': 'Managed places',
  'admin.places.list.createNew': 'Create new place',
  'admin.places.create.title': 'Create a Place',
  'admin.places.update.title': 'Update a Place',

  //QR
  'qr.print': 'Print this document and place it where your customers can easily see it',
  'qr.pleaseScanAppointments': 'Scan this QR code to schedule an appointment',
  'qr.pleaseScanLivequeue': 'Scan this QR code to queue in',
  'qr.pleaseScanIntegrated': 'Scan this QR code to queue in or to make an appointment',
  'qr.followThisLink': 'Or simply follow this URL',
  'qr.error': 'Something went wrong. Try later, please',

  //CounterPage
  'counter.processing': 'Processing',
  'counter.processing.add': 'Let next in',
  'counter.liveQueue': 'Live Queue',
  'counter.stopQueue': 'Unpaused',
  'counter.startQueue': 'Paused',
  'counter.liveQueue.add': 'Add new',
  'counter.appointments': 'Appointments',
  'counter.calendar': 'Calendar',
  'counter.appointments.add': 'Add new',
  'counter.appointments.dialog.header': 'Book appointment',
  'counter.appointments.dialog.edit.header': 'Edit ticket {tag}',
  'counter.appointments.dialog.comment.header': 'Add comment to {tag}',
  'counter.appointments.dialog.add': 'Book',
  'counter.appointments.dialog.cancel': 'Cancel',
  'counter.appointments.dialog.selectDate': 'Select date',
  'counter.appointments.dialog.pickTime': 'Pick time',
  'counter.appointments.dialog.availableTime':
    'Available time (click on different slots to create multiple appointments at once)',
  'counter.appointments.dialog.noAvailableTimeSlot':
    'There are no available time slots on the selected date for given service',
  'counter.appointments.dialog.noAvailableTime':
    'There are no available time slots on the selected date for given service',
  'counter.appointments.dialog.noAvailableTimeToday': 'There are no available time slots for today',
  'counter.appointments.dialog.selectedSlots': 'Following appointments will be created',
  'counter.appointments.dialog.selectService':
    'Select the type of service to see the available time slots',
  'counter.appointments.dialog.serviceType': 'Type of service',
  'counter.appointments.dialog.order': 'Order',
  'counter.liveQueue.dialog.header': 'Queue in',
  'counter.liveQueue.dialog.cancel': 'Cancel',
  'counter.userInfo.nickname': 'Name',
  'counter.userInfo.email': 'Email',
  'counter.userInfo.phone': 'Phone',
  'counter.userInfo.birthDate': 'Date of birth',
  'counter.userInfo.address': 'Address',
  'counter.toQueue': 'Queue in',
  'counter.invite': 'Invite',
  'counter.processed': 'Processed',
  'counter.processedTime': 'Processing Time',
  'counter.invited': 'Invited',
  'counter.noname': 'No name',
  'counter.walkin': 'Walk-in',
  'counter.done': 'Done',
  'counter.error': 'An error occurred, please contact support: {error}',
  'counter.info.dialog.notset': 'not set',
  'counter.appointments.info.queueIn': 'Queue in',
  'counter.info.dialog.sureCancel': 'Do you want to reject this customer?',
  'counter.info.dialog.time': 'Time',
  'counter.info.dialog.email': 'Email',
  'counter.info.dialog.phone': 'Phone',
  'counter.info.dialog.username': 'Name',
  'counter.info': '...',

  'counter.appointment.status.noShow': 'No Show',
  'counter.appointment.status.waiting': 'Pending',
  'counter.appointment.status.confirmed': 'Confirmed',
  'counter.appointment.status.queued': 'Queued',
  'counter.appointment.status.invited': 'Invited',
  'counter.appointment.status.scheduled': 'Scheduled',
  'counter.appointment.status.walkin': 'Walk-in',
  'counter.appointment.status.processed': 'Processed',
  'counter.appointment.status.cancelled': 'Cancelled',
  'counter.appointment.status.rejected': 'Rejected',

  'counter.table.header.ticket': 'Ticket',
  'counter.table.header.when': 'When',
  'counter.table.header.name': 'Name',
  'counter.table.header.email': 'Email',
  'counter.table.header.service': 'Service',
  'counter.table.header.status': 'Status',
  'counter.table.header.statusChanged': 'Status changed',
  'counter.table.header.type': 'Type',

  'counter.appointment.action.noShow': 'No Show',
  'counter.appointment.action.invite': 'Invite',
  'counter.appointment.action.queueIn': 'Add to Queue',
  'counter.appointment.action.done': 'Done',
  'counter.appointment.action.confirm': 'Confirm',
  'counter.appointment.action.cancel': 'Cancel',
  'counter.appointment.action.reject': 'Reject',
  'counter.appointment.action.edit': 'Edit',
  'counter.appointment.action.comment': 'Comment',
  'counter.appointment.action.noComment': 'No comments yet',
  'counter.appointment.action.backToQueue': 'Back to Queue',
  'counter.appointment.action.backToAppointment': 'Back to Appointment',
  'counter.showOnly': 'Show only',
  'counter.filter.all': 'All',
  'counter.filter.allServices': 'All services',
  'counter.noAppointments': 'There are no appointments made for the selected date',
  'counter.noAppointmentsToday': 'There are no appointments made for today',
  'counter.noTickets': 'There are no tickets made for the selected date',
  'counter.tabs.today': 'Today',
  'counter.tabs.calendar': 'History',
  'counter.filter.types.noShow': 'No Show',
  'counter.filter.types.active': 'Active Tickets',
  'counter.filter.types.rejected': 'Rejected',
  'counter.filter.types.cancelled': 'Cancelled',
  'counter.filter.types.processed': 'Processed',
  'counter.filter.types.notConfirmed': 'Not Confirmed',
  'counter.tabs.counterName': 'Counter Name',
  'counter.tabs.anyName': 'Any Name',

  //PlaceQRPage
  'qr.neverWaste': '2Meters. Never waste lifetime in queues again.',
  'qr.header': 'Scan this qr code to claim your time slot',

  // PlansPage
  'plans.text':
    'All features of 2Meters service can be used for free but limited to 100 tickets a month. This includes both queue and appointment tickets. With Pro plan does not impose any limitations',
  'plans.month.place': '/MONTH/PLACE',
  'plans.free': 'Free*',
  'plans.preloader': 'Please wait while we oil the gears before processing your payment',
  'plans.list.private': 'Private',
  'plans.list.pro': 'Pro',
  'plans.list.basic_monthly': 'Pro',
  'plans.list.basic_annual': 'Pro',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.business': 'Business',
  'plans.list.business_annual': 'Business',
  'plans.list.business_monthly': 'Business',
  'plans.list.enterprise': 'Enterprise',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.trial': 'Trial',
  'plans.chooseAPlan': 'Choose a plan',
  'plans.ps':
    '* we will charge additional costs for the consumption of SMS and Whatsapp notifications by the end of each month if you choose to use them. The prices are typical for the country of origin of the recipient’s phone number and depend on the prices of the local providers. For detailed information contact sales.',
  'plans.promo': 'Give your team the features they need to succeed.',
  'plans.contactUs': 'Contact us',
  'plans.missingFeature': 'Missing a feature?',
  'plans.currentPlan': 'Current plan',
  'plans.upgradePlan': 'Upgrade plan',
  'plans.cancelSubscription': 'Cancel subscription',
  'plans.private.features.1': '100 visitors per month',
  'plans.private.features.2': 'Unlimited App clients',
  'plans.private.features.3': 'Live Queue system',
  'plans.private.features.4': 'Slot Appointment system',
  'plans.private.features.5': 'Product catalog',
  'plans.private.features.6': 'Ordering system',
  'plans.private.features.7': 'Intuitive Counter App',
  'plans.private.features.8': 'History calendar',
  'plans.private.features.9': 'Display App',
  'plans.private.features.10': 'SMS/Whatsapp/Email/Push',
  'plans.pro.features.first': 'Same as Private',
  'plans.pro.features.1': 'Unlimited visitors',
  'plans.pro.features.2': 'Unlimited App clients',
  'plans.pro.features.3': 'Visitor validation per SMS',
  'plans.pro.features.4': 'Performance analytics',
  'plans.pro.features.5': 'Custom visitor forms',
  'plans.pro.features.6': 'Named counters',
  'plans.pro.features.7': 'Extended support',
  'plans.enterprise.features.first': 'Same as Pro',
  'plans.enterprise.features.1': 'Unlimited visitors',
  'plans.enterprise.features.2': 'Unlimited App clients',
  'plans.enterprise.features.3': 'Integrations',
  'plans.enterprise.features.4': 'Custom SMS Gateway',
  'plans.enterprise.features.5': 'Custom branding',
  'plans.enterprise.features.6': 'Custom features',
  'plans.enterprise.features.7': 'Premium support',
  'plans.enterprise.promo': 'Custom',
  'plans.switcher.monthly': 'Monthly',
  'plans.switcher.annualy': 'Annualy',
  'plans.mostPopular': 'Most popular',
  'plans.private.submit': 'Current plan',
  'plans.pro.submit': 'Select',
  'plans.enterprise.submit': 'Contact us',
  'plans.discont': '10% off',

  'plans.pro_monthly.cancelSubscription': 'Cancel Pro Monthly Plan',
  'plans.pro_annual.cancelSubscription': 'Cancel Pro Annual Plan',
  'plans.enterprise_monthly.cancelSubscription': 'Cancel Enterprise Monthly Plan',
  'plans.enterprise_annual.cancelSubscription': 'Cancel Enterprise Annual Plan',
  'plans.keepSubscribtion': 'Keep subscription',
  'plans.cancelSubscription.text':
    'Are you sure you want to cancel your subscription plan? Canceling will stop any future payments and your access to premium features will end at the end of your current billing cycle.If you have any questions or concerns, please contact our support team for assistance.',

  'poorConection.title': 'Poor internet connection',
  'poorConection.description':
    'Your internet connection is unfortunately insufficient for 2meters App to operate. Please check your internet connection or network security policies. Please consider that we use web sockets in order to bring you the live data.',

  //Checkout
  'checkout.success': 'Checkout was successful',
  'checkout.redirect': 'You will be redirected in a second',
  'checkout.falied': 'Checkout failed. Please contact support',

  'monitor.desktopViewHeader': 'Monitor View',
  'monitor.desktopViewBody':
    'You can install a screen to show your visitors the state of the queue. Open this page on the computer connected to the screen and click on the button to enter the fullscreen mode',
  'monitor.fullscreen': 'Fullscreen',
  'monitor.switchTheMonitor': 'Switch the monitor language',

  //Statistics
  'statistics.dashboard': 'Statistics Dashboard',
  'statistics.ticketsAndServices': 'Tickets and Services',
  'statistics.thisMonth': 'This month',
  'statistics.thisWeek': 'This week',
  'statistics.today': 'Today',
  'statistics.booked': 'Booked',
  'statistics.noData': 'There is no data for selected period',
  'statistics.totalAmount': 'Total Amount',
  'statistics.avgWaitingTime': 'Average Waiting Time',
  'statistics.avgProcessingTime': 'Average Processing Time',
  'statistics.minutes': 'minutes',

  'statistics.period': 'Period',
  'statistics.lastSevenDays': 'Last 7 days',
  'statistics.month': 'This month',
  'statistics.custom': 'Custom date range',
  'statistics.services': 'Services',
  'statistics.all': 'All services',
  'statistics.periodError': 'The start date should be before the end date',
  'statistics.сonfirmationStatus': 'Confirmation Status',
  'statistics.ticketType': 'Ticket Type',
  'statistics.ticketStatus': 'Ticket Status',
  'statistics.processed': 'Processed',
  'statistics.noShow': 'No Show',
  'statistics.invited': 'Invited',
  'statistics.cancelled': 'Cancelled',
  'statistics.confirmed': 'Confirmed',
  'statistics.pending': 'Pending',
  'statistics.rejected': 'Rejected',
  'statistics.queued': 'Queued',
  'statistics.dashboardIsEmpty': 'Dashboard is empty',
  'statistics.dashboardIsEmptyDesc':
    'In order to have access to statistics dashboard please upgrade to the higher subscription tier.',
  'statistics.upgadePlan': 'Upgade Plan',

  // Scheduler

  'scheduler.error.endBeforeStart': 'The starting date should be before the end date',
  'scheduler.error.notSameDay': 'Start and end time should be on the same day',
  'scheduler.error.visitTypeNotSet': 'The type of the service must be set',
  'scheduler.error.invalidSchedule':
    'Invalid schedule. Check that the end of the repeat period is not before the start date',
  'scheduler.numberOfAppointments': '{num} time periods in this interval',
  'scheduler.simple': 'Simple',
  'scheduler.advanced': 'Advanced',
  'scheduler.serviceType': 'Type of service',
  'scheduler.timePeriodForm.commit': 'Confirm',
  'scheduler.timePeriodForm.repeat': 'Repeat',
  'scheduler.timePeriodForm.never': 'Never',
  'scheduler.timePeriodForm.daily': 'Daily',
  'scheduler.timePeriodForm.weekly': 'Weekly',
  'scheduler.timePeriodForm.monthly': 'Monthly',
  'scheduler.timePeriodForm.yearly': 'Yearly',
  'scheduler.timePeriodForm.repeatEvery': 'Repeat every',
  'scheduler.timePeriodForm.days': 'day(s)',
  'scheduler.timePeriodForm.endRepeat': 'End repeat',
  'scheduler.timePeriodForm.on': 'On',
  'scheduler.timePeriodForm.after': 'After',
  'scheduler.timePeriodForm.occurs': 'occurrence(s)',
  'scheduler.timePeriodForm.weeksOn': 'week(s) on:',
  'scheduler.timePeriodForm.month': 'month(s)',
  'scheduler.timePeriodForm.everyMonth': 'of every month',
  'scheduler.timePeriodForm.the': 'The',
  'scheduler.timePeriodForm.first': 'First',
  'scheduler.timePeriodForm.second': 'Second',
  'scheduler.timePeriodForm.third': 'Third',
  'scheduler.timePeriodForm.fourth': 'Fourth',
  'scheduler.timePeriodForm.last': 'Last',
  'scheduler.timePeriodForm.years': 'year(s)',
  'scheduler.timePeriodForm.of': 'of',
  'scheduler.timePeriodForm.every': 'Every',
  'scheduler.timePeriodForm.timePeriods': 'Time Periods',
  'scheduler.timePeriodForm.headerCaption':
    'Please define each time period, when you provide your particular service. The slots for this period will be calculated automatically depending on the slot duration value.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Service Assignment and Slots',
  'scheduler.timePeriodForm.createButton': 'Create',

  'scheduler.confirmCancel': 'Would you really like to discard unsaved changes?',
  'scheduler.confirmDelete': 'Would you really like to delete this time period?',
  'scheduler.cancelButton': 'Cancel',
  'scheduler.deleteButton': 'Delete',
  'scheduler.discardButton': 'Discard',

  'scheduler.editRecurrenceMenu.editTitle': 'Edit recurring time periods',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Delete recurring time periods',
  'scheduler.editRecurrenceMenu.cancelButton': 'Cancel',
  'scheduler.editRecurrenceMenu.commitButton': 'Confirm',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'All time periods',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods': 'This and following time periods',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'This time period',

  // Managers

  'manager.title': 'Managers',
  'manager.description':
    'Here you can add and remove people who has access to this place. Managers can manipulate appointments and queue from the counter view. But they can not edit the place settings.',
  'manager.add': 'Add manager',
  'manager.remove': 'Remove',
  'manager.admin': 'Administrator',
  'manager.manager': 'Manager',
  'manager.name': 'Name',
  'manager.email': 'Email',
  'manager.role': 'Role',
  'manager.invitationSent': 'Invitation sent',
  'manager.sendInvite': 'Send Invite',
  'manager.invitePlaceholder': 'Invite new users',
  'manager.table.name': 'Name',
  'manager.table.accepted': 'Accepted',
  'manager.table.invited': 'Sent',
  'manager.table.email': 'Email',
  'manager.table.role': 'Role',
  'manager.table.dateAdded': 'Date added',
}
