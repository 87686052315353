import {
  AppointmentShort,
  InventoryProduct,
  ManagedPlace,
  Place,
  PlaceRequest,
  Subscription,
} from '@2meters/shared'
import {
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import lodash from 'lodash'
import Stripe from 'stripe'
import { applyScheduleChanges, getWholeSchedule } from './complexSchedule'
import { db, functions } from './firebase-init'
import { prepareRequest, readAs, resultAs } from './firebase-utils'

const _ = require('deepdash')(lodash)

const defaultPlace: any = {
  tags: [],
  openingTime: '10:00',
  closingTime: '18:00',
  slotDuration: 30,
  shopCapacity: 1,
  workdays: {
    0: { dayOfWeek: 0, open: true, openingTime: '09:00', closingTime: '18:00' },
    1: { dayOfWeek: 1, open: true, openingTime: '09:00', closingTime: '18:00' },
    2: { dayOfWeek: 2, open: true, openingTime: '09:00', closingTime: '18:00' },
    3: { dayOfWeek: 3, open: true, openingTime: '09:00', closingTime: '18:00' },
    4: { dayOfWeek: 4, open: true, openingTime: '09:00', closingTime: '18:00' },
    5: {
      dayOfWeek: 5,
      open: false,
      openingTime: '09:00',
      closingTime: '18:00',
    },
    6: {
      dayOfWeek: 6,
      open: false,
      openingTime: '09:00',
      closingTime: '18:00',
    },
  },
}

const deletePlace = (placeId: string): Promise<void> =>
  httpsCallable(
    functions,
    'owner-deletePlace'
  )({ placeId })
    .then(result => {
      console.log(`Place deleted ${placeId}`)
      return Promise.resolve()
    })
    .catch(e => {
      console.error(`Place was not deleted ${placeId} ${e}`)
      return Promise.reject(e)
    })

const createPlace = (place: PlaceRequest): Promise<Place> =>
  httpsCallable(
    functions,
    'owner-createPlace'
  )(prepareRequest({ place }))
    .then((result: any) => {
      console.log(`Place created ${result?.data?.id}`)
      return resultAs<Place>(result.data)
    })
    .catch(e => {
      console.error(`Place was not created ${place}: ${e}`)
      return Promise.reject(e)
    })

const getInventory = (placeId: string): Promise<InventoryProduct[]> => {
  let q = query(collection(db, `queue/${placeId}/inventory/`))
  return getDocs(q).then((snapshot: QuerySnapshot<any>) =>
    snapshot.docs.map((doc: any) => readAs<InventoryProduct>(doc))
  )
}

const updatePlace = (placeId: string, place: Partial<PlaceRequest>): Promise<Place> =>
  httpsCallable(
    functions,
    'owner-updatePlace'
  )(prepareRequest({ placeId, update: place }))
    .then(result => {
      console.log(`Place ${placeId} updated`)
      return resultAs<Place>(result.data)
    })
    .catch(e => {
      console.error(`Place was not updated ${place}: ${e}`)
      return Promise.reject(e)
    })

const getPlace = async (id: string): Promise<Place | undefined> =>
  getDoc(doc(db, `queue/${id}`)).then(doc => {
    if (doc.exists()) return readAs<Place>(doc)
    else return undefined
  })

const getManagedPlace = async (id: string): Promise<ManagedPlace | undefined> =>
  getDoc(doc(db, `managedQueue/${id}`)).then(doc => {
    if (doc.exists()) return readAs<ManagedPlace>(doc)
    else return undefined
  })

const addPlaceManager = async (placeId: string, email: string): Promise<void> => {
  httpsCallable(
    functions,
    'owner-addManager'
  )(prepareRequest({ placeId, email }))
    .then(result => {
      console.log(`Manager ${email} added`)
      return resultAs<Place>(result.data)
    })
    .catch(e => {
      console.error(`Manager was not added: ${e}`)
      return Promise.reject(e)
    })
}

const removePlaceManager = async (placeId: string, email: string): Promise<void> => {
  httpsCallable(
    functions,
    'owner-removeManager'
  )(prepareRequest({ placeId, email }))
    .then(result => {
      console.log(`Manager ${email} removed`)
      return resultAs<Place>(result.data)
    })
    .catch(e => {
      console.error(`Manager was not removed: ${e}`)
      return Promise.reject(e)
    })
}

const getSubscription = async (placeId: string): Promise<Subscription | undefined> => {
  const q = query(collection(db, 'subscriptions'), where('placeId', '==', placeId), limit(1))
  return getDocs(q).then(snap => {
    if (snap.docs.length > 0) return readAs<Subscription>(snap.docs[0])
    else return undefined
  })
}

const createStripeCheckoutSession = async ({
  domain,
  id,
  price,
  isOldApp,
}: Pick<Place, 'id'> & {
  domain: string
  price?: string
  isOldApp?: boolean
}): Promise<Stripe.Checkout.Session> =>
  httpsCallable(
    functions,
    'stripe-createStripeCheckoutSession'
  )({ id, domain, price, isOldApp })
    .then(result => {
      console.log(`Stripe Checkout Session created for place ${id}`)
      return result.data as Stripe.Checkout.Session
    })
    .catch(e => {
      console.error(`Stripe Checkout Session was not created for place ${id}`)
      return Promise.reject(e)
    })

const cancelSubscription = async (placeId: string): Promise<void> =>
  httpsCallable(
    functions,
    'owner-cancelSubscription'
  )({ placeId })
    .then(result => {
      console.log(`Subscription cancelled in ${placeId}`)
    })
    .catch(e => {
      console.error(`Subscription was not canceled in ${placeId}`)
      return Promise.reject(e)
    })

const getPlaceByShortUrl = (short: string): Promise<Place> => {
  if (!short || short === '') return Promise.reject(Error('Not found'))
  const q = query(collection(db, 'queue'), where('shortUrl', '==', short.toLocaleLowerCase()))
  return getDocs(q).then(snapshot => {
    const doc = _.head(snapshot.docs)
    console.log('PLACE BY SHORT URL', doc.data())
    if (doc && doc.exists()) return readAs<Place>(doc!)
    else return Promise.reject(Error('Not found'))
  })
}

const getAppointmentsOfPlace = async (
  placeId: string,
  from: Date,
  until: Date,
  visitType?: string
): Promise<AppointmentShort[]> => {
  let conditios = [
    where('startTime', '>=', from),
    where('startTime', '<=', until),
    where('confirmation', 'in', ['confirmed', 'not_yet']),
    where('cancelled', '==', false),
  ]
  if (visitType) conditios = [...conditios, where('visitType', '==', visitType)]

  let q = query(collection(db, `queue/${placeId}/appointments_short`), ...conditios)

  return getDocs(q).then(s => s.docs.map(doc => readAs<AppointmentShort>(doc)))
}

const placesOfUserSubscribe = (userId: string, callback: (apps: Place[]) => void): (() => void) => {
  const q = query(collection(db, 'queue'), where('userId', '==', userId))
  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let places: Place[] = snapshot.docs.map(doc => readAs<Place>(doc))
    callback(places)
  })
}

const managedPlacesOfUserSubscribe = (
  email: string,
  callback: (apps: Place[]) => void
): (() => void) => {
  const q = query(collection(db, 'managedQueue'), where('managers', 'array-contains-any', [email]))
  return onSnapshot(q, async (snapshot: QuerySnapshot<any>) => {
    let places: Place[] = (
      await Promise.all(
        snapshot.docs.map(doc => readAs<{ id: string }>(doc)).map(p => getPlace(p.id))
      )
    ).filter((item: Place | undefined): item is Place => !!item) // filter out undefined and cast to Place[]
    callback(places)
  })
}

const inventorySubscribe = (id: string, callback: (apps: InventoryProduct[]) => void) => {
  const q = query(collection(db, `queue/${id}/inventory`))

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    const products = snapshot.docs.map(doc => readAs<InventoryProduct>(doc))
    callback(products)
  })
}

const placeSubscribe = (
  id: string,
  callback: (apps: Place) => void,
  notFound: () => void
): (() => void) => {
  console.info('Subscribing to place')
  const ref = doc(db, `queue/${id}`)
  return onSnapshot(ref, doc => {
    if (doc.exists()) callback(readAs<Place>(doc))
    else notFound()
  })
}

const managedPlaceSubscribe = (
  id: string,
  callback: (apps: ManagedPlace) => void,
  notFound: () => void
): (() => void) => {
  console.info('Subscribing to managed place')
  const ref = doc(db, `managedQueue/${id}`)
  return onSnapshot(ref, doc => {
    if (doc.exists()) callback(readAs<ManagedPlace>(doc))
    else notFound()
  })
}

const getManagerInvites = (placeId: string) => {
  const q = query(collection(db, `queue/${placeId}/managerInvitations`))
  return getDocs(q)
    .then(snap => snap.docs.map(doc => readAs(doc)))
    .then(invites => _(invites).keyBy('email').value())
}

const copyPlace = (sourceId: string) => {
  return getPlace(sourceId)
    .then(place => {
      const request = { ...place, id: undefined } as PlaceRequest
      return createPlace(request)
    })
    .then(place =>
      getWholeSchedule(sourceId)
        .then(schedule =>
          applyScheduleChanges(
            place.id,
            {
              added: schedule,
              changed: {},
              deleted: [],
            },
            place.timezone
          )
        )
        .then(() => place)
    )
}

export const places = {
  getPlace,
  getManagedPlace,
  getAppointmentsOfPlace,
  getPlaceByShortUrl,
  updatePlace,
  createPlace,
  deletePlace,
  getSubscription,
  createStripeCheckoutSession,
  cancelSubscription,
  copyPlace,
  getInventory,
  addPlaceManager,
  removePlaceManager,
  getManagerInvites,
  defaultPlace,
  subscriptions: {
    inventory: inventorySubscribe,
    placesOfUser: placesOfUserSubscribe,
    managedPlacesOfUser: managedPlacesOfUserSubscribe,
    place: placeSubscribe,
    managedPlaceSubscribe,
  },
}
