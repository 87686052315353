import { CircularProgress as Progress, Box } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 146px)',
    width: '100%',
  },
}))

const CircularProgress = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Progress />
    </Box>
  )
}

export default CircularProgress
