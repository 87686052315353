import { createTheme, Theme } from '@mui/material/styles'
import { grey, common } from '@mui/material/colors'
// import '@mui/styles'
import { COLORS } from './colors'

declare module '@mui/system' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  export interface ButtonPropsColorOverrides {
    buttonLightGrey: true
  }
}

declare module '@mui/material/styles' {
  export interface Palette {
    buttonLightGrey: Palette['primary']
    purple: Palette['primary']
    boxShadow: Palette['primary']
  }
  export interface PaletteOptions {
    buttonLightGrey: PaletteOptions['primary']
    purple: PaletteOptions['primary']
    boxShadow: PaletteOptions['primary']
  }
}

const defaultTheme = createTheme()

export const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          borderColor: COLORS.primary.main,
        },
      },
    },
  },
  palette: {
    mode: 'light',
    text: {
      primary: COLORS.text.primary,
      secondary: COLORS.text.secondary,
    },
    background: {
      default: COLORS.grey_700,
      paper: COLORS.white_900,
    },
    primary: {
      main: COLORS.primary.main,
      dark: COLORS.primary.dark,
      contrastText: common.white,
    },
    secondary: {
      main: COLORS.secondary.main,
      contrastText: common.white,
    },
    error: {
      main: COLORS.error.main,
      contrastText: common.white,
    },
    success: {
      main: COLORS.success.main,
      contrastText: common.white,
    },
    purple: defaultTheme.palette.augmentColor({
      color: {
        main: COLORS.purple,
      },
      name: 'purple',
    }),
    buttonLightGrey: defaultTheme.palette.augmentColor({
      color: {
        main: common.white,
        contrastText: grey[900],
        dark: grey[300],
      },
      name: 'buttonLightGrey',
    }),
    boxShadow: defaultTheme.palette.augmentColor({
      color: { main: COLORS.black_300 },
      name: 'boxShadow',
    }),
    grey: {
      700: COLORS.grey_700,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: 38,
      fontWeight: 700,
      lineHeight: '48px',
      letterSpacing: 'normal',
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: 'normal',
    },
    h3: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: 'normal',
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: 'normal',
    },
    h5: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    // Not in the Design System
    h6: {
      fontSize: 13,
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: 'normal',
    },
    // Not in the Design System
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    // Not in the Design System
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 'normal',
    },
    body2: {
      fontSize: 15,
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: 'normal',
    },
    // Called Caption2 in the Design System
    button: {
      fontSize: 11,
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
    // Called Caption1 in the Design System
    caption: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
  },
})
