import React, { ReactNode } from 'react'
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'

interface Props {
  loading?: boolean
  labelId?: string
  labelData?: Record<string, ReactNode>
  height?: string
  width?: string
  color?: string
  justifyContent?: string
}

const ButtonLoading: React.FC<Props & ButtonProps> = ({
  children,
  loading,
  labelId,
  labelData,
  fullWidth,
  justifyContent,
  height,
  width,
  style,
  color,
  disabled,
  ...rest
}) => {
  return (
    <Box
      textAlign='center'
      width={width || 'auto'}
      height={height}
      display='flex'
      alignItems='center'
      justifyContent={`${justifyContent ?? 'center'}`}
    >
      {loading ? (
        <Box display='flex' justifyContent='center' minWidth={`${width || '80px'}`}>
          <CircularProgress size={30} color='primary' />
        </Box>
      ) : (
        <Button
          fullWidth={fullWidth ?? true}
          disabled={disabled}
          color={color ?? 'inherit'}
          {...rest}
          style={{
            height: height || '32px',
            width: width || '100%' || 'inherit',
            minWidth: 106,
            borderRadius: '3px',
            whiteSpace: 'nowrap',
            ...style,
          }}
        >
          {children || (
            <Typography variant='body1'>
              <FormattedMessage id={labelId} values={labelData} />
            </Typography>
          )}
        </Button>
      )}
    </Box>
  )
}

export default ButtonLoading
