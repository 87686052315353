import { TextField, Box, Typography, Paper, List, ListItem, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { theme } from 'themes/theme'
import { FormattedMessage } from 'react-intl'

interface Props {
  onSelect: (result: any) => void
  defaultValue?: string
  error?: boolean
}

const LocationSearchInput: React.FC<Props> = ({ onSelect, defaultValue, error }) => {
  const [address, setAddress] = useState(defaultValue || '')

  const handleChange = (address: string) => {
    setAddress(address)
    onSelect({ formatted_address: address })
  }

  const handleSelect = (address: string) => {
    setAddress(address)
    geocodeByAddress(address)
      .then(results => {
        onSelect(results[0])
      })
      .catch(error => console.error('Error', error))
  }

  return (
    <>
      <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Box>
            <TextField
              variant='standard'
              fullWidth
              error={error}
              autoFocus
              InputProps={getInputProps()}
            />

            <Paper
              sx={{
                marginTop: 0.3,
              }}
            >
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                  <CircularProgress size={25} />
                </Box>
              )}

              <List
                sx={{
                  padding: `${suggestions.length === 0 && 0}`,
                }}
              >
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const style = suggestion.active
                    ? {
                        backgroundColor: theme.palette.grey[200],
                        cursor: 'pointer',
                      }
                    : {
                        backgroundColor: theme.palette.background.paper,
                        cursor: 'pointer',
                      }

                  return (
                    <>
                      {suggestions.length !== 0 ? (
                        <ListItem
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <Typography variant='body2' color='textSecondary'>
                            {suggestion.description}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem>
                          <Typography variant='body2' color='textSecondary'>
                            <FormattedMessage id='admin.place.noOptions' />
                          </Typography>
                        </ListItem>
                      )}
                    </>
                  )
                })}
              </List>
            </Paper>
          </Box>
        )}
      </PlacesAutocomplete>
    </>
  )
}

export default LocationSearchInput
