import { Place } from '@2meters/shared'

export const MAP = '/map'
export const HOME = '/'

export const MONITOR_PAGE = '/monitor/:id'
export const MONITOR_SHORT = '/m/:shortUrl'
export const SCAN_QR = '/qr'
export const FAQ = '/faq'
export const LOGIN = '/login'
export const RESET_PASSWORD = '/reset-password'

export const DEFAULT_LOGO = '/default-place-logo.png'
export const DEFAULT_PRODUCT = '/default-product-image.jpg'
export const DING_DONG = '/dingdong.mp3'
export const LIVEQUEUE_SOUND = '/liveQueueSound.wav'
export const APPOINTMENT_SOUND = '/appointmentSound.wav'

export const VISITOR = {
  PLACE_SHORT: '/p/:shortUrl',
  PLACE_PAGE: '/places/:id',
  APPOINTMENT_SHORT: '/app/:shortUrl',
  APPOINTMENT_PAGE: '/places/:id/appointments',
  LIVEQUEUE_SHORT: '/q/:shortUrl',
  LIVEQUEUE_PAGE: '/places/:id/queuein',
}

// Admin
export const ADMIN = {
  STATISTICS: '/admin/places/:id/statistics',
  CHECKOUT_CANCEL: '/admin/checkout/cancel',
  CHECKOUT_SUCCESS: '/admin/places/:id/success',
  ADMIN_PAGE: '/admin',
  PLACES_LIST: '/admin/places',
  PLACE_PAGE: '/admin/places/:id',
  PLACE_CREATED: '/admin/created/:id', // mirror of PLACE_PAGE. needed to track user conversion
  PLACE_EDIT: '/admin/places/:id/edit',
  PLACE_MONITOR_PAGE: '/admin/places/:id/monitor',
  COUNTER_PAGE: '/admin/places/:id/counter',
  PLACE_WIZARD: '/admin/place/wizard',
  PLACE_QR: '/admin/places/:id/qr', // not working
  PLACE_MANAGERS: '/admin/places/:id/managers',
  PLACE_PAGE_SHORT: '/a/:shortUrl',
  ONBOARDING: '/admin/onboarding',
  BILLING_LOG_NOTIFICATIONS: '/admin/billinglog/notifications',
  USER_PROFILE: '/admin/user-profile',
}

export const BASE_URL = `${process.env.REACT_APP_DOMAIN}`

export const embedCode = (placeId: string) =>
  `<iframe width="560" height="850" src="visitor.${BASE_URL}/places/${placeId}" frameborder="0" allowfullscreen></iframe>`

export const TERMS = 'https://2meters.app/terms-of-service/'
export const PRIVACY_POLICY = 'https://2meters.app/privacy-policy/'

export const adminUrl = (place: Place, relative = false) => {
  const base = relative ? '' : BASE_URL
  return place.shortUrl
    ? base + ADMIN.PLACE_PAGE_SHORT.replace(':shortUrl', place.shortUrl)
    : base + ADMIN.PLACE_PAGE.replace(':id', place.id)
}

export const placeUrl = (place: Place) =>
  place.shortUrl
    ? `https://visitor.${BASE_URL}${VISITOR.PLACE_SHORT.replace(':shortUrl', place.shortUrl)}`
    : `https://visitor.${BASE_URL}${VISITOR.PLACE_PAGE.replace(':id', place.id)}`

export const appointmentUrl = (place: Place) => {
  if (BASE_URL == 'app.2meters.app') {
    return place.shortUrl
      ? `https://visitor.2meters.app${VISITOR.APPOINTMENT_SHORT.replace(
          ':shortUrl',
          place.shortUrl
        )}`
      : `https://visitor.2meters.app${VISITOR.APPOINTMENT_PAGE.replace(':id', place.id)}`
  } else {
    return place.shortUrl
      ? `https://visitor.${BASE_URL}${VISITOR.APPOINTMENT_SHORT.replace(
          ':shortUrl',
          place.shortUrl
        )}`
      : `https://visitor.${BASE_URL}${VISITOR.APPOINTMENT_PAGE.replace(':id', place.id)}`
  }
}

export const livequeueUrl = (place: Place) => {
  if (BASE_URL == 'app.2meters.app') {
    return place.shortUrl
      ? `https://visitor.2meters.app${VISITOR.LIVEQUEUE_SHORT.replace(':shortUrl', place.shortUrl)}`
      : `https://visitor.2meters.app${VISITOR.LIVEQUEUE_PAGE.replace(':id', place.id)}`
  } else {
    return place.shortUrl
      ? `https://visitor.${BASE_URL}${VISITOR.LIVEQUEUE_SHORT.replace(':shortUrl', place.shortUrl)}`
      : `https://visitor.${BASE_URL}${VISITOR.LIVEQUEUE_PAGE.replace(':id', place.id)}`
  }
}

export const monitorUrl = (place: Place) => {
  if (BASE_URL == 'app.2meters.app') {
    return place.shortUrl
      ? `https://monitor.2meters.app${MONITOR_SHORT.replace(':shortUrl', place.shortUrl)}`
      : `https://monitor.2meters.app${MONITOR_PAGE.replace(':id', place.id)}`
  } else {
    return place.shortUrl
      ? `https://monitor.${BASE_URL}${MONITOR_SHORT.replace(':shortUrl', place.shortUrl)}`
      : `https://monitor.${BASE_URL}${MONITOR_PAGE.replace(':id', place.id)}`
  }
}

export const loginUrl = () => {
  return `https://${BASE_URL}${LOGIN}`
}

export const shortUrl = (
  place: Place,
  type?: 'appointment' | 'livequeue' | 'place' | any
): string => {
  switch (type) {
    case 'appointment':
      return appointmentUrl(place)
    case 'livequeue':
      return livequeueUrl(place)
    default:
      return placeUrl(place)
  }
}
