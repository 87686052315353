import lodash from 'lodash'
import { DateTime } from 'luxon'
import { AppointmentShort } from '@2meters/shared'
import { db } from './firebase-init'
import { readAs } from './firebase-utils'
import { collection, onSnapshot, query, QuerySnapshot, where } from 'firebase/firestore'

const _ = require('deepdash')(lodash)

// Subscriptions

//* subscribes to the queue changes in one particular place, returns a list of Appointment  */
const queueAppointmentsSubscribe = (
  placeId: string,
  callback: (apps: AppointmentShort[]) => void
): (() => void) => {
  console.log('Subscribing to queue appointments')
  let q = query(
    collection(db, `queue/${placeId}/appointments_short`),
    where('expiresAt', '>=', new Date()),
    where('cancelled', '==', false),
    where('processed', '==', false),
    where('invited', '==', false),
    where('queued', '==', true),
    where('confirmation', 'in', ['not_yet', 'confirmed'])
  )

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs
      .filter(doc => doc.id !== '--stats--')
      .map(doc => readAs<AppointmentShort>(doc))

    let sortedApps = _.sortBy(apps, (app: AppointmentShort) => app.queuedAt.getTime())
    console.log('Queued appointments', sortedApps)
    callback(sortedApps)
  })
}

//* subscribes to the invited Appointments  */
const invitedAppointmentsSubscribe = (
  placeId: string,
  callback: (apps: AppointmentShort[]) => void
): (() => void) => {
  console.log('Subscribing to invited appointments')
  const q = query(
    collection(db, `queue/${placeId}/appointments_short`),
    where('invited', '==', true),
    where('cancelled', '==', false),
    where('processed', '==', false),
    where('confirmation', 'in', ['not_yet', 'confirmed'])
  )

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps: AppointmentShort[] = snapshot.docs.map(doc => readAs<AppointmentShort>(doc))
    console.log('Invited appointments', apps)
    callback(apps)
  })
}

const appointmentsOfPlaceSubscribe = (
  placeId: string,
  from: DateTime,
  until: DateTime,
  handler: (apps: AppointmentShort[]) => void
): (() => void) => {
  console.log('Subscribing to appointments of a place')
  const q = query(
    collection(db, `queue/${placeId}/appointments_short`),
    where('startTime', '>=', from.toJSDate()),
    where('startTime', '<=', until.toJSDate()),
    where('cancelled', '==', false),
    where('confirmation', 'in', ['not_yet', 'confirmed'])
  )

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs.map((doc: any) => readAs<AppointmentShort>(doc))

    handler(apps)
  })
}

export const monitor = {
  subscriptions: {
    invitedAppointments: invitedAppointmentsSubscribe,
    queueAppointments: queueAppointmentsSubscribe,
    appointmentsOfPlace: appointmentsOfPlaceSubscribe,
  },
}
