import { DateTime } from 'luxon'
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts'
import {
  DefaultTimezone,
  EstimatedWaitingTimeModes,
  Place,
  WeekdayIndex,
  WorkDays,
} from '@2meters/shared'

export const timePeriodInText = (durationSec: number | undefined, locale: string): string => {
  if (!durationSec) return '-'

  const normalisedDuration = Math.max(durationSec, 60)
  const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage()
  const humanizer: HumanizeDuration = new HumanizeDuration(langService)
  const language = locale.slice(0, 2)
  return humanizer.humanize(normalisedDuration * 1000, {
    language,
    round: true,
    largest: 1,
  })
}

export const appointmentWaitingTimeText = (
  place: Place,
  locale: string,
  estimatedWaitingTimeSec: number
): string | undefined => {
  if (place.estimatedWaitingTimeMode === 'off') return undefined

  return timePeriodInText(estimatedWaitingTimeSec, locale)
}

export const queueWaitingTimeText = (
  place: Place,
  locale: string,
  positionInQueue?: number
): string | undefined => {
  if (place.estimatedWaitingTimeMode === 'constant' && place.estimatedWaitingTimeConstantMinutes)
    return timePeriodInText(
      place.estimatedWaitingTimeConstantMinutes * 60 * (positionInQueue! + 1),
      locale
    )

  if (place.estimatedWaitingTimeMode === 'off') return undefined

  if (
    !place.stats ||
    place.stats.averageProcessingTimeSec === undefined ||
    positionInQueue === undefined
  )
    return undefined

  return timePeriodInText(place.stats.averageProcessingTimeSec * (positionInQueue! + 1), locale)
}

export const isSameDay = (time2: DateTime, time1: DateTime) =>
  +time1.startOf('day') === +time2.startOf('day')

export const isToday = (time2: DateTime) => isSameDay(time2, DateTime.local())

export function isOutOfService(
  date: DateTime,
  workdays: WorkDays,
  openingDate?: DateTime,
  closingDate?: DateTime
): boolean {
  const outOfOpenDates =
    (openingDate && date <= openingDate && !isSameDay(date, openingDate)) ||
    (closingDate && date > closingDate && !isSameDay(date, closingDate))

  const isDayOff = workdays[(date.get('weekday') % 7) as WeekdayIndex].open

  return outOfOpenDates || isDayOff
}

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const startOfToday = (timezone: string) => DateTime.local().setZone(timezone).startOf('day')

export const startOfaDay = (timezone: string, date: Date) =>
  DateTime.fromJSDate(date).setZone(timezone).startOf('day')

export const endOfaDay = (timezone: string, date: Date) =>
  DateTime.fromJSDate(date).setZone(timezone).endOf('day')

export const startOfHour = (timezone: string, date: Date) =>
  DateTime.fromJSDate(date).setZone(timezone).startOf('hour')

export const endOfHourIncl = (timezone: string, date: Date) =>
  DateTime.fromJSDate(date).setZone(timezone).plus({ hour: 1 }).startOf('hour')

export const endOfToday = (timezone: string) => DateTime.local().setZone(timezone).startOf('day')

export const startOfWeek = (timezone: string) => DateTime.local().setZone(timezone).startOf('day')

export const inTwoDaysFrom = (date: Date, timezone: string) =>
  DateTime.fromJSDate(date).plus({ day: 2 }).setZone(timezone).startOf('day')

export const nowOfPlace = (place: Place): DateTime =>
  DateTime.local().setZone(place.timezone || DefaultTimezone)

export const toPlaceTime = (time: Date, place: Place): DateTime =>
  DateTime.fromJSDate(time, { zone: place.timezone || DefaultTimezone })
