import { string, array, optional, type, boolean, number, Infer } from 'superstruct'

export const ScheduleTimeRangeStruct = type({
  id: string(), // random id
  from: string(), // string in format HH:mm (10:30)
  until: string(), // string in format HH:mm (10:30)
  days: array(string()), // array of days (Mo, Tu, We, Th, Fr, Sa, Su)
})
export const ScheduleDateRangeStruct = type({
  id: string(), // firestore document id
  enabled: boolean(), // true if enabled. false if temporary disabled
  startDate: string(), // starting date of the interval in format dd/mm/yyyy (01/01/2021)
  endDate: optional(string()), // optional end date of the interval in format dd/mm/yyyy (01/01/2021). no value - no end
  slotDurationMinutes: number(), // duration of the slot in minutes. 15, 30, 60, etc. duration an interval should be multple of that
  capacity: number(), // simultaneous capacity describes how many appointments can be taken on the same time slot
  excemptions: array(string()), // dates excepmted from the schedule in format dd/mm/yyyy (01/01/2021)
  timeRanges: array(ScheduleTimeRangeStruct),
})
export const Schedule3000Struct = type({
  id: string(), // firestore document id
  name: string(), // name of the schedule. use ```default``` for default schedule
  serviceName: string(), // service id
  timezone: string(), // timezone in format Europe/Berlin
  dateRanges: array(ScheduleDateRangeStruct),
})

export type Schedule3000 = Infer<typeof Schedule3000Struct>
export type ScheduleDateRange = Infer<typeof ScheduleDateRangeStruct>
export type ScheduleTimeRange = Infer<typeof ScheduleTimeRangeStruct>
