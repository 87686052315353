import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ReactComponent as Preloader } from 'assets/preloader.svg'
import { FormattedMessage } from 'react-intl'
import DesktopLayout, { Container } from 'components/Layouts/Desktop'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}))

interface Props {
  text: string
}

const PacmanProgress: React.FC<Props> = ({ text }) => {
  const classes = useStyles()
  return (
    <DesktopLayout>
      <Container>
        <Box className={classes.root}>
          <Box m={2}>
            <Preloader />
            <Typography style={{ fontWeight: 600 }} variant='button'>
              <FormattedMessage id='onboarding.loading' />
            </Typography>
          </Box>
          <Box maxWidth={310} display='flex' justifyContent='center'>
            <Typography variant='body1' align='center'>
              <FormattedMessage id={text} />
            </Typography>
          </Box>
        </Box>
      </Container>
    </DesktopLayout>
  )
}

export default PacmanProgress
