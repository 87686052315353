import _ from 'lodash'
import uuid from 'short-uuid'
import { DateTime } from 'luxon'
import {
  AppointmentShort,
  DefaultSlotDurationMin,
  SchedulerItemRequest,
  Slot,
  SchedulerItem,
} from '@2meters/shared'
import { ScheduleChanges } from 'pages/Admin/Place'
import { db } from './firebase-init'
import { readAs } from './firebase-utils'
import { places } from './places'
import { VEvent } from './rschedule'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  runTransaction,
  setDoc,
  where,
} from 'firebase/firestore'

export type SchedulerInterval = {
  id: string
  startTime: DateTime
  endTime: DateTime
  slotDurationMin: number
  shopCapacity: number
  visitType: string
}

export const getAvailability = async (
  placeId: string,
  date: DateTime,
  timezone: string,
  visitType: string
): Promise<{ availability: Slot[]; appointments: AppointmentShort[] }> => {
  const startOfDay = date.startOf('day')
  const endOfDay = date.endOf('day')
  return await Promise.all([
    getWholeSchedule(placeId, visitType),
    await places.getAppointmentsOfPlace(
      placeId,
      startOfDay.toJSDate(),
      endOfDay.toJSDate(),
      visitType
    ),
  ]).then(([items, appointments]) => {
    const intervals = calculateIntervals(items, startOfDay, endOfDay, timezone, visitType)

    const slots = calculateSlots(intervals, timezone)
    const slotsWithOccupancy = fillInOccupiedSlots(appointments, slots, visitType)
    return { availability: slotsWithOccupancy, appointments }
  })
}

/**
 * From the open-time intervals list calculates appointment slots list
 */
const calculateSlots = (intervals: SchedulerInterval[], timezone: string): Slot[] => {
  function* makeRangeIterator(interval: SchedulerInterval) {
    let iterationCount = 0

    let step = interval.slotDurationMin || DefaultSlotDurationMin
    for (
      let i = interval.startTime;
      i.plus({ minute: step }) <= interval.endTime;
      i = i.plus({ minute: step })
    ) {
      iterationCount += 1
      yield {
        from: i,
        to: i.plus({ minute: step }),
        interval,
        id: uuid.generate(),
      }
    }
    return iterationCount
  }

  return _(intervals)
    .flatMap(interval => Array.from(makeRangeIterator(interval)))
    .map(({ from, to, interval, id }) => {
      const label = `${from.setZone(timezone).toFormat('HH:mm')}-${to
        .setZone(timezone)
        .toFormat('HH:mm')}`

      return {
        id,
        startTime: from.toJSDate(),
        endTime: to.toJSDate(),
        label,
        key: label,
        occupancy: 0,
        appointments: [],
        disabled: false,
        visitType: interval.visitType,
        shopCapacity: interval.shopCapacity || 1,
      } as Slot
    })
    .groupBy(slot => slot.key)
    .map((group, key) => {
      // Combining capacity of overlaping slots
      const slot: Slot = _.reduce(group, (prev, cur) => ({
        ...prev,
        shopCapacity: prev.shopCapacity + cur.shopCapacity,
      }))!

      return slot
    })
    .sortBy(i => i.startTime)
    .value()
}

/**
 * fills in the booked appointments inside every availability slot
 */
const fillInOccupiedSlots = (
  appointments: AppointmentShort[],
  slots: Slot[],
  visitType: string
): Slot[] => {
  return slots.map(slot => {
    const occupancy = appointments.filter(
      app =>
        app.visitType === visitType &&
        app.startTime &&
        app.startTime >= slot.startTime &&
        app.startTime < slot.endTime
    )
    return { ...slot, occupancy: occupancy.length, appointments: occupancy }
  })
}

export const getWholeSchedule = (placeId: string, visitType?: string): Promise<SchedulerItem[]> => {
  let conditions: QueryConstraint[] = []
  if (visitType) conditions = [where('visitType', '==', visitType)]
  let q = query(collection(db, `queue/${placeId}/schedule/`), ...conditions)

  return getDocs(q).then((snapshot: QuerySnapshot<any>) =>
    snapshot.docs.map((doc: any) => readAs<SchedulerItem>(doc))
  )
}

export const wholeScheduleSubscribe = (
  placeId: string,
  handler: (apps: SchedulerItem[]) => void,
  visitType?: string
): (() => void) => {
  let conditions: QueryConstraint[] = []
  if (visitType) conditions = [where('visitType', '==', visitType)]
  let q = query(collection(db, `queue/${placeId}/schedule/`), ...conditions)

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs.map((doc: any) => readAs<SchedulerItem>(doc))
    handler(apps)
  })
}

export const applyScheduleChanges = (
  placeId: string,
  changes: ScheduleChanges,
  timezone?: string
) => {
  return Promise.all(changes.added.map(add => createScheduleItem(placeId, add, timezone)))
    .then(() => {
      Promise.all(
        Object.keys(changes.changed).map(id =>
          updateScheduleItem(placeId, id, changes.changed[id], timezone)
        )
      )
    })
    .then(() => Promise.all(changes.deleted.map(id => deleteScheduleItem(placeId, id))))
}

export const toIcal = (item: SchedulerItemRequest, timezone?: string): string => {
  console.log('toIcal', item)
  const icalDateFormat = "yyyyMMdd'T'HHmmss'Z'"
  const startDate = DateTime.fromJSDate(item.startDate as Date)
    .setZone('UTC')
    .toFormat(icalDateFormat)
  const endDate = DateTime.fromJSDate(item.endDate as Date)
    .setZone('UTC')
    .toFormat(icalDateFormat)
  const timeZoneStr = timezone ? `;TZID=${timezone}` : ''
  let iCalStr = `DTSTART${timeZoneStr}:${startDate}\nDTEND${timeZoneStr}:${endDate}\n`
  if (item.exDate) iCalStr += `EXDATE:${item.exDate}\n`
  if (item.rRule) {
    if (item.rRule.search('RRULE:') === -1) iCalStr += `RRULE:${item.rRule}\n`
    else iCalStr += `${item.rRule}\n`
  }
  console.log('iCalStr', iCalStr)
  return iCalStr
}

export const calculateIntervals = (
  items: SchedulerItem[],
  startOfDay: DateTime,
  endOfDay: DateTime,
  timezone: string,
  visitType: string
): SchedulerInterval[] => {
  return _(items)
    .filter(item => !visitType || !item.visitType || item.visitType === visitType)
    .flatMap((item: SchedulerItem) => {
      try {
        const vEvent = VEvent.fromICal(item.iCal)[0]
        const occures = vEvent.occursBetween(startOfDay, endOfDay)
        if (occures) {
          const startDate = DateTime.fromJSDate(item.startDate).setZone(timezone)
          const endDate = DateTime.fromJSDate(item.endDate).setZone(timezone)

          if (startDate >= endDate) return []

          const startTime = startOfDay.setZone(timezone).set({
            hour: startDate.hour,
            minute: startDate.minute,
            second: 0,
          })
          const endTime = startOfDay.setZone(timezone).set({
            hour: endDate.hour,
            minute: endDate.minute,
            second: 0,
          })
          const interval = {
            startTime,
            endTime,
            id: uuid.generate(),
            visitType: item.visitType,
            slotDurationMin: item.slotDurationMin,
            shopCapacity: item.shopCapacity,
          } as SchedulerInterval
          return [interval]
        }
        return []
      } catch (e) {
        console.error(`could not parse iCal ${item.iCal}, ${e}`)
        return []
      }
    })
    .value()
}

export const intervalsSubscribe = (
  date: DateTime,
  placeId: string,
  visitType: string,
  timezone: string,
  handler: (apps: SchedulerInterval[]) => void
): (() => void) => {
  const startOfDay = date.startOf('day')
  const endOfDay = date.endOf('day')
  const q = query(
    collection(db, `queue/${placeId}/schedule/`),
    where('startDate', '<=', endOfDay.toJSDate())
  )
  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let items = snapshot.docs.map((doc: any) => readAs<SchedulerItem>(doc))

    handler(calculateIntervals(items, startOfDay, endOfDay, timezone, visitType))
  })
}

export const createScheduleItem = (
  placeId: string,
  item: SchedulerItemRequest,
  timezone?: string
): Promise<void> => {
  ;(item.startDate as Date).setSeconds(0) //seconds sometimes set to random number
  ;(item.endDate as Date).setSeconds(0)
  const schedulerItem = {
    ...item,
    allDay: false, // not supporting allDay
    iCal: toIcal(item, timezone),
  }
  if (schedulerItem.id) {
    const ref = doc(db, `queue/${placeId}/schedule/${schedulerItem.id}`)
    return setDoc(ref, schedulerItem).then(() => undefined)
  } else {
    return addDoc(collection(db, `queue/${placeId}/schedule`), schedulerItem).then(() => undefined)
  }
}

export const updateScheduleItem = (
  placeId: string,
  itemId: string,
  update: any,
  timezone?: string
): Promise<void> => {
  return runTransaction(db, async t => {
    const ref = doc(db, `queue/${placeId}/schedule/${itemId}`)
    const d = await getDoc(ref)
    // const doc = await t.get(ref);
    if (d.exists()) {
      let item = readAs<SchedulerItem>(d)
      item = { ...item, ...update, id: undefined }
      item = { ...item, iCal: toIcal(item, timezone) }
      t.set(ref, item, { merge: true })
    }
  }).then(() => undefined)
}

export const deleteScheduleItem = (placeId: string, itemId: string): Promise<void> => {
  return deleteDoc(doc(db, `queue/${placeId}/schedule/${itemId}`)).then(() => undefined)
}

export const getScheduleItem = (placeId: string, itemId: string): Promise<SchedulerItem> => {
  return getDoc(doc(db, `queue/${placeId}/schedule/${itemId}`)).then(doc =>
    readAs<SchedulerItem>(doc)
  )
}
