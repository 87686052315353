import React from 'react'
import { Container as MaterialContainer } from '@mui/material/'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 126px)',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
}))

const Container: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    // @TODO: fix children
    <MaterialContainer className={classes.root}>{children ?? ''}</MaterialContainer>
  )
}

export default Container
