export default {
  'weekdays.0': 'Sonntag',
  'weekdays.1': 'Montag',
  'weekdays.2': 'Dienstag',
  'weekdays.3': 'Mittwoch',
  'weekdays.4': 'Donnerstag',
  'weekdays.5': 'Freitag',
  'weekdays.6': 'Samstag',

  'wd.1': 'Mo',
  'wd.2': 'Di',
  'wd.3': 'Mi',
  'wd.4': 'Do',
  'wd.5': 'Fr',
  'wd.6': 'Sa',
  'wd.7': 'So',
  'wd.0': 'So',

  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Ukrainisch',
  'tr-TRLang': 'Türkçe',
  EUR: 'Euro',
  USD: 'US Dollar',
  GBP: 'Britischer Pfund',
  INR: 'Indische Rupie',
  UAH: 'Ukrainische Griwna',
  JPY: 'Japanische Yen',
  BTC: 'BTC',
  TRY: 'Türkische Lire',
  Rand: 'Südafrikanischer Rand',
  generalError: 'Ein unerwarteter Fehler ist aufgetreten',
  error: '{error}',
  enabled: 'aktiviert',
  disabled: 'deaktiviert',
  appointments: 'Termine',
  appointment: 'Termin',
  livequeues: 'Warteschlangen',
  livequeue: 'Warteschlange',
  info: 'Info',
  ok: 'OK',
  today: 'Heute',
  delete: 'Löschen',
  cancel: 'Abbrechen',
  create: 'Erstellen',
  upgrade: 'Upgrade',
  update: 'Aktualisieren',
  invite: 'Einladen',
  queueIn: 'zu Warteschlange',
  edit: 'Bearbeiten',
  sure: 'Sind Sie sicher?',
  yes: 'Ja',
  no: 'Nein',
  go: 'Go',
  add: 'Hinzufügen',
  save: 'Speichern',
  next: 'Weiter',
  leave: 'Verlassen',
  yourAppointments: 'Ihre Termine',
  yourAppointment: 'Ihr Termin',
  address: 'Adresse',
  copyToClipboard: 'Kopieren',
  copiedToClipboard: 'Text kopiert',
  showQRCode: 'QR-Code anzeigen',
  showPDF: 'PDF anzeigen',
  showNumberTickets: 'Anzahl der Tickets',
  clipboardCopyError: 'Fehler während Kopiervorgang',
  imageUploadFailed: 'Hochladen fehlgeschlagen',
  uploadLogo: 'Logo hochladen',
  thankYou: 'Danke',

  // Not Found Page
  'notfoundpage.header': 'Seite nicht gefunden',
  'notfoundpage.message':
    'Die angegebene Datei wurde auf dieser Website nicht gefunden. Bitte überprüfen Sie die URL auf Fehler und versuchen Sie es erneut.',

  // Reset Password
  'resetPassword.resetPassword': 'Passwort zurücksetzen',
  'resetPassword.currentPassword': 'Aktuelles Passwort',
  'resetPassword.changePassword': 'Passwort ändern',
  'resetPassword.currentPasswordWrong': 'Das aktuelle Passwort ist nicht korrekt',
  'resetPassword.newPassword': 'Neues Passwort',
  'resetPassword.confirmNewPassword': 'Bestätigen Sie das neue Passwort',
  'resetPassword.linkNotValid':
    'Ihr Link zum Zurücksetzen ist ungültig. Bitte senden Sie Ihre Anfrage erneut',
  'resetPassword.dontMatch': 'Passwörter stimmen nicht überein',
  'resetPassword.passwordChanged': 'Passwort geändert',
  'resetPassword.signNewPassword': 'Sie können sich jetzt mit Ihrem neuen Passwort anmelden',
  'resetPassword.errorEncountered': 'Fehler aufgetreten',
  'resetPassword.modeIsInvalid': 'Der ausgewählte Seitenmodus ist ungültig',
  'resetPassword.logout': 'Abmeldung',
  'resetPassword.logout.title': 'Ihr Passwort wurde geändert',
  'resetPassword.logout.descritption': 'Bitte melden Sie sich erneut bei Ihrem Konto an',

  // Update Page
  'rolledOutUpdate.reload': 'Seite neu laden',
  'rolledOutUpdate.header': 'Das Update ist da!',
  'rolledOutUpdate.message': 'Ein neues Update wurde aufgespielt. Bitte laden Sie die Seite neu!',

  //*************** VISITOR *******************/

  'dialogs.pageIsForMobile':
    'Sie haben diese Seite mit Ihrem Desktop Browser geöffnet. ' +
    'Bitte öffnen Sie die Seite in dem Browser auf Ihrem Smartphone, ansonsten werden Ihnen Ihre gebuchten Termine' +
    ' auf Ihrem Smartphone nicht zur Verfügung stehen.',
  'dialogs.pageIsForMobileTitle': 'Verwenden Sie Ihr mobiles Gerät',
  'dialogs.messagemodal.walkin': 'Warteschlange',
  'dialogs.whatsapp.text':
    'Wir empfehlen Ihnen, sich mit WhatsApp-Benachrichtigungen zu verbinden, wenn Sie wissen möchten, wie viele Leute vor Ihnen warten oder wie lange Ihre Wartezeit sein wird. Klicken Sie auf die Schaltfläche "Verbinden", um WhatsApp zu öffnen und uns eine Nachricht zu senden, die wir vorbereitet haben, ohne sie zu ändern.',
  'dialogs.whatsapp.connect': 'Verbinden',
  'dialogs.whatsapp.urlText':
    'Ich%20möchte%20Benachrichtigungen%20mit%20Informationen%20über%20Statusänderungen%20meines%20Tickets%20erhalten',

  // Main
  'home.queues': 'Ihre Warteschlangen',
  'home.queue.showCode': 'Zeigen Sie diese Nummer auf Anfrage',
  'home.queue.peopleAhead': 'Menschen vor Ihnen',
  'home.placesNearby': 'Orte in der Nähe',
  'home.noPlacenear': 'Es gibt leider keinen registrierten Ort in Ihrer Nähe',
  'home.enableGeoLocation':
    'Sie haben die geografische Position deaktiviert. Bitte ändern Sie die Browsereinstellungen, um diese Funktion zu verwenden',
  'home.useToBook': 'Verwenden Sie die Suche oder den QR-Code-Scanner, um einen Platz zu buchen',
  'home.findNearby': 'Platz in der Nähe finden',
  'home.bookASpot': 'Um einen Termin vereinbaren oder sich anzustellen',
  'home.scanQR': 'scannen Sie den QR-Code',
  'home.enterShortUrl': 'oder den kurzen Code des Ortes eingeben',
  'home.enterShortcode': 'Geben Sie den Shortcode ein',
  'home.selectNearby': 'oder wählen Sie einfach aus Orten in der Nähe',
  'home.loginAsAdmin': 'Melden Sie sich als Ortsadministrator an',

  //LiveQueueTab
  'messaging.permissionGranted': 'Benachrichtigungen können empfangen werden',
  'messaging.permissionNotGranted':
    'Die App muss im Vordergrund bleiben, damit Sie Benachrichtigungen erhalten können, wenn die Warteschlange nach vorne rückt. ' +
    'Bitte erteilen Sie Ihre Zustimmung, um Benachrichtigungen zu bekommen auch wenn Ihr Browserfenster inaktiv ist.',
  'messaging.tokenError': 'Etwas ist schief gegangen',
  'messaging.webPushNotificationsNotSupported':
    'Web Push Notifications werden von Ihrem Browser leider nicht unterstützt. Sie werden keine Benachrichtigungen bekommen, ' +
    'wenn die Warteschlange nach vorne rückt. Achten Sie auf die Anzahl von Personen vor Ihnen in der Anzeige unten.',

  //AppointmentsPage
  'appointment.disabledButtonHint': 'Termin stornieren',
  'appointment.dayOff':
    'Das Geschäft ist an diesem Tag geschlossen. Bitte wählen Sie ein anderes Datum.',
  'appointment.visitorInformation': 'Ihre Informationen',
  'appointment.visitorInformation.numberOfVisitors': 'Anzahl der Besucher',
  'appointment.visitorInformation.numberOfVisitors.alert': 'Wie viele Personen kommen?',
  'appointment.visitorInformation.counter': 'Schalter',
  'appointment.visitorInformation.hint': 'Bitte geben Sie Kontaktinformationen an',
  'appointment.customInformation': 'Zusätzliche Informationen',
  'appointment.customInformation.hint': 'Bitte geben Sie zusätliche Informationen an',
  'appointment.visitorPhoneNumValidationStep': 'Telefonnummer Überprüfung',
  'appointment.visitorPhoneNumValidationStep.hint':
    'Wir haben Ihnen einen Code an {phone} gesendet. Bitte geben Sie es in die folgenden Felder ein. ',
  'appointment.waitToSendAgain': 'Code in {sec} Sekunden erneut senden',
  'appointment.sendAgain': 'Erneut senden',
  'appointment.visitorInformation.notRequired': 'Es sind keine Kontaktdaten erforderlich',
  'appointment.visitorReason': 'Termin auswählen',
  'appointment.visitorSelectDate': 'Datum auswählen',
  'appointment.services': 'Dienstleistungen',
  'appointment.order': 'Bestellung',
  'appointment.services.hint': 'Wählen Sie eine Dienstleistung aus, die Sie erhalten möchten',
  'appointment.order.hint':
    'Wählen Sie die Produkte aus, die Sie bestellen möchten. Bitte beachten Sie, dass es eine Grenze für die Anzahl der Artikel pro Bestellung geben kann.',
  'appointment.noMoreToday': 'Heute sind keine Termine verfügbar',
  'appointment.add': 'Termin vereinbaren',
  'appointment.cancelAppointment': 'Termin Absagen',
  'appointment.leaveQueue': 'Ticket Löschen',
  'appointment.next': 'Weiter: {step}',
  'appointment.next.contactInfo': 'Kontaktinformationen',
  'appointment.next.customFields': 'Zusätzliche Informationen',
  'appointment.next.timeslot': 'Zeitfenster',
  'appointment.next.messagingPrompt': 'Benachrichtigungen',
  'appointment.next.phoneNumValidation': 'Bestätigung der Telefonnummer',
  'appointment.next.book': 'Jetzt buchen',
  'appointment.next.whatsapp': 'WhatsApp-Benachrichtigung',
  'appointment.next.numberOfVisitors': 'Anzahl der Besucher',
  'appointment.not_vaccinated': 'Nicht geimpft',
  'appointment.partially_vaccinated': 'Teilweise geimpft',
  'appointment.fully_vaccinated': 'Vollständig geimpft',
  'appointment.next.queueIn': 'Schlange jetzt an',
  'appointment.next.services': 'Dienstleistungen',
  'appointment.next.products': 'Produkte',
  'appointment.next.submit': 'Einsenden',
  'appointment.submit.error': 'Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten',
  'appointment.submit.standby': 'Einen Moment bitte',
  'appointment.cancel': 'Abbrechen',
  'appointment.back': 'Zurück',
  'appointment.chooseTime': 'Wähle die Zeit',
  'appointment.messagingPrompt': 'Benachrichtigungen aktivieren',
  'appointment.submit': 'Anfrage einreichen',
  'appointment.makeAnother': 'Einen anderen Termin vereinbaren',
  'appointment.queueInAgain': 'Sich erneut anstellen',
  'appointment.openHours': 'Öffnungszeiten:',
  'appointment.contacts': 'Kontakte:',
  'appointment.description': 'Allgemeine Beschreibung:',
  'appointment.address': 'Adresse',
  'appointment.comment': 'Kommentar',
  'appointment.birthDate': 'Geburtsdatum',
  'appointment.visitType': 'Service',
  'appointment.shareName': 'Wie heißen Sie?',
  'appointment.sharePhone': 'Handynummer',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Suche',
  'appointment.sharePhone.searchNotFound': 'Keine Einträge zu zeigen',
  'appointment.sharePhone.inValid':
    'Die Rufnummer ist ungültig. Bitte versuchen Sie, Ihre Nummer im internationalen Format einzugeben: +1123456789',
  'appointment.shareEmail': 'E-mail',
  'appointment.shareAddress': 'Adresse',
  'appointment.shareBirthDate': 'Geburtsdatum',
  'appointment.vaccinationStatus': 'Covid-19 Impfstatus',
  'appointment.time': 'Datum und Uhrzeit',
  'appointment.invitedAt': 'Eingeladen am',
  'appointment.queuedAt': 'Angestellt am',
  'appointment.closedTime': 'Geschlossen. Öffnet am',
  'appointment.opensToday': 'Geschlossen. Öffnet heute um',
  'appointment.ticket': 'Ticket',
  'appointment.position': 'Vor Ihnen',
  'appointment.sure': 'Sind Sie sicher?',
  'appointment.queueLength': 'In der Warteschlange',
  'appointment.eta': 'Wartezeit',
  'appointment.nickname': 'Wie heißen Sie? (optional)',
  'appointment.group': 'Wie viele Personen kommen?',
  'appointment.pleaseProceed': 'Bitte gehen Sie zum Schalter.',
  'appointment.header': 'Ihre Tickets',
  'appointment.estimatedWaitingTime': 'Geschätzte Wartezeit',
  'appointment.waitingTime': 'Wartezeit',
  'appointment.min': 'min.',
  'appointment.people': 'Personen',
  'appointment.error.invalidFormFields': 'Bitte füllen Sie fehlende Informationen im Formular aus',
  'appointment.error.invalidVisitType': 'Bitte geben Sie einen Grund Ihres Besuchs an',
  'appointment.error.noSlotSelected': 'Keine Uhrzeit ausgewählt',
  'appointment.confirmation.preliminaryWarning':
    'Diese Buchung ist vorläufig, bis Sie ein Bestätigungsschreiben erhalten.',
  'appointment.confirmation.rejectedWarningAppointment':
    'Es tut uns leid, aber der Administrator hat Ihren Termin abgelehnt. Versuchen Sie, einen anderen zu buchen.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'Es tut uns leid, aber der Administrator hat Ihr Ticket abgelehnt. Versuchen Sie, sich erneut anzustellen.',
  'appointment.products.error.maxOrder':
    'Die Bestellung kann nur bis zu {quantity} Produkte enthalten. Bitte reduzieren Sie die Gesamtmenge entsprechend.',
  'appointment.products.price': 'Preis',
  'appointment.products.inStock': 'Vorrätig',
  'appointment.products.outofstock': 'Nicht vorrätig',
  'appointment.confirmation.appointmentHasPreference':
    'Bitte beachten Sie, dass die im Voraus gebuchten Termine möglicherweise Priorität über Warteschlange haben können',

  'appointment.disabled':
    'Der Administrator hat das Terminierungssystem für diesen Ort deaktiviert',

  'appointment.messaging.pending':
    'To send you push notifications about your appointment we need you to enable it in your browser. ' +
    'Click <b>allow</b> if you agree.',
  'appointment.messaging.denied':
    'Die Berechtigung zum Senden von Benachrichtigungen wurde nicht aktiviert. Sie werden keine Push-Benachrichtigungen erhalten',
  'appointment.messaging.granted': 'Permission to send push noification granted. Thank you!',

  //LiveQueuetab
  'livequeue.peopleNum': 'Anzahl der Menschen',
  'livequeue.yourNumberInQueue': 'Ihre Position in der Schlange',
  'livequeue.youAreNext': 'Sie sind als nächstes dran',
  'livequeue.leave': 'Warteschlange verlassen',
  'livequeue.showAtRequest': 'Zeigen Sie dieses Ticket auf Aufforderung vor',
  'livequeue.submit': 'Jetzt einreihen',
  'livequeue.liveQueuePaused':
    'Der Administrator der Website hat die digitale Warteschlange vorübergehend angehalten. Bitte versuchen Sie es später noch einmal.',
  'livequeue.liveQueueOpenHours':
    'Sie versuchen, sich außerhalb der Öffnungszeiten in die Warteschlange einzureihen. Bitte kommen Sie später wieder',
  'livequeue.disabled':
    'Das digitale Warteschlangensystem für diesen Ort wurde von dem Administrator deaktiviert',
  'livequeue.queueTooLong':
    'Der Administrator hat die Anzahl der Personen in der Warteschlange begrenzt. Bitte versuchen Sie später erneut',

  //MonitorView
  'monitor.pleaseProceed': 'Bitte gehen Sie zum Schalter',
  'monitor.livequeue': 'Warteschlange',
  'monitor.appointments': 'Geplante Termine.',
  'monitor.appointment.type.appointment': 'Termin',
  'monitor.appointment.type.livequeue': 'Warteschlange',
  'monitor.stats': 'Auslastung heute',
  'monitor.peopleInQueue': 'Mehr Tickets',
  'monitor.pleaseScanAppointments': 'Scannen Sie diesen QR-Code, um einen Termin zu vereinbaren',
  'monitor.pleaseScanLivequeue': 'Scannen Sie diesen QR-Code, um sich digital anzustellen',
  'monitor.pleaseScanIntegrated':
    'Scannen Sie diesen QR-Code, um einen Termin zu vereinbaren oder sich digital anzustellen',
  'monitor.followThisLink': 'Oder folgen Sie einfach dieser URL',

  // Notifications
  'notifications.title': 'SMS-Benachrichtigungen',
  'notifications.informationBanner.sms': 'Gesendete SMS',
  'notifications.informationBanner.whatsapp': 'Whatsapp gesendet',
  'notifications.progressBar.freeSms': 'Noch im freien Kontingent vorhanden',
  'notifications.progressBar.exhaustedSms': 'Gratis-SMS-Pool erschöpft',
  'notifications.progressBar.exhaustedWhatsapp': 'Kostenloser Whatsapp-Pool erschöpft',
  'notifications.informationBanner.totalSmsCost': 'SMS-Kosten insgesamt',
  'notifications.informationBanner.totalWhatsappCost': 'Gesamtkosten für Whatsapp',
  'notifications.informationBanner.totalCost': 'Gesamtkosten',
  'notifications.table.recipient': 'Empfänger',
  'notifications.table.date': 'Datum',
  'notifications.table.time': 'Zeit',
  'notifications.table.place': 'Ort',
  'notifications.table.type': 'Typ',
  'notifications.table.price': 'Preis',
  'notifications.table.country': 'Land',
  'notifications.table.message': 'Nachricht',
  'notifications.empty.header':
    'Ihre Orte haben in der angegebenen Zeitperiode noch keine bezahlten Benachrichtigungen erzeugt',
  'notifications.filter.startDate': 'Startdatum',
  'notifications.filter.endDate': 'Enddatum',
  'notifications.exportTable.status': 'Status',
  'notifications.exportTable.type': 'Typ',
  'notifications.exportTable.isEmpty': 'ist leer',

  // Export Appointments
  'exportAppointments.tickets': 'Tickets',
  'exportAppointments.tag': 'Tag',
  'exportAppointments.date': 'Datum',
  'exportAppointments.time': 'Zeit',
  'exportAppointments.service': 'Dienst',
  'exportAppointments.name': 'Name',
  'exportAppointments.status': 'Status',
  'exportAppointments.statusChangeDate': 'Datum der Statusänderung',
  'exportAppointments.statusChangeTime': 'Zeit der Statusänderung',
  'exportAppointments.type': 'Typ',
  'exportAppointments.phone': 'Telefon',
  'exportAppointments.email': 'E-Mail',
  'exportAppointments.address': 'Adresse',
  'exportAppointments.birthDate': 'Geburtsdatum',
  'exportAppointments.comments': 'Kommentare',
  'exportAppointments.appointment': 'Ernennung',
  'exportAppointments.queue': 'Warteschlange',

  //Cookies Concent
  'cookies.concent':
    'Diese Webseite verwendet ausschließlich technisch notwendige Cookies, die nicht abgewählt werden können. Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriffe auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren. ',
  'cookies.ok': 'Verstanden',

  //*************** ADMIN *******************/

  trial: 'Testversion starten',

  'dialogs.pageIsForDesktop':
    'Sie haben diese Seite mit Ihrem mobilen Browser geöffnet. ' +
    'Diese Seite ist für einen Desktop Rechner optimiert. Einige Funktionen können Ihnen nicht zur Verfügung stehen und ' +
    'die Seite wird möglicherweise nicht ordnungsgemäß anzegeigt.',
  'dialogs.pageIsForDesktopTitle': 'Desktop Ansicht',
  'dialogs.reject': 'Ablehnen',
  'dialogs.confirm': 'Bestätigen',

  // SignUp
  'signUp.referralCode': 'Geben Sie einen Empfehlungscode ein, wenn Sie einen haben',

  // Login
  'login.header': 'Danke, dass Sie sich für 2Meters entschieden haben!',
  'login.header2': 'Wir haben unseren Service entwickelt um Ihnen dabei zu helfen',
  'login.text1':
    'ein komfortables online Terminbuchungssystem einzurichten, das Ihre Kunden lieben werden',
  'login.text2':
    'einen innovativen, digitalen Warteraum einzurichten, der sämtliche Warteschlangen in ihren Räumen überflüssig macht und Ihnen dabei hilft Bestandskunden zu halten und Neukunden zu gewinnen',
  'login.text3':
    'oder einfach beides zu machen, denn unser Service integriert den digitalen Warteraum für Laufkundschaft nahtlos mit dem Terminplan und füllt Lücken. Wartende Kunden können sich immer für einen späteren Termin eintragen, sollte die Wartezeit zu lang sein.',
  'login.google': 'Login mit Google',
  'login.facebook': 'Login mit Facebook',
  'login.withPassword': 'Einloggen mit E-mail und Passwort',
  'login.newUser': 'Ein Konto bei 2Meters erstellen',
  'login.existingUser': 'Ich habe bereits einen Account',
  'login.signUp': 'Registrieren',
  'login.signIn': 'Einloggen',
  'login.email': 'E-mail',
  'login.email.placeholder': 'max.mustermann@gmail.com',
  'login.password': 'Passwort',
  'login.password.placeholder': '8+ Zeichen',
  'login.passwordRepeat': 'Passwort wiederholen',
  'login.passwordRepeat.placeholder': '8+ Zeichen',
  'login.forgotPassword': 'Passwort vergessen?',
  'login.resetPassword': 'Passwort zurücksetzen',
  'login.passwordsDontMatch': 'Passwörter stimmen nicht überein',
  'login.sendRecoveryEmail':
    'Wir senden Ihnen eine E-Mail mit einem Link zum Ändern Ihres Passwortes. Bitte sagen Sie uns mit welcher E-Mail sie sich registriert haben.',
  'login.checkEmail': 'Bitte überprüfen Sie Ihr Postfach und folgen Sie den Anweisungen',
  'login.send': 'Senden',
  'login.recoveryEmailSent': 'Wiederherstellungs-Mail wurde an {email} versendet',
  'login.passwordTooShort': 'Das Passwort sollte mindestens 8 Zeichen lang sein',
  'login.missingTermsConsent.title': 'Nur noch ein Schritt',
  'login.missingTermsConsent.desc':
    'Bitte akzeptieren Sie unsere Geschäftsbedingungen, um unseren Service nutzen zu können. Vielen Dank!',

  'customerProfileForm.firstName': 'Vorname',
  'customerProfileForm.lastName': 'Nachname',
  'customerProfileForm.email': 'E-Mail',
  'customerProfileForm.organization': 'Name der Organisation',
  'customerProfileForm.country': 'Land',
  'customerProfileForm.streetAddress': 'Straße und Hausnummer',
  'customerProfileForm.postalCode': 'Postleitzahl',
  'customerProfileForm.city': 'Stadt',
  'customerProfileForm.vatId': 'USt Id',
  'customerProfileForm.referralCode': 'Empfehlungscode',
  'customerProfileForm.placeholder.firstName': 'Bitte Vornamen eingeben',
  'customerProfileForm.placeholder.lastName': 'Bitte Nachname eingeben',
  'customerProfileForm.placeholder.email': 'Bitte E-Mail eingeben',
  'customerProfileForm.placeholder.organization': 'Bitte geben Sie den Namen der Organisation ein',
  'customerProfileForm.placeholder.country': 'Bitte Land eingeben',
  'customerProfileForm.placeholder.streetAddress': 'Bitte geben Sie Ihre Adresse ein',
  'customerProfileForm.placeholder.postalCode': 'Bitte Postleitzahl eingeben',
  'customerProfileForm.placeholder.city': 'Bitte Stadt eingeben',
  'customerProfileForm.placeholder.vatId': 'Bitte Umsatzsteuer-ID eingeben',
  'customerProfileForm.placeholder.referralCode': 'Bitte Empfehlungscode eingeben',
  'customerProfileForm.industry': 'Industrie',
  'customerProfileForm.industry.governmental': 'Staatliche',
  'customerProfileForm.industry.education': 'Bildung',
  'customerProfileForm.industry.healthcare': 'Gesundheitswesen',
  'customerProfileForm.industry.retail': 'Einzelhandel',
  'customerProfileForm.industry.wholesale': 'Großhandel',
  'customerProfileForm.industry.rental': 'Vermietung',
  'customerProfileForm.industry.banking': 'Bankwesen/Finanzwesen',
  'customerProfileForm.industry.transportation': 'Transport',
  'customerProfileForm.industry.hospitality': 'Gastfreundschaft',
  'customerProfileForm.industry.hotel': 'Hotel und Freizeiteinrichtungen',
  'customerProfileForm.industry.travel': 'Reisen und Tourismus',
  'customerProfileForm.industry.other': 'Andere',

  'login.dontHaveAccount': 'Sie haben noch kein Konto?',
  'login.alreadyHaveAccount': 'Sie haben schon ein Konto?',
  'login.enterDetails': 'Geben Sie Ihre Login-Daten ein',
  'login.alreadyRegistered': 'Einloggen',
  'login.getStarted': 'Loslegen',
  'login.acceptTerms': 'Ich akzeptiere',
  'login.termsAndConditions': 'die Geschäftsbedingungen',
  'login.termsNotChecked':
    'Bitte akzeptieren Sie unsere Geschäftzbedingungen und Datenschutzerklärung um fortzufahren',
  'login.acceptPrivacyPolicy': 'Ich akzeptiere',
  'login.privacyPolicy': 'die Datenschutzerklärung',
  'login.privacyPolicyNotChecked':
    'Bitte akzeptieren Sie unsere Datenschutzerklärung um fortzufahren',

  // Onboarding
  'onboarding.wait':
    'Bitte warten Sie, während wir Ihr Benutzerprofil erstellen und alles Notwendige einrichten',
  'onboarding.createPlace.wait': 'Bitte halten Sie sich bereit, während wir laden...',
  'onboarding.loading': 'Laden...',
  'onboarding.back': 'Zurück',
  'onboarding.next': 'Nächste',
  'onboarding.skip': 'Zum Leerzeichen springen',
  // Onboarding: Step 1
  'onboarding.step1.header': 'Terminbuchungen mit 2Meters',
  'onboarding.step1.text.p1':
    'Um das Terminbuchungssystem zu aktivieren, erstellen Sie einen Ort und geben Sie die ein Paar Daten ein',
  'onboarding.step1.text.list1.item1': 'Name und Location',
  'onboarding.step1.text.list1.item2': 'Öffnungszeiten und Tage',
  'onboarding.step1.text.list1.item3': 'Übliche Termindauern',
  'onboarding.step1.text.p2':
    'Um noch besseren Service zu bieten, können Sie eine Liste von Dienstleistungen eingeben. Kunden können so bereits bei Buchung den Grund ihres Besuchs auswählen, so dass Sie sich besser vorbereiten können.',
  'onboarding.step1.text.p3':
    'Unser Bestätigungsfeature erlaubt Ihnen, falls eingeschaltet, Kundenanfragen zu bestätigen oder abzulehnen, bevor diese eine Terminzusage erhalten. So haben Sie immer volle Kontrolle über Ihren Terminplan.',
  // Onboarding: Step 2
  'onboarding.step2.header': 'Terminbuchungen mit 2Meters',
  'onboarding.step2.text.p1':
    'Nach dem die Einrichtung abgeschlossen ist, kopieren Sie einfach den generierten Link in eine E-Mail oder auf Ihre Website damit Ihre Kunden Zugriff auf das System erhalten.',
  'onboarding.step2.text.p2':
    'Sind Sie ein Profi? Dann bauen Sie unser interaktives Interface über einen Codeschnipsel in Ihre Website ein.',
  // Onboarding: Step 3
  'onboarding.step3.header': 'Terminbuchungen mit 2Meters',
  'onboarding.step3.text.p1':
    'Heißen Sie nun Ihren ersten Terminkunden willkommen! Jeder Kunde erhält nach erfolgreicher Terminbuchung ein digitales Ticket auf sein Mobiltelefon. Falls nötig, können Sie sich dieses Ticket vorzeigen lassen.',
  'onboarding.step3.text.p2':
    'Sollten Sie zusätzlich zu Terminkunden auch noch Laufkundschaft bedienen wollen, wird es Sie freuen zu hören, dass unser digitaler Warteraum beide Kundenströme nahtlos verschmilzt. Aber wie genau funktioniert das?',
  // Onboarding: Step 4
  'onboarding.step4.header': 'Digitaler Warteraum mit 2Meters',
  'onboarding.step4.text.p1':
    'Unser digitaler Warteraum bietet genau das, was Kunden von einem modernen Servicekonzept erwarten. Das Warten erledigen die Smartphones, während ihre Besitzer einen Spaziergang machen, einen Kaffee trinken oder andere Einkäufe erledigen.',
  'onboarding.step4.text.p2':
    'Wir benachrichtigen Ihre Kunden über den Fortschritt so dass sie rechtzeitig vor Ort sind um bedient zu werden.',
  'onboarding.step4.text.p3':
    'Schalten Sie den digitalen Warteraum einfach ein, wenn Sie ihren Ort konfigurieren. Sie können ihn jederzeit abschalten.',
  // Onboarding: Step 5
  'onboarding.step5.header': 'Digitaler Warteraum mit 2Meters',
  'onboarding.step5.text.p1':
    'Drucken Sie den automatisch generierten QR Code aus und platzieren Sie ihn gut sichtbar dort wo Sie ihren Kunden das digitale Warten ermöglichen wollen.',
  'onboarding.step5.text.p2':
    'Stellen Sie auf Wunsch einen Bildschirm auf um Ihren Kunden anzuzeigen wer gerade bedient wird, wer als nächster dran ist und wie viele Leute warten. Auch hier wird der QR Code angezeigt.',
  // Onboarding: Step 6
  'onboarding.step6.header': 'Digitaler Warteraum mit 2Meters',
  'onboarding.step6.text.p1':
    'Es gibt eine Fülle von weiteren Features zu entdecken. Schreiben Sie uns, falls Sie mehr darüber wissen wollen, wie Sie 2Meters optimal einstellen. Wir haben für Sie einige vorkonfigurierte Vorlagen erstellt. Wählen Sie eine davon aus und passen Sie sie an oder erstellen Sie einen ganz neuen Ort. Was möchten Sie tun?',
  'onboarding.step6.btn1': 'Beispielort mit digitaler Warteschlange erstellen (Friseursalon)',
  'onboarding.step6.btn2':
    'Beispielort mit digitalem Terminbuchungssystem erstellen (Friseursalon)',
  'onboarding.step6.btn3': 'Terminbuchungsystem oder virtuellen Warteraum erstellen',
  'onboarding.template.barbershop.name': 'Demo Ort (Barber shop)',
  'onboarding.template.barbershop.description':
    'Bart ist Trend. Richtig schön wird das Haar im Gesicht aber erst durch eine gute Pflege und ein perfektes Styling. Wir eröffnen einen Ort, an dem Männer sich entspannen können und unter sich sind. Sie erwartet eine individuelle Beratung zum Thema Haar-/ Bartpflege und -styling aber auch gepflegte Hände & Füße gehören dazu! In klassischer Atmosphäre – zwischen stylischen Ledersesseln, Holzassecoires, Backsteinwänden und einer integrierten Whisky- und Zigarrenlounge – wird gefeilt, gepeelt und rasiert.',
  'onboarding.template.barbershop.service1': 'Rasur',
  'onboarding.template.barbershop.service2': 'Bartschnitt',
  'onboarding.template.barbershop.service3': 'Farbe',
  'onboarding.template.barbershop.service4': 'Konturenschnitt',
  'onboarding.template.default.service1': 'Meine Dienstleistung 1',
  'onboarding.template.default.service2': 'Meine Dienstleistung 2',
  'onboarding.template.covid.name': 'Covid Test Zentrum (Vorlage)',
  'onboarding.template.popup.name': 'PopUp Laden (Vorlage)',
  'onboarding.template.shopping.name': 'Terminshopping (Vorlage)',
  'onboarding.template.restaraunt.name': 'Restaurant (Vorlage)',

  // Place
  'admin.place.livequeueUrlInfo': 'Link zu Live-Warteschlange',
  'admin.place.settings': 'Einstellungen',
  'admin.place.navigation.overview': 'Übersicht',
  'admin.place.editSetting': 'Ort bearbeiten',
  'admin.place.editManagers': 'Manager bearbeiten',
  'admin.place.navigation.dashboard': 'Dashboard',
  'admin.place.navigation.queues': 'Schalteransicht',
  'admin.place.navigation.monitor': 'Monitoransicht',
  'admin.place.navigation.billing': 'Abrechnung',
  'admin.place.navigation.backToPlaces': 'Zurück zu den Orten',
  'admin.place.navigation.back': 'Zurück',
  'admin.place.businessName': 'Geben Sie Ihrem Ort einen Namen',
  'admin.place.averageDuration': 'Durchschnittliche Dauer (Minuten)',
  'admin.place.capacity.help': 'Wie viele Kunden können zur gleichen Zeit ihren Ort betreten?',
  'admin.place.capacity': 'Kapazität',
  'admin.place.openingTime': 'Öffnet um',
  'admin.place.closingTime': 'Schließt um',
  'admin.place.openingDays': 'Offen an folgenden Wochentagen',
  'admin.place.openingDate': 'Beginn der Kampagne',
  'admin.place.openingDate.help':
    'Sollten Sie 2Meters nur für eine begrenzte Zeit nutzen wollen, Geben Sie hier das Datum an, an dem das System aktiviert werden soll',
  'admin.place.closingDate': 'Ende der Kampagne',
  'admin.place.closingDate.help':
    'Sollten Sie 2Meters nur für eine begrenzte Zeit nutzen wollen, Geben Sie hier das Datum an, an dem das System aktiviert werden soll',
  'admin.place.contactEmail': 'Ihre E-Mail für Kontakt mit Besuchern',
  'admin.place.contactEmail.help':
    'Sie können dieses Feld ausfüllen, wenn Sie wollen, dass Ihre E-Mail-Adresse in den E-Mail-Benachrichtigungen angezeigt wird, die wir an Ihre Besucher verschicken. (Nur wenn Sie entscheiden die E-Mail-Adressen Ihrer Besucher abzufragen)',
  'admin.place.contactPhoneNumber': 'Ihre Telefonnummer für Kontakt mit Besuchern',
  'admin.place.contactPhoneNumber.help':
    'Sie können dieses Feld ausfüllen, wenn Sie wollen, dass Ihre Telefonnummer in den E-Mail-Benachrichtigungen angezeigt wird, die wir an Ihre Besucher verschicken. (Nur wenn Sie entscheiden die E-Mail-Adressen Ihrer Besucher abzufragen)',
  'admin.place.description': 'Beschreibung',
  'admin.place.description.help': 'Diese Beschreibung werden Ihre Kunden sehen',
  'admin.place.timezone': 'Zeitzone',
  'admin.place.noOptions': 'Keine Optionen',
  'admin.place.toVisitors': 'zum Besucherbereich',
  'admin.place.printQR': 'QR-Code anzeigen',
  'admin.place.address': 'Ihre Geschäftsadresse',
  'admin.place.appointments.delete': 'Stornieren',
  'admin.place.appointments.cancel': 'Abbrechen',
  'admin.place.addAppointmentDialogTitle': 'Termin hinzufügen',
  'admin.place.addAppointmentDialogDescription':
    'Sie können auch den Namen eines Besuchers notieren sowie zusätzliche Informationen zum Termin eingeben.',
  'admin.place.appointmentNickname': 'Name',
  'admin.place.appointmentDescription':
    'Beschreiben Sie kurz Ihren Ort und die Dienstleistungen die Sie anbieten',
  'admin.place.visitType': 'Stellen Sie eine Liste der von Ihnen angebotenen Dienste bereit',
  'admin.place.visitType.help':
    'Ihre Kunden müssen den Service bei der Buchung eines Termins aus dieser Liste auswählen.',
  'admin.place.managers': 'Liste der Administratoren',
  'admin.place.managers.help':
    'Geben Sie hier die E-Maild der Angestellten an, die Sie zu Administratoren machen wollen. Bestätigen Sie jede E-Mail mit Enter.',
  'admin.place.titles.settings': 'Übersicht',
  'admin.place.wizard.titles.details': 'Übersicht Ihres Ortes',
  'admin.place.wizard.titles.visitorData': 'Besucher',
  'admin.place.wizard.titles.inventory': 'Ihr Inventar',
  'admin.place.wizard.titles.queue': 'Warteschlange',
  'admin.place.wizard.titles.appointments': 'Zeitplanverwaltung',
  'admin.place.wizard.titles.access': 'Zugriff',
  'admin.place.wizard.titles.plans': 'Wählen Sie Ihren Plan',
  'admin.place.wizard.details': 'Einzelheiten',
  'admin.place.wizard.visitorData': 'Besucher',
  'admin.place.wizard.inventory': 'Vorräte',
  'admin.place.wizard.settings': 'Einstellungen',
  'admin.place.wizard.schedule': 'Zeitplan',
  'admin.place.wizard.access': 'Zugriff',
  'admin.place.wizard.plans': 'Abbonements',
  'admin.place.wizard.actions.next': 'Weiter',
  'admin.place.wizard.actions.trial': 'Start Trial',
  'admin.place.wizard.actions.finish': 'Abschließen',
  'admin.place.wizard.actions.saveAll': 'Speichern',
  'admin.place.wizard.actions.back': 'Zurück',
  'admin.place.wizard.actions.cancel': 'Abbrechen',
  'admin.place.wizard.actions.agree': 'Zustimmen',
  'admin.place.wizard.actions.create': 'Erstellen',
  'admin.place.wizard.whatsappStep.title':
    'Sind Sie damit einverstanden, Benachrichtigungen über WhatsApp zu erhalten?',
  'admin.place.wizard.whatsappStep.accept': 'Akzeptieren',
  'admin.place.wizard.whatsAppModal.title': 'WhatsApp-Benachrichtigungen',
  'admin.place.wizard.whatsAppModal.description':
    'Bitte beachten Sie, dass bei der Aktivierung von WhatsApp-Benachrichtigungen zusätzliche Kosten anfallen. Wir gewähren Ihnen 100 kostenlose WhatsApp-Nachrichten, die Sie zu Testzwecken verwenden können. Für jede darüber hinaus versendete WhatsApp-Nachricht berechnen wir Ihnen jedoch einen Preis, der sich nach dem Land richtet, aus dem Ihre WhatsApp-Empfänger kommen. Wir erstellen zusätzliche Rechnungen und senden Ihnen diese jeden Monat separat zu. Sie können diese Funktion jederzeit abschalten. Wenn Sie detaillierte Informationen zu den Preisen benötigen, wenden Sie sich bitte an unsere Vertriebsabteilung unter ',
  'admin.place.wizard.requiredFields': 'Bitte füllen Sie dort die entsprechenden Felder aus',
  'admin.place.wizard.smsModal.title': 'SMS-Benachrichtigungen',
  'admin.place.wizard.smsModal.description':
    'Bitte beachten Sie, dass mit der Aktivierung von SMS-Benachrichtigungen zusätzliche Kosten anfallen. Wir gewähren Ihnen 100 kostenlose SMS, die Sie zu Testzwecken verwenden können. Für jede über diese Menge hinausgehende SMS berechnen wir Ihnen jedoch einen Preis, der sich nach den Preisen der lokalen Mobilfunkanbieter des Landes richtet, aus dem Ihre Besucher kommen. Wir erstellen zusätzliche Rechnung für diese Leistung und versenden sie jeden Monat separat. Sie können diese Funktion jederzeit abschalten. Wenn Sie detaillierte Informationen über die Preisgestaltung benötigen, wenden Sie sich bitte an unsere Vertriebsabteilung unter ',
  'admin.place.queue.text':
    'Unser einzigartiges System ermöglicht es Ihren Kunden und Besuchern sich ganz einfach durch das Scannen eines QR-Codes oder durch das eingeben der Kurz-URL in ihrem Browserfenster anzustellen. Sie können die digitale Warteschlange dann effizient von der Kasse oder aus dem Geschäftsführerbüro verwalten. Dadurch wird ein schneller und zufriedenstellender Service für alle Kunden gewährleistet. Unser System verschmilzt die Warteschlange nahtlos mit Terminkunden - letztere werden einige Minuten vor ihrem anstehenden Termin einfach vorn in die Warteschlange eingereiht. Wenn Sie digitale Warteschlangen an Ihrem Ort benötigen, setzen Sie unten das Häckchen.',
  'admin.place.inventory.text':
    'Wenn Sie Artikel verkaufen und möchten, dass Ihre Besucher diese bestellen können, fügen Sie diese einfach zu Ihrem Inventar hinzu. Die Aktivierung des Inventars ändert die Art und Weise, wie Ihr Besucher mit unserer App interagiert. Es schafft einen zusätzlichen Schritt im Besucherteil der Anwendung, in dem ein Besucher jeden Ihrer Artikel buchen oder kaufen kann, während er sich in der Warteschlange anstellt oder einen Termin vereinbart. Wenn Sie Bestellungen Ihrer Kunden bearbeiten, kann dies sehr hilfreich sein, da Sie wissen, was Ihr Besucher wünscht. Sie können die Bestellung vorbereiten, während Ihr Kunde in der Warteschlange steht. Während Sie die Artikel in Ihrem Inventar konfigurieren, können Sie auch deren Bestand angeben, beispielsweise wenn Ihre Artikel nur begrenzt verfügbar sind. Sie können auch die maximale Anzahl der Artikel, die ein Kunde kaufen kann begrenzen, um sicherzustellen, dass alle Ihre Kunden die Möglichkeit haben bestimmte Artikel zu bekommen. Wenn Ihr Bestand unbegrenzt ist, wie in den meisten Fällen, lassen Sie das entsprechende Feld einfach leer.',
  'admin.place.visitorData.text':
    'Wir haben 2Meters so entwickelt, dass Sie Ihren Besuchern vollständige Anonymität gewähren können. Sollen Sie dennoch private Angaben von Nutzern benötigen um Ihre Leistungen anbieten zu können,  so können Sie hier solche Pflichtangaben auswählen. Falls Sie möchten dass Besucher automatische E-Mails zum Terminstatus erhalten, müssen Sie die E-Mailadressen als Pflichtfeld einschalten. Sämtliche persönliche Daten die Ihre Nutzer hinterlassen, werden zwei Tage nach dem Termin automatisch von unseren Servern gelöscht.',
  'admin.place.appointments.text':
    'Legen Sie Ihre Öffnungszeiten und Ihren Serviceplan fest. Sie bestimmen, wie unsere digitale Warteschlange und die Terminfunktionen arbeiten. Wir bieten zwei Varianten der Terminverwaltung an. Bei der einfachen Variante legen Sie nur Ihre Arbeitstage und -stunden fest. Bei der erweiterten Variante haben Sie die volle Kontrolle über den Zeitplan.',
  'admin.place.access.text':
    'Falls Sie mit Angestellten arbeiten, werden Sie wollen, dass diese Zugriff auf bestimmte Funktionen des Systems erhalten ohne die Grundeinstellungen verändern zu können. Von der Kassen- bzw. Empfangsansicht können Ihre Angestellten dann z.B. Termin- und Laufkundschaft verwalten und die Ströme steuern. Wollen Sie Angestellte hinzufügen, geben Sie bitte die entsprechendnen E-Mails unten an.',
  'admin.place.cancelDialog': 'Abbrechen',
  'admin.place.rte.text': 'Text',
  'admin.place.rte.link': 'Verknüpfung',
  'admin.place.rte.addLink': 'Link hinzufügen',
  'admin.place.rte.remove': 'Entfernen',
  'admin.place.rte.save': 'Speichern',
  'admin.place.addAppointmentManually': 'Erstellen',
  'admin.place.livequeue.hint': 'Aktivieren Sie die digitale Warteschlange an Ihrer Stelle',
  'admin.place.fullscreen': 'Vollbildansicht',
  'admin.place.monitor': 'Monitor',
  'admin.account.billingLog': 'Abrechnungsprotokoll',
  'admin.account.account': 'Konto',
  'admin.account.signOut': 'Ausloggen',
  'admin.account.notifications': 'Benachrichtigungen',
  'admin.account.billing': 'Pläne und Abrechnungen',
  'admin.place.form.inventory': 'Welche Artikel bieten Sie an?',
  'admin.place.form.inventory.help':
    'Wenn Sie Ihre Artikel hier definieren, werden Ihre Besucher gefragt, welche Waren sie kaufen möchten während sie einen Termin wählen oder sich in die digitale Warteschlange anstellen. Tragen Sie keine Waren an, wenn Sie keine anbieten.',
  'admin.place.shortUrlInfo': 'Link zu Ihrem Ort',
  'admin.place.shortUrl.error':
    'Diese kurze URL ist bereits vergeben. Probieren Sie eine andere aus',
  'admin.place.monitorUrlInfo': 'Link zur Bildschirm-Anzeige',
  'admin.place.qrCode': 'QR-Code für Ihren Platz',
  'admin.place.form.askEmail': 'E-mail',
  'admin.place.form.askPhone': 'Telefonnummer',
  'admin.place.form.phoneVerificationEnabled': 'SMS-Validierung',
  'admin.place.form.phoneVerificationDisabled':
    'SMS-Validierung (nicht verfügbar im Privatabonnement)',
  'admin.place.form.askName': 'Name',
  'admin.place.form.askAddress': 'Adresse',
  'admin.place.form.askBirthDate': 'Geburtsdatum',
  'admin.place.form.useMailMessenger': 'Email-Benachrichtigungen',
  'admin.place.form.useUIMessenger': 'In-App-Benachrichtigungen',
  'admin.place.form.useWebPushMessenger': 'Push-Benachrichtigungen (Android)',
  'admin.place.form.useWhatsAppMessenger': 'WhatsApp-Benachrichtigungen',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'WhatsApp-Benachrichtigungen (nicht verfügbar im Privatabonnement)',
  'admin.place.form.useSMSMessenger': 'SMS-Benachrichtigungen',
  'admin.place.form.useSMSMessengerDisabled':
    'SMS-Benachrichtigungen (im Privat-Abo nicht verfügbar)',
  'admin.place.form.askVaccinationStatus': 'Covid-19 Impfstatus',
  'admin.place.form.vaccinationStatus': 'Covid-19 Impfstatus',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Aktivieren Sie diese Option, wenn Sie möchten, dass Ihre Besucher ihren Impfstatus angeben',
  'admin.place.form.numberOfVisitors': 'Anzahl der Besucher',
  'admin.place.form.askNumberOfVisitors': 'Anzahl der Besucher',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Aktivieren Sie diese Option, wenn Ihre Besucher in Gruppen kommen können und Sie deren Anzahl wissen wollen',
  'admin.place.form.askWhat': 'Um welche verpflichtenden Angaben möchten Sie Ihre Besucher bitten?',
  'admin.place.form.askWhat.help':
    'Sollten Sie an vollständiger Anonymität Ihrer Kunden interssiert sein, lassen Sie alle Felder weg. Kundendaten werden immer innerhalb von zwei Tagen von unseren Servern gelöscht.',
  'admin.place.form.messagingConfiguration': 'Messaging-Konfiguration',
  'admin.place.form.messagingConfiguration.help':
    'Sie können zwischen mehreren Kanälen wählen, um Ihre Besucher über den Status ihrer Tickets zu informieren.',
  'admin.place.form.enableFeatures': 'Aktivieren Sie zusätzliche Funktionen',
  'admin.place.form.allowAppointmentConfirmation': 'Bestätigung erforderlich',
  'admin.place.form.allowAppointmentConfirmation.help':
    'Überprüfen Sie, ob Sie Termine manuell bestätigen oder ablehnen möchten. Ihre Kunden werden per E-Mail benachrichtigt. ',
  'admin.place.form.placeLocale': 'Standardsprache von Ihrem Ort',
  'admin.place.form.placeLocale.help':
    'Eine Sprache, die wir verwenden werden, um Benachrichtigungen an Ihre Besucher zu schicken',
  'admin.place.embedCode.title': 'Embed code',
  'admin.place.embedCode.description':
    'Benutzen Sie diesen Code, um 2Meters-Widget auf Ihrer Webseite einzubetten.',
  'admin.place.appointments.enable': 'Termine für diesen Ort aktivieren',
  'admin.place.livequeue.enable': 'Digitale Warteschlange für diesen Ort aktivieren',
  'admin.place.form.settings.constraints.help': 'Einschränkungen',
  'admin.place.form.settings.constraints.appsperuser.label':
    'Wie viele Tickets kann ein Benutzer zu einem Zeitpunkt nehmen?',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'Eins nach dem anderen',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'Eins pro service',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Unbegrenzt',

  'admin.place.form.settings.automation.help': 'Automatisierung',
  'admin.place.form.settings.automation.processing.help':
    'Leer lassen wenn Sie es vorziehen, von Hand aufzuräumen',
  'admin.place.form.settings.automation.processing.label':
    'min. Eingeladene Tickets werden nach dieser Zeit automatisch entfernt',

  'admin.place.form.settings.automation.eta.auto': 'Auto',
  'admin.place.form.settings.automation.eta.constant': 'Konstante',
  'admin.place.form.settings.automation.eta.off': 'Aus',
  'admin.place.form.settings.automation.eta.label':
    'Modus zur Berechnung der geschätzten Wartezeit',
  'admin.place.form.settings.automation.eta.help': `Die Art und Weise, wie die Wartezeit für ein bestimmtes Warteschlangenticket verließ, wird berechnet. \n
    Auto: Berücksichtigt eine durchschnittliche Zeit der letzten 6 verarbeiteten Tickets.\n
    Konstant: Sie können die Zeit angeben, die für die Verarbeitung eines Tickets benötigt wird.\n
    Aus: Die geschätzte Wartezeit wird nicht angezeigt.
  `,
  'admin.place.form.settings.automation.eta.constant.label':
    'Minuten. Zeit der Bearbeitung eines Tickets',
  'admin.place.form.settings.automation.eta.constant.help':
    'Es wird verwendet, um die Wartezeit in der Warteschlange abzuschätzen',

  'admin.place.settings.text':
    'Auf dieser Seite können Sie verschiedene Funktionen für Ihren Ort aktivieren',
  'admin.place.form.settings.appointments.help':
    'Mit Terminen können Ihre Kunden ein vordefiniertes Zeitfenster für ihren Besuch festlegen',
  'admin.place.form.settings.appointments.label': 'Termine aktivieren.',
  'admin.place.form.settings.livequeue.label': 'Digitale Warteschlange aktivieren',
  'admin.place.form.settings.livequeue.help':
    'Im Gegensatz zu Terminen können Ihre Kunden in der digitalen Warteschlange sich anstellen ohne eine bestimmte Zeit festzulegen, wie es bei Laufkundschaft üblich ist.',
  'admin.place.form.settings.livequeue.maxLength.label': 'Max Warteschlangenlänge',
  'admin.place.form.settings.livequeue.maxLength.help':
    'Dieses Feld bestimmt, wie viele Besucher sich gleichzeitig anstellen können.',
  'admin.place.form.settings.livequeue.openingHours.label': 'Nur in den Öffnungszeiten',
  'admin.place.form.settings.livequeue.openingHours.help':
    'Wenn diese Funktion aktiviert ist, können sich Ihre Kunden nur innerhalb der Geschäftszeiten in die Warteschlange einreihen',
  'admin.place.form.settings.appointment.confirmation.label': 'Erfordert Bestätigung',
  'admin.place.form.settings.appointment.confirmation.help':
    'Kreuzen Sie das an, wenn Sie Termine manuell bestätigen oder ablehnen möchten. Ihre Kunden werden über die Benachrichtigungskanäle benachrichtigt, die Sie in den vorherigen Schritten definiert haben',

  'admin.place.form.schedulerType': 'Wie komplex ist Ihre Zeitplanung?',
  'admin.place.form.schedulerType.help':
    'Wenn Ihre Dienstleistungen zu unterschiedlichen Zeiten angeboten werden, wählen Sie den fortgeschrittenen Zeitplaner. Im fortgeschrittenen Zeitplaner können Sie Ihre Öffnungszeiten in einem Kalender eintragen und Ihre Dienstleistungen zuweisen. Bitte beachten Sie, dass der fortgeschrittene Zeitplaner sich noch in der Entwicklung befindet. Bitte geben Sie uns Ihr Feedback.',
  'admin.place.shopCapacity.help':
    'Wie viele Kunden können Sie gleichzeitig bedienen? Jeder Zeitschlitz kann so oft von verschiedenen Kunden gebucht werden',
  'admin.place.averageDuration.help':
    'Ihre Arbeitszeit wird in die Zeitfenster der folgenden Dauer aufgeteilt',
  'admin.place.inventory.enable': 'Das Inventar für diesen Ort aktivieren',
  'admin.place.inventory.placeCurrency': 'Währung',
  'admin.place.inventory.placeCurrency.help':
    'Eine Währung, in der die Preise angegeben werden sollen',
  'admin.place.inventory.inventoryMaxOrder': 'Maximale Kaufmenge',
  'admin.place.inventory.inventoryMaxOrder.help':
    'Eine Menge von Waren, die ein Besucher kaufen kann. Lassen Sie das Feld leer, falls Ihre Vorräte unbegrezt sind.',
  'admin.place.inventory.appointmentsNotAvailable':
    'Der Slotplaner für Termine ist nicht verfügbar, während die Termine deaktiviert sind',

  'admin.userProfile.title': 'Benutzerprofil',
  'admin.profile.title': 'Profil',
  'admin.profile.editProfile': 'Profil bearbeiten',

  // Inventory
  'inventory.quantity': 'Menge',
  'inventory.maxPerUser': 'Grenze',
  'inventory.newProduct': 'Neues Produkt',
  'inventory.editProduct': 'Ein Produkt ändern',
  'inventory.edit': 'Bearbeiten',
  'inventory.delete': 'Löschen',
  'inventory.menu': 'Menü',
  'inventory.duplicate': 'Duplizieren',
  'inventory.addProduct': 'Produkt hinzufügen',
  'inventory.enterProductData': 'Geben Sie hier Ihre Produktdaten ein',
  'inventory.productName': 'Name',
  'inventory.productName.placeholder': 'Das Feld darf nicht leer sein',
  'inventory.productDescription': 'Beschreibung',
  'inventory.productDescription.placeholder': 'Das Feld darf nicht leer sein',
  'inventory.productStock': 'Menge',
  'inventory.productStock.placeholder':
    'Lassen Sie das Feld leer, falls Sie eine unbegrenzte Menge haben.',
  'inventory.unlimited': 'unbegrenzt',
  'inventory.product.crop': 'Crop',
  'inventory.product.cropTitle': 'Beschneiden Sie Ihr Foto',
  'inventory.product.uploadImage': 'Hochladen',
  'inventory.price': 'Preis',
  'inventory.price.placeholder': 'Produktpreis',

  //Subscription
  'admin.subscription': 'Abonnement',
  'admin.subscription.trial': 'Trial',
  'admin.subscription.nextInvoice': 'Nächste Rechnung',
  'admin.subscription.cancelAt': 'Abonnement endet am',
  'admin.subscription.cancel': 'Kündigen',
  'admin.subscription.sure_cancel': 'Möchten Sie Ihr Abonnement wirklich kündigen?',
  'admin.subscription.cancelled': 'Das Abonnement wurde gekündigt',
  'admin.subscription.trial_description':
    'Die Testphase beginnt sofort und endet in 30 Tagen. Während des Testzeitraums stehen Ihnen alle Funktionen zur Verfügung. Wir werden Sie nicht nach Zahlungsinformationen fragen, um die Testversion zu starten. Am Ende des Testzeitraums wird der Ort automatisch in einen privaten Plan geändert. Wenn Ihnen unser Service gefällt, abonnieren Sie ihn jederzeit. Die Testversion kann nur einmal verwendet werden.',
  'admin.subscription.trial_started': 'Die Probezeit hat begonnen und endet auf {date}',

  //PlaceList
  'admin.places.list.empty.createNew': 'Erstellen Sie einen neuen Ort',
  'admin.places.list.ticketsUsed': 'Tickets verwendet',
  'admin.places.list.empty.header': 'Sie haben noch keine Orte erstellt',
  'admin.places.list.empty.body': 'Erstellen Sie einen Ort und beginnen Sie ihn zu verwalten',
  'admin.places.list.header': 'Ihre Orte',
  'admin.places.list.managed': 'Zugewiesene Orte',
  'admin.places.list.createNew': 'Erstellen Sie einen neuen Ort',
  'admin.places.create.title': 'Erstellen Sie einen neuen Ort',
  'admin.places.update.title': 'Aktualisieren Sie einen Ort',
  'admin.place.shortUrl':
    'Legen Sie ein Kürzel für Ihren Ort fest, der es Ihren Kunden ermöglicht Ihre Seite schnell aufzurufen.',
  'admin.place.shortUrl.help':
    'Wenn Sie z.B. "bbq" als Kürzel für Ihren Grillstand wählen, werden Kunden ihn über den Browser unter app.2meters.app/p/bbq aufruden können.',

  //QR
  'qr.print':
    'Drucken Sie dieses Dokument aus und platzieren Sie es dort, wo Ihre Kunden es leicht sehen können',
  'qr.pleaseScanAppointments': 'Scannen Sie diesen QR-Code, um einen Termin zu vereinbaren',
  'qr.pleaseScanLivequeue': 'Scannen Sie diesen QR-Code, um sich digital anzustellen',
  'qr.pleaseScanIntegrated':
    'Scannen Sie diesen QR-Code, um einen Termin zu vereinbaren oder sich digital anzustellen',
  'qr.followThisLink': 'Oder folgen Sie einfach dieser URL',
  'qr.error': 'Es ist etwas schief gegangen. Bitte versuchen Sie es später',

  //CounterPage
  'counter.processing': 'In Bearbeitung',
  'counter.processing.add': 'Nächster Kunde',
  'counter.liveQueue': 'Live-Warteschlange',
  'counter.stopQueue': 'Laufend',
  'counter.startQueue': 'Angehalten',
  'counter.liveQueue.add': 'Hinzufügen',
  'counter.appointments': 'Termine',
  'counter.calendar': 'Kalender',
  'counter.appointments.add': 'Hinzufügen',
  'counter.appointments.dialog.header': 'Termin manuell eintragen',
  'counter.appointments.dialog.edit.header': 'Ticket bearbeiten {tag}',
  'counter.appointments.dialog.comment.header': 'Kommentar hinzufügen zu {tag}',
  'counter.appointments.dialog.add': 'Anmelden',
  'counter.appointments.dialog.cancel': 'Stornieren',
  'counter.appointments.dialog.selectDate': 'Datum auswählen',
  'counter.appointments.dialog.pickTime': 'Abholzeit',
  'counter.appointments.dialog.availableTime':
    'Verfügbare Zeit (auf mehrere klicken, um mehrfache Termine zu erstellen)',
  'counter.appointments.dialog.noAvailableTime':
    'Für den angegebenen Service sind am ausgewählten Datum keine Zeitfenster verfügbar',
  'counter.appointments.dialog.noAvailableTimeSlot':
    'An dem gewählten Datum sind keine Zeitfenster für den betreffenden Dienst verfügbar',
  'counter.appointments.dialog.noAvailableTimeToday':
    'Für heute gibt es keine Zeitfenster für heute',
  'counter.appointments.dialog.selectedSlots': 'Folgende Termine werden erstellt',
  'counter.appointments.dialog.selectService':
    'Wählen Sie die Art des Dienstes aus, um die verfügbaren Zeitfenster anzuzeigen',
  'counter.appointments.dialog.serviceType': 'Art des Dienstes',
  'counter.appointments.dialog.order': 'Bestellung',
  'counter.liveQueue.dialog.header': 'Warteschlange',
  'counter.userInfo.nickname': 'Name',
  'counter.userInfo.email': 'E-Mail',
  'counter.userInfo.phone': 'Handynummer',
  'counter.userInfo.birthDate': 'Geburtsdatum',
  'counter.userInfo.address': 'Adresse',
  'counter.toQueue': 'Anstellen',
  'counter.invite': 'Einladen',
  'counter.processed': 'Verarbeitet',
  'counter.processedTime': 'Bearbeitungszeit',
  'counter.invited': 'Eingeladen',
  'counter.done': 'Erledigt',
  'counter.noname': 'Kein Name',
  'counter.walkin': 'Walk-in',
  'counter.error': 'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support: {error}',
  'counter.info.dialog.notset': 'nicht festgelegt',
  'counter.appointments.info.queueIn': 'In Schlange einreihen',
  'counter.info.dialog.sureCancel': 'Möchten Sie diesen Kunden stornieren?',
  'counter.info.dialog.time': 'Zeit',
  'counter.info.dialog.email': 'E-mail',
  'counter.info.dialog.phone': 'Telefon',
  'counter.info.dialog.username': 'Name',

  'counter.appointment.status.noShow': 'Nicht Erschienen',
  'counter.appointment.status.waiting': 'Anhängig',
  'counter.appointment.status.confirmed': 'Akzeptiert',
  'counter.appointment.status.queued': 'Warteschlange',
  'counter.appointment.status.invited': 'Eingeladen',
  'counter.appointment.status.scheduled': 'Geplant',
  'counter.appointment.status.walkin': 'Reingehen',
  'counter.appointment.status.processed': 'Verarbeitet',
  'counter.appointment.status.cancelled': 'Abgesagt',
  'counter.appointment.status.rejected': 'Abgelehnt',

  'counter.table.header.ticket': 'Ticket',
  'counter.table.header.when': 'Wenn',
  'counter.table.header.name': 'Name',
  'counter.table.header.email': 'E-Mail',
  'counter.table.header.service': 'Dienst',
  'counter.table.header.status': 'Status',
  'counter.table.header.statusChanged': 'Status geändert',
  'counter.table.header.type': 'Typ',

  'counter.appointment.action.noShow': 'Nicht Erschienen',
  'counter.appointment.action.invite': 'Einladen',
  'counter.appointment.action.queueIn': 'Zur Warteschlange hinzufügen',
  'counter.appointment.action.done': 'Erledigt',
  'counter.appointment.action.confirm': 'Akzeptieren',
  'counter.appointment.action.cancel': 'Stornieren',
  'counter.appointment.action.reject': 'Ablehnen',
  'counter.appointment.action.edit': 'Bearbeiten',
  'counter.appointment.action.comment': 'Kommentar',
  'counter.appointment.action.noComment': 'Noch keine Kommentare',
  'counter.appointment.action.backToQueue': 'Zurück zur Warteschlange',
  'counter.appointment.action.backToAppointment': 'Zurück zu den Terminen',
  'counter.showOnly': 'nur Anzeigen',
  'counter.filter.all': 'Alle',
  'counter.filter.allServices': 'Alle Dienstleistungen',
  'counter.noAppointments': 'Für das ausgewählte Datum wurden keine Termine vereinbart',
  'counter.noAppointmentsToday': 'Für heute wurden keine Termine vereinbart',
  'counter.noTickets': 'Für das ausgewählte Datum gibt es keine Tickets',
  'counter.tabs.today': 'Heute',
  'counter.tabs.calendar': 'Geschichte',
  'counter.filter.types.noShow': 'Nicht Erschienen',
  'counter.filter.types.active': 'Aktive Tickets',
  'counter.filter.types.rejected': 'Abgelehnt',
  'counter.filter.types.cancelled': 'Abgesagt',
  'counter.filter.types.processed': 'Verarbeitet',
  'counter.filter.types.notConfirmed': 'Nicht bestätigt',
  'counter.tabs.counterName': 'Name des Schalters',
  'counter.tabs.anyName': 'Beliebiger Name',

  // PlaceQRPage
  'qr.neverWaste': '2Meters. Nutzen Sie Ihre Zeit für etwas bedeutungsvolleres als Warten.',
  'qr.header':
    'Scannen Sie diesen QR-Code, um Termine zu buchen oder sich in die virtuelle Warteschlange einzureihen',

  // PlansPage
  'plans.text':
    'Alle Funktionen des 2Meters-Dienstes können kostenlos genutzt werden, sind jedoch auf 100 Tickets pro Monat begrenzt. Dies umfasst sowohl Warteschlangen- als auch Terminkarten. Mit Pro Plan gibt es keine Einschränkungen',
  'plans.free': 'Kostenlos*',
  'plans.month.place': '/MONAT/PLATZ',
  'plans.preloader':
    'Bitte warten Sie, bis wir die Zahnräder geölt haben, bevor wir Ihre Zahlung bearbeiten',
  'plans.list.private': 'Privat',
  'plans.list.pro': 'Pro',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.basic_monthly': 'Pro',
  'plans.list.basic_annual': 'Pro',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.business': 'Enterprise',
  'plans.list.business_annual': 'Enterprise',
  'plans.list.business_monthly': 'Enterprise',
  'plans.list.enterprise': 'Enterprise',

  'plans.list.trial': 'Trial',
  'plans.chooseAPlan': 'Wählen Sie einen Plan',
  'plans.ps':
    '* Wir berechnen zusätzliche Kosten für den Verbrauch von SMS- und Whatsapp-Benachrichtigungen am Ende eines jeden Monats, wenn Sie sich dafür entscheiden, diese zu nutzen. Die Preise sind typisch für das Herkunftsland der Telefonnummer des Empfängers und hängen von den Preisen der lokalen Anbieter ab. Für detaillierte Informationen wenden Sie sich bitte an den Vertrieb.',
  'plans.promo': 'Geben Sie Ihrem Team die Funktionen, die es zum Erfolg benötigt.',
  'plans.contactUs': 'Kontaktieren Sie uns',
  'plans.missingFeature': 'Vermissen Sie eine Funktion?',
  'plans.currentPlan': 'Derzeitiger Plan',
  'plans.upgradePlan': 'Abo upgraden',
  'plans.subscription.cancelAt': 'Gültig bis',
  'plans.cancelSubscription': 'Abo kündigen',
  'plans.private.features.1': '100 Besucher pro Monat',
  'plans.private.features.2': 'Unbegrenzte App Clients',
  'plans.private.features.3': 'Live Warteschlangensystem',
  'plans.private.features.4': 'Slot Terminsystem',
  'plans.private.features.5': 'Produktkatalog',
  'plans.private.features.6': 'Bestellsystem',
  'plans.private.features.7': 'Intuitive Schalter-App',
  'plans.private.features.8': 'Historischer Kalender',
  'plans.private.features.9': 'Anzeige App',
  'plans.private.features.10': 'SMS/Whatsapp/Email/Push',
  'plans.pro.features.first': 'Wie bei Privat',
  'plans.pro.features.1': 'Unbegrenzte Besucher',
  'plans.pro.features.2': 'Unbegrenzte App Clients',
  'plans.pro.features.3': 'Besuchervalidierung per SMS',
  'plans.pro.features.4': 'Performance-Analysen',
  'plans.pro.features.5': 'Benutzerdefinierte Besucherformulare',
  'plans.pro.features.6': 'Benannte Schalter',
  'plans.pro.features.7': 'Erweiterte Support',
  'plans.enterprise.features.first': 'Wie bei Pro',
  'plans.enterprise.features.1': 'Unbegrenzte Besucher',
  'plans.enterprise.features.2': 'Unbegrenzte App Clients',
  'plans.enterprise.features.3': 'Integrationen',
  'plans.enterprise.features.4': 'Benutzerdefinierter SMS-Gateway',
  'plans.enterprise.features.5': 'Benutzerdefiniertes Branding',
  'plans.enterprise.features.6': 'Individuelle Funktionen',
  'plans.enterprise.features.7': 'Premium Support',
  'plans.enterprise.promo': 'Benutzerdefiniert',
  'plans.switcher.monthly': 'Monatlich',
  'plans.switcher.annualy': 'Jährlich',
  'plans.mostPopular': 'Am beliebtesten',
  'plans.private.submit': 'Aktueller Plan',
  'plans.pro.submit': 'Wählen Sie',
  'plans.enterprise.submit': 'Kontakt',
  'plans.discont': '10% Rabatt',

  'plans.keepSubscribtion': 'Abonnement behalten',
  'plans.pro_monthly.cancelSubscription': 'Pro-Monatsplan kündigen',
  'plans.pro_annual.cancelSubscription': 'Pro-Jahresplan kündigen',
  'plans.enterprise_monthly.cancelSubscription': 'Enterprise-Monatsplan kündigen',
  'plans.enterprise_annual.cancelSubscription': 'Enterprise-Jahresplan stornieren',
  'plans.cancelSubscription.text':
    'Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten? Wenn Sie Ihr Abonnement kündigen, werden alle zukünftigen Zahlungen gestoppt und Ihr Zugang zu den Premium-Funktionen endet am Ende des aktuellen Abrechnungszeitraums Wenn Sie Fragen oder Bedenken haben, kontaktieren Sie bitte unser Support-Team.',

  'poorConection.title': 'Schlechte Internetverbindung',
  'poorConection.description':
    'Ihre Internetverbindung ist leider nicht ausreichend für den Betrieb der 2meters App. Bitte überprüfen Sie Ihre Internetverbindung oder Ihre Netzwerksicherheitsrichtlinien. Bitte beachten Sie, dass wir Web-Sockets verwenden, um Ihnen die Live-Daten zu liefern.',

  //Checkout
  'checkout.success': 'Die Kaufabwicklung war erfolgreich',
  'checkout.redirect': 'Sie werden in einer Sekunde umgeleitet',
  'checkout.falied': 'Kaufabwicklung fehlgeschlagen. Bitte wenden Sie sich an den Support',

  'monitor.desktopViewHeader': 'Bildschirmansicht',
  'monitor.desktopViewBody':
    'Sie können einen Bildschirm installieren, um Ihren Besuchern den Stand der Warteschlange zu zeigen. Öffnen Sie diese Seite auf dem Computer, der mit dem Bildschirm verbunden ist, und klicken Sie auf die Schaltfläche, um den Vollbildmodus zu aktivieren',
  'monitor.fullscreen': 'Vollbildansicht',
  'monitor.switchTheMonitor': 'Umschalten der Monitorsprache',

  //Statistics
  'statistics.dashboard': 'Statistik Dashboard',
  'statistics.ticketsAndServices': 'Tickets und Dienstleistungen',
  'statistics.thisMonth': 'in diesem Monat',
  'statistics.thisWeek': 'in dieser Woche',
  'statistics.today': 'Heute',
  'statistics.booked': 'Gebucht',
  'statistics.noData': 'Für den ausgewählten Zeitraum liegen keine Daten vor',
  'statistics.totalAmount': 'Gesamtbetrag',
  'statistics.avgWaitingTime': 'Durchschnittliche Wartezeit',
  'statistics.avgProcessingTime': 'Durchschnittliche Bearbeitungszeit',

  'statistics.period': 'Zeitraum',
  'statistics.lastSevenDays': 'Letzte 7 Tage',
  'statistics.month': 'Diesen Monat',
  'statistics.custom': 'Benutzerdefinierter Datumsbereich',
  'statistics.services': 'Dienstleistungen',
  'statistics.all': 'Alle Dienstleistungen',
  'statistics.periodError': 'Das Startdatum sollte vor dem Enddatum liegen',
  'statistics.minutes': 'minuten',

  'statistics.сonfirmationStatus': 'Status der Bestätigung',
  'statistics.ticketType': 'Ticket-Typ',
  'statistics.ticketStatus': 'Ticket-Status',
  'statistics.processed': 'Verarbeitet',
  'statistics.invited': 'Eingeladen',
  'statistics.noShow': 'Nicht Erschienen',
  'statistics.cancelled': 'Abgesagt',
  'statistics.confirmed': 'Bestätigt',
  'statistics.pending': 'Anhängig',
  'statistics.rejected': 'Abgelehnt',
  'statistics.queued': 'Warteschlange',
  'statistics.dashboardIsEmpty': 'Das Dashboard ist leer',
  'statistics.dashboardIsEmptyDesc':
    'Um Zugriff auf das Statistik-Dashboard zu erhalten, müssen Sie ein Upgrade auf die höhere Abonnementstufe vornehmen.',
  'statistics.upgadePlan': 'Upgade-Plan',

  // Scheduler
  'scheduler.error.endBeforeStart': 'Das Startdatum sollte vor dem Enddatum liegen',
  'scheduler.error.notSameDay': 'Start- und Endzeit sollten am selben Tag sein',
  'scheduler.error.visitTypeNotSet': 'Die Art des Dienstes muss festgelegt werden',
  'scheduler.error.invalidSchedule':
    'Ungültiger Zeitplan. Stellen Sie sicher, dass das Ende der Wiederholungszeit nicht vor dem Startdatum ist',
  'scheduler.numberOfAppointments': '{num} Terminen',
  'scheduler.simple': 'Einfach',
  'scheduler.advanced': 'Fortgeschritten',
  'scheduler.serviceType': 'Art der Dienstleisung',
  // German only translation. English taken from defaults
  'scheduler.commit': 'Bestätigen',
  'scheduler.repeat': 'Wiederholen',
  'scheduler.never': 'Unendlich',
  'scheduler.daily': 'Täglich',
  'scheduler.weekly': 'Wöchentlich',
  'scheduler.monthly': 'Monatlich',
  'scheduler.yearly': 'Jährlich',
  'scheduler.repeatEvery': 'jede',
  'scheduler.days': 'Tage',
  'scheduler.endRepeat': 'Wiederholen bis',
  'scheduler.on': 'nach',
  'scheduler.after': 'nach dem',
  'scheduler.occures': 'Vorkommen',
  'scheduler.weeksOn': 'Woche',
  'scheduler.month': 'Monat',
  'scheduler.everyMonth': 'von jedem Monat',
  'scheduler.the': 'der',
  'scheduler.first': 'erste',
  'scheduler.second': 'zweite',
  'scheduler.third': 'drite',
  'scheduler.fourth': 'viertens',
  'scheduler.last': 'letzte',
  'scheduler.years': 'jahre',
  'scheduler.of': 'von',
  'scheduler.every': 'jeder',
  'scheduler.timePeriodForm.commit': 'Bestätigen',
  'scheduler.timePeriodForm.repeat': 'Wiederholen',
  'scheduler.timePeriodForm.never': 'Unendlich',
  'scheduler.timePeriodForm.daily': 'Täglich',
  'scheduler.timePeriodForm.weekly': 'Wöchentlich',
  'scheduler.timePeriodForm.monthly': 'Monatlich',
  'scheduler.timePeriodForm.yearly': 'Jährlich',
  'scheduler.timePeriodForm.repeatEvery': 'jede',
  'scheduler.timePeriodForm.days': 'Tage',
  'scheduler.timePeriodForm.endRepeat': 'Wiederholen bis',
  'scheduler.timePeriodForm.on': 'nach',
  'scheduler.timePeriodForm.after': 'nach dem',
  'scheduler.timePeriodForm.occurs': 'Vorkommen',
  'scheduler.timePeriodForm.weeksOn': 'Woche',
  'scheduler.timePeriodForm.month': 'Monat',
  'scheduler.timePeriodForm.everyMonth': 'von jedem Monat',
  'scheduler.timePeriodForm.the': 'der',
  'scheduler.timePeriodForm.first': 'erste',
  'scheduler.timePeriodForm.second': 'zweite',
  'scheduler.timePeriodForm.third': 'drite',
  'scheduler.timePeriodForm.fourth': 'viertens',
  'scheduler.timePeriodForm.last': 'letzte',
  'scheduler.timePeriodForm.years': 'jahre',
  'scheduler.timePeriodForm.of': 'von',
  'scheduler.timePeriodForm.every': 'jeder',
  'scheduler.timePeriodForm.timePeriods': 'Zeitraum anlegen',
  'scheduler.timePeriodForm.headerCaption':
    'Definieren Sie bitte jeden Zeitraum, in dem Sie Ihre Dienstleistung anbieten. Die Zeitfenster für diesen Zeitraum werden automatisch berechnet, je nach Wert der durchschnittlichen Dauer.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Dienstleistungs- und Zeitfensterzuweisung',
  'scheduler.timePeriodForm.createButton': 'Erstellen',

  'scheduler.confirmCancel': 'Nicht gespeicherte Änderungen verwerfen?',
  'scheduler.confirmDelete': 'Möchten Sie diesen Zeitraum wirklich löschen?',
  'scheduler.cancelButton': 'Abbrechen',
  'scheduler.deleteButton': 'Löschen',
  'scheduler.discardButton': 'Verwerfen',

  'scheduler.editRecurrenceMenu.editTitle': 'Wiederkehrende Zeiträume bearbeiten',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Wiederkehrende Zeiträume löschen',
  'scheduler.editRecurrenceMenu.cancelButton': 'Abbrechen',
  'scheduler.editRecurrenceMenu.commitButton': 'Bestätigen',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'Alle Zeiträume',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods': 'Dieser und folgende Zeiträume',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'Nur dieser Zeitraum',

  // Manager
  'manager.title': 'Manager.',
  'manager.description':
    'Hier können Sie Personen hinzufügen und entfernen, die Zugang zu diesem Ort haben. Manager können Termine und Warteschlangen aus der Schalteransicht verwalten. Die Managers können jedoch keine Einstellungen des Ortes bearbeiten.',
  'manager.add': 'Manager hinzufügen',
  'manager.remove': 'Entfernen',
  'manager.admin': 'Administrator',
  'manager.manager': 'Manager',
  'manager.name': 'Name',
  'manager.email': 'Email',
  'manager.role': 'Rolle',
  'manager.invitationSent': 'Einladung verschickt',
  'manager.sendInvite': 'Einladung senden',
  'manager.invitePlaceholder': 'Neue Benutzer einladen',
  'manager.table.name': 'Name',
  'manager.table.email': 'Email',
  'manager.table.role': 'Rolle',
  'manager.table.dateAdded': 'Datum hinzugefügt',
}
