import { useState, MouseEvent } from 'react'
import { Avatar, Menu, MenuItem, IconButton } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { localeFlag } from 'i18n'
import { LocaleLong } from '@2meters/shared'
import { adminLocalesNames, useAdminLocale } from 'store/useAdminLocale'

const LanguageSelect = ({ ...props }: any) => {
  const { adminLocale, setAdminLocale } = useAdminLocale()

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null)
  const onChange = (locale: LocaleLong) => {
    setAdminLocale(locale)
    setMenuAnchor(null)
  }

  const getIcon = (locale: LocaleLong) => {
    return <Avatar src={localeFlag(locale)} style={{ width: 24, height: 24 }} />
  }

  return (
    <>
      <IconButton
        size='small'
        onClick={(event: MouseEvent) => setMenuAnchor(event.currentTarget as Element)}
      >
        {getIcon(adminLocale)}
      </IconButton>
      <Menu
        id='locales-menu'
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {adminLocalesNames.map((locale: LocaleLong) => (
          <MenuItem key={locale} onClick={() => onChange(locale)}>
            {getIcon(locale)}&#160;&#160;
            <FormattedMessage id={`${locale}Lang`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelect
