import FLAG_DE from 'assets/flags/de.png'
import FLAG_LT from 'assets/flags/lt.png'
import FLAG_EN from 'assets/flags/en.png'
import FLAG_BR from 'assets/flags/br.png'
import FLAG_ES from 'assets/flags/es.png'
import FLAG_UA from 'assets/flags/ua.png'
import FLAG_TR from 'assets/flags/tr.png'
import { LocaleLong } from '@2meters/shared'

const icons = {
  'en-GB': FLAG_EN,
  'de-DE': FLAG_DE,
  'lt-LT': FLAG_LT,
  'pt-BR': FLAG_BR,
  'es-ES': FLAG_ES,
  'uk-UA': FLAG_UA,
  'tr-TR': FLAG_TR,
}

export const localeFlag = (locale: LocaleLong) => icons[locale]
