import { string, optional, type, boolean, number, Infer, enums } from 'superstruct'

export const Queue3000Struct = type({
  id: string(), // firestore document id
  name: string(), // name of the queue. use ```default``` for default schedule
  isMaxLengthEnabled: optional(boolean()), // max allowed length of the queue
  maxLength: optional(number()), // max allowed length of the queue
  visitorsToNotify: optional(number()), // number of visitors in fron of the queue to notify about the advancing of the queue
  estimatedWaitingTimeMode: optional(enums(['auto', 'constant', 'off'])), // mode for calculating waiting time
  estimatedWaitingTimeConstantMinutes: optional(number()), // mintes for calculating waiting time in constant mode
  isOnlyInOpeningHours: optional(boolean()), // if true, queue will be available only in opening hours
  pauesed: optional(boolean()), // if true, queue will be paused
})

export type Queue3000 = Infer<typeof Queue3000Struct>
