import React from 'react'
import { places } from 'api/Firebase/places'

const withPlace = Loading => Component => {
  class WithPlaceAccess extends React.Component {
    constructor(props) {
      super(props)
      const { id, shortUrl } = this.props.match.params
      this.state = { placeId: id, shortUrl, notFound: false }
    }

    async componentDidMount() {
      let placePromise
      if (this.state.shortUrl) placePromise = places.getPlaceByShortUrl(this.state.shortUrl)
      else placePromise = places.getPlace(this.state.placeId)

      placePromise
        .then(place => {
          this.setState({ place, placeId: place.id })
        })
        .catch(e => this.setState({ notFound: true }))
    }

    render() {
      const { place, placeId, notFound } = this.state

      if (!place) return <Loading placeId={this.state.placeId} notFound={notFound} />
      else {
        const props = {
          ...this.props,
          place,
          placeId,
        }
        return <Component {...props} />
      }
    }
  }

  return WithPlaceAccess
}

export { withPlace }
