import { enums, Infer } from 'superstruct'

export const LocaleLongStruct = enums([
  'en-GB',
  'de-DE',
  'lt-LT',
  'pt-BR',
  'es-ES',
  'uk-UA',
  'tr-TR',
])
export type LocaleLong = Infer<typeof LocaleLongStruct>
export function isLocaleLong(value: string): boolean {
  return LocaleLongStruct.is(value as any)
}

export const DefaultLocaleLong = 'en-GB'
