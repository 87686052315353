import { User } from '@2meters/shared'
import { User as AuthUser } from '@firebase/auth-types'
import create from 'zustand'

export interface CurrentUserStore {
  userRecord?: User
  authUser?: AuthUser
  setUserRecord: (user?: User) => void
  setAuthUser: (user: AuthUser) => void
}

export const useCurrentUser = create<CurrentUserStore>(set => ({
  userRecord: undefined,
  authUser: undefined,
  setUserRecord: user => {
    set({ userRecord: user })
  },
  setAuthUser: (user: AuthUser) => {
    set({ authUser: user })
  },
}))
