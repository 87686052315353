import { PhoneNumberLookup } from '@2meters/shared'
import { httpsCallable } from 'firebase/functions'
import { functions } from './firebase-init'
import { resultAs } from './firebase-utils'

const validatePhoneNumber = (phoneNumber: string): Promise<PhoneNumberLookup> =>
  httpsCallable(
    functions,
    'integrations-lookupPhoneNumber'
  )({ phoneNumber })
    .then(res => resultAs<PhoneNumberLookup>(res.data))
    .catch(e => {
      console.error('Phone number lookup failed')
      return Promise.resolve({ valid: true })
    })

export const integrations = {
  validatePhoneNumber,
}
