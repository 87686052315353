import { useCallback } from 'react'
import { styled } from '@mui/styles'
import MSnackbar from '@mui/material/Snackbar'
import { Alert, Fade } from '@mui/material'
import styles from './Snack.module.sass'
import { useSnackBar } from 'store/useSnackBar'

const Snackbar = styled(MSnackbar)(({ theme }) => ({
  maxWidth: 600,
  '& > * + *': {
    marginTop: theme.spacing(3),
  },
}))

const Snack = ({ onClose }: any) => {
  const { snack, hideSnack } = useSnackBar()

  const hide = useCallback(() => {
    hideSnack()
    if (onClose) {
      onClose()
    }
  }, [hideSnack, onClose])

  return (
    <Snackbar
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(snack)}
      autoHideDuration={5000}
      onClose={hide}
    >
      {snack && (
        <Alert
          className={styles.snackContent}
          elevation={6}
          variant='filled'
          onClose={hide}
          severity={snack.type}
        >
          {snack?.message}
        </Alert>
      )}
    </Snackbar>
  )
}

export default Snack
