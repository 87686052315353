import { boolean, enums, Infer, optional, type } from 'superstruct'

export const WeekdayStruct = enums(['MO', 'TU', 'WE', 'TH', 'FR', 'ST', 'SU'])
export const HoursStruct = enums([
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
])

export const WorkDayStruct = type({
  open: boolean(),
  openingTime: optional(HoursStruct),
  closingTime: optional(HoursStruct),
})

export const OpeningHoursStruct = type({
  MO: WorkDayStruct,
  TU: WorkDayStruct,
  WE: WorkDayStruct,
  TH: WorkDayStruct,
  FR: WorkDayStruct,
  ST: WorkDayStruct,
  SU: WorkDayStruct,
})

export type Weekday = Infer<typeof WeekdayStruct>
export type Hours = Infer<typeof HoursStruct>
export type OpeningHours = Infer<typeof OpeningHoursStruct>

export function weekdayFromIndex(dayIndex: number): Weekday {
  switch (Number(dayIndex)) {
    case 0:
      return 'SU'
    case 1:
      return 'MO'
    case 2:
      return 'TU'
    case 3:
      return 'WE'
    case 4:
      return 'TH'
    case 5:
      return 'FR'
    case 6:
      return 'ST'
    case 7:
      return 'SU'
    default:
      throw new Error(`Invalid day index: ${dayIndex}`)
  }
}

export function readTime(value?: Hours): [number, number] {
  if (!value) return [0, 0]
  let [hour, minute] = value.split(':').map((e: any) => Number(e))
  hour = Number.isNaN(hour) || hour < 0 || hour >= 24 ? 0 : hour
  minute = Number.isNaN(minute) || minute < 0 || minute >= 60 ? 0 : minute
  return [hour, minute]
}
