import create from 'zustand'
import { AlertColor } from '@mui/material'

export interface Snack {
  type: AlertColor
  message: string
}

export interface SnackStore {
  snack?: Snack
  showSnack: (snack: Snack) => void
  showSuccess: (message: string) => void
  showError: (message: string) => void
  showInfo: (message: string) => void
  hideSnack: () => void
}

export const useSnackBar = create<SnackStore>(set => ({
  snack: undefined,
  showSnack: (snack: Snack) => set({ snack }),
  showSuccess: (message: string) => set({ snack: { type: 'success', message } }),
  showError: (message: string) => set({ snack: { type: 'error', message } }),
  showInfo: (message: string) => set({ snack: { type: 'info', message } }),
  hideSnack: () => set({ snack: undefined }),
}))
