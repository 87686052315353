import {
  Appointment,
  AppointmentComment,
  AppointmentUpdateRequest,
  MakeMultipleAppointmentsRequest,
  OrderProduct,
  Place,
} from '@2meters/shared'
import {
  collection,
  doc,
  onSnapshot,
  query,
  QuerySnapshot,
  updateDoc,
  where,
  getDocs,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { functions, db } from './firebase-init'
import { prepareRequest, readAs, resultAs } from './firebase-utils'

const confirmAppointment = (placeId: string, appId: string): Promise<void> => {
  return updateDoc(doc(db, `queue/${placeId}/appointments/${appId}`), {
    confirmation: 'confirmed',
    confirmationAt: new Date(),
  })
    .then(x =>
      httpsCallable(functions, 'counter-confirmAppointment')(prepareRequest({ placeId, appId }))
    )
    .then(() => undefined)
}

const rejectAppointment = (placeId: string, appId: string) => {
  const cloudFunc = httpsCallable(functions, 'counter-rejectAppointment')
  return cloudFunc(prepareRequest({ placeId, appId })).then(result => true)
}

const makeMultipleAppointments = (
  appointment: MakeMultipleAppointmentsRequest
): Promise<Appointment[]> => {
  const cloudFunc = httpsCallable(functions, 'counter-makeMultipleAppointment')
  return cloudFunc(prepareRequest(appointment)).then(
    result => resultAs(result.data) as Appointment[]
  )
}

const updateAppointment = (
  place: Place,
  app: Appointment,
  update: AppointmentUpdateRequest
): Promise<void> => {
  const cloudFunc = httpsCallable(functions, 'counter-updateAppointment')
  return cloudFunc(prepareRequest({ placeId: place.id, appId: app.id, update })).then(
    result => resultAs(result.data) as void
  )
}

const addAppointmentComment = (
  place: Place,
  appointment: Appointment,
  comment: AppointmentComment
): Promise<void> => {
  const cloudFunc = httpsCallable(functions, 'counter-addAppointmentComment')
  return cloudFunc(prepareRequest({ placeId: place.id, appId: appointment.id, comment })).then(
    result => resultAs(result.data) as void
  )
}

const removeAppointmentComment = (
  place: Place,
  appointment: Appointment,
  comment: AppointmentComment
): Promise<void> => {
  const cloudFunc = httpsCallable(functions, 'counter-removeAppointmentComment')
  return cloudFunc(prepareRequest({ placeId: place.id, appId: appointment.id, comment })).then(
    result => resultAs(result.data) as void
  )
}

const queueInAppointment = (placeId: string, appId: string): Promise<void> => {
  const cloudFunc = httpsCallable(functions, 'counter-queueInAppointment')
  return cloudFunc(prepareRequest({ placeId, appId })).then(() => undefined)
}

const inviteAppointment = (placeId: string, appId: string, counter?: string): Promise<void> => {
  return httpsCallable(
    functions,
    'counter-inviteAppointment'
  )({
    placeId,
    appId,
    counter,
  }).then(() => undefined)
}
const backToQueue = (placeId: string, appId: string): Promise<void> => {
  return httpsCallable(
    functions,
    'counter-backToQueue'
  )({
    placeId,
    appId,
  }).then(() => undefined)
}

const backToAppointment = (
  placeId: string,
  appId: string,
  startTime?: Date,
  endTime?: Date
): Promise<void> => {
  const request = prepareRequest({
    placeId,
    appId,
    startTime,
    endTime,
  })

  return httpsCallable(functions, 'counter-backToAppointment')(request).then(() => undefined)
}

const finishProcessing = (placeId: string, appId: string, noShow = false): Promise<void> => {
  return httpsCallable(
    functions,
    'counter-processAppointment'
  )({
    placeId,
    appId,
    noShow,
  }).then(() => undefined)
}

export const pauseLivequeuePlace = (placeId: string, paused: boolean): Promise<void> =>
  httpsCallable(
    functions,
    'counter-pauseLivequeue'
  )(prepareRequest({ placeId, paused })).then(result => {
    console.log(`Place ${placeId} ${paused ? 'paused' : 'unpaused'}`)
  })

const queueIn = (
  placeId: string,
  app: {
    nickname?: string
    email?: string
    phone?: string
    numberOfVisitors?: number
    vaccinationStatus?: string
    address?: string
    birthDate?: Date
    visitType?: string
    products?: OrderProduct[]
  }
): Promise<Appointment> => {
  const queueUpCloudFunc = httpsCallable(functions, 'counter-makeWalkInAppointment')
  //TODO set queued on client side for speed.
  return queueUpCloudFunc(prepareRequest({ placeId, ...app })).then(result => {
    return result.data as Appointment
  })
}

const appointmentsOfPlaceSubscribe = (
  placeId: string,
  from: DateTime,
  until: DateTime,
  handler: (apps: Appointment[]) => void
): (() => void) => {
  const q = query(
    collection(db, `queue/${placeId}/appointments`),
    where('startTime', '>=', from.toJSDate()),
    where('startTime', '<=', until.toJSDate())
  )
  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs.map((doc: any) => readAs<Appointment>(doc))
    handler(apps)
  })
}

const getWalkInsOfPlaceSubscribe = (
  placeId: string,
  from: DateTime,
  until: DateTime,
  handler: (apps: Appointment[]) => void
): (() => void) => {
  const q = query(
    collection(db, `queue/${placeId}/appointments`),
    where('queuedAt', '>=', from.toJSDate()),
    where('queuedAt', '<=', until.toJSDate())
  )
  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs
      .map((doc: any) => readAs<Appointment>(doc))
      .filter(app => !app.startTime) // filter only walk-ins
    handler(apps)
  })
}

//* subscribes to the invited Appointments  */
const invitedAppointmentsSubscribe = (
  placeId: string,
  callback: (apps: Appointment[]) => void
): (() => void) => {
  // let query: firebase.firestore.Query<firebase.firestore.DocumentData> = db
  let q = query(
    collection(db, `queue/${placeId}/appointments`),
    where('invited', '==', true),
    where('processed', '==', false),
    where('cancelled', '==', false),
    where('confirmation', 'in', ['not_yet', 'confirmed'])
  )

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps: Appointment[] = snapshot.docs.map(doc => readAs<Appointment>(doc))
    console.log(apps)

    callback(apps)
  })
}

//* subscribes to the queue changes in one particular place, returns a list of Appointment  */
const queueAppointmentsSubscribe = (
  placeId: string,
  callback: (apps: Appointment[]) => void
): (() => void) => {
  let q = query(
    collection(db, `queue/${placeId}/appointments`),
    where('queued', '==', true),
    where('invited', '==', false),
    where('processed', '==', false),
    where('cancelled', '==', false),
    where('confirmation', 'in', ['not_yet', 'confirmed'])
  )

  return onSnapshot(q, (snapshot: QuerySnapshot<any>) => {
    let apps = snapshot.docs
      .filter(doc => doc.id !== '--stats--')
      .map(doc => readAs<Appointment>(doc))

    let sortedApps = _.sortBy(apps, (app: Appointment) => app.queuedAt?.getTime())

    callback(sortedApps)
  })
}

const getUnacceptedManagerInvitation = (
  placeId: string,
  userEmail: string
): Promise<{ email: string; acceptedAt: string } | undefined> => {
  const q = query(
    collection(db, `queue/${placeId}/managerInvitations`),
    where('email', '==', userEmail),
    where('acceptedAt', '==', null)
  )

  return getDocs(q).then(snapshot => {
    const data = snapshot.docs[0]?.data()
    if (snapshot.empty) {
      return undefined
    } else {
      return { email: data.email, acceptedAt: data.acceptedAt }
    }
  })
}

const acceptManagerInvitation = (placeId: string) => {
  const acceptManagerInvitationFunc = httpsCallable(functions, 'counter-acceptManagerInvitation')
  return acceptManagerInvitationFunc({ placeId })
}

export const counter = {
  queueIn,
  finishProcessing,
  inviteAppointment,
  queueInAppointment,
  makeMultipleAppointments,
  confirmAppointment,
  cancelAppointment: rejectAppointment,
  updateAppointment,
  addAppointmentComment,
  removeAppointmentComment,
  backToQueue,
  backToAppointment,
  pauseLivequeuePlace,
  getUnacceptedManagerInvitation,
  acceptManagerInvitation,
  subscriptions: {
    queueAppointments: queueAppointmentsSubscribe,
    invitedAppointments: invitedAppointmentsSubscribe,
    appointmentsOfPlace: appointmentsOfPlaceSubscribe,
    getWalkInsOfPlaceSubscribe,
  },
}
