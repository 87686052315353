import { Box, CircularProgress, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import NotFoundImage from 'assets/not-found.svg'
import { useHistory } from 'react-router-dom'
import { BoxRounded } from 'components'

type NotFoundPage = {
  notFound?: boolean
}

const NotFoundPage: React.FC<NotFoundPage> = ({ notFound = true }) => {
  const history = useHistory()

  function getPageName(str: string) {
    return str.indexOf('admin')
  }

  if (notFound === false) {
    return (
      <Box
        bgcolor={
          getPageName(history.location.pathname) > 0
            ? `background.transparent`
            : `background.default`
        }
        height={getPageName(history.location.pathname) > 0 ? `70vh` : `100vh`}
        justifyContent='center'
        alignItems='center'
        display='flex'
        width='100%'
      >
        <CircularProgress />
      </Box>
    )
  }
  return (
    <BoxRounded display='flex' flexDirection='column' justifyContent='center' py={9}>
      <Box mx='auto' width={238} height={238} mb={4}>
        <img src={NotFoundImage} alt='Page not found' />
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box mb={1}>
          <Typography variant='h6' gutterBottom>
            <FormattedMessage id='notfoundpage.header' />
          </Typography>
        </Box>
        <Box maxWidth='60%' display='flex' justifyContent='center'>
          <Typography variant='body2'>
            <FormattedMessage id='notfoundpage.message' />
          </Typography>
        </Box>
      </Box>
    </BoxRounded>
  )
}

export default NotFoundPage
