import {
  ConfirmationResult,
  createUserWithEmailAndPassword,
  IdTokenResult,
  onAuthStateChanged,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  confirmPasswordReset,
  User,
} from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { getToken, isSupported } from 'firebase/messaging'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { convertDataUrlToFile } from 'utils/productImage'
import {
  auth,
  facebookProvider,
  functions,
  googleProvider,
  isMessaggingSupported,
  messaging,
  publicVapidKey,
  storage,
} from './firebase-init'

export const doSignInAnonymously = () => signInAnonymously(auth)

export const doSignInWithGoogle = () => signInWithPopup(auth, googleProvider)

export const doSignInWithFaceBook = () => signInWithPopup(auth, facebookProvider)

export const doSignInWithPassword = (email: string, pass: string) =>
  signInWithEmailAndPassword(auth, email, pass)

export const doSignUPWithPassword = (email: string, pass: string) =>
  createUserWithEmailAndPassword(auth, email, pass)

export const doRecoveryEmail = (email: string) => sendPasswordResetEmail(auth, email)

export const doResetPassword = (oobCode: string, newPassword: string) =>
  confirmPasswordReset(auth, oobCode, newPassword)

export const doSignOut = () => auth.signOut()

export const getAuthUser = () => auth.currentUser

export const isUserAnonymous = () => !auth.currentUser || auth.currentUser!.isAnonymous

export const onAuthUserListener = (
  next: (user: User, userToken?: IdTokenResult) => void,
  fallback: () => void
) =>
  onAuthStateChanged(auth, async (authUser: User | null) => {
    console.log(`onAuthStateChanged ${authUser?.uid}`)
    if (authUser) {
      const token = await authUser?.getIdTokenResult()
      // FIXME: merge db user with auth user
      next(authUser, token)
    } else {
      fallback()
    }
  })

export const getFCMToken = async () => {
  const isMessaggingSupported = await isSupported()

  const registration = await navigator.serviceWorker.register('/firebase-messaging-module-sw.js', {
    scope: '/firebase-cloud-messaging-push-scope',
    type: 'module',
    updateViaCache: 'imports',
  })

  if (isMessaggingSupported && messaging && registration) {
    var FCMToken: string | undefined = undefined
    try {
      FCMToken = await getToken(messaging!, {
        serviceWorkerRegistration: registration,
        vapidKey: publicVapidKey,
      })
      localStorage.setItem('userOrigin', FCMToken)
    } catch (e) {
      console.log('FAILED TO getToken', e)
      throw Error('Messaging is not supported')
    }

    FCMToken && setFCMTokenInAppointments(FCMToken)

    return FCMToken
  }

  throw Error('Messaging is not supported')
}

export const isMessagingSupported = (): boolean => isMessaggingSupported

// TODO: refactor, move to separate module, get rid of the auth. Make verification only
// TODO: move firebase component to api folder
let smsConfirmation: ConfirmationResult
let recaptchaVerifier: RecaptchaVerifier
let elementId: string

export const initSmsVerification = (
  signInButtonId: string,
  phoneNumber: string,
  onError?: (error: string) => void
): Promise<boolean> => {
  elementId = signInButtonId
  console.log('Initializing SMS verification')
  recaptchaVerifier = new RecaptchaVerifier(
    signInButtonId,
    {
      size: 'invisible',
      callback: (response: any) => {
        console.log('reCAPTCHA solved, sending SMS to ', phoneNumber)
        signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
          .then((confirmationResult: ConfirmationResult) => {
            smsConfirmation = confirmationResult
          })
          .catch(error => {
            console.error('SMS verification error', error.message)
            onError && onError(error.message)
          })
      },
      'expired-callback': () => {
        console.error('reCAPTCHA expired.')
        onError && onError('reCAPTCHA expired.')
      },
    },
    auth
  )
  // TODO: refactor
  resendSmsCode()
  return recaptchaVerifier
    .render()
    .then(() => true)
    .catch(error => {
      console.log('SMS verification error: ', error)
      onError && onError(error.message)
      throw error
    })
}

export const sendSmsCode = () => {
  if (elementId) document.getElementById(elementId)?.click()
}

export const resendSmsCode = async () => await recaptchaVerifier.verify()

export const verifySmsCode = (smsCode: string): Promise<boolean> => {
  if (smsConfirmation) {
    return smsConfirmation
      .confirm(smsCode)
      .then(result => {
        recaptchaVerifier.clear()
        return true
      })
      .catch(error => {
        // recaptchaVerifier.clear();
        console.log('SMS verification error: ', error)
        return false
      })
  }
  // recaptchaVerifier.clear();
  return Promise.resolve(false)
}

const uploadAssetToStorage = (
  storagePath: string | undefined,
  dataUrl: string,
  onComplete: () => void,
  onError: (err: Error) => void
): void => {
  const storageRef = ref(storage, storagePath)
  const uploadTask = uploadBytesResumable(storageRef, convertDataUrlToFile(dataUrl))
  uploadTask.on('state_changed', () => undefined, onError, onComplete)
}

const setFCMTokenInAppointments = (token: string) => {
  const updateWebPushTokenCloudFunc = httpsCallable(functions, 'messenger-updateWebPushToken')

  return updateWebPushTokenCloudFunc({ token })
    .then(result => result.data)
    .catch(e => {
      console.error(e.message)
      return null
    })
}

export const backend = {
  doSignInAnonymously,
  doSignInWithGoogle,
  doSignInWithFaceBook,
  doSignInWithPassword,
  doSignUPWithPassword,
  doRecoveryEmail,
  doSignOut,
  doResetPassword,
  isUserAnonymous,
  getAuthUser,
  onAuthUserListener,
  isMessagingSupported,
  getFCMToken,
  setFCMTokenInAppointments,
  initSmsVerification,
  sendSmsCode,
  resendSmsCode,
  verifySmsCode,
  uploadAssetToStorage,
}
