import { groupBy } from 'lodash'
import uuid from 'short-uuid'
import { DateTime } from 'luxon'
import { AppointmentShort, DefaultTimezone, Place, Slot, WeekdayIndex } from '@2meters/shared'
import { localTimezone } from 'utils/time'
import { places } from './places'
import { monitor } from './monitor'

const getAvailability = async (
  place: Place,
  date: DateTime
): Promise<{ availability: Slot[]; appointments: AppointmentShort[] }> => {
  return await places
    .getAppointmentsOfPlace(place.id, date.startOf('day').toJSDate(), date.endOf('day').toJSDate())
    .then(unfilterd => {
      let apps = unfilterd.filter(
        (app: AppointmentShort) => app.confirmation !== 'rejected' //TODO @Serge use filter in subscribe instead
      )
      return calculateAvailability(place, date, apps)
    })
}

const openingHoursRanged = (
  date: DateTime,
  stepMinutes: number,
  timezone: string,
  startTime?: string,
  endTime?: string
): { from: DateTime; to: DateTime }[] => {
  function* makeRangeIterator(start: DateTime, end: DateTime, step: number) {
    let iterationCount = 0
    for (let i = start; i.plus({ minute: step }) <= end; i = i.plus({ minute: step })) {
      iterationCount += 1
      yield { from: i, to: i.plus({ minute: step }) }
    }
    return iterationCount
  }

  if (!startTime || !endTime) return []

  const [openHours, openMinutes] = startTime.split(':').map((e: any) => Number(e))

  const [closeHours, closeMinutes] = endTime.split(':').map((e: any) => Number(e))

  const openingDate = date.setZone(timezone).set({ hour: openHours, minute: openMinutes })

  const closingDate = date.setZone(timezone).set({ hour: closeHours, minute: closeMinutes })

  return Array.from(makeRangeIterator(openingDate, closingDate, stepMinutes))
}

const calculateAvailability = (
  place: Place,
  date: DateTime,
  apps: AppointmentShort[]
): { availability: Slot[]; appointments: AppointmentShort[] } => {
  // eslint-disable-next-line no-param-reassign
  place = { ...places.defaultPlace, ...place } as Place
  const timeZone = place.timezone || localTimezone
  const notCancelledApps = apps.filter(app => app.confirmation !== 'rejected') //TODO filter when fetching
  let slotsGrouped = groupBy(notCancelledApps, item =>
    DateTime.fromJSDate(item.startTime!).setZone(timeZone).toFormat('HH:mm')
  )
  let weekday = (date.get('weekday') % 7) as WeekdayIndex
  let workday = place.workdays[weekday]
  let intervals = openingHoursRanged(
    date,
    place.slotDuration,
    place.timezone || DefaultTimezone,
    workday.openingTime,
    workday.closingTime
  )

  const availability: Slot[] = Array.from(intervals).map(interval => {
    let key = interval.from.toFormat('HH:mm')
    let spotsInSlot = slotsGrouped[key] || []
    let from = DateTime.fromJSDate(interval.from.toJSDate()).setZone(timeZone)
    let to = DateTime.fromJSDate(interval.to.toJSDate()).setZone(timeZone)

    return {
      id: uuid.generate(),
      key,
      label: `${from.toFormat('HH:mm')} - 
          ${to.toFormat('HH:mm')}`,
      disabled: spotsInSlot.length >= place.shopCapacity || !place.workdays[weekday].open,
      startTime: from.toJSDate(),
      endTime: to.toJSDate(),
      occupancy: spotsInSlot.length,
      appointments: spotsInSlot,
      shopCapacity: place.shopCapacity,
    }
  })

  return { availability, appointments: apps }
}

const availabilitySubscribe = (
  place: Place,
  date: DateTime,
  callback: (response: [Slot[], AppointmentShort[]]) => void
) =>
  monitor.subscriptions.appointmentsOfPlace(
    place.id,
    date.setZone(place.timezone || DefaultTimezone).startOf('day'),
    date.setZone(place.timezone || DefaultTimezone).endOf('day'),
    async (apps: AppointmentShort[]) => {
      //TODO filter rejected

      const response = calculateAvailability(place, date, apps)
      callback([response.availability, apps])
    }
  )

export const simpleSchedule = {
  getAvailability,
  openingHoursRanged,
  subscriptions: {
    availability: availabilitySubscribe,
  },
}
