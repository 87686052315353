import * as ROUTES from 'constants/routes'
import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, useParams } from 'react-router-dom'
import { compose } from 'recompose'

import { users } from 'api/Firebase'
import { AdminLanguageProvider } from 'components/Desktop/AdminLanguageProvider'
import { withAuthentication } from 'components/Session/withAuthentication' // @TODO: <-- performance leak
import Snack from 'components/Snack/Snack'
import { ProgressWithBackground } from 'components/Preloader'
import MonitorPage from 'pages/Admin/MonitorPage/MonitorPage'

const StatisticsPage = React.lazy(() => import('pages/Admin/StatisticsPage'))
const AdminNotFoundPage = React.lazy(() => import('pages/Admin/NotFoundPage'))

const PlacePage = React.lazy(() => import('pages/Admin/PlacePage/PlacePage'))
const PlaceQRPage = React.lazy(() => import('pages/Admin/PlaceQRPage/PlaceQRPage'))
const LoginPage = React.lazy(() => import('pages/Admin/LoginPage/LoginPage'))
const OnboardingPage = React.lazy(() => import('pages/Admin/OnboardingPage/OnboardingPage'))
const PlaceWizardPage = React.lazy(
  () => import('pages/Admin/Place/PlaceWizardPage/PlaceWizardPage')
)
const PlaceEditPage = React.lazy(() => import('pages/Admin/Place/PlaceEditPage/PlaceEditPage'))
const AdminPlacesList = React.lazy(() => import('pages/Admin/PlacesList'))
const CounterPage = React.lazy(() => import('pages/Admin/CounterPage/CounterPage'))
const CheckoutCancelPage = React.lazy(
  () => import('pages/Admin/CheckoutCancelPage/CheckoutCancelPage')
)
const CheckoutSuccessPage = React.lazy(
  () => import('pages/Admin/CheckoutSuccessPage/CheckoutSuccessPage')
)
const ResetPassword = React.lazy(() => import('pages/Admin/ResetPassword/ResetPassword'))
const NotificationsBillingLog = React.lazy(() => import('pages/Admin/BillingLog/Notifications'))
const UserProfile = React.lazy(() => import('pages/Admin/UserProfile/UserProfile'))
const ManagersPage = React.lazy(() => import('pages/Admin/ManagersPage/ManagersPage'))

function RedirectWithId(pathTemplate: string) {
  const { id } = useParams<{ id: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  console.log('Redirecting to visitor...')
  const path = pathTemplate.replace('{id}', id)
  window.location.href = `https://visitor.${domain}${path}`
  return null
}

function RedirectWithShortUrl(pathTemplate: string) {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  const path = pathTemplate.replace('{shortUrl}', shortUrl)
  window.location.href = `https://visitor.${domain}${path}`
  return null
}

function RedirectToVisitorShortUrl() {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://visitor.${domain}/p/${shortUrl}`
  return null
}

function RedirectToQueueShortUrl() {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://visitor.${domain}/q/${shortUrl}`
  return null
}

function RedirectToAppointmentsShortUrl() {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://visitor.${domain}/app/${shortUrl}`
  return null
}

function RedirectToAppointments() {
  const { id } = useParams<{ id: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://visitor.${domain}/places/${id}/appointments`
  return null
}

function RedirectToQueue() {
  const { id } = useParams<{ id: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://visitor.${domain}/places/${id}/queue`
  return null
}

function RedirectToMonitor() {
  console.log('Redirecting to monitor...')
  const { id } = useParams<{ id: string }>()
  const domain = process.env.REACT_APP_DOMAIN
  window.location.href = `https://monitor.${domain}/places/${id}`
  return null
}

const App = () => {
  useEffect(() => {
    try {
      users.updateUserOriginInLocal(users.getUserOriginFromQuery())
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [])

  return (
    <Suspense fallback={<ProgressWithBackground />}>
      <Router>
        <Switch>
          <Route
            path={[
              '/',
              ROUTES.ADMIN.ADMIN_PAGE,
              ROUTES.ADMIN.PLACES_LIST,
              ROUTES.ADMIN.PLACE_WIZARD,
              ROUTES.ADMIN.PLACE_EDIT,
              ROUTES.ADMIN.PLACE_QR,
              ROUTES.ADMIN.PLACE_PAGE,
              ROUTES.ADMIN.PLACE_PAGE_SHORT,
              ROUTES.ADMIN.PLACE_CREATED,
              ROUTES.ADMIN.COUNTER_PAGE,
              ROUTES.ADMIN.CHECKOUT_CANCEL,
              ROUTES.ADMIN.CHECKOUT_SUCCESS,
              ROUTES.ADMIN.ONBOARDING,
              ROUTES.ADMIN.PLACE_MANAGERS,
              ROUTES.ADMIN.PLACE_MONITOR_PAGE,
              ROUTES.LOGIN,
              ROUTES.RESET_PASSWORD,

              //Visitor redirects
              ROUTES.SCAN_QR,
              ROUTES.VISITOR.PLACE_SHORT,
              ROUTES.MONITOR_PAGE,
              ROUTES.VISITOR.PLACE_PAGE,
              ROUTES.VISITOR.APPOINTMENT_SHORT,
              ROUTES.VISITOR.APPOINTMENT_PAGE,
              ROUTES.VISITOR.LIVEQUEUE_SHORT,
              ROUTES.VISITOR.LIVEQUEUE_PAGE,
            ]}
          >
            <AdminLanguageProvider>
              <Switch>
                <Route exact path={ROUTES.ADMIN.ADMIN_PAGE}>
                  <Redirect to={ROUTES.ADMIN.PLACES_LIST} />
                </Route>
                <Route exact path={ROUTES.ADMIN.PLACES_LIST} component={AdminPlacesList} />
                <Route exact path={ROUTES.ADMIN.PLACE_WIZARD} component={PlaceWizardPage} />
                <Route exact path={ROUTES.ADMIN.PLACE_EDIT} component={PlaceEditPage} />
                <Route exact path={ROUTES.ADMIN.PLACE_QR} component={PlaceQRPage} />
                <Route exact path={ROUTES.ADMIN.PLACE_PAGE} component={PlacePage} />
                <Route exact path={ROUTES.ADMIN.PLACE_CREATED} component={PlacePage} />
                <Route exact path={ROUTES.ADMIN.COUNTER_PAGE} component={CounterPage} />
                <Route exact path={ROUTES.ADMIN.PLACE_PAGE} component={PlacePage} />
                <Route exact path={ROUTES.ADMIN.PLACE_PAGE_SHORT} component={PlacePage} />
                <Route exact path={ROUTES.ADMIN.COUNTER_PAGE} component={CounterPage} />
                <Route exact path={ROUTES.ADMIN.ONBOARDING} component={OnboardingPage} />
                <Route exact path={ROUTES.ADMIN.STATISTICS} component={StatisticsPage} />
                <Route exact path={ROUTES.ADMIN.CHECKOUT_CANCEL} component={CheckoutCancelPage} />
                <Route exact path={ROUTES.ADMIN.CHECKOUT_SUCCESS} component={CheckoutSuccessPage} />
                <Route
                  exact
                  path={ROUTES.ADMIN.BILLING_LOG_NOTIFICATIONS}
                  component={NotificationsBillingLog}
                />
                <Route exact path={ROUTES.ADMIN.USER_PROFILE} component={UserProfile} />
                <Route exact path={ROUTES.ADMIN.PLACE_MANAGERS} component={ManagersPage} />
                <Route exact path={ROUTES.ADMIN.PLACE_MONITOR_PAGE} component={MonitorPage} />
                <Route exact path={ROUTES.LOGIN} component={LoginPage} />
                <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
                <Route exact path='/'>
                  <Redirect to={ROUTES.ADMIN.PLACES_LIST} />
                </Route>

                {/* REDIRECTS */}
                <Route path={ROUTES.MONITOR_PAGE} component={() => RedirectToMonitor()} />
                <Route
                  path={ROUTES.VISITOR.PLACE_PAGE}
                  component={() => RedirectWithId('/places/{id}')}
                />
                <Route
                  path={ROUTES.VISITOR.APPOINTMENT_PAGE}
                  component={() => RedirectWithId('/places/{id}/appointments')}
                />
                <Route
                  path={ROUTES.VISITOR.LIVEQUEUE_PAGE}
                  component={() => RedirectWithId('/places/{id}/queuein')}
                />
                <Route
                  path={ROUTES.VISITOR.APPOINTMENT_SHORT}
                  component={() => RedirectWithShortUrl('/p/{shortUrl}/appointments')}
                />
                <Route
                  path={ROUTES.VISITOR.LIVEQUEUE_SHORT}
                  component={() => RedirectWithShortUrl('/p/{shortUrl}/queuein')}
                />
                <Route
                  path={ROUTES.VISITOR.PLACE_SHORT}
                  component={() => RedirectWithShortUrl('/p/{shortUrl}')}
                />
                <Route exact path={ROUTES.SCAN_QR} component={() => RedirectWithId('/qr')} />
                {/* Handling 404 */}
                <Route component={AdminNotFoundPage} />
              </Switch>
            </AdminLanguageProvider>
          </Route>
        </Switch>
        <Snack />
      </Router>
    </Suspense>
  )
}

export default compose(withAuthentication)(App)
