/* Messaging system */

import { Price } from './basic'
import { LocaleLong } from './locale'

export const FREE_SMS_AMOUNT = 100
export const FREE_WHATSAPP_AMOUNT = 100

// export declare interface MessagingConfig {
//   useUIMessenger?: boolean;
//   useMailMessenger?: boolean;
//   useWebPushMessenger?: boolean;
//   useSMSMessenger?: boolean;
//   useWhatsAppMessenger?: boolean;
//   useBulkgateMessenger?: boolean;
// }

export declare interface Message {
  templateName: string
  data: any
  subject: string
  body: string
}

export declare interface MessageRecipient {
  appointmentId?: string
  email?: string
  webPushToken?: string
  phoneNumber?: string
  whatsappConsent?: boolean
}

export declare type MessageType = 'ui' | 'sms' | 'web-push' | 'mail' | 'whatsapp'

export declare type MessageProvider = 'ui' | 'sms' | 'web-push' | 'mail' | 'whatsapp' | 'bulkgate'

export declare type MessageStatus =
  | 'NEW'
  | 'DISPATCH_PENDING'
  | 'DISPATCH_SUCCESS'
  | 'DISPATCH_FAILURE'
  | 'DELIVERY_SUCCESS'
  | 'DELIVERY_FAILURE'

export declare interface MessageRecord {
  id?: string
  placeId: string
  placeName?: string
  createdAt: Date
  type: MessageType
  foreignId?: string
  message: Message
  recipient: MessageRecipient
  price?: Price
  countryCode?: string
  status: MessageStatus
  delivery?: any
}

export declare interface SendResult {
  status: MessageStatus
  foreignId?: string
  price?: {
    amount: number
    currency: string
  }
  country?: string
  delivery?: any
}

export declare interface UIMessagePayload {
  id?: string
  userId: string
  appData?: {
    tag: string
    timestamp: Date
    timezone: string
    locale: LocaleLong
  }
  message: Message
  status: 'new' | 'received' | 'displayed' | 'failed'
  createdAt: Date
}

export declare interface MailMessagePayload {
  to: string
  message: {
    subject: string
    html: string
    text?: string
  }
}

export declare interface SMSMessagePayload {
  originator: string
  body: string
  type: string
  recipients: string[]
}

export declare interface WhatsAppMessagePayload {
  to: string
  type: 'hsm'
  channelId: string
  content: {
    hsm: {
      namespace: string
      templateName: string
      language: {
        policy: 'deterministic'
        code: string
      }
      components: Array<{
        type: 'body' | 'header'
        parameters: any
      }>
    }
  }
}

export declare interface WebPushMessagePayload {
  data: {
    id: string
    title: string
    body: string
    badge: string
    icon: string
  }
}

export declare interface MessageLogTotals {
  count: number
  priceTotals: { [key: string]: Price }
}
