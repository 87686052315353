import { FC } from 'react'
import { Box } from '@mui/material/'
import BgImage from 'assets/bg.svg'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/system'

const BoxStyled = styled(Box)<BoxProps>(({ theme }) =>
  theme.palette.mode === 'light'
    ? {
        backgroundImage: 'linear-gradient(66deg, #485563 5%, #29323c 93%)',
        backgroundColor: 'default',
        color: 'white',
        position: 'relative',
        zIndex: 10,
        '&:after': {
          position: 'fixed',
          content: '""',
          width: '100%',
          height: '100%',
          left: 0,
          bottom: 0,
          background: `url(${BgImage}) no-repeat center bottom`,
          backgroundSize: 'contain',
          zIndex: -1,
        },
      }
    : {
        color: 'white',
        backgroundColor: 'default',
      }
)

const Desktop: FC = ({ children }) => {
  return (
    <>
      <BoxStyled height='100%' minHeight='100vh'>
        {children}
      </BoxStyled>
    </>
  )
}

export default Desktop
