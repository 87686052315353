import { places } from 'api/Firebase/places'
import * as ROUTES from 'constants/routes'
import LoadingPage from 'pages/Admin/LoadingPage/LoadingPage'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCurrentUser } from 'store/useCurrentUser'

const withOwnerAndManagerAccess = ownerOnly => Component => {
  const WithPlaceAccess = props => {
    const { id: placeId, shortUrl } = props.match.params
    const [notFound, setNotFound] = useState(false)
    const [place, setPlace] = useState()
    const [managedPlace, setManagedPlace] = useState()
    const [authToken, setAuthToken] = useState()
    const history = useHistory()
    const { authUser } = useCurrentUser()

    useEffect(() => {
      authUser?.getIdTokenResult().then(token => {
        setAuthToken(token)
      })
    }, [authUser])

    useEffect(() => {
      if (authUser && authUser.isAnonymous) {
        history.push(ROUTES.LOGIN)
      }
    }, [authUser, history])

    useEffect(() => {
      let placePromise
      if (shortUrl) placePromise = places.getPlaceByShortUrl(shortUrl)
      else placePromise = places.getPlace(placeId)

      placePromise
        .then(place => {
          return places
            .getManagedPlace(place.id)
            .then(managedPlace => {
              setManagedPlace(managedPlace)
            })
            .catch(e => {
              console.error(e)
            })
            .finally(() => {
              setPlace(place)
            })
        })
        .catch(e => {
          console.log('err', e)
          setNotFound(true)
        })
    }, [placeId, shortUrl])

    const isAuthenticated = authUser => {
      return authUser && !authUser.isAnonymous
    }

    const isPlaceAccessible = (authUser, place, managedPlace, userToken) => {
      return (
        (authUser.uid && authUser.uid === place.userId) ||
        userToken?.claims?.superadmin ||
        (!ownerOnly &&
          managedPlace &&
          managedPlace.managers &&
          managedPlace.managers.includes(authUser.email))
      )
    }

    if (!place) return <LoadingPage place={place} notFound={notFound} />

    const placeProps = {
      ...props,
      place,
      placeId,
    }

    return place &&
      isAuthenticated(authUser) &&
      isPlaceAccessible(authUser, place, managedPlace, authToken) ? (
      <Component {...placeProps} />
    ) : (
      <LoadingPage place={place} notFound={true} />
    )
  }

  return WithPlaceAccess
}

const withPlaceAccess = withOwnerAndManagerAccess(false)
const withOwerOnlyAccess = withOwnerAndManagerAccess(true)

export { withPlaceAccess, withOwerOnlyAccess }
