export declare class Subscription {
  id: string
  placeId: string
  prolongAt?: Date
  type: SubscriptionType
  cancelAt?: Date
  createdAt?: Date
}

/**
 * Object to update subscription part of the place (not the subscription itself)
 */
export declare class SubscriptionUpdate {
  subscriptionType?: SubscriptionType
  subscriptionId?: string
}

export type SubscriptionType =
  | 'private'
  | 'trial'
  | 'pro_monthly'
  | 'pro_annual'
  | 'enterprise_monthly'
  | 'enterprise_annual'

export const SUBSCRIPTION_PRIVATE: SubscriptionType = 'private'
export const SUBSCRIPTION_TRIAL: SubscriptionType = 'trial'
export const SUBSCRIPTION_PRO_MONTHLY: SubscriptionType = 'pro_monthly'
export const SUBSCRIPTION_PRO_ANNUAL: SubscriptionType = 'pro_annual'
export const SUBSCRIPTION_ENTERPRISE_MONTHLY: SubscriptionType = 'enterprise_monthly'
export const SUBSCRIPTION_ENTERPRISE_ANNUAL: SubscriptionType = 'enterprise_annual'
