import lodash from 'lodash'
import {
  Appointment,
  MakeAppointmentRequest,
  QueueInRequest,
  UIMessagePayload,
  ConsentToNotificationsRequest,
} from '@2meters/shared'
import { functions, db, storage } from './firebase-init'
import { prepareRequest, readAs, resultAs } from './firebase-utils'
import { getDownloadURL, ref, deleteObject } from 'firebase/storage'
import { HttpsCallable, httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'

const _ = require('deepdash')(lodash)

const makeAppointment = (appointment: MakeAppointmentRequest) => {
  const makeAppointmentCloudFunc = httpsCallable(functions, 'visitor-makeAppointment')
  console.log('Make appointment', appointment)
  return makeAppointmentCloudFunc(prepareRequest(_.pickBy(appointment, _.identity))).then(result =>
    resultAs<Appointment>(result.data)
  )
}

const changeConsentToNotificationsStatus = (request: ConsentToNotificationsRequest) => {
  const consentToNotificationsCloudFunc = httpsCallable(functions, 'visitor-consentToNotifications')
  return consentToNotificationsCloudFunc(request).then(result => resultAs<Appointment>(result.data))
}

const changeAppointmentValue = (
  placeId: string,
  appId: string,
  key: 'nickname' | 'email' | 'phone' | 'address' | 'visitType',
  value: any
) => {
  let data = {} as any
  data[key] = value
  return updateDoc(doc(db, `queue/${placeId}/appointments/${appId}`), data)
    .then(x => true)
    .catch(e => console.error(e.message))
}

const cancelAppointment = (placeId: string, appId: string) => {
  const queueInCloudFunc = httpsCallable(functions, 'visitor-cancelAppointment')

  return queueInCloudFunc({ placeId, appId }).catch(e => {
    console.error(e.message)
    throw e
  })
}

const queueIn = (request: QueueInRequest): Promise<Appointment> => {
  const queueInCloudFunc = httpsCallable<QueueInRequest, { id: string }>(
    functions,
    'visitor-makeWalkInAppointment'
  )
  console.log('queue in', request)
  return queueInCloudFunc(prepareRequest(request))
    .then((result: HttpsCallableResult<{ id: string }>) => {
      console.log(`Queued In. Spot id ${result.data.id}`)
      return resultAs<Appointment>(result.data)
    })
    .catch(e => {
      console.error(e.message)
      throw e
    })
}

export const deleteAssetFromStorage = (path?: string) => {
  return deleteObject(ref(storage, path))
}

export const getAssetLinkFromStorage = async (path?: string) => {
  return getDownloadURL(ref(storage, path))
}

export const getPlaceLogoLinkFromStorage = async (baseStoragePath?: string, imageFile?: string) => {
  return getDownloadURL(ref(storage, `${baseStoragePath}/logos/${imageFile}`))
}

export const getProfileLogoLinkFromStorage = async (
  baseStoragePath?: string,
  imageFile?: string
) => {
  return getDownloadURL(ref(storage, `${baseStoragePath}/${imageFile}`))
}

export const getLogoPathFromStorage = (baseStoragePath?: string) => {
  return `${baseStoragePath}/logos`
}

// Subscriptions

const usersAppointmentsInPlaceSubscribe = (
  placeId: string,
  userId: string,
  callback: (apps: Appointment[]) => void
): (() => void) => {
  console.log('Subscribing to users appointments')
  const q = query(
    collection(db, `queue/${placeId}/appointments`),
    where('userId', '==', userId),
    where('processed', '==', false),
    where('cancelled', '==', false)
  )
  return onSnapshot(q, snapshot => {
    let apps: Appointment[] = snapshot.docs.map(doc => readAs<Appointment>(doc))
    callback(apps)
  })
}

const changeUINotificationStatus = async (
  messageId: string | undefined,
  status: 'new' | 'received' | 'displayed' | 'failed'
) => {
  if (messageId)
    return await updateDoc(doc(db, `uiNotifications/${messageId}`), {
      status,
    })
  else throw Error('no message id set')
}

const usersUINotificationsSubscribe = (
  userId: string,
  onReceive?: (uiMessages: UIMessagePayload[]) => void,
  onFailure?: () => void
): (() => void) => {
  console.log('Subscribing to UI notifications', userId)
  const q = query(
    collection(db, `uiNotifications`),
    where('userId', '==', userId),
    where('status', '==', 'new')
  )

  console.log('usersUINotificationsSubscribe')
  return onSnapshot(
    q,
    snapshot => {
      const messages: UIMessagePayload[] = []
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          messages.push(readAs<UIMessagePayload>(change.doc))
        }
      })
      console.log('Received UI messages', messages)
      if (onReceive) onReceive(messages)
    },
    (error: Error) => {
      console.log(error)
      if (onFailure) onFailure()
    }
  )
}

export const visitor = {
  queueIn,
  cancelAppointment,
  makeAppointment,
  changeAppointmentValue,
  changeUINotificationStatus,
  changeConsentToNotificationsStatus,
  deleteAssetFromStorage,
  getAssetLinkFromStorage,
  subscriptions: {
    usersAppointmentsInPlace: usersAppointmentsInPlaceSubscribe,
    usersUINotifications: usersUINotificationsSubscribe,
  },
}
