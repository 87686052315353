import { boolean, Infer, number, enums, string, type, array, optional } from 'superstruct'

export const FormFieldOptionStruct = type({
  id: string(),
  value: string(),
})

export const FormFieldStruct = type({
  id: string(),
  label: string(),
  position: number(),
  defaultValue: optional(string()),
  type: enums([
    'number',
    'select',
    'checkbox',
    'big-text',
    'small-text',
    'date',
    'birthdate',
    'phone',
    'email',
    'image-upload',
    'file-upload',
  ]),
  required: boolean(),
  options: optional(array(FormFieldOptionStruct)),
})

export const FormPageStruct = type({
  id: string(),
  position: number(),
  title: optional(string()),
  description: optional(string()),
  fields: array(FormFieldStruct),
})

export const FormStruct = type({
  id: string(),
  name: string(),
  pages: array(FormPageStruct),
})

export type Form = Infer<typeof FormStruct>
export type FormPage = Infer<typeof FormPageStruct>
export type FormField = Infer<typeof FormFieldStruct>
export type FormFieldOption = Infer<typeof FormFieldOptionStruct>
