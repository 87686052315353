import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { getMessaging, isSupported, Messaging, onMessage } from 'firebase/messaging'
import { getFirestore, initializeFirestore } from 'firebase/firestore'

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  region: process.env.REACT_APP_FIREBASE_APP_REGION,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID,
}

console.log('Firebase config', config)

export const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY || ''

export const app = initializeApp(config)

export const analytics = getAnalytics()

/* Firebase APIs */
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
})
export const db = getFirestore(app)

export const auth = getAuth(app)
export const functions = getFunctions(app, config.region)
export const storage = getStorage()

/* Social Sign In Method Provider */
export const googleProvider = new GoogleAuthProvider()
export const facebookProvider = new FacebookAuthProvider()

export let isMessaggingSupported = false
export let messaging: Messaging | undefined = undefined

/* Setting up firebase messaging */
isSupported()
  .then(supported => {
    console.log('Init messaging')
    isMessaggingSupported = supported
    messaging = getMessaging(app)
    onMessage(messaging, payload => {
      console.log('Message received. ', payload)

      navigator.serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then(registration => {
          const title = payload?.data?.title || '2Meters'
          const body = payload?.data?.body || 'You have a new message'

          const options = {
            title: title,
            body: body,
            icon: '/default-notification-logo.png',
          }

          registration?.showNotification(title, options)
        })
    })
  })
  .catch(e => console.log(`Init Messaging Error`, e))
