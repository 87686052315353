/* eslint-disable react/no-this-in-sfc */
import { useMemo } from 'react'
import { Box, Grid, Link, Container, Typography, useMediaQuery } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header } from 'components/Layouts/Desktop'
import * as ROUTES from 'constants/routes'
import { Place } from '@2meters/shared'
import { useWindowSize } from 'components/Layouts'
import { theme } from 'themes/theme'
import { useCurrentUser } from 'store/useCurrentUser'

type PlaceHeaderProps = {
  navigationLink?: boolean
  place?: Place
}

type NavigationItemProps = {
  component: typeof RouterLink | typeof HTMLAnchorElement
  key: string
  to: string | { pathname: string }
  target: '_blank' | '_self'
  text: string
  active: boolean
}

const PlaceHeader: React.FC<PlaceHeaderProps> = ({ place, navigationLink = true }) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const authUser = useCurrentUser(state => state.authUser)

  const windowWidth = useWindowSize()[0]
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const navigation = useMemo(
    (): NavigationItemProps[] => [
      {
        key: 'settings',
        component: RouterLink,
        target: '_self',
        to: ROUTES.ADMIN.PLACE_PAGE.replace(':id', place?.id!),
        text: intl.formatMessage({ id: 'admin.place.navigation.overview' }),
        get active() {
          return (
            pathname === this.to ||
            pathname === ROUTES.ADMIN.PLACE_EDIT.replace(':id', place?.id!) ||
            pathname === ROUTES.ADMIN.PLACE_QR.replace(':id', place?.id!)
          )
        },
      },
      {
        key: 'queues',
        component: RouterLink,
        target: '_self',
        to: ROUTES.ADMIN.COUNTER_PAGE.replace(':id', place?.id!),
        text: intl.formatMessage({ id: 'admin.place.navigation.queues' }),
        get active() {
          return pathname === this.to
        },
      },
      {
        key: 'monitor',
        component: RouterLink,
        target: '_self',
        to: ROUTES.ADMIN.PLACE_MONITOR_PAGE.replace(':id', place?.id!),
        text: intl.formatMessage({ id: 'admin.place.navigation.monitor' }),
        get active() {
          return pathname === this.to
        },
      },
      {
        key: 'analytics',
        component: RouterLink,
        target: '_self',
        to: ROUTES.ADMIN.STATISTICS.replace(':id', place?.id!),
        text: intl.formatMessage({ id: 'admin.place.navigation.dashboard' }),
        get active() {
          return pathname === this.to
        },
      },
    ],
    [intl, place, pathname]
  )

  return (
    <Header
      SubHeader={
        <>
          {!authUser?.isAnonymous && (
            <Box sx={classes.placeHeader} pt={1}>
              <Container sx={classes.container}>
                <Grid
                  container
                  spacing={matches ? 1 : 3}
                  justifyContent='space-between'
                  style={{
                    gridTemplateColumns: `minmax(max-content, 1fr) repeat(${navigation.length}, max-content) minmax(min-content, 1fr)`,
                    display: 'grid',
                  }}
                  sx={classes.container}
                >
                  <Grid item style={{ whiteSpace: 'nowrap' }}>
                    <Link
                      to={ROUTES.ADMIN.PLACES_LIST}
                      component={RouterLink}
                      color='textPrimary'
                      underline='none'
                      variant='body2'
                      sx={classes.navigationLink}
                    >
                      &lt;{' '}
                      {matches ? (
                        <FormattedMessage id='admin.place.navigation.back' />
                      ) : (
                        <FormattedMessage id='admin.place.navigation.backToPlaces' />
                      )}
                    </Link>
                  </Grid>
                  {navigationLink &&
                    navigation.map(({ key, to, target, text, active }) => (
                      <Grid key={key} item>
                        <Link
                          to={to}
                          component={RouterLink}
                          color='textPrimary'
                          underline='none'
                          variant='body2'
                          target={target}
                          sx={classes.navigationLink}
                          data-active={active}
                        >
                          {text}
                        </Link>
                      </Grid>
                    ))}
                  <Grid item />
                </Grid>
              </Container>
            </Box>
          )}
        </>
      }
    >
      {windowWidth > 860 && (
        <>
          {place && (
            <Box maxWidth='45vw' margin='auto'>
              <Typography sx={classes.placeName} variant='body1' align='center' color='textPrimary'>
                {place.name}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Header>
  )
}

const classes = {
  placeHeader: {
    height: 40,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  placeName: {
    maxWidth: '45vw',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 600,
  },
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingRight: '2px',
    },
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingLight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4),
    },
  },
  navigationLink: {
    fontWeight: 600,
    position: 'relative',
    '&[data-active=true]::after': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: 4,
      backgroundColor: 'primary.main',
      left: 0,
      bottom: -12,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}

export default PlaceHeader
