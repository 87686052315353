export default {
  'weekdays.0': 'Неділя',
  'weekdays.1': 'Понеділок',
  'weekdays.2': 'Вівторок',
  'weekdays.3': 'Середа',
  'weekdays.4': 'Четвер',
  'weekdays.5': 'Пʼятниця',
  'weekdays.6': 'Saturday',

  'wd.1': 'Пн',
  'wd.2': 'Вт',
  'wd.3': 'Ср',
  'wd.4': 'Чт',
  'wd.5': 'Пт',
  'wd.6': 'Сб',
  'wd.7': 'Нд',
  'wd.0': 'Нд',

  'en-GBLang': 'English',
  'de-DELang': 'Deutsch',
  'lt-LTLang': 'Lietuvis',
  'es-ESLang': 'Español',
  'pt-BRLang': 'Português',
  'uk-UALang': 'Українська',
  'tr-TRLang': 'Türkçe',
  EUR: 'Євро',
  USD: 'Долар США',
  GBP: 'Англійський Фунт',
  INR: 'Індійська Рупія',
  UAH: 'Українська гривня',
  JPY: 'Японська ієна',
  BTC: 'BTC',
  TRY: 'Турецька ліра',
  Rand: 'Південноафриканський ранд',
  generalError: 'Помилка',
  error: '{error}',
  enabled: 'Включено',
  disabled: 'Виключено',
  appointments: 'Записи на відвідування',
  livequeues: 'Черги',
  livequeue: 'Черга',
  info: 'Інформація',
  ok: 'OK',
  today: 'Сьогодні',
  delete: 'Видалити',
  remove: 'Прибрати',
  email: 'Email',
  role: 'Роль',
  admin: 'Адміністратор',
  manager: 'Менеджер',
  cancel: 'Відміна',
  create: 'Створити',
  upgrade: 'Оновити',
  update: 'Актуалізувати',
  invite: 'Запросити',
  queueIn: 'Вхідна черга',
  edit: 'Редагувати',
  sure: 'Ви впевнені?',
  yes: 'Так',
  no: 'Ні',
  add: 'Додати',
  save: 'Зберегти',
  go: 'Вперед',
  next: 'Далі',
  leave: 'Вийти',
  yourAppointments: 'Ваші записи',
  yourAppointment: 'Ваші записи',
  address: 'Адреса',
  copyToClipboard: 'Копіювати',
  copiedToClipboard: 'Текст скопійовано',
  showQRCode: 'Показати QR код',
  showPDF: 'Показати PDF',
  showNumberTickets: 'Кількість квитків',
  clipboardCopyError: 'Не зкопіювалося в буфер обміну',
  imageUploadFailed: 'Не вдалося завантажити картинку',
  uploadLogo: 'Завантажити логотип',
  thankYou: 'Наступний тиждень',

  // Not Found Page
  'notfoundpage.header': 'Сторінку не знайдено',
  'notfoundpage.message':
    'Вказаний файл не знайдено на цьому веб-сайті. Перевірте URL-адресу на наявність помилок та повторіть спробу.',

  // Reset Password
  'resetPassword.resetPassword': 'Скинути пароль',
  'resetPassword.newPassword': 'Новий пароль',
  'resetPassword.confirmNewPassword': 'Підтвердіть новий пароль',
  'resetPassword.currentPassword': 'Поточний пароль',
  'resetPassword.changePassword': 'Змінити пароль',
  'resetPassword.currentPasswordWrong': 'Поточний пароль невірний',
  'resetPassword.linkNotValid':
    'Ваше посилання для скидання недійсне. Будь ласка, надішліть запит повторно',
  'resetPassword.dontMatch': 'Паролі не збігаються',
  'resetPassword.passwordChanged': 'Змінено пароль',
  'resetPassword.signNewPassword': 'Тепер ви можете увійти за новим паролем',
  'resetPassword.errorEncountered': 'Виникла помилка',
  'resetPassword.modeIsInvalid': 'Вибраний режим сторінки є недійсним',
  'resetPassword.logout': 'Вихід з системи',
  'resetPassword.logout.title': 'Ваш пароль змінено',
  'resetPassword.logout.descritption': 'Будь ласка, увійдіть до свого облікового запису ще раз',

  // Update Page
  'rolledOutUpdate.reload': 'Перезавантажити сторінку',
  'rolledOutUpdate.header': 'Вийшло оновлення!',
  'rolledOutUpdate.message': 'Випущено нове оновлення. Будь ласка, перезавантажте сторінку!',

  //*************** VISITOR *******************/

  'dialogs.pageIsForMobile':
    'Ви переглядаєте цю сторінку з браузера на комп’ютері. ' +
    'Будь ласка, відкрийте посилання у своєму мобільному браузері, якщо ви хочете, щоб заброньована зустріч була доступна на вашому смартфоні.',
  'dialogs.pageIsForMobileTitle': 'Використовуйте свій мобільний пристрій',
  'dialogs.messagemodal.walkin': 'Жива черга',
  'dialogs.whatsapp.text':
    'Ми рекомендуємо вам підключитися до сповіщень WhatsApp, якщо ви хочете знати, скільки людей чекають перед вами або скільки триватиме ваш час очікування. Натисніть кнопку «Підключити», щоб відкрити WhatsApp та надіслати нам повідомлення, яке ми підготували, не змінюючи його.',
  'dialogs.whatsapp.connect': 'Підключити',
  'dialogs.whatsapp.urlText':
    'Я%20хочу%20отримувати%20сповіщення,%20що%20містять%20інформацію%20про%20зміну%20статусу%20мого%20квитка',

  // Main
  'home.queues': 'Ваші живі черги',
  'home.queue.showCode': 'Покажіть цей номер за запитом',
  'home.queue.peopleAhead': 'Попереду вас є люди',
  'home.placesNearby': 'Місця поблизу',
  'home.noPlacenear': 'Поруч з вами немає зареєстрованого місця',
  'home.enableGeoLocation':
    'Ви вимкнули геолокацію. Будь ласка, увімкніть його в налаштуваннях браузера, щоб використовувати цю функцію',
  'home.bookASpot': 'Щоб записатися на прийом або стати в цифрову чергу',
  'home.scanQR': 'відскануйте QR-код',
  'home.enterShortUrl': 'або введіть короткий код місця',
  'home.enterShortcode': 'Введіть короткий код',
  'home.selectNearby': 'або просто виберіть місце поблизу',
  'home.useToBook': 'Скористайтеся пошуком або сканером QR-коду, щоб забронювати',
  'home.findNearby': 'Знайдіть місце поблизу',
  'home.loginAsAdmin': 'Увійдіть як адміністратор місця',

  //LiveQueueTab
  'messaging.permissionGranted': 'Дозвіл на сповіщення надано',
  'messaging.permissionNotGranted':
    'Ви не отримуватимете жодних сповіщень, коли програма працює у фоновому режимі, коли ваша черга прогресує. Будь ласка, дозвольте сповіщення.',
  'messaging.tokenError': 'Щось пішло не так',
  'messaging.webPushNotificationsNotSupported':
    'Ваш браузер не підтримує веб-повідомлення. Ви не отримаєте жодного ' +
    'сповіщення в міру розвитку черги. Будь ласка, зверніть увагу на кількість людей у рядку, що відображається нижче.',

  //AppointmentsPage
  'appointment.disabledButtonHint': 'Зробити запис',
  'appointment.dayOff': 'У цей час місце закрите. Будь ласка, виберіть іншу дату.',
  'appointment.visitorInformation': 'Інформація про Вас',
  'appointment.visitorInformation.hint': 'Будь ласка, надайте про себе наступну інформацію',
  'appointment.customInformation': 'Додаткова інформація',
  'appointment.customInformation.hint': 'Будь ласка, надайте додаткову інформацію',
  'appointment.visitorInformation.numberOfVisitors': 'Кількість відвідувачів',
  'appointment.visitorInformation.numberOfVisitors.alert': 'Скільки людей приходить?',
  'appointment.visitorInformation.counter': 'Лічильник',
  'appointment.visitorInformation.notRequired': 'Контактні дані не потрібні',
  'appointment.visitorPhoneNumValidationStep': 'Перевірка номера телефону',
  'appointment.visitorPhoneNumValidationStep.hint':
    'Ми надіслали вам код на адресу {phone}. Будь ласка, введіть його в поля нижче.',
  'appointment.waitToSendAgain': 'Надішліть код знову через {sec} секунд',
  'appointment.sendAgain': 'Надішліть ще раз',
  'appointment.services': 'Послуги',
  'appointment.services.hint': 'Виберіть послугу, яку б хотіли отримати',
  'appointment.order.hint':
    'Виберіть продукти, які хочете замовити. Зверніть увагу, що кількість одиниць послуги у одному замовленні може бути обмежена.',
  'appointment.order': 'Замовлення',
  'appointment.visitorSelectDate': 'Виберіть дату',
  'appointment.noMoreToday': 'Сьогодні більше немає доступних записів',
  'appointment.add': 'Записатися',
  'appointment.cancelAppointment': 'Відмінити запис',
  'appointment.leaveQueue': 'Скасувати талон черги',
  'appointment.next': 'Наступний крок: {step}',
  'appointment.next.contactInfo': 'Контактна інформація',
  'appointment.next.customFields': 'Додаткова інформація',
  'appointment.next.timeslot': 'Час',
  'appointment.next.messagingPrompt': 'Сповіщення',
  'appointment.next.phoneNumValidation': 'Перевірка номера телефону',
  'appointment.next.book': 'Бронювати',
  'appointment.next.whatsapp': 'WhatsApp повідомлення',
  'appointment.next.numberOfVisitors': 'Кількість відвідувачів',
  'appointment.next.queueIn': 'Зараз у черзі',
  'appointment.next.services': 'Послуги',
  'appointment.next.products': 'Продукти',
  'appointment.next.submit': 'Відіслати',
  'appointment.messagingPrompt': 'Увімкнути сповіщення',
  'appointment.submit': 'Подати запит',
  'appointment.submit.error': 'Під час подання запиту сталася помилка',
  'appointment.submit.standby': 'Будь ласка, хвилиночку',
  'appointment.cancel': 'Відміна',
  'appointment.back': 'Назад',
  'appointment.chooseTime': 'Виберіть час',
  'appointment.makeAnother': 'Зробити новий запис',
  'appointment.queueInAgain': 'Знову зайти у чергу',
  'appointment.nickname': 'Додайте імʼя, яким Вас можна називати',
  'appointment.group': 'Скільки людей приходить',
  'appointment.openHours': 'Години роботи:',
  'appointment.contacts': 'Контакти:',
  'appointment.description': 'Загальний опис:',
  'appointment.address': 'Адреса',
  'appointment.comment': 'Коментар',
  'appointment.birthDate': 'Дата народження',
  'appointment.visitType': 'Сервіс',
  'appointment.shareName': 'Ваше імʼя',
  'appointment.sharePhone': 'Телефонний номер',
  'appointment.phoneMask': '(+12-345-678900)',
  'appointment.sharePhone.search': 'Пошук',
  'appointment.sharePhone.searchNotFound': 'Немає записів для показу',
  'appointment.sharePhone.inValid':
    'Номер телефону недійсний. Будь ласка, спробуйте ввести свій номер у міжнародному форматі, наприклад: +1123456789',
  'appointment.shareEmail': 'Email',
  'appointment.shareAddress': 'Адреса',
  'appointment.shareBirthDate': 'Дата народження',
  'appointment.sure': 'Ви впевнені?',
  'appointment.vaccinationStatus': 'Статус вакцинації від Covid-19',
  'appointment.not_vaccinated': 'Не вакцинований',
  'appointment.partially_vaccinated': 'Частково вакцинований',
  'appointment.fully_vaccinated': 'Повністю вакцинований',
  'appointment.time': 'Дата та час',
  'appointment.invitedAt': 'Запрошено на',
  'appointment.queuedAt': 'У черзі на',
  'appointment.closedTime': 'Зачинено. Час роботи:',
  'appointment.opensToday': 'Зачинено. Відкривається сьогодні о',
  'appointment.ticket': 'Квиток',
  'appointment.position': 'Перед вами',
  'appointment.queueLength': 'В черзі',
  'appointment.eta': 'Приблизний час',
  'appointment.pleaseProceed': 'Будь ласка, продовжуйте через 5 хвилин',
  'appointment.header': 'Ваші квитки',
  'appointment.estimatedWaitingTime': 'Орієнтовний час очікування',
  'appointment.waitingTime': 'Час очікування',
  'appointment.min': 'Хв',
  'appointment.people': 'Людей',
  'appointment.error.invalidFormFields':
    'Будь ласка, заповніть виділені поля форми, щоб продовжити',
  'appointment.error.invalidVisitType': 'Будь ласка, вкажіть причину вашого візиту, щоб продовжити',
  'appointment.error.noSlotSelected': 'No time slot selected',
  'appointment.confirmation.preliminaryWarning':
    'Це бронювання є попереднім, поки ви не отримаєте листа-підтвердження.',
  'appointment.confirmation.rejectedWarningAppointment':
    'Вибачте, але адміністратор скасував вашу зустріч. Спробуйте забронювати інший.',
  'appointment.confirmation.rejectedWarningLivequeue':
    'Вибачте, але адміністратор скасував ваше подання. Спробуйте знову стати в чергу.',
  'appointment.products.error.maxOrder':
    'Замовлення може містити до {quantity} елементів. Будь ласка, зменште кількість.',
  'appointment.products.price': 'Ціна',
  'appointment.products.inStock': 'В наявності',
  'appointment.products.outofstock': 'Немає в наявності',
  'appointment.confirmation.appointmentHasPreference':
    'Зверніть увагу, що записи, заброньовані заздалегідь, можуть мати пріоритет перед живою чергою',

  'appointment.disabled': 'Адміністратор вимкнув запiси для цього місця.',

  'appointment.messaging.pending':
    'Щоб мати змогу сповіщувати вас про оновлення статусу запису, ми маємо увімкнути push-повідомлення.' +
    'Натисність <b>дозволити</b> якщо ви згодні.',
  'appointment.messaging.denied':
    'Дозволи на надсилання сповіщень не ввімкнено. Ви не отримуватимете push-сповіщень',
  'appointment.messaging.granted': 'Дозвіл на надсилання push-повідомлення надано. Дякую!',

  //LiveQueuetab
  'livequeue.peopleNum': 'Кількість людей у черзі',
  'livequeue.yourNumberInQueue': 'Your position in the queue',
  'livequeue.youAreNext': 'Ваше місце в черзі',
  'livequeue.leave': 'Залишити чергу',
  'livequeue.showAtRequest': 'Покажіть цей квиток за запитом',
  'livequeue.submit': 'Встати в чергу',
  'livequeue.liveQueuePaused':
    'Адміністратор місця тимчасово зупинив цифрову чергу. Будь-ласка спробуйте пізніше.',
  'livequeue.liveQueueOpenHours':
    'Ви намагаєтеся стати в чергу в неробочий час. Будь ласка, поверніться пізніше',
  'livequeue.disabled': 'Адміністратор вимкнув цифрову чергу для цього місця.',
  'livequeue.queueTooLong':
    'Адміністратор обмежив кількість людей у черзі. Будь-ласка спробуйте пізніше.',

  //MonitorView
  'monitor.pleaseProceed': 'Будь-ласка продовжуйте',
  'monitor.livequeue': 'Жива черга',
  'monitor.peopleInQueue': 'Більше квитків',
  'monitor.appointments': 'Заплановані записи',
  'monitor.appointment.type.appointment': 'записи',
  'monitor.appointment.type.livequeue': 'Жива черга',

  'monitor.stats': 'Заповненість сьогодні',
  'monitor.pleaseScanAppointments': 'Відскануйте цей QR-код, щоб запланувати запис',
  'monitor.pleaseScanLivequeue': 'Відскануйте цей QR-код, щоб стати в чергу',
  'monitor.pleaseScanIntegrated': 'Відскануйте цей QR-код, щоб стати в чергу або зробити запис',
  'monitor.followThisLink': 'Or simply follow this URL',

  // Notifications
  'notifications.title': 'Сповіщення',
  'notifications.informationBanner.sms': 'SMS відіслано',
  'notifications.informationBanner.whatsapp': 'Whatsapp відіслано',
  'notifications.progressBar.freeSms': 'Безкоштовний рівень є наявним',
  'notifications.progressBar.exhaustedSms': 'Безкоштовні sms використані',
  'notifications.informationBanner.totalCost': 'Загальна вартість',
  'notifications.progressBar.exhaustedWhatsapp': 'Безкоштовний пул WhatsApp вичерпано',
  'notifications.informationBanner.totalSmsCost': 'Загальна вартість SMS',
  'notifications.informationBanner.totalWhatsappCost': 'Загальна вартість Whatsapp',
  'notifications.table.recipient': 'Отримувач',
  'notifications.table.date': 'Дата',
  'notifications.table.time': 'Час',
  'notifications.table.place': 'Місце',
  'notifications.table.type': 'Тип',
  'notifications.table.price': 'Ціна',
  'notifications.table.country': 'Країна',
  'notifications.table.message': 'Повідомлення',
  'notifications.empty.header':
    'Ваші місця ще не генерували жодних платних сповіщень за вказаний період часу',
  'notifications.filter.startDate': 'Дата початку',
  'notifications.filter.endDate': 'Дата закінчення',
  'notifications.exportTable.status': 'Статус',
  'notifications.exportTable.type': 'Тип',
  'notifications.exportTable.isEmpty': 'є пустим',

  // Export Appointments
  'exportAppointments.tickets': 'Квитки',
  'exportAppointments.tag': 'Тег',
  'exportAppointments.date': 'Дата',
  'exportAppointments.time': 'Час',
  'exportAppointments.service': 'Обслуговування',
  'exportAppointments.name': 'Ім`я',
  'exportAppointments.status': 'Статус',
  'exportAppointments.statusChangeDate': 'Дата зміни статусу',
  'exportAppointments.statusChangeTime': 'Час зміни статусу',
  'exportAppointments.phone': 'Телефон',
  'exportAppointments.email': 'Електронна пошта',
  'exportAppointments.type': 'Тип',
  'exportAppointments.address': 'Адреса',
  'exportAppointments.birthDate': 'Дата народження',
  'exportAppointments.comments': 'Коментарі',
  'exportAppointments.appointment': 'Призначення',
  'exportAppointments.queue': 'Черга',

  //Cookies Concent
  'cookies.concent':
    'Цей веб-сайт використовує лише технічно необхідні файли cookie, вибір яких не можна скасувати. Необхідні файли cookie допомагають зробити веб-сайт придатним для використання, увімкнувши основні функції, такі як навігація сторінкою та доступ до безпечних областей веб-сайту. Веб-сайт не може працювати належним чином без цих файлів cookie.',
  'cookies.ok': 'Зрозуміло!',

  'dialogs.pageIsForDesktop':
    'Ви переглядаєте цю сторінку зі свого мобільного браузера. ' +
    'Ця сторінка оптимізована для настільного комп’ютера. Деякі функції можуть бути недоступними ' +
    'та сторінка може бути відтворена з помилками.',
  'dialogs.pageIsForDesktopTitle': 'Перегляд з настільного компʼютера',
  'dialogs.reject': 'Не погоджуюся',
  'dialogs.confirm': 'Згода',

  //*************** ADMIN *******************/

  trial: 'Start trial',

  // SignUp
  'signUp.referralCode': 'Введіть реферальний код, якщо він у вас є',

  // Login
  'login.header': 'Дякуємо за вибір 2meters! ',
  'login.header2': 'Ми створили наш сервіс, щоб допомогти вам ',
  'login.text1': 'створити зручну систему онлайн-бронювання, яка сподобається вашим клієнтам',
  'login.text2':
    'створити інноваційне рішення цифрової черги для вашого магазину або сервісу, щоб утримувати старих клієнтів та залучати нових, позбавляючись від дратівливих черг, де б вони не з’являлися',
  'login.text3':
    'або зробити те та інше, оскільки наше рішення поєднує обидві функції. Дозвольте вашому клієнту робити запис на певний час, якщо час очікування в черзі занадто довгий',
  'login.google': 'Увійти за допомогою Google',
  'login.facebook': 'Увійти за допомогою Facebook',
  'login.withPassword': 'Увійти за допомогою Email',
  'login.newUser': 'Зареєструватися за допомогою  2Meters',
  'login.existingUser': 'Увійти за допомогою 2Meters',
  'login.signUp': 'Зареєструватися',
  'login.signIn': 'Увійти',
  'login.email': 'Email',
  'login.email.placeholder': 'example@domain.com',
  'login.password': 'Пароль',
  'login.password.placeholder': '8+ символів',
  'login.passwordRepeat': 'Повторити пароль',
  'login.passwordRepeat.placeholder': '8+ символів',
  'login.forgotPassword': 'Забули пароль?',
  'login.passwordsDontMatch': 'Паролі не співпадають',
  'login.sendRecoveryEmail':
    'Ми надішлемо вам посилання для зміни пароля. Будь ласка, повідомте нам електронну пошту, на яку ви зареєструвалися',
  'login.checkEmail': 'Перевірте свою електронну пошту та дотримуйтесь інструкцій',
  'login.resetPassword': 'Скинути пароль',
  'login.recoveryEmailSent': 'Електронну пошту для відновлення надіслано на адресу {email}',
  'login.passwordTooShort': 'Пароль має містити не менше 8 символів',
  'login.dontHaveAccount': 'Немає облікового запису?',
  'login.alreadyHaveAccount': 'Вже маєте обліковий запис?',
  'login.enterDetails': 'Введіть деталі нижче',
  'login.alreadyRegistered': 'Увійти',
  'login.getStarted': 'Розпочати',
  'login.acceptTerms': 'Я приймаю',
  'login.termsAndConditions': 'Правила та умови',
  'login.termsNotChecked': 'Будь ласка, прийміть наші Загальні положення та умови, щоб продовжити',
  'login.acceptPrivacyPolicy': 'Я погоджуюсь',
  'login.privacyPolicy': 'Політика конфіденційності',
  'login.privacyPolicyNotChecked':
    'Будь ласка, прийміть нашу політику конфіденційності, щоб продовжити',
  'login.missingTermsConsent.title': 'Ще один крок',
  'login.missingTermsConsent.desc':
    'Будь ласка, прийміть наші Загальні положення та умови та Політику конфіденційності, щоб отримати доступ до нашого сервісу. Дякую!',

  'customerProfileForm.firstName': 'Ім`я',
  'customerProfileForm.lastName': 'Прізвище',
  'customerProfileForm.email': 'Електронна пошта',
  'customerProfileForm.organization': 'Назва організації',
  'customerProfileForm.country': 'Країна',
  'customerProfileForm.streetAddress': 'Адреса вулиці',
  'customerProfileForm.postalCode': 'Поштовий індекс',
  'customerProfileForm.city': 'Місто',
  'customerProfileForm.vatId': 'Номер платника ПДВ',
  'customerProfileForm.referralCode': 'Реферальний код',
  'customerProfileForm.placeholder.firstName': 'Будь ласка, введіть ім`я',
  'customerProfileForm.placeholder.lastName': 'Будь ласка, введіть прізвище',
  'customerProfileForm.placeholder.email': 'Будь ласка, введіть email',
  'customerProfileForm.placeholder.organization': 'Будь ласка, введіть назву організації',
  'customerProfileForm.placeholder.country': 'Будь ласка, введіть країну',
  'customerProfileForm.placeholder.streetAddress': 'Будь ласка, введіть адресу',
  'customerProfileForm.placeholder.postalCode': 'Будь ласка, введіть поштовий індекс',
  'customerProfileForm.placeholder.city': 'Будь ласка, введіть місто',
  'customerProfileForm.placeholder.vatId':
    'Будь ласка, введіть ідентифікаційний номер платника ПДВ',
  'customerProfileForm.placeholder.referralCode': 'Будь ласка, введіть реферальний код',
  'customerProfileForm.industry': 'Промисловість',
  'customerProfileForm.industry.governmental': 'Урядовий',
  'customerProfileForm.industry.education': 'Освіта',
  'customerProfileForm.industry.healthcare': 'Healthcare',
  'customerProfileForm.industry.retail': 'Роздрібна торгівля',
  'customerProfileForm.industry.wholesale': 'Оптова торгівля',
  'customerProfileForm.industry.rental': 'Оренда',
  'customerProfileForm.industry.banking': 'Банківська справа/фінанси',
  'customerProfileForm.industry.transportation': 'Транспорт',
  'customerProfileForm.industry.hospitality': 'Гостинність',
  'customerProfileForm.industry.hotel': 'Готельно-відпочинковий',
  'customerProfileForm.industry.travel': 'Подорожі та туризм',
  'customerProfileForm.industry.other': 'Інший',

  // Onboarding
  'onboarding.wait':
    'Будь ласка, зачекайте, поки ми створимо ваш профіль користувача i налаштуємо все необхідне',
  'onboarding.createPlace.wait': 'Будь ласка, зачекайте, поки ми завантажуємо...',
  'onboarding.loading': 'Завантаження...',
  'onboarding.back': 'Назад',
  'onboarding.next': 'Вперед',
  'onboarding.skip': 'Пропустити до наступного пустого місця',
  // Onboarding: Step 1
  'onboarding.step1.header': 'Створення запису за допомогою 2Meters',
  'onboarding.step1.text.p1':
    'Щоб увімкнути записи, просто налаштуйте своє місце, заповнивши його основні дані:',
  'onboarding.step1.text.list1.item1': 'Назва та місцезнаходження',
  'onboarding.step1.text.list1.item2': 'Робочі дні та години',
  'onboarding.step1.text.list1.item3': 'Типова тривалість прийому',
  'onboarding.step1.text.p2':
    'Щоб забезпечити ще краще обслуговування, ви можете створити список послуг або товарів, які ви пропонуєте, щоб клієнти могли заздалегідь повідомити вам причину свого візиту.',
  'onboarding.step1.text.p3':
    'Для повного контролю над своїм розкладом ви можете ввімкнути функцію, яка дозволяє вам підтверджувати або відхиляти записи, попередньо заброньовані вашими клієнтами.',
  // Onboarding: Step 2
  'onboarding.step2.header': 'Створення запису за допомогою 2Meters',
  'onboarding.step2.text.p1':
    'Після налаштування просто скопіюйте надане посилання в електронний лист або розмістіть його на своєму веб-сайті, де ваші клієнти зможуть натиснути його або натиснути на нього.',
  'onboarding.step2.text.p2':
    'Крім того, ви можете інтегрувати наш інтерактивний інтерфейс у свій веб-сайт за допомогою фрагмента коду.',
  // Onboarding: Step 3
  'onboarding.step3.header': 'Створення запису за допомогою 2Meters',
  'onboarding.step3.text.p1':
    'Ласкаво просимо ваших клієнтів на першу зустріч! Ви можете легко ідентифікувати їх, попросивши надати електронні квитки, збережені на їхніх смартфонах.',
  'onboarding.step3.text.p2':
    'Якщо вам доводиться обробляти запити відвідувачів в порядку живої черги додатково до попередніх записів - в нас є функція цифрової черги, що легко об’єднує обидва потоки клієнтів. Але як це працює?',
  // Onboarding: Step 4
  'onboarding.step4.header': 'Усуваємо черги за допомогою 2Meters',
  'onboarding.step4.text.p1':
    'Наша цифрова черга пропонує саме те, чого очікують ваші клієнти від сучасної концепції обслуговування – смартфони стоять у черзі, поки їхні власники можуть вийти на прогулянку, або випити чашку кави.',
  'onboarding.step4.text.p2':
    'Ми сповіщаємо вашого клієнта про переміщення черги, щоб він міг з’явитися вчасно.',
  'onboarding.step4.text.p3':
    'Щоб створити цифрову чергу, просто увімкніть цю функцію під час створення свого місця.',
  // Onboarding: Step 5
  'onboarding.step5.header': 'Усуваємо черги за допомогою 2Meters',
  'onboarding.step5.text.p1':
    'Роздрукуйте PDF-файл із наданим QR-кодом та розмістіть його в будь-якому місці, де ви хочете, щоб ваші клієнти стояли в цифровій черзі.',
  'onboarding.step5.text.p2':
    'Налаштуйте дисплей, щоб відображати всі квитки, які наближаються та обслуговуються, та зробіть своє місце схожим на сучасний сервісний центр Кремнієвої долини.',
  // Onboarding: Step 6
  'onboarding.step6.header': 'Усуваємо черги за допомогою 2Meters',
  'onboarding.step6.text.p1':
    'Є багато інших функцій, які варто вивчити. Зв’яжіться з нами, якщо вам потрібна додаткова інформація про те, як налаштувати цифрову чергу відповідно до вашого особистого випадку. Ми підготували для вас кілька попередньо налаштованих шаблонів. Виберіть шаблон і налаштуйте його відповідно до ваших потреб або створіть нове місце. Оберіть, що саме ви б хотіли зробити?',
  'onboarding.step6.btn1': 'Створіть тестове місце з живою чергою (барбершоп або перукарня)',
  'onboarding.step6.btn2': 'Створіть тестове місце із зустрічами (барбершоп або перукарня)',
  'onboarding.step6.btn3': 'Створіть систему запису на зустріч або цифрову чергу',

  'onboarding.template.barbershop.name': 'Тестове місце із зустрічами (барбершоп або перукарня)',
  'onboarding.template.barbershop.description':
    'Beard is a trend. The hair on the face only becomes really beautiful with good care and perfect styling. We open up a place where men can relax and be among themselves. You can expect individual advice on hair / beard care and styling, but well-groomed hands and feet are also part of it! In a classic atmosphere - between stylish leather armchairs, wooden accessories, brick walls and an integrated whiskey and cigar lounge - there is filing, peeling and shaving.',
  'onboarding.template.barbershop.service1': 'Regular haircut',
  'onboarding.template.barbershop.service2': 'Beard cut',
  'onboarding.template.barbershop.service3': 'Dye',
  'onboarding.template.barbershop.service4': 'Contour cut',
  'onboarding.template.default.service1': 'My service 1',
  'onboarding.template.default.service2': 'My service 2',
  'onboarding.template.covid.name': 'Covid Test Center (Template)',
  'onboarding.template.popup.name': 'Walk-In PopUp Shop (Template)',
  'onboarding.template.shopping.name': 'Appointment Shopping (Template)',
  'onboarding.template.restaraunt.name': 'Restaraunt (Template))',

  // Place
  'admin.place.settings': 'Налаштування',
  'admin.place.editSetting': 'Редагувати місце',
  'admin.place.editManagers': 'Редагувати менеджерів',
  'admin.place.navigation.overview': 'Огляд',
  'admin.place.navigation.dashboard': 'Дашборд',
  'admin.place.navigation.queues': 'Віконце',
  'admin.place.navigation.monitor': 'Монітор',
  'admin.place.navigation.billing': 'Біллінг',
  'admin.place.navigation.backToPlaces': 'Назад до списку місць',
  'admin.place.navigation.back': 'Назад',
  'admin.place.averageDuration': 'Тривалість слоту (хвилини)',
  'admin.place.capacity.help': 'Скільки клієнтів можуть увійти до вашого місця одночасно',
  'admin.place.capacity': 'Місткість установи (кількість клієнтів)',
  'admin.place.closingTime': 'Час закриття',
  'admin.place.openingTime': 'Час відкриття',
  'admin.place.openingDays': 'Дні відкриття',
  'admin.place.openingDate': 'Дата початку кампанії',
  'admin.place.openingDate.help':
    'Якщо ви збираєтеся використовувати 2Meters протягом обмеженого періоду, введіть дату, коли система має бути увімкнена',
  'admin.place.closingDate': 'Дата завершення кампанії',
  'admin.place.closingDate.help':
    'Якщо ви маєте намір використовувати 2Meters протягом обмеженого періоду, введіть дату, коли система має бути вимкнена',
  'admin.place.contactEmail': 'Контактна електронна адреса адміністратора місця',
  'admin.place.contactEmail.help':
    'Ви можете заповнити це поле, якщо ви хочете, щоб ваша контактна адреса електронної пошти відображалася в сповіщеннях електронною поштою, які ми надсилатимемо вашим відвідувачам (лише якщо ви попросите їх надати свою електронну адресу в цьому налаштуванні)',
  'admin.place.contactPhoneNumber': 'Контактний телефон адміністратора закладу',
  'admin.place.contactPhoneNumber.help':
    'Ви можете заповнити це поле, якщо хочете, щоб ваш контактний номер телефону відображався в сповіщеннях електронною поштою, які ми надсилатимемо вашим відвідувачам (лише якщо ви попросите їх надати свою адресу електронної пошти в цьому налаштуванні)',
  'admin.place.description': 'Будь ласка, надайте короткий опис свого місця та послуг.',
  'admin.place.description.help': 'Ваші клієнти побачать цей опис',
  'admin.place.timezone': 'Часовий пояс',
  'admin.place.toVisitors': 'Сторінка для відвідувачів',
  'admin.place.noOptions': 'Немає варіантів',
  'admin.place.printQR': 'роздрукувати QR',
  'admin.place.shortUrl':
    'Виберіть короткий код, щоб ваші відвідувачі могли швидко отримати доступ до вашої сторінки.',
  'admin.place.shortUrl.error': 'Ця коротка URL-адреса вже зайнята. Спробуйте іншу',
  'admin.place.shortUrl.help':
    'Якщо ви виберете bbq для вашого місця для барбекю, воно буде доступне з будь-якого браузера за адресою app.2meters.app/p/bbq',
  'admin.place.shortUrlInfo': 'Посилання на ваше місце',
  'admin.place.appointmentUrlInfo': 'Посилання на процес запису на зустріч',
  'admin.place.livequeueUrlInfo': 'Посилання на живу чергу',
  'admin.place.monitorUrlInfo': 'Посилання на перегляд монітора',
  'admin.place.qrCode': 'QR-код для вашого місця',
  'admin.place.address': 'Адреса вашого місця',
  'admin.place.yourAppointment': 'Ваш запис',
  'admin.place.appointments.delete': 'Видалити',
  'admin.place.appointments.cancel': 'Відміна',
  'admin.place.addAppointmentDialogTitle': 'Додати запис',
  'admin.place.addAppointmentDialogDescription':
    'До зустрічі можна додати псевдонім відвідувача та короткий опис',
  'admin.place.appointmentNickname': 'Псевдонім',
  'admin.place.appointmentDescription': 'Опис',
  'admin.place.visitType': 'Надайте список послуг, які ви пропонуєте',
  'admin.place.visitType.help':
    'Ваші клієнти повинні будуть вибрати послугу з цього списку під час бронювання зустрічі.',
  'admin.place.managers': 'Список менеджерів місць',
  'admin.place.managers.help':
    'Введіть адреси електронної пошти, щоб призначити ролі менеджера своїм співробітникам. Підтверджуйте кожну електронну пошту за допомогою Enter.',
  'admin.place.titles.settings': 'Огляд місця',
  'admin.place.wizard.titles.details': 'Деталі місця',
  'admin.place.wizard.titles.visitorData': 'Відвідувачі',
  'admin.place.wizard.titles.inventory': 'Ваш список послуг або товарів',
  'admin.place.wizard.titles.queue': 'Жива черга',
  'admin.place.wizard.titles.appointments': 'Розклад',
  'admin.place.wizard.titles.access': 'Доступ',
  'admin.place.wizard.titles.plans': 'Оберіть свій тарифний план',
  'admin.place.wizard.details': 'Деталі',
  'admin.place.wizard.visitorData': 'Відвідувачі',
  'admin.place.wizard.inventory': 'Список послуг/товарів',
  'admin.place.wizard.settings': 'Налаштування',
  'admin.place.wizard.schedule': 'Розклад',
  'admin.place.wizard.access': 'Access',
  'admin.place.wizard.plans': 'Тарифний план',
  'admin.place.wizard.actions.next': 'Далі',
  'admin.place.wizard.actions.trial': 'Почати пробну версію',
  'admin.place.wizard.actions.finish': 'Закінчити',
  'admin.place.wizard.actions.saveAll': 'Зберегти',
  'admin.place.wizard.actions.back': 'Назад',
  'admin.place.wizard.actions.cancel': 'Скасувати',
  'admin.place.wizard.actions.agree': 'Згода',
  'admin.place.wizard.actions.create': 'Створити',
  'admin.place.wizard.whatsappStep.title': 'Чи згодні ви отримувати сповіщення через WhatsApp?',
  'admin.place.wizard.whatsappStep.accept': 'Прийняти',
  'admin.place.wizard.whatsAppModal.title': 'Повідомлення WhatsApp',
  'admin.place.wizard.whatsAppModal.description':
    "Зверніть увагу, що ввімкнувши SMS-сповіщення, ви повинні враховувати, що стягується додаткова плата. Ми даруємо вам 100 безкоштовних повідомлень WhatsApp, які ви можете використовувати для тестування. Однак за будь-яке повідомлення WhatsApp, вище цього ліміту, ми стягуватимемо з вас ціну, яка залежить від країни, з якої надходять ваші одержувачі WhatsApp. Ми створюватимемо додаткові рахунки-фактури та надсилатимемо їх окремо щомісяця. Ви можете вимкнути цю функцію в будь-який час. Якщо вам потрібна детальна інформація щодо ціни, будь ласка, зв'яжіться з нашим відділом продажів за адресою ",
  'admin.place.wizard.requiredFields': 'Будь ласка, заповніть усі обов’язкові поля',
  'admin.place.wizard.smsModal.title': 'SMS-повідомлення',
  'admin.place.wizard.smsModal.description':
    "Зверніть увагу, що ввімкнувши SMS-сповіщення, ви повинні враховувати, що стягується додаткова плата. Ми надаємо вам 100 безкоштовних SMS, які ви можете використати для тестування. Однак за будь-яке SMS, вище цього ліміту, ми будемо стягувати з вас ціну, яка є на рівні цін місцевих операторів мобільного зв’язку країни, з якої приходять ваші відвідувачі. Ми створюватимемо додаткові рахунки-фактури та надсилатимемо їх окремо щомісяця. Ви можете вимкнути цю функцію в будь-який час. Якщо вам потрібна детальна інформація щодо ціни, будь ласка, зв'яжіться з нашим відділом продажів за адресою ",
  'admin.place.settings.text': 'На цій сторінці ви можете ввімкнути різні функції для свого місця',
  'admin.place.inventory.text':
    'Якщо ви продаєте якісь предмети та хочете, щоб відвідувачі замовили їх, просто додайте їх у свій інвентар. Увімкнення інвентаризації створює додатковий крок у частині програми для відвідувачів, де відвідувач може забронювати або придбати будь-який із ваших товарів. Ви можете налаштувати запас для своїх товарів або встановити їх кількість необмеженою. Ви також можете обмежити максимальну кількість предметів, які надходять в одні руки, щоб переконатися, що всі ваші клієнти матимуть змогу отримати їх.',
  'admin.place.visitorData.text':
    'Ми свідомо вирішили забезпечити повну анонімність користувачів, але в деяких випадках може знадобитися збирати дані для надання ваших послуг. Тут ви можете вибрати, що саме ви хочете знати про своїх клієнтів, коли вони записуються або стоять у черзі. Якщо ви вирішите збирати адреси їх електронних пошт, ми також зможемо надсилати їм сповіщення на ці скриньки, що також може бути зручним для клієнтів. Зауважте, що особисті дані, які ваш клієнт залишає, якщо ви вибрали будь-який варіант, видаляються з наших серверів через два дні після відвідування.',
  'admin.place.appointments.text':
    'Налаштуйте графік роботи та розклад обслуговування. Він визначає, як працюють наші цифрові функції черги та запису. Ми пропонуємо два типи керування розкладом. Простий і розширений. З простим типом керування ви встановлюєте лише робочі дні та години. З розширеним ви маєте повний контроль над розкладом.',
  'admin.place.access.text':
    'Якщо у вас є співробітники, ви можете захотіти, щоб вони мали доступ до перегляду лічильника, не повідомляючи ваші облікові дані. З огляду на це співробітники можуть керувати записами та живою чергою. Вони не можуть змінити налаштування або будь-які інші  важливі властивості вашого місця. Щоб призначити ролі менеджера своїм співробітникам, введіть їх електронні адреси нижче.',
  'admin.place.cancelDialog': 'Скасувати',
  'admin.place.rte.text': 'Текст',
  'admin.place.rte.link': 'Посилання',
  'admin.place.rte.addLink': 'Додати посилання',
  'admin.place.rte.remove': 'Видалити',
  'admin.place.rte.save': 'Save',
  'admin.place.addAppointmentManually': 'Додати',
  'admin.place.livequeue.hint': 'Увімкніть цифрову чергу на своєму місці',
  'admin.place.businessName': 'Як називається ваше місце?',
  'admin.place.fullscreen': 'Повноекранний режим',
  'admin.place.monitor': 'Екран',
  'admin.account.billingLog': 'Журнал біллінгу',
  'admin.account.account': 'Рахунок',
  'admin.account.signOut': 'Вийти з аккаунта',
  'admin.account.billing': 'Плани та біллінг',
  'admin.place.form.inventory': 'Які продукти надає ваше місце?',
  'admin.place.form.inventory.help':
    'Ваш відвідувач зможе вибрати продукт під час запису на зустріч або реєстрації в цифровій черзі. Залиште це порожнім, якщо ви не продаєте жодних продуктів.',

  'admin.place.form.settings.appointments.help':
    'Попередній запис дозволяє вашим клієнтам визначити заздалегідь визначений проміжок часу для свого відвідування',
  'admin.place.form.settings.appointments.label': 'Включити попередні записи',
  'admin.place.form.settings.livequeue.label': 'Увімкнути живу чергу',
  'admin.place.form.settings.livequeue.help':
    'На відміну від зустрічей, оперативна черга дозволяє вашим клієнтам встановлювати місце в черзі без визначеного часу, як зазвичай роблять клієнти',
  'admin.place.form.settings.livequeue.maxLength.label': 'Максимальна довжина черги',
  'admin.place.form.settings.livequeue.maxLength.help':
    'Жодні нові клієнти не можуть стояти в черзі, якщо довжина черги досягає цього числа. Видаліть значення для зняття обмеження на довжину',
  'admin.place.form.settings.livequeue.openingHours.label': 'Тільки в години роботи',
  'admin.place.form.settings.livequeue.openingHours.help':
    'Якщо це поле ввімкнено, ваші клієнти не зможуть стояти в черзі протягом робочого часу',
  'admin.place.form.settings.appointment.confirmation.label': 'Потрібне підтвердження',
  'admin.place.form.settings.appointment.confirmation.help':
    'Виберіть, чи хочете ви вручну підтверджувати чи відхиляти зустрічі. Ваші клієнти отримають сповіщення за допомогою каналів сповіщень, які ви визначили на попередніх кроках',

  'admin.place.form.settings.automation.help': 'Автоматизація',
  'admin.place.form.settings.automation.processing.help':
    'Зніміть галочку, якщо ви хочете чистити чергу вручну',
  'admin.place.form.settings.automation.processing.label':
    'хв. Після закінчення цього часу запрошені квитки буде видалено з екрана',

  'admin.place.form.settings.automation.eta.auto': 'Авто',
  'admin.place.form.settings.automation.eta.constant': 'Постійний',
  'admin.place.form.settings.automation.eta.off': 'Вимкнено',
  'admin.place.form.settings.automation.eta.label':
    'Режим обчислення розрахункового часу очікування',
  'admin.place.form.settings.automation.eta.help': `Як обчислюється час очікування, що залишився для певного квитка на чергу.\n
      Авто: враховує середній час останніх 6 оброблених квитків. \n
      Постійний: Ви можете вказати час, який він повинен зайняти, щоб обробити один квиток. \n
      Вимкнено: Оцінений час очікування не буде показано.
    `,
  'admin.place.form.settings.automation.eta.constant.label': 'хвилини.Час обробки одного квитка',
  'admin.place.form.settings.automation.eta.constant.help':
    'Він буде використаний для оцінки часу очікування в черзі',

  'admin.place.form.settings.constraints.help': 'Обмеження',
  'admin.place.form.settings.constraints.appsperuser.label':
    'Скільки квитків може взяти користувач за раз',
  'admin.place.form.settings.constraints.appsperuser.one-per-place': 'По одному',
  'admin.place.form.settings.constraints.appsperuser.one-per-service': 'Один на послугу',
  'admin.place.form.settings.constraints.appsperuser.unlimited': 'Необмежено',

  'admin.place.form.askEmail': 'Електронна пошта',
  'admin.place.form.askPhone': 'Номер телефону',
  'admin.place.form.phoneVerificationEnabled': 'SMS підтвердження',
  'admin.place.form.phoneVerificationDisabled': 'Перевірка SMS (недоступна в Приватній підписці)',
  'admin.place.form.askName': 'імʼя',
  'admin.place.form.askAddress': 'Адреса',
  'admin.place.form.askBirthDate': 'Дата народження',
  'admin.place.form.useMailMessenger': 'Повідомлення електронною поштою',
  'admin.place.form.useUIMessenger': 'Повідомлення в аплiкацii',
  'admin.place.form.useWebPushMessenger': 'Веб-повідомлення Push (лише для Android)',
  'admin.place.form.useWhatsAppMessenger': 'WhatsApp cповiщення',
  'admin.place.form.useWhatsAppMessengerDisabled':
    'WhatsApp cповiщення (недоступно в приватній підписці)',
  'admin.place.form.useSMSMessenger': 'SMS-повідомлення',
  'admin.place.form.useSMSMessengerDisabled': 'SMS-повідомлення (недоступно в приватній підписці)',
  'admin.place.form.askWhat': 'Будь ласка, виберіть обовʼязкову інформацію про користувача',
  'admin.place.form.askVaccinationStatus': 'Статус вакцинації Covid-19',
  'admin.place.form.vaccinationStatus': 'Статус вакцинації Covid-19',
  'admin.place.form.askVaccinationStatus.tooltip':
    'Відмітьте тут, якщо ви хочете, щоб відвідувачі повідомляли свій статус вакцинації',
  'admin.place.form.numberOfVisitors': 'Кількість відвідувачів',
  'admin.place.form.askNumberOfVisitors': 'Кількість відвідувачів',
  'admin.place.form.askNumberOfVisitors.tooltip':
    'Позначте це, якщо ваші відвідувачі можуть зайти групою, та ви хочете знати їх кількість',
  'admin.place.form.askWhat.help':
    'Щоб забезпечити повну анонімність відвідувачів, не відмічайте жодного прапорця. Дані відвідувачів будуть автоматично видалені з наших серверів протягом двох днів після відвідування.',
  'admin.place.form.messagingConfiguration': 'Конфігурація обміну повідомленнями',
  'admin.place.form.messagingConfiguration.help':
    'Ви можете вибрати один із кількох каналів, щоб повідомляти відвідувачам про статус їхніх квитків.',
  'admin.place.form.enableFeatures': 'Увімкнути додаткові функції',
  'admin.place.form.placeLocale': 'Мова місця за замовчуванням',
  'admin.place.form.placeLocale.help':
    'Мова, яку ми використовуватимемо для надсилання сповіщень вашим відвідувачам, якщо вони не встановили бажану мову',
  'admin.place.embedCode.title': 'Код вставки',
  'admin.place.embedCode.description':
    'Використовуйте цей код, щоб вставити віджет 2Meters у свій веб-сайт',
  'admin.place.appointments.enable': 'Увімкнути записи для цього місця',
  'admin.place.livequeue.enable': 'Увімкнути живу чергу для цього місця',
  'admin.place.form.schedulerType': 'Виберіть тип розкладу',
  'admin.place.form.schedulerType.help':
    'Розширений графік знаходиться на ранній стадії розробки. Ми не надаємо гарантій щодо цього. Будь ласка, скористайтеся ним і надайте нам відгук.',
  'admin.place.shopCapacity.help':
    'Скільки клієнтів ви можете обслуговувати одночасно. Кожен часовий інтервал може бути заброньований цим багато разів різними клієнтами',
  'admin.place.averageDuration.help':
    'Ваші робочі години будуть розділені на часові проміжки наступної тривалості',
  'admin.place.inventory.enable': 'Додати товари для цього місця',
  'admin.place.inventory.placeCurrency': 'Валюта',
  'admin.place.inventory.placeCurrency.help':
    'Це значення буде використовуватися, щоб показати вашим відвідувачам правильні ціни',
  'admin.place.inventory.inventoryMaxOrder': 'Максимальна кількість товарів на замовлення',
  'admin.place.inventory.inventoryMaxOrder.help':
    'Кількість товарів, які відвідувач може замовити у вас. Залиште це поле порожнім, якщо ваш запас необмежений.',
  'admin.place.inventory.appointmentsNotAvailable':
    'Планувальник інтервалів для зустрічей недоступний, зустрічі вимкнено',

  'admin.userProfile.title': 'Профіль користувача',
  'admin.profile.title': 'Профіль',
  'admin.profile.editProfile': 'Редагувати профіль',

  // Inventory
  'inventory.quantity': 'Запас',
  'inventory.maxPerUser': 'Ліміт',
  'inventory.newProduct': 'Новий продукт',
  'inventory.editProduct': 'Редагувати продукт',
  'inventory.edit': 'Редагувати',
  'inventory.delete': 'Видалити',
  'inventory.menu': 'Меню',
  'inventory.duplicate': 'Дублікат',
  'inventory.addProduct': 'Додати продукт',
  'inventory.enterProductData': 'Будь ласка, введіть дані про продукт',
  'inventory.productName': 'Ім’я',
  'inventory.productName.placeholder': 'Це поле не повинно бути порожнім',
  'inventory.productDescription': 'Опис',
  'inventory.productDescription.placeholder': 'Це поле не повинно бути порожнім',
  'inventory.productStock': 'Запас',
  'inventory.productStock.placeholder':
    'Будь ласка, залиште поле пустим, якщо ваш запас необмежений',
  'inventory.unlimited': 'необмежено',
  'inventory.price': 'Ціна',
  'inventory.price.placeholder': 'Ціна продукту',
  'inventory.product.crop': 'Обрізати',
  'inventory.product.cropTitle': 'Обріжте свою фотографію',
  'inventory.product.uploadImage': 'Завантажити',

  //Subscription
  'admin.subscription': 'Підписка',
  'admin.subscription.trial': 'Пробний період',
  'admin.subscription.nextInvoice': 'Наступний рахунок',
  'admin.subscription.cancelAt': 'Підписка буде скасована',
  'admin.subscription.cancel': 'Скасувати',
  'admin.subscription.sure_cancel': 'Ви впевнені, що хочете скасувати підписку?',
  'admin.subscription.cancelled': 'Підписку скасовано',
  'admin.subscription.trial_description':
    'Випробувальний період почнеться негайно та закінчиться через 30 днів. Під час пробного періоду вам будуть доступні всі функції. Ми не будемо просити у вас платіжну інформацію для початку пробної версії. До кінця пробного періоду місце буде автоматично змінено на приватний план. Якщо вам подобається наш сервіс, підпишіться в будь-який час. Пробну версію можна використати лише один раз.',
  'admin.subscription.trial_started': 'Випробувальний період розпочався та закінчиться {date}',

  //PlaceList
  'admin.places.list.empty.createNew': 'Створити нове місце',
  'admin.places.list.ticketsUsed': 'Використано квитків',
  'admin.places.list.empty.header': 'Ви ще не створили жодного місця',
  'admin.places.list.empty.body': 'Створіть нове місце та розпочніть обробляти чергу',
  'admin.places.list.header': 'Ваші місця',
  'admin.places.list.managed': 'Місця, якими ви керуєте',
  'admin.places.list.createNew': 'Створити нове місце',
  'admin.places.create.title': 'Створіть місце',
  'admin.places.update.title': 'Оновіть місце',

  //QR
  'qr.print':
    'Роздрукуйте цей документ та розмістіть його так, щоб клієнти могли легко його побачити',
  'qr.pleaseScanAppointments': 'Відскануйте цей QR-код, щоб записатися на зустріч',
  'qr.pleaseScanLivequeue': 'Відскануйте цей QR-код, щоб стати в чергу',
  'qr.pleaseScanIntegrated': 'Відскануйте цей QR-код, щоб стати в чергу або записатися на зустріч',
  'qr.followThisLink': 'Або просто перейдіть за цією URL-адресою',
  'qr.error': 'Щось пішло не так. Спробуйте пізніше, будь ласка',

  //CounterPage
  'counter.processing': 'Обробка',
  'counter.processing.add': 'Впустити наступного',
  'counter.liveQueue': 'Жива черга',
  'counter.stopQueue': 'Не призупинено',
  'counter.startQueue': 'Призупинено',
  'counter.liveQueue.add': 'Додати новий',
  'counter.appointments': 'Записи',
  'counter.calendar': 'Календар',
  'counter.appointments.add': 'Додати новий',
  'counter.appointments.dialog.header': 'Запис на прийом',
  'counter.appointments.dialog.edit.header': 'Редагувати квиток {tag}',
  'counter.appointments.dialog.comment.header': 'Додати коментар до {tag}',
  'counter.appointments.dialog.add': 'Забронювати',
  'counter.appointments.dialog.cancel': 'Скасувати',
  'counter.appointments.dialog.selectDate': 'Виберіть дату',
  'counter.appointments.dialog.pickTime': 'Виберіть час',
  'counter.appointments.dialog.availableTime':
    'Доступний час (натискайте на декілька слотів, щоб створити кілька записів одночасно)',
  'counter.appointments.dialog.selectedSlots': 'Будуть створені наступні записи',
  'counter.appointments.dialog.selectService':
    'Виберіть тип послуги, щоб побачити доступні часові проміжки',
  'counter.appointments.dialog.serviceType': 'Вид послуги',
  'counter.appointments.dialog.order': 'Запит',
  'counter.liveQueue.dialog.header': 'Записати в чергу',
  'counter.liveQueue.dialog.cancel': 'Скасувати',
  'counter.userInfo.nickname': 'Ім’я',
  'counter.userInfo.email': 'Електронна пошта',
  'counter.userInfo.phone': 'Телефон',
  'counter.userInfo.birthDate': 'Дата народження',
  'counter.userInfo.address': 'Адреса',
  'counter.toQueue': 'Записати в чергу',
  'counter.invite': 'Запросити',
  'counter.processed': 'Оброблено',
  'counter.processedTime': 'Час обробки',
  'counter.invited': 'Запрошено',
  'counter.noname': 'Без імені',
  'counter.walkin': 'Вхідна зона',
  'counter.done': 'Готово',
  'counter.error': 'Сталася помилка, зв’яжіться зі службою підтримки: {error}',
  'counter.info.dialog.notset': 'не встановлено',
  'counter.appointments.info.queueIn': 'Записатися в чергу',
  'counter.info.dialog.sureCancel': 'Ви хочете відмовити цьому клієнту?',
  'counter.info.dialog.time': 'Час',
  'counter.info.dialog.email': 'Електронна пошта',
  'counter.info.dialog.phone': 'Телефон',
  'counter.info.dialog.username': 'Ім’я',
  'counter.info': '...',

  'counter.appointment.status.noShow': "Не з'явився",
  'counter.appointment.status.waiting': 'Очікується',
  'counter.appointment.status.confirmed': 'Підтверджено',
  'counter.appointment.status.queued': 'В черзі',
  'counter.appointment.status.invited': 'Запрошено',
  'counter.appointment.status.scheduled': 'Записано',
  'counter.appointment.status.walkin': 'Вхідна зона',
  'counter.appointment.status.processed': 'Завершено',
  'counter.appointment.status.cancelled': 'Скасовано',
  'counter.appointment.status.rejected': 'Відхилено',

  'counter.table.header.ticket': 'Квиток',
  'counter.table.header.when': 'Коли',
  'counter.table.header.name': 'Ім`я',
  'counter.table.header.email': 'Електронна пошта',
  'counter.table.header.service': 'Сервіс',
  'counter.table.header.status': 'Статус',
  'counter.table.header.statusChanged': 'Статус змінено',
  'counter.table.header.type': 'Тип',

  'counter.appointment.action.noShow': "Не з'явився",
  'counter.appointment.action.invite': 'Запросити',
  'counter.appointment.action.queueIn': 'Додати до черги',
  'counter.appointment.action.done': 'Готово',
  'counter.appointment.action.confirm': 'Підтвердити',
  'counter.appointment.action.cancel': 'Скасувати',
  'counter.appointment.action.reject': 'Відмовити',
  'counter.appointment.action.edit': 'Редагувати',
  'counter.appointment.action.comment': 'Коментар',
  'counter.appointment.action.noComment': 'Ще немає коментарів',
  'counter.appointment.action.backToQueue': 'Назад до черги',
  'counter.appointment.action.backToAppointment': 'Повернутися до запису',
  'counter.showOnly': 'Показувати тільки',
  'counter.filter.all': 'всі',
  'counter.filter.allServices': 'Усі послуги',
  'counter.noAppointments': 'На вибрану дату не зроблено жодного запису',
  'counter.noAppointmentsToday': 'На сьогодні не призначені записи',
  'counter.noTickets': 'Квитків на вибрану дату немає',
  'counter.tabs.today': 'Сьогодні',
  'counter.tabs.calendar': 'Історія',
  'counter.filter.types.noShow': "Не з'явився",
  'counter.filter.types.active': 'Активні квитки',
  'counter.filter.types.rejected': 'Відмовлено',
  'counter.filter.types.cancelled': 'Скасовано',
  'counter.filter.types.processed': 'Оброблено',
  'counter.filter.types.notConfirmed': 'Не підтверджено',
  'counter.tabs.counterName': 'Назва каси',
  'counter.tabs.anyName': 'Будь-яке ім`я',

  //PlaceQRPage
  'qr.neverWaste': '2Meters. Ніколи більше не стійте в чергах.',
  'qr.header': 'Відскануйте цей qr-код, щоб зробити запис',

  // PlansPage
  'plans.text':
    'Усіми функціями послуги 2Meters можна користуватися безкоштовно, але кількість відвідувань обмежена 100 квитками на місяць. Це включає як черги, так і квитки на зустріч. З Pro планом немає жодних обмежень',
  'plans.list.private': 'Приватний',
  'plans.month.place': '/МІСЯЦЬ/МІСЦЕ',
  'plans.free': 'Безкоштовно*',
  'plans.preloader':
    'Будь ласка, зачекайте, поки ми змастимо шестерні перед обробкою Вашого платежу',
  'plans.list.pro': 'Pro',
  'plans.list.basic_monthly': 'Pro',
  'plans.list.basic_annual': 'Pro',
  'plans.list.pro_annual': 'Pro',
  'plans.list.pro_monthly': 'Pro',
  'plans.list.business': 'Enterprise',
  'plans.list.business_annual': 'Enterprise',
  'plans.list.business_monthly': 'Enterprise',
  'plans.list.enterprise': 'Enterprise',
  'plans.list.enterprise_annual': 'Enterprise',
  'plans.list.enterprise_monthly': 'Enterprise',
  'plans.list.enterprise_business_annual': 'Enterprise',
  'plans.list.trial': 'Пробний період',
  'plans.chooseAPlan': 'Виберіть план',
  'plans.ps':
    '* ми будемо стягувати додаткову плату за споживання SMS-повідомлень та повідомлень Whatsapp до кінця кожного місяця, якщо Ви вирішите ними користуватися. Ціни є типовими для країни походження номера телефону отримувача та залежать від цін місцевих провайдерів. За детальною інформацією звертайтеся до відділу продажу.',
  'plans.promo': 'Надайте своїй команді функціонал, необхідний для успіху.',
  'plans.contactUs': 'Зв’яжіться з нами',
  'plans.missingFeature': 'Пропустили функції?',
  'plans.currentPlan': 'Поточний план',
  'plans.upgradePlan': 'Оновити план',
  'plans.subscription.cancelAt': 'Дійсний до',
  'plans.cancelSubscription': 'Скасувати підписку',
  'plans.private.features.1': '100 відвідувачів на місяць',
  'plans.private.features.2': 'Необмежена кількість клієнтів додатку',
  'plans.private.features.3': 'Система живої черги',
  'plans.private.features.4': 'Система призначення слотів',
  'plans.private.features.5': 'Каталог продукції',
  'plans.private.features.6': 'Система замовлень',
  'plans.private.features.7': 'Інтуїтивно зрозумілий додаток-лічильник',
  'plans.private.features.8': 'Календар історії',
  'plans.private.features.9': 'Додаток для дисплея',
  'plans.private.features.10': 'SMS/Whatsapp/Email/Push',
  'plans.pro.features.first': 'Те саме, що і в Privat',
  'plans.pro.features.1': 'Необмежена кількість відвідувачів',
  'plans.pro.features.2': 'Необмежена кількість клієнтів додатків',
  'plans.pro.features.3': 'Валідація відвідувачів по SMS',
  'plans.pro.features.4': 'Аналітика ефективності',
  'plans.pro.features.5': 'Кастомні форми відвідувачів',
  'plans.pro.features.6': 'Іменовані лічильники',
  'plans.pro.features.7': 'Розширена підтримка',
  'plans.enterprise.features.first': 'Те саме, що і в Pro',
  'plans.enterprise.features.1': 'Необмежена кількість відвідувачів',
  'plans.enterprise.features.2': 'Необмежена кількість клієнтів додатків',
  'plans.enterprise.features.3': 'Інтеграції',
  'plans.enterprise.features.4': 'Кастомний SMS-шлюз',
  'plans.enterprise.features.5': 'Кастомний брендинг',
  'plans.enterprise.features.6': 'Кастомні функції',
  'plans.enterprise.features.7': 'Преміум підтримка',
  'plans.enterprise.promo': 'Нестандартний',
  'plans.switcher.monthly': 'Щомісяця',
  'plans.switcher.annualy': 'Щорічно',
  'plans.mostPopular': 'Найпопулярніші',
  'plans.private.submit': 'Поточний план',
  'plans.pro.submit': 'Виберіть',
  'plans.enterprise.submit': 'Зв`яжіться з нами',
  'plans.discont': '10% знижка',

  'plans.pro_monthly.cancelSubscription': 'Скасування місячного тарифного плану Pro',
  'plans.pro_annual.cancelSubscription': 'Скасувати річний план Pro',
  'plans.enterprise_monthly.cancelSubscription': 'Скасування Enterprise місячного тарифного плану',
  'plans.enterprise_annual.cancelSubscription': 'Скасувати річний план Enterprise',
  'plans.cancelSubscription.text':
    'Ви впевнені, що хочете скасувати свій план підписки? Скасування припинить будь-які майбутні платежі, і ваш доступ до преміум-функцій буде припинено в кінці поточного розрахункового циклу. Якщо у вас виникли запитання або проблеми, зверніться за допомогою до нашої служби підтримки.',
  'plans.keepSubscribtion': 'Зберегти підписку',

  'poorConection.title': 'Погане підключення до Інтернету',
  'poorConection.description':
    'На жаль, вашого інтернет-з’єднання недостатньо для роботи програми 2meters. Перевірте підключення до Інтернету або політику безпеки мережі. Будь ласка, врахуйте, що ми використовуємо веб-сокети, щоб передавати вам дані в реальному часі.',

  //Checkout
  'checkout.success': 'Оформлення пройшло успішно',
  'checkout.redirect': 'Ви будете перенаправлені через секунду',
  'checkout.falied': 'Помилка оплати. Зверніться до служби підтримки',

  'monitor.desktopViewHeader': 'Перегляд монітора',
  'monitor.desktopViewBody':
    'Ви можете встановити екран, щоб показувати відвідувачам стан черги. Відкрийте цю сторінку на комп’ютері, підключеному до екрана, і натисніте кнопку, щоб увійти в повноекранний режим',
  'monitor.fullscreen': 'Повноекранний режим',
  'monitor.switchTheMonitor': 'Переключити мову монітора',

  //Statistics
  'statistics.dashboard': 'Дашборд Статистика',
  'statistics.appointments': 'Записи',
  'statistics.thisMonth': 'Цього місяця',
  'statistics.thisWeek': 'Цього тижня',
  'statistics.today': 'Сьогодні',
  'statistics.booked': 'Заброньовано',
  'statistics.processed': 'Оброблено',
  'statistics.noShow': "Не з'явився",

  'statistics.period': 'Період',
  'statistics.week': 'Цього тижня',
  'statistics.month': 'Цього місяця',
  'statistics.custom': 'Спеціальний діапазон дат',
  'statistics.services': 'Послуги',
  'statistics.all': 'Усі послуги',
  'statistics.periodError': 'Дата початку має передувати даті завершення',

  'statistics.ticketsAndServices': 'Квитки та послуги',
  'statistics.noData': 'Немає даних за вибраний період',
  'statistics.totalAmount': 'Загальна кількість',
  'statistics.avgWaitingTime': 'Середній час очікування',
  'statistics.avgProcessingTime': 'Середній час обробки',
  'statistics.minutes': 'хвилин',

  'statistics.lastSevenDays': 'Останні 7 днів',
  'statistics.сonfirmationStatus': 'Статус підтвердження',
  'statistics.ticketType': 'Тип квитка',
  'statistics.ticketStatus': 'Статус квитка',
  'statistics.invited': 'Запрошено',
  'statistics.cancelled': 'Скасовано',
  'statistics.confirmed': 'Підтверджено',
  'statistics.pending': 'Очікується',
  'statistics.rejected': 'Відхилено',
  'statistics.queued': 'У черзі',
  'statistics.dashboardIsEmpty': 'Дашборд порожня',
  'statistics.dashboardIsEmptyDesc':
    'Щоб мати доступ до інформаційної панелі статистики, перейдіть до вищого рівня підписки.',
  'statistics.upgadePlan': 'Оновити план',

  //Scheduler

  'scheduler.error.endBeforeStart': 'Дата початку має бути перед датою завершення',
  'scheduler.error.notSameDay': 'Час початку та закінчення має бути в один день',
  'scheduler.error.visitTypeNotSet': 'Необхідно встановити тип послуги',
  'scheduler.error.invalidSchedule':
    'Недійсний розклад. Перевірте, щоб кінець періоду повторення не був раніше дати початку',
  'scheduler.numberOfAppointments': '{num} записів в цьому інтервалі',
  'scheduler.simple': 'Простий',
  'scheduler.advanced': 'Розширений',
  'scheduler.serviceType': 'Вид послуги',

  'scheduler.timePeriodForm.commit': 'Підтвердьте',
  'scheduler.timePeriodForm.repeat': 'Повторюйте',
  'scheduler.timePeriodForm.never': 'Ніколи',
  'scheduler.timePeriodForm.daily': 'Щодня',
  'scheduler.timePeriodForm.weekly': 'Щотижня',
  'scheduler.timePeriodForm.monthly': 'Щомісячно',
  'scheduler.timePeriodForm.yearly': 'Щорічно',
  'scheduler.timePeriodForm.repeatEvery': 'Повторювати кожен',
  'scheduler.timePeriodForm.days': 'день(и)',
  'scheduler.timePeriodForm.endRepeat': 'Завершіть повтор',
  'scheduler.timePeriodForm.on': 'На',
  'scheduler.timePeriodForm.after': 'Після',
  'scheduler.timePeriodForm.occurs': 'випадки',
  'scheduler.timePeriodForm.weeksOn': 'тиждень(и) на:',
  'scheduler.timePeriodForm.month': 'місяць(и)',
  'scheduler.timePeriodForm.everyMonth': 'кожного місяця',
  'scheduler.timePeriodForm.the': '',
  'scheduler.timePeriodForm.first': 'Перший',
  'scheduler.timePeriodForm.second': 'Другий',
  'scheduler.timePeriodForm.third': 'Третій',
  'scheduler.timePeriodForm.fourth': 'Четвертий',
  'scheduler.timePeriodForm.last': 'Останній',
  'scheduler.timePeriodForm.years': 'рік(и)',
  'scheduler.timePeriodForm.of': '',
  'scheduler.timePeriodForm.every': 'Кожен',
  'scheduler.timePeriodForm.timePeriods': 'Періоди часу',
  'scheduler.timePeriodForm.headerCaption':
    'Будь ласка, визначте кожен період часу, коли ви надаєте свою конкретну послугу. Вільні слоти за цей період будуть розраховані автоматично залежно від значення тривалості слоту.',
  'scheduler.timePeriodForm.servicesAndSlots': 'Призначення сервісу та визначення слотів',
  'scheduler.timePeriodForm.createButton': 'Створити',

  'scheduler.confirmCancel': 'Ви дійсно хочете відхилити незбережені зміни?',
  'scheduler.confirmDelete': 'Ви дійсно хочете видалити цей період часу?',
  'scheduler.cancelButton': 'Скасувати',
  'scheduler.deleteButton': 'Видалити',
  'scheduler.discardButton': 'Відкинути',

  'scheduler.editRecurrenceMenu.editTitle': 'Редагувати повторювані періоди часу',
  'scheduler.editRecurrenceMenu.deleteTitle': 'Видалити повторювані періоди часу',
  'scheduler.editRecurrenceMenu.cancelButton': 'Скасувати',
  'scheduler.editRecurrenceMenu.commitButton': 'Підтвердити',
  'scheduler.editRecurrenceMenu.allTimePeriods': 'Усі періоди часу',
  'scheduler.editRecurrenceMenu.currentAndFollowingTimePeriods': 'Цей та наступні періоди часу',
  'scheduler.editRecurrenceMenu.currentTimePeriods': 'Цей період часу',

  // Managers

  'manager.title': 'Менеджери',
  'manager.description':
    'Тут ви можете додавати та видаляти людей, які мають доступ до цього місця. Менеджери можуть маніпулювати зустрічами та чергою з перегляду лічильника. Але вони не можуть редагувати налаштування місця.',
  'manager.add': 'Додати менеджера',
  'manager.remove': 'Видалити',
  'manager.admin': 'Адміністратор',
  'manager.manager': 'Менеджер',
  'manager.name': 'Ім’я',
  'manager.email': 'Електронна пошта',
  'manager.role': 'Роль',
  'manager.invitationSent': 'Запрошення надіслано',
  'manager.sendInvite': 'Надіслати запрошення',
  'manager.invitePlaceholder': 'Запросити нових користувачів',
  'manager.table.name': 'Ім’я',
  'manager.table.email': 'Електронна пошта',
  'manager.table.role': 'Роль',
  'manager.table.dateAdded': 'Дату додано',
}
