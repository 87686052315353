import { MessageRecord, MessageType, MessageLogTotals } from '@2meters/shared'
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  startAfter,
  limitToLast,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { db, functions } from './firebase-init'
import { prepareRequest, readAs, resultAs } from './firebase-utils'

interface GetMessageLogOptions {
  from?: Date
  until?: Date
  messageType?: MessageType[]
  limit?: number
  offset?: number
}

const getMessageLog = async (
  userId: string,
  { from, until, messageType, limit, offset }: GetMessageLogOptions
): Promise<MessageRecord[]> => {
  const q1 = query(collection(db, 'queue'), where('userId', '==', userId))
  const places = await getDocs(q1).then(snapshot => snapshot.docs.map(doc => doc.id))

  if (places.length == 0) return []

  let conditions = [where('placeId', 'in', places)]
  if (from) {
    conditions = [...conditions, where('createdAt', '>=', from)]
  }
  if (until) {
    conditions = [...conditions, where('createdAt', '<=', until)]
  }
  conditions = [...conditions, orderBy('createdAt', 'desc')] as any
  if (offset) {
    conditions = [...conditions, startAfter(offset)] as any
  }
  if (limit) {
    conditions = [...conditions, limitToLast(limit)] as any
  }

  let q2 = query(collection(db, 'messageLog'), ...conditions)

  if (messageType) {
    return await getDocs(q2).then(snapshot =>
      snapshot.docs
        .filter(doc => messageType.includes(doc.get('type')))
        .map(doc => readAs<MessageRecord>(doc))
    )
  }

  return await getDocs(q2).then(snapshot => snapshot.docs.map(doc => readAs<MessageRecord>(doc)))
}

const getMessageLogTotals = async (
  from?: Date,
  until?: Date,
  messageType?: MessageType
): Promise<MessageLogTotals> =>
  httpsCallable(
    functions,
    'messenger-getMessageLogTotals'
  )(prepareRequest({ from, until, messageType }))
    .then(result => {
      return resultAs<MessageLogTotals>(result.data)
    })
    .catch(e => {
      console.error(`Message log totals were not fetched: ${e}`)
      return Promise.reject(e)
    })

export const billingLog = {
  getMessageLog,
  getMessageLogTotals,
}
