import { backend } from 'api/Firebase'
import { ProgressWithBackground } from 'components/Preloader'
import { useEffect } from 'react'
import { useCurrentUser } from 'store/useCurrentUser'

export const withAuthentication = Component => {
  const WithAuthentication = ({ ...rest }) => {
    const { authUser, setAuthUser } = useCurrentUser()

    useEffect(() => {
      return backend.onAuthUserListener(
        authUser => {
          if (process.env.NODE_ENV === 'development') {
            console.log(
              `Signed in user. userid=${authUser.uid} isAnonymous=${authUser.isAnonymous}`
            )
          }
          if (window.pendo)
            window.pendo.initialize({
              visitor: {
                id: authUser.uid,
              },
              account: {
                id: authUser.uid,
              },
            })
          setAuthUser(authUser)
          // localStorage.setItem('authUser', JSON.stringify(authUser));
        },
        () => {
          localStorage.removeItem('authUser')
          if (process.env.NODE_ENV === 'development') {
            console.log('No Authenticated User, authenticating anonymously')
          }
          backend.doSignInAnonymously().catch(error => {
            console.error(error.message)
          })
          setAuthUser(null)
        }
      )
    }, [setAuthUser, authUser])

    return authUser && authUser.uid ? <Component {...rest} /> : <ProgressWithBackground />
  }

  return WithAuthentication
}
